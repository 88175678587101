import React, { PureComponent, Fragment } from 'react';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortUp,
  faSortDown
} from '@fortawesome/free-solid-svg-icons';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ProgressBar from './ProgressBar';
import Style from './css/InventoryPageViewComponent.module.css';
import GridCard from '../GridCard';
import ListView from '../ListView';
import GridView from '../GridView';
import SortingIcon from '../Order/SortingIcon';
import {
  getFormattedDate,
  getNumberOfDaysToDate,
  getImage,
  getSmallCustomerLogo
} from '../../../common/Utilities';

const healthColorMap = {
  Critical: '#F62227',
  Warning: '#FFCF0F',
  Normal: '#C6CBD4',
  Healthy: '#00B200',
  Overstock: '#4298FA',
  Empty: '#808080'
};

const SortIcon = {
  sortFunc: (items, attribute, order, groupby, status) => {
    if (groupby === 'locations' && (attribute === 'identifier' || attribute === 'healthStatus')) {
      attribute = 'name';
    } else if (
      (groupby === 'type' && attribute === 'identifier') ||
      (!groupby && attribute === 'healthStatus')
    ) {
      attribute = 'identifier';
    }
    let statusAttribute = '';
    switch (status) {
      case 'expiration':
        statusAttribute = 'expiryStatusNumber';
        break;
      case 'stock':
        statusAttribute = 'stockStatusNumber';
        break;
    }
    const itemList = items.items ? items.items : items;
    return itemList.sort((a, b) => {
      return sortByAttribute(a, b, attribute, order, statusAttribute);
    });
  }
};

const sortByAttribute = (a, b, attribute, order, status) => {
  let fieldA = a[attribute];
  let fieldB = b[attribute];
  if (attribute === 'healthStatus') {
    fieldA = a.healthStatus[status];
    fieldB = b.healthStatus[status];
  }
  // convert to number type if possible
  if (!isNaN(fieldA) || !isNaN(fieldB)) {
    fieldA = Number(fieldA);
    fieldB = Number(fieldB);
  }

  if (attribute !== 'stock') {
    // check for null fields
    if (!fieldA) {
      return 1;
    }
    if (!fieldB) {
      return -1;
    }
  }

  switch (typeof fieldA) {
    case 'number':
      return order === 'ascending' ? fieldA - fieldB : fieldB - fieldA;
    default:
      return order === 'ascending'
        ? String(fieldA).localeCompare(String(fieldB))
        : String(fieldB).localeCompare(String(fieldA));
  }
};

export default class InventoryPageViewComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      topMetricsDataList: {},
      bottomMetricsDataList: {},
      filterString: '',
      dataList: this.props.dataList,
      displayListView: false,
      currentSort: {
        identifier: 'ascending',
        stock: 'ascending',
        healthStatus: 'descending'
      },
      attribute: 'healthStatus', // default sort
      healthDetermineBy: 'stock'
    };
  }

  listViewDataListControl = (dataList, groupby) => {
    dataList.forEach((item) => {
      if (!groupby) {
        if (item.expiryDate) {
          const daysDiff = getNumberOfDaysToDate(item.expiryDate);
          item.expiryStatus = daysDiff <= 0 ? 'Item has Expired' : `${daysDiff} days`;
          item.formattedExpiration = getFormattedDate(new Date(item.expiryDate), 'YYYY-MM-DD');
        } else {
          item.formattedExpiration = 'No Expiry Date';
          item.expiryStatus = 'No Expiry Date';
        }

        item.lastUpdated = getFormattedDate(new Date(item.lastUpdatedTime), 'YYYY-MM-DD');
      }

      item.stockedItems = item.stock - item.incoming;
    });
    return dataList;
  };

  exportXlsx = () => {
    const sortIcon = SortIcon;
    const { groupby } = this.props;
    const { currentSort, attribute } = this.state;

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.csv';
    const headerStructureList = this.getHeaderStructure(groupby);
    let renderData = this.filterDataList();
    renderData = sortIcon.sortFunc(renderData, attribute, currentSort[attribute], groupby);
    const data = this.listViewDataListControl(renderData);
    const dataJson = data.map((each) => {
      const one = {};
      headerStructureList.forEach((eachHeader) => {
        one[eachHeader.label] = Array.isArray(each[eachHeader.id])
          ? each[eachHeader.id].length
            ? each[eachHeader.id].join()
            : '-'
          : each[eachHeader.id];
      });
      return one;
    });
    const temp = XLSX.utils.json_to_sheet(
      dataJson,
      headerStructureList.map((eachHeader) => {
        return eachHeader.label;
      })
    );
    const wb = { Sheets: { data: temp }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const thing = new Blob([excelBuffer], { type: fileType });
    const date = new Date();
    date.setHours(date.getHours() - 24);
    const dateString = date
      .toISOString()
      .replace(/-/g, '_')
      .split('T')[0];
    FileSaver.saveAs(thing, `InventoryDataSheet_${dateString + fileExtension}`);
  };

  getHeaderStructure = (groupby) => {
    const { healthDetermineBy } = this.state;
    switch (groupby) {
      case 'type':
        if (healthDetermineBy === 'stock') {
          return [
            { id: 'identifier', title: true, label: 'Item Name' },
            { id: 'incoming', label: 'Incoming Items' },
            { id: 'stockedItems', label: 'Stocked Items' },
            { id: 'stock', label: 'Total Items' },
            { id: 'threshold', label: 'Min Stock' },
            { id: 'maxThreshold', label: 'Max Stock' }
          ];
        }
        return [
          { id: 'identifier', title: true, label: 'Item Name' },
          { id: 'expired', label: 'Expired Count' },
          { id: 'soonToExpire', label: 'Soon to Expire' },
          { id: 'stockedItems', label: 'Stocked Items' }
        ];

      case 'locations':
        return [
          { id: 'name', title: true, label: 'Name' },
          { id: 'stockedItems', label: 'Stocked Count' },
          { id: 'incoming', label: 'Incoming Count' },
          { id: 'expired', label: 'Expired Items' },
          { id: 'soonToExpire', label: 'Soon to Expire' },
          { id: 'outOfStockCount', label: 'Out of Stock Count' },
          { id: 'belowThresholdCount', label: 'Low Stock Count' }
        ];
      default:
        return [
          { id: 'tag', label: 'EPC' },
          { id: 'lotNumber', label: 'Lot Number' },
          { id: 'expiryStatus', label: 'Days till Expired' },
          { id: 'formattedExpiration', label: 'Expiration Date' },
          { id: 'lastUpdated', label: 'Last Updated' },
          { id: 'location', label: 'Current Location' },
          { id: 'targetLocationName', label: 'Target Location' }
        ];
    }
  };

  renderGridCardComponentControl = (groupby) => {
    switch (groupby) {
      case 'type':
        return this.renderTypeCard;
      case 'locations':
        return this.renderLocationsCard;
    }
  };

  renderTypeCard = (each) => {
    const { handleClickType } = this.props;
    const color = this.getHealthColor(each.healthStatus);
    const borderColorStyle = {
      borderTop: `15px solid ${color}`
    };
    return (
      <GridCard
        topComponent={this.typeGridCardTopComponent(each)}
        onClick={handleClickType}
        solution="inventory"
        key={each.id}
        id={each.id}
        style={borderColorStyle}
      />
    );
  };

  renderLocationsCard = (each) => {
    const { handleClickLocation } = this.props;
    const borderColorStyle = {
      borderTop: `15px solid ${healthColorMap[this.calculateHealth(each)]}`
    };
    return (
      <GridCard
        topComponent={this.renderLocationCardTop(each)}
        onClick={handleClickLocation}
        solution="inventory"
        key={each.id}
        id={each.id}
        style={borderColorStyle}
      />
    );
  };

  renderLocationCardTop = (each) => {
    return (
      <Fragment>
        <div className={Style.details_location}>
          <p className={Style.name_location}>{each.name}</p>
          <div className={Style.location_details}>
            <label>
              Out of Stock Count:
              {' '}
              {each.outOfStockCount}
                        </label>
            <label>
              Low Stock Count:
              {' '}
              {each.belowThresholdCount}
                        </label>
            <label>
              Expired Items:
              {' '}
              {each.expired}
                        </label>
            <label>
              Soon to Expired:
              {' '}
              {each.soonToExpire}
                        </label>
          </div>
        </div>
      </Fragment>
    );
  };

  calculateHealth = (location) => {
    let hasWarnings = false;
    for (let i = 0; i < location.itemType.length; i++) {
      const current = location.itemType[i];
      const physicalCount = current.stock - current.incoming;
      if (physicalCount === 0) {
        return 'Critical';
      }
      if (physicalCount < current.threshold) {
        hasWarnings = true;
      }
    }
    return hasWarnings ? 'Warning' : 'Healthy';
  };

  typeGridCardTopComponent = (each) => {
    // set threshold in progress bar
    const { healthDetermineBy } = this.state;
    const lowStockThreshold = each.threshold;
    const image = getImage(`${each.identifier}.png`) || getSmallCustomerLogo();

    return (
      <Fragment>
        <img className={Style.img} src={image} alt="Item" />
        <pre className={Style.name}>{each.identifier}</pre>
        <ProgressBar
          min={lowStockThreshold}
          value={each.stock}
          incoming={each.incoming}
          healthColor={this.getHealthColor(each.healthStatus)}
          healthDetermineBy={healthDetermineBy}
          expired={each.expired}
          soonToExpire={each.soonToExpire}
        />
      </Fragment>
    );
  };

  cardBottomComponent = (expired, soonToExpire) => {
    return (
      <Fragment>
        <div className={Style.bottom_group_small}>
          <p className={Style.bottom_group_title}>Expired:</p>
          <p className={Style.bottom_group_data}>{expired}</p>
        </div>
        <div className={Style.bottom_group_small}>
          <p className={Style.bottom_group_title}>Expires in 2 Weeks:</p>
          <p className={Style.bottom_group_data}>{soonToExpire}</p>
        </div>
      </Fragment>
    );
  };

  getHealthColor(healthStatus) {
    const { healthDetermineBy } = this.state;
    if (healthDetermineBy === 'expiration') {
      return healthColorMap[healthStatus.expiryStatusString];
    }
    if (healthDetermineBy === 'stock') {
      return healthColorMap[healthStatus.stockStatusString];
    }
  }

  getSortSchema = () => {
    const { groupby } = this.props;
    const { currentSort } = this.state;
    const sortSchema = [
      {
        name: groupby === 'type' ? 'Item Type Name' : 'Location Name',
        attribute: 'identifier',
        icon: currentSort.identifier === 'ascending' ? faSortAlphaDown : faSortAlphaUp
      }
    ];
    if (groupby === 'type') {
      sortSchema.push({
        name: 'Total Units',
        attribute: 'stock',
        icon: currentSort.stock === 'ascending' ? faSortNumericDown : faSortNumericUp
      });
      sortSchema.push({
        name: 'Status',
        attribute: 'healthStatus',
        icon: currentSort.healthStatus === 'ascending' ? faSortUp : faSortDown
      });
    }
    return sortSchema;
  };

  sortClick = (attribute) => {
    const { currentSort } = this.state;
    let nextSort = '';
    currentSort[attribute] === 'ascending' ? (nextSort = 'descending') : (nextSort = 'ascending');
    this.setState({
      currentSort: {
        ...this.state.currentSort,
        [attribute]: nextSort
      },
      attribute
    });
  };

  getPlaceholderText = () => {
    const { groupby } = this.props;
    switch (groupby) {
      case 'locations':
        return '  Filter by location name';
      case 'type':
        return '  Filter by item name';
      default:
        return '  Filter by EPC';
    }
  };

  filterDataList = () => {
    const { dataList, groupby, filterString } = this.props;
    const searchText = filterString;
    let filteredList = dataList;
    if (searchText !== '') {
      const data = dataList;
      const searchString = searchText.toUpperCase();
      filteredList = data.reduce((array, data) => {
        if (
          (data.identifier && data.identifier.toUpperCase().includes(searchString)) ||
          (data.name && data.name.toUpperCase().includes(searchString))
        ) {
          array.push(data);
        } else if (
          !groupby &&
          ((data.tag && data.tag.includes(searchString)) ||
            (data.lotNumber && data.lotNumber.toString().includes(searchString)) ||
            (data.location && data.location.toUpperCase().includes(searchString)))
        ) {
          array.push(data);
        }
        return array;
      }, []);
    }
    return filteredList;
  };

  renderSwitchIcons = () => {
    const { displayListView } = this.state;
    const { groupby } = this.props;
    if (displayListView && groupby) {
      return (
        <ViewModuleIcon
          className={Style.icon_button}
          onClick={() => {
            this.setState({ displayListView: !displayListView });
          }}
        />
      );
    }
    if (groupby) {
      return (
        <FormatListBulletedIcon
          className={Style.icon_button}
          onClick={() => {
            this.setState({ displayListView: !displayListView });
          }}
        />
      );
    }
  };

  renderExpirationSwitch = () => {
    const { healthDetermineBy } = this.state;
    return (
      <div className={Style.expiration_switch_container}>
        Show status for:
        <button
          className={
            healthDetermineBy === 'stock'
              ? `btn ${Style.switch_button} ${Style.selected_button}`
              : `btn ${Style.switch_button} ${Style.not_selected_button}`
          }
          onClick={() => {
            this.setState({ healthDetermineBy: 'stock' });
          }}
        >
          Stock
        </button>
        <button
          className={
            healthDetermineBy === 'expiration'
              ? `btn ${Style.switch_button} ${Style.selected_button}`
              : `btn ${Style.switch_button} ${Style.not_selected_button}`
          }
          onClick={() => {
            this.setState({ healthDetermineBy: 'expiration' });
          }}
        >
          Expiration
        </button>
      </div>
    );
  };

  render() {
    const {
      groupby,
      filterHandler,
      filterString,
      handleClickLocation,
      handleClickType
    } = this.props;
    const { displayListView, attribute, currentSort, healthDetermineBy } = this.state;
    const sortIcon = SortIcon;
    let renderData = this.filterDataList();
    renderData = sortIcon.sortFunc(
      renderData,
      attribute,
      currentSort[attribute],
      groupby,
      healthDetermineBy
    );
    return (
      <Fragment>
        <div className={Style.filter_group}>
          <input
            className={Style.filter_bar}
            value={filterString}
            placeholder={this.getPlaceholderText()}
            onChange={(event) => filterHandler(event)}
          />
          <GetAppIcon className={Style.icon_button} onClick={this.exportXlsx} />
          {!displayListView && groupby && (
            <SortingIcon
              sortFunction={this.sortClick.bind(this)}
              sortSchema={this.getSortSchema()}
              attribute={attribute}
            />
          )}
          {this.renderSwitchIcons()}
        </div>
        {groupby === 'type' && this.renderExpirationSwitch()}
        {!groupby || displayListView ? (
          <ListView
            handleClick={groupby === 'locations' ? handleClickLocation : handleClickType}
            dataList={this.listViewDataListControl(renderData, groupby)}
            headerStructureList={this.getHeaderStructure(groupby)}
            onboarding
          />
        ) : (
          <GridView
            dataList={renderData}
            renderComponent={this.renderGridCardComponentControl(groupby)}
          />
        )}
      </Fragment>
    );
  }
}
