import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { StyledTextField } from './Forms/InputField';


export default function MUiSearchDropdown({searchMap, label, selectItem}) {

  const handleSelection = (event, value) => {
    if (value) {
      selectItem(value.id)
    }
  }
  return (
    <Autocomplete
      options={searchMap}
      getOptionLabel={option => option.displayString }
      style={{width: "100%"}}
      onChange={(event, value) => handleSelection(event, value)}
      // this has same style with InputField.js
      renderInput={params =>
        <StyledTextField {...params} label={label} variant="outlined" />}
    />
  );
}

