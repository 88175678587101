import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

// https://material-ui.com/components/tables/#tables

const RowPerPageOptions = [5, 10, 25];

const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort, headerStructureList }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headerStructureList.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id && (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  rowTitle: {
    color: '#166ed5',
    cursor: 'pointer',
    fontSize: '16px'
  },
  errorRow: {
    borderColor: 'red',
    borderStyle: 'solid'
  },
  redColor: {
    color: '#F62227'
  }
}));

const ListView = ({
  headerStructureList = [],
  dataList = [],
  onboarding,
  handleClick,
  handleDelete,
  defaultSort = 'name'
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(defaultSort);
  const [page, setPage] = React.useState(0);
  const [stateDataList, setDataList] = React.useState(dataList);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    RowPerPageOptions[Math.floor(RowPerPageOptions.length / 2)]
  );
  if (stateDataList !== dataList) {
    setDataList(dataList);
    setPage(0);
  }

  const desc = (a, b, order_by) => {
    if (Array.isArray(a[order_by]) || Array.isArray(b[order_by])) {
      const statusLevelCalculator = (id) => {
        switch (id) {
          case 'delayed':
          case 'missing':
            return 4;
          case 'expired':
          case 'expedited':
            return 2;
          case 'on-Time':
          case 'expiringSoon':
            return 1;
          default:
            return 0;
        }
      };
      let aLevel = 0;
      let bLevel = 0;
      a[order_by].forEach((each) => {
        aLevel += statusLevelCalculator(each.id);
      });
      b[order_by].forEach((each) => {
        bLevel += statusLevelCalculator(each.id);
      });
      return aLevel < bLevel ? 1 : -1;
    }
    if (isNaN(a[order_by]) || isNaN(b[order_by])) {
      return String(a[order_by]).localeCompare(b[order_by], undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
    return Number(a[order_by]) - Number(b[order_by]);
  };

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const or = cmp(a[0], b[0]);
      if (or !== 0) return or;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getSorting = (or) => {
    return or === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => desc(b, a, orderBy);
  };
  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, stateDataList.length - page * rowsPerPage);
  if (!stateDataList.length) {
    return (
      <div
        style={{
          padding: '70px',
          color: 'gray',
          textAlign: 'center'
        }}
      >
        No Items
      </div>
    );
  }
  return (
    <div style={{ padding: '10px' }}>
      <div className={onboarding ? undefined : classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headerStructureList={headerStructureList}
          />
          <TableBody>
            {stableSort(stateDataList, getSorting(order))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const rowKey = onboarding ? row.identifier : row.key || row.id;
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={rowKey}
                    classes={row.error && { root: classes.errorRow }}
                  >
                    {headerStructureList.map((eachHeader) => {
                      return (
                        <TableCell
                          key={`${row.id} ${eachHeader.id}`}
                          onClick={eachHeader.title && handleClick}
                          name={
                            eachHeader.renderComponent
                              ? eachHeader.renderComponent(row[eachHeader.id])
                              : row[eachHeader.id]
                          }
                          id={row.id}
                          scope="row"
                          padding="default"
                          classes={eachHeader.title && { root: classes.rowTitle }}
                          align="left"
                        >
                          {eachHeader.renderComponent
                            ? eachHeader.renderComponent(row[eachHeader.id])
                            : row[eachHeader.id]}
                        </TableCell>
                      );
                    })}
                    {handleDelete && (
                      <TableCell
                        key={rowKey}
                        onClick={() => handleDelete(row)}
                        scope="row"
                        padding="default"
                        classes={{ root: classes.rowTitle }}
                        align="left"
                      >
                        <DeleteIcon className={classes.redColor} />
                      </TableCell>
                    )}
                    {row.errorMessage && (
                      <TableCell
                        key={rowKey}
                        scope="row"
                        padding="default"
                        classes={{ root: classes.redColor }}
                        align="left"
                      >
                        <div>{row.errorMessage}</div>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={RowPerPageOptions}
        component="div"
        count={stateDataList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page'
        }}
        nextIconButtonProps={{
          'aria-label': 'next page'
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default ListView;
