import axios from 'axios';
import { mockResponse, shouldMockRequest } from './serve-mocks';

const uuid = require('uuid');

const mockUrl = 'https://zq7p6gvl5d.execute-api.us-east-1.amazonaws.com/dev/mocked';

axios.interceptors.request.use((config) => {
  const mustMock = shouldMockRequest(config);

  if (mustMock) {
    const overriddenConfig = { ...config };
    const { url, data, method } = config;
    const requestId = uuid.v4();
    // console.log(`==> Mocking Request[Id:${requestId}]:\n${method} ${url}\nPayload: ${data}`);

    overriddenConfig.originUrl = config.url;
    overriddenConfig.url = mockUrl;
    overriddenConfig.isMockedRequest = true;
    overriddenConfig.mockRequestId = requestId;
    return overriddenConfig;
  }

  return config;
});

axios.interceptors.response.use((response) => {
  const { config: request } = response;
  const { isMockedRequest, mockRequestId } = request;

  if (isMockedRequest) {
    request.url = request.originUrl;
    const mockResponseData = mockResponse(request);
    const overriddenResponse = { ...response, ...{ data: mockResponseData } };

    const { data: mockedData } = overriddenResponse;
    console.log(`==> Mocked Response[Request#${mockRequestId}]:\n${JSON.stringify(mockedData)}`);

    return overriddenResponse;
  }
  return response;
});
