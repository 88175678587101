import { AbstractApi } from '../abstract-api';

const webClientConfig = {
  modelMap: {
    location: {
      properties: {
        id: {
          __updatable: false,
          displayName: 'Location Id'
        },
        name: {
          __updatable: true,
          __addable: true,
          displayName: 'Name'
        },
        description: {
          __updatable: true,
          __addable: true,
          optional: true,
          displayName: 'Description'
        }
      }
    },
    detector: {
      displayName: 'Detector',
      propertyOrders: ['vid', 'class'],
      properties: {
        vid: {
          __addable: true,
          displayName: 'Detector #'
        },
        class: {
          __addable: true,
          __updatable: false,
          displayName: 'Detector Type',
          options: [
            { key: 'Handheld', value: 'Handheld Reader' },
            {
              key: 'Mounted',
              value: 'Mounted Reader',
              properties: {
                action: {
                  displayName: 'Detector Actions',
                  options: [
                    {
                      key: 'default_mounted_reader',
                      value: 'Default'
                    },
                    {
                      key: 'endTrackingSession',
                      value: 'Mark as Complete'
                    },
                    {
                      key: 'sameDetectorEndTrackingSession',
                      value: 'Entry / Exit doorway'
                    }
                  ]
                }
              }
            }
          ]
        },
        vendor: {
          displayName: 'Vendor'
        }
      }
    }
  },
  serviceMap: {
    xGraph: {
      name: 'XemelgoService',
      region: 'us-west-2',
      endpoint: 'https://rq57siqii9.execute-api.us-west-2.amazonaws.com/dev',
      // endpoint: 'https://44etjow313.execute-api.us-west-2.amazonaws.com/prod',
      apiVersion: 'v2'
    },
    xMain: {
      name: 'xemelgo',
      endpoint: 'https://1of819bdf2.execute-api.us-west-2.amazonaws.com/ct-prod',
      region: 'us-west-2'
    }
  },
  webClient: {
    landingUrl: '/asset',
    appConfigurationMap: {
      myFacility: {
        modelMap: {
          plant: {
            __ref__: 'location',
            override: {
              displayName: 'Plant',
              containsLocationModel: 'department',
              category: {
                name: 'Plant',
                displayName: 'Plant',
                groupDisplayName: 'Plants'
              }
            }
          },
          department: {
            __ref__: 'location',
            override: {
              displayName: 'Department',
              category: {
                name: 'Department',
                displayName: 'Department',
                groupDisplayName: 'Departments'
              },
              canAttachDetector: true,
              locatedInModel: 'plant'
            }
          },
          detector: true
        },
        tabs: ['locations', 'detectors'],
        featureMap: {
          listLocations: {
            modelOrders: ['plant', 'department'],
            featureMap: {
              addResource: {
                modelMap: {
                  department: true,
                  plant: true,
                  detector: {
                    __ref__: 'detector',
                    override: {
                      vid: {
                        optional: true,
                        optionallyDependsOn: ['class']
                      },
                      class: {
                        optional: true,
                        optionallyDependsOn: ['vid']
                      }
                    }
                  }
                }
              }
            }
          },
          listDetectors: {
            featureArgumentMap: {
              addResource: {
                location: 'department'
              },
              editResource: {
                location: 'department'
              }
            }
          }
        }
      }
    }
  }
};

class ConfigurationApi extends AbstractApi {
  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  canMock(payload, headers) {
    return true;
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  execute(payload, headers, method) {
    return {
      configData: {
        ...webClientConfig,
        features: {
          analysis: [],
          tracking: {
            itemDetailView: {
              'Work Order Traveller': ['ShowExpectedRoute']
            },
            defaultView: {
              ColorTechCustomView: {}
            },
            trackingViews: {
              ColorTechCustomView: {}
            }
          },
          notifications: ['Backlog', 'Idle', 'Missing Asset', 'Low Stock']
        },
        components: {
          item: {
            ItemRelationshipConstraints: {
              'Work Order Traveller': {
                contains: {
                  part: {
                    quantity: 'many'
                  }
                },
                part_of: []
              }
            },
            ItemTypes: {
              'Work Order Traveller': {
                itemDescriptionTemplate: {
                  identifier: {
                    type: 'String',
                    friendlyAlias: 'Work Order Id'
                  },
                  numberOfContainers: {
                    type: 'Number',
                    subType: 'non-negative Integer',
                    friendlyAlias: 'Number Of Containers'
                  },
                  quantity: {
                    type: 'Number',
                    subType: 'non-negative Integer',
                    friendlyAlias: 'Quantity'
                  }
                },
                class: 'Traveller'
              }
            },
            TrackedItemTypes: ['Work Order Traveller']
          },
          location: {
            containmentConstraints: {
              Warehouse: [],
              Department: [],
              Facility: ['Department']
            },
            possibleTypes: ['Facility', 'Department', 'Warehouse']
          },
          partner: {
            possiblePartnerClasses: ['Customer']
          },
          production: {
            TaskTypes: ['Work Order'],
            TaskTypeToItemTypeMap: {
              'Work Order': {
                output: [],
                input: [],
                represented_by: ['Work Order Traveller']
              }
            }
          },
          tracking: {
            conditionsForMissing: {
              maxShippingDuration: 57600000,
              maxSameBatchDuration: 3600000
            },
            vendorToProtocol: {
              TSL: 'API',
              Brady: 'MQTT'
            },
            sensorProfileTypeToVendor: {
              RFID: ['Brady']
            },
            possibleDetectorProfileTypes: ['Receiving', 'Shipping'],
            detectorTypeToVendor: {
              Handheld: ['TSL'],
              Mounted: ['Brady']
            },
            possibleDetectorLocations: ['Department', 'Warehouse']
          },
          expectedRoute: {}
        },
        solutionConfiguration: {
          // this is temporary placement, due to the fact that config client only retrieve a set of defined properties.
          trackedObjectDataViewerConfiguration: {
            trackedObjectDataViewerPageHeader: 'Orders',
            trackedObjectDataViewerPageDescription: 'Select an order number to see more details'
          },
          manageOrganizationConfiguration: {
            tabs: ['Plant'],
            defaultTab: 0
          },
          trackPageConfiguration: {
            groupbyEnabled: true,
            possibleGroupbyTabs: ['Location'],
            possibleTabs: ['All Orders', 'All Parts'],
            defaultView: 'listview',
            defaultTab: 'All Orders'
          },
          staticTiles: [
            {
              count: 0,
              name: 'Missing Orders',
              id: 'missingOrder',
              title: 'Missing',
              alert: 'missing'
            },
            {
              count: 0,
              name: 'Misrouted Orders',
              id: 'misroutedOrder',
              title: 'Misrouted',
              alert: 'misrouted'
            }
          ]
        },
        solutionType: ['order', 'asset', 'inventory'],
        infrastructure: {
          apiGateway: {
            URL: 'https://1of819bdf2.execute-api.us-west-2.amazonaws.com/ct-prod',
            REGION: 'us-west-2'
          },
          xGraph: {
            region: 'us-west-2',
            endpoint: 'https://rq57siqii9.execute-api.us-west-2.amazonaws.com/dev',
            apiVersion: 'v2'
          },
          imageStorage: {
            URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-gt',
            REGION: 'us-west-2'
          }
        },
        customerName: 'mockDemo'
      },
      lastUpdatedTime: 1573956891264,
      versionNumber: '0.0.0',
      tenantId: '7704'
    };
  }
}

const api = new ConfigurationApi();
export { api };
