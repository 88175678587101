import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';
import { Link } from 'react-router-dom';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import AuthService from '../../services/AuthService';
import XemelgoLogo from '../../logo/XemelgoLogo.png';
import SearchBar from '../../pages/header/TrackedObjectSearchBar';
import HeaderComponentStyle from './HeaderComponent.module.css';
import { useSoftCacheService } from '../../services/soft-cache-service';
import { LocalCacheService } from '../../services/local-cache-service';

const HeaderComponent = ({ containerStyle }) => {
  const [username, setUsername] = useState('guest');
  const softCacheService = useSoftCacheService();

  useEffect(() => {
    const userProfile = LocalCacheService.loadUserProfile();
    if (userProfile) {
      const { profile = {} } = userProfile;
      const uname = profile.firstName || profile.email || username;
      setUsername(uname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UserDropdown = () => {
    return (
      <MDBDropdown>
        <MDBDropdownToggle
          nav
          caret
          id="custom-nav"
          className={HeaderComponentStyle.profile}
          tabIndex={-1}
        >
          <div className={HeaderComponentStyle.profile_name}>{`Hello, ${username}`}</div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdownmenu">
          <Link to="/profile">
            <MDBDropdownItem>Profile</MDBDropdownItem>
          </Link>
          <a href="/login" className={HeaderComponentStyle.logout}>
            <MDBDropdownItem
              onClick={() => {
                AuthService.logout();
                softCacheService.clearCaches();
                LocalCacheService.clearAllCaches();
              }}
              className={HeaderComponentStyle.logout}
            >
              Log Out
            </MDBDropdownItem>
          </a>
        </MDBDropdownMenu>
      </MDBDropdown>
    );
  };

  const NotificationDropdown = () => {
    return <NotificationsNoneRoundedIcon fontSize="large" />;
  };

  return (
    <div className={containerStyle}>
      <a href="/" className={HeaderComponentStyle.home_icon_container}>
        <img
          src={XemelgoLogo}
          alt="Unable to load this icon"
          className={HeaderComponentStyle.home_image_style}
        />
      </a>
      <div className={HeaderComponentStyle.search_bar_container}>
        <div className={HeaderComponentStyle.search_bar}>
          <SearchBar />
        </div>
      </div>
      <div className={HeaderComponentStyle.user_actions_container}>
        <div className={HeaderComponentStyle.user_actions}>
          <NotificationDropdown />
          <UserDropdown />
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;

HeaderComponent.defaultProps = {
  containerStyle: null
};

HeaderComponent.propTypes = {
  containerStyle: PropTypes.string
};
