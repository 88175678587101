const ApiRouter = {
  query: 'x-graph',
  mutate: 'x-graph',
  'restock-requests': 'request-inventory',
  'stock-items': 'request-inventory'
};

/**
 * @param api
 * @returns {null|AbstractApi}
 */
const getMockApi = (api) => {
  const serviceName = ApiRouter[api];
  if (!serviceName) {
    return null;
  }

  try {
    require('./backends/request-inventory/restock-requests');
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const module = require(`./backends/${serviceName}/${api}`);
    if (!module) {
      return null;
    }

    return module.api;
  } catch (e) {
    return null;
  }
};

const backendMockExists = (api, payload, headers, method, apiSegments) => {
  const mockApi = getMockApi(api);

  if (!mockApi) {
    return false;
  }

  return mockApi.canMock(payload, headers, method, apiSegments);
};

const getMockResponse = (api, payload, headers, method, apiSegments) => {
  const mockApi = getMockApi(api);

  if (!mockApi) {
    return [];
  }

  return mockApi.execute(payload, headers, method, apiSegments);
};

/**
 * Parse it into analyzable information
 * @param request { url: string, method: string, data: string, headers: object}
 */
export const parseRequest = (request) => {
  const { url, method, data, headers, isMocked } = request;

  // aws api looks something like: https://x05m5hmphg.execute-api.us-west-2.amazonaws.com/dev/query
  // where 'dev' is the stage
  const urlSegments = url.split(/\/+/);
  return {
    url,
    method,
    isMocked,
    headers,
    stage: urlSegments[2],
    api: urlSegments[3],
    apiSegments: urlSegments.slice(3, urlSegments.length),
    payload: data ? JSON.parse(data) : null
  };
};

/**
 * Check if mocked module exists, if it is, then we should intercept the request
 * @param request
 * @returns {*|boolean}
 */
export const shouldMockRequest = (request) => {
  const parsedResults = parseRequest(request);
  const { api, payload, headers, method, apiSegments } = parsedResults;
  return backendMockExists(api, payload, headers, method, apiSegments);
};

export const mockResponse = (mockRequest) => {
  const request = { ...mockRequest };
  const parsedResults = parseRequest(request);
  const { api, payload, headers, method, apiSegments } = parsedResults;
  return getMockResponse(api, payload, headers, method, apiSegments);
};
