import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import './DataTableComponent.css';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

export default class DataTableComponent extends Component {
  constructor(props) {
    super(props);

    this.onGridReady = this.onGridReady.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.returnOverallActions = this.returnOverallActions.bind(this);

    let action = this.props.cellClickAction;
    let columnDefs = _.cloneDeep(this.props.columnDefs);
    let frameworkComponents = {};
    if (action) {
      action.forEach(act => {
        columnDefs.push({
          headerName: act.headerName,
          field: act.field,
          cellRenderer: act.rendererName,
          width: act.width,
          actionHandler: act.actionHandler
        });
        frameworkComponents[act.rendererName] = act.renderer;
      });
    }
    this.state = {
      columnDefs: columnDefs,
      frameworkComponents: frameworkComponents
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setSortModel(this.props.sort);
    this.gridApi.setSideBarVisible(false);
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged = event => {
    this.gridApi.setQuickFilter(event.target.value);
  };

  returnOverallActions() {
    if (this.props.overallActions) {
      return this.props.overallActions.map(action => {
        return (
          <button key={action.name} className={action.className} onClick={action.action}>
            {action.name}
          </button>
        );
      });
    }
  }

  render() {
    let className = this.props.customClassName ? this.props.customClassName : 'data-table-body';

    return (
      <Fragment>
        <div className={className}>
          <div className="actions-bar">
            {this.props.customlink ? (
              <a href={this.props.customlink.link}>{this.props.customlink.displayText}</a>
            ) : (
              <h4 className="table-name">{this.props.tableName}</h4>
            )}
            <div className="actions">
              <input
                id="ag-grid-search"
                type="text"
                placeholder="Type text from any column..."
                onChange={this.onFilterTextBoxChanged}
                hidden={!this.props.searchBar}
              />
              {this.returnOverallActions()}
            </div>
          </div>
          <div className="ag-theme-material aggrid-grid">
            <AgGridReact
              onGridReady={this.onGridReady.bind(this)}
              enableSorting={true}
              enableFilter={true}
              rowSelection="multiple"
              animateRows={true}
              enableColResize={true}
              columnDefs={this.state.columnDefs}
              rowData={this.props.rowData}
              gridOptions={this.props.gridOptions}
              cellClickActions={this.props.cellClickActions}
              frameworkComponents={this.state.frameworkComponents}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
