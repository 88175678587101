import ConfigurationService from 'services/ConfigurationService';
import moment from 'moment';

export function getSimpleName(fullName) {
  // if has white space
  if (/\s/g.test(fullName)) {
    const matches = fullName.match(/\b(\w)/g); // ['J','S','O','N']
    const acronym = matches.join('');
    return acronym;
  }

  if (fullName.length > 6) {
    return fullName.substring(0, 3);
  }
  return fullName;
}

export function getFormattedDate(date, format) {
  const finalFormat = format || 'MM/DD/YYYY HH:mm:ss';
  return moment(date).format(finalFormat);
}

// The format of dateCell is mm/dd/yyyy hh:mm:ss
export function dateCellToDate(dateCell) {
  return new Date(dateCell);
}

export function dateComparatorSort(dateCell1, dateCell2) {
  // In the example application, dates are stored as mm/dd/yyyy hh:mm:ss
  // We create a Date object for comparison against the filter date
  if (dateCell2 == null) return -1;
  if (dateCell1 == null) return 1;

  const date1 = dateCellToDate(dateCell1);
  const date2 = dateCellToDate(dateCell2);

  // Now that both parameters are Date objects, we can compare
  if (date2 < date1) {
    return -1;
  }
  if (date2 > date1) {
    return 1;
  }
  return 0;
}

export function dateComparatorFilter(filterLocalDateAtMidnight, cellValue) {
  // In the example application, dates are stored as mm/dd/yyyy  hh:mm:ss
  // We create a Date object for comparison against the filter date
  if (cellValue == null) return -1;
  const cellDate = dateCellToDate(cellValue);

  // Now that both parameters are Date objects, we can compare
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
}
export function importAllImages(r) {
  const images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}
export function getHomePageImage() {
  const images = importAllImages(require.context('../img', false, /(_homepage)\.(png)$/));
  return ConfigurationService.getCustomerName().then((customerName) => {
    const imageName = `${customerName}_homepage.png`;
    return images[`${imageName}`];
  });
}

export function getMockAlerts() {
  const mockAlerts = [
    {
      alert_name: 'Low Stock',
      alert_trigger_time: 1578403606000,
      alert_type: 'Warning',
      alert_message:
        'Stock count of 3M Abrasive is down to 4. You have 4 left in stock, which is below the threshold of 5.',
      alert_object_id: '89588e98-0d41-4be7-b284-eec7612275ce1',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Low Stock',
      alert_trigger_time: 1578833986000,
      alert_type: 'Critical',
      alert_message:
        'Stock count of PQ986 is down to 25. You have 25 left in stock, which is below the threshold of 30.',
      alert_object_id: '89588e98-0d41-4be7-b284-eec7612275ce1',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Expired Inventory',
      alert_trigger_time: 1576156966000,
      alert_type: 'Critical',
      alert_message: 'Inventory item LSA600 expired 2 days ago.',
      alert_object_id: '89588e98-0d41-4be7-b284-eec7612275ce2',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Calibration Expired',
      alert_trigger_time: 1575412786000,
      alert_type: 'Critical',
      alert_message: 'Calibration of Angle Grinder B has expired and needs to be re-calibrated.',
      alert_object_id: '89588e98-0d41-4be7-b284-eec7612275ce3',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Missing Asset',
      alert_trigger_time: 1579270846000,
      alert_type: 'Critical',
      alert_message: 'Angle Grinder B is currently missing.',
      alert_object_id: '89588e98-0d41-4be7-b284-eec7612275ce4',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Asset Found',
      alert_trigger_time: 1579277026000,
      alert_type: 'Info',
      alert_message: 'Angle Grinder B has been found at the Assembly area.',
      alert_object_id: '0809b109-7591-4569-bd78-d4d9a39d40e5',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Expedited Order',
      alert_trigger_time: 1578434926000,
      alert_type: 'Info',
      alert_message: 'Order 35628E has been expedited.',
      alert_object_id: '89588e98-0d41-4be7-b284-eec7612275ce6',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Order Backlog',
      alert_trigger_time: 1577981326000,
      alert_type: 'Critical',
      alert_message: 'Order backlog count at QA has reached Critical state with 25 orders.',
      alert_object_id: '0809b109-7591-4569-bd78-d4d9a39d40e7',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Idle Order',
      alert_trigger_time: 1577991646000,
      alert_type: 'Warning',
      alert_message: 'Order WT76538 has been Idle at Assembly department for 36 hours.',
      alert_object_id: '89588e98-0d41-4be7-b284-eec7612275ce8',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Order Arrival',
      alert_trigger_time: 1578088486000,
      alert_type: 'Info',
      alert_message: 'Order PP74628 arrived at QA.',
      alert_object_id: '89588e98-0d41-4be7-b284-eec7612275ce9',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Expiring Inventory',
      alert_trigger_time: 1577120446000,
      alert_type: 'Warning',
      alert_message: 'Inventory item RD450 is going to expire in 30 days.',
      alert_object_id: '89588e98-0d41-4be7-b284-eec7612275ce2',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Calibration Expiring',
      alert_trigger_time: 1575216766000,
      alert_type: 'Warning',
      alert_message: 'Calibration of Angle Grinder B going to expire in 7 days.',
      alert_object_id: '89588e98-0d41-4be7-b284-eec7612275ce3',
      alert_user_status: 'READ'
    },
    {
      alert_name: 'Order Backlog',
      alert_trigger_time: 1578004246000,
      alert_type: 'Warning',
      alert_message: 'Order backlog count at Assembly has reached Warning state with 10 orders.',
      alert_object_id: '0809b109-7591-4569-bd78-d4d9a39d40e7',
      alert_user_status: 'READ'
    }
  ];

  return mockAlerts;
}

export function getCustomerLogo() {
  const images = importAllImages(require.context('../img', false, /(_logo)\.(png)$/));
  return ConfigurationService.getCustomerName().then((customerName) => {
    const imageName = `${customerName}_logo.png`;
    return images[`${imageName}`];
  });
}
export function getSmallCustomerLogo() {
  const images = importAllImages(require.context('../img', false, /(_small)\.(png)$/));
  return ConfigurationService.getCustomerName().then((customerName) => {
    const imageName = `${customerName}_small.png`;
    return images[`${imageName}`];
  });
}

export function getLocationImage(locationName) {
  const images = importAllImages(require.context('../img', false, /(_location)\.(png)$/));
  let imageName = null;
  if (locationName) {
    imageName = `${locationName.replace('/', '')}_location.png`;
  } else {
    imageName = 'all_location.png';
  }
  return images[`${imageName}`];
}

export function getImage(name) {
  const images = importAllImages(require.context('../img', false, /.*\.(png)$/));
  return images[name];
}

export function sortResources(resourceList) {
  return resourceList.sort(function(a, b) {
    if (a.resourceParent === null && b.resourceParent === null) {
      return a.resourceName.localeCompare(b.resourceName);
    }
    if (a.resourceParent === null) {
      return -1;
    }
    if (b.resourceParent === null) {
      return 1;
    }

    if (a.resourceParent.name !== b.resourceParent.name) {
      return a.resourceParent.name.localeCompare(b.resourceParent.name);
    }

    // parents were equal, compare names instead
    return a.resourceName.localeCompare(b.resourceName);
  });
}

export function msToTime(duration) {
  const seconds = parseInt(Math.floor((duration / 1000) % 60), 10);

  const minutes = parseInt(Math.floor(duration / (1000 * 60)) % 60, 10);

  const hours = parseInt(Math.floor((duration / (1000 * 60 * 60)) % 24), 10);

  const days = parseInt(Math.floor((duration / (1000 * 60 * 60 * 24)) % 31), 10);

  const months = parseInt(Math.floor((duration / (1000 * 60 * 60 * 24 * 31)) % 12), 10);

  const years = parseInt(Math.floor(duration / (1000 * 60 * 60 * 24 * 31 * 12)), 10);

  let time = '';
  if (years > 0) {
    time += `${years}y `;
  }
  if (months > 0) {
    time += `${months}mo `;
  }
  if (days > 0) {
    time += `${days}d `;
  }
  if (hours > 0) {
    time += `${hours}h `;
  }
  if (minutes > 0) {
    time += `${minutes}m `;
  }
  if (seconds > 0 || time === '') {
    time += `${seconds}s`;
  }

  return time;
}

export function getNumberOfDaysToDate(date) {
  return moment(moment(date).format('YYYY-MM-DD')).diff(
    moment(moment().format('YYYY-MM-DD')),
    'days'
  );
}
