import React, { PureComponent } from 'react';
import Style from './css/DataTable.module.css';

export default class DataTable extends PureComponent {
  renderColumnTitle = titleList => {
    return (
      <div className={Style.tracking_links_header}>
        {titleList.map(eachTitle => (
          <div className={Style.time_detail} key={eachTitle.key}>
            {eachTitle.value}
          </div>
        ))}
      </div>
    );
  };

  renderRowData = (rowData, titleList) => {
    return (
      <td key={null} className={Style.tracking_department_link_row}>
        {titleList.map(eachTitle => {
          return (
            <div key={eachTitle.key} className={Style.time_duration}>
              {rowData[eachTitle.key] || '-'}
            </div>
          );
        })}
      </td>
    );
  };

  render() {
    const { dataList = [], titleList = [] } = this.props;

    return (
      <div className={Style.tracking_departments_links}>
        {this.renderColumnTitle(titleList)}
        <div className={Style.tracking_departments_links_detail}>
          {dataList.map(rowData => {
            return this.renderRowData(rowData, titleList);
          })}
        </div>
      </div>
    );
  }
}