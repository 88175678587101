import React, { PureComponent, Fragment } from 'react';
import { Chart as DonutChart } from 'react-google-charts';
import { isAbsolute } from 'path';
import PropTypes from 'prop-types';
import LoadingCircle from '../../loading/LoadingCircle';
import './css/Chart.css';

/*
 * Chart takes props from InventoryComponent and displays Plant/Location name and DonutChart
 * Reference Google Charts documentation for DonutChart details
 * https://developers.google.com/chart/interactive/docs/gallery/piechart#making-a-donut-chart
 */
export default class Chart extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <Fragment>
        <div className="title-text">
          <p className="title-separation title-text">Inventory Health</p>
        </div>
        <div className="my-pretty-chart-container">
          <DonutChart
            position={isAbsolute}
            width={400}
            height={270}
            chartType="PieChart"
            loader={<LoadingCircle />}
            data={data}
            options={{
              titlePosition: 'none',
              legend: { position: 'top', alignment: 'center' },
              chartArea: { left: 0, top: 55, right: 0, bottom: 55 },
              colors: ['#F74446', '#F5B052', '#61D438'],
              pieSliceText: 'none',
              pieHole: 0.5,
              backgroundColor: { fill: 'transparent' }
            }}
            rootProps={{ 'data-testid': '1' }}
            chartWrapperParams={{ view: { columns: [0, 1] } }}
            chartPackages={['corechart', 'controls']}
          />
        </div>
      </Fragment>
    );
  }
}

Chart.propTypes = {
  plant: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.array).isRequired
};
