import { v4 as uuidV4 } from 'uuid';
import { AbstractResourceManager } from '../../abstract-resource-manager';

const init = (addItemFn) => {
  const subscriptionProfileSchemaMap = [
    {
      id: '0ab7e571-651a-2997-174a-09f3484ec6a8',
      node_last_updated_time: null,
      label: 'SubscriptionProfile_orion',
      emails: ['francis.wu@xemelgo.com'],
      sms: []
    }
  ];

  subscriptionProfileSchemaMap.forEach((element) => {
    addItemFn(element);
  });
};

// eslint-disable-next-line import/prefer-default-export
export class SubscriptionProfileResourceManager extends AbstractResourceManager {
  constructor() {
    super('id');
    init(this.addItem.bind(this));
  }

  supportMock(api, action, payload, headers) {
    if (api === 'query') {
      return true;
    }
    return super.supportMock(api, payload, headers);
  }

  list() {
    const subscriptionProfiles = super.list();

    return subscriptionProfiles;
  }
}

const resourceManager = new SubscriptionProfileResourceManager();
export { resourceManager };
