import React, { Fragment, Component} from 'react';
import { Card, CardBody } from 'mdbreact';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { XemelgoService } from '../../services/XemelgoService';
import { inputValidation, isRequired } from '../../components/Forms/Validation';
import ListView from '../../components/TrackPageComponents/ListView';
import AddObjectForm from './AddObjectForm';
import { useInputField, useDateField, useMUiSeachDropdown } from '../../components/Forms/BasicAddForm';
//spinner
import ClipLoader from "react-spinners/ClipLoader";
import MoreIcon from '../../img/More.png';

import AddItemStyle from './css/AddItem.module.css';
import BasicDialog from './BasicDialog';
import update from 'immutability-helper';
import {DialogContent, Typography} from '@material-ui/core';

class AddItemType extends Component {
  constructor(props) {
    super(props);

    const formControl = {
      itemTypeIdentifier: {
        tabIndex: 1,
        render: useMUiSeachDropdown,
        id: 'itemTypeIdentifier',
        label: 'Item Type *',
        value: '',
        searchList: [],
        valid: true,
        errorMessage: '',
        helperText: '',
        isRequired: true,
        validationRules: [isRequired]
      },
      itemIdentifier: {
        tabIndex: 2,
        render: useInputField,
        id: 'itemIdentifier',
        label: 'Identifier (EPC)',
        value: '',
        valid: true,
        errorMessage: '',
        helperText: '',
        isRequired: true,
        validationRules: [isRequired]
      },
      category: {
        tabIndex: 3,
        render: useInputField,
        id: 'category',
        label: 'Category',
        value: '',
        valid: true,
        errorMessage: '',
        isRequired: true,
        helperText: '',
        validationRules: [isRequired]
      },
      description: {
        tabIndex: 4,
        render: useInputField,
        id: 'description',
        label: 'Description',
        value: '',
        valid: true,
        errorMessage: '',
        helperText: '',
        validationRules: []
      },
      lotNumber: {
        tabIndex: 5,
        render: useInputField,
        id: 'lotNumber',
        label: 'Lot Number',
        value: '',
        valid: true,
        errorMessage: '',
        helperText: '',
        validationRules: []
      },
      expiryDate: {
        tabIndex: 6,
        render: useDateField,
        id: 'expiryDate',
        label: 'Expiry Date',
        value: null,
        valid: true,
        errorMessage: '',
        placeholder: 'MM/DD/YYYY',
        validationRules: [],
        dateStyle: {width: "100%"}
      }
    };

    const xemelgoClient = XemelgoService.getClient();
    const itemTypeClient = xemelgoClient.getItemTypeClient();
    const itemClient = xemelgoClient.getItemClient();
    const sensorProfileClient = xemelgoClient.getSensorProfileClient();
    const trackingSessionClient = xemelgoClient.getTrackingSessionClient();

    this.state = {
      itemTypeClient,
      itemClient,
      sensorProfileClient,
      trackingSessionClient,
      formControl,
      showDialog: false,
      errorMessage: '',
      objectsToCreate: [],
      headers: [],
      showPreview: false,
      typeInfo: {},
      showSpinner: false,
      itemTypeIdentifierList:[],
    };
  }

  async componentDidMount() {
    const { formControl, itemTypeClient } = this.state;
    // Get all the itemType
    const listOfItemType = await itemTypeClient.listItemTypes();
    let itemTypesForSearch = [];
    listOfItemType.forEach(item => {
      itemTypesForSearch.push({
        id: item.id,
        key: [item.identifier, item.id],
        displayString: item.identifier
      })
    });
    itemTypesForSearch.sort((o1, o2) => o1.displayString.localeCompare(o2.displayString));
    const newFormControl = update(formControl, {
      itemTypeIdentifier: {
        searchList: {$set: itemTypesForSearch}
      }
    });

    this.setState(prevState => ({
      ...prevState,
      formControl: newFormControl
    }));
  }

  consoleLogging = msg => {
    const prod = true;
    if (!prod) {
      console.log(msg);
    }
  };

  displayDialog = () => {
    const {errorMessage, showDialog} = this.state;
    let bodyComponent;
    let showCloseButton = false;
    if (typeof(errorMessage) === 'string') {
    bodyComponent = <DialogContent><Typography>{errorMessage}</Typography></DialogContent>;
    showCloseButton = true;
    } else {
      bodyComponent = errorMessage;
    }
    return (
      <BasicDialog
        title="Error!"
        isOpen={showDialog}
        bodyComponent={bodyComponent}
        onClose={() => this.setState({showDialog:false})}
        showCloseButton={showCloseButton}
      />
    )
  };

  // *** Order Form Handlers *** //
  submitBatchCreateFromCSV = async () => {
    // JELI TODO: 1. Need to add the spinning circle of death
    //            2. Ask them to confirm it again before submitting the orders ? maybe we don't
    //
    // Assumed that all the input has already been validated

    function getUnique(arr, comp) {
      const unique = arr
        .map(e => e[comp])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e])
        .map(e => arr[e]);

      return unique;
    }

    const {
      itemTypeClient,
      itemClient,
      sensorProfileClient,
      trackingSessionClient,
      objectsToCreate
    } = this.state;

    // Get all the itemType
    const listOfItemType = await itemTypeClient.listItemTypes();
    this.consoleLogging(JSON.stringify(listOfItemType));

    let listOfItemTypeIdentifier = listOfItemType.map(item => {
      return { identifier: item.identifier, id: item.id };
    });
    this.consoleLogging(JSON.stringify(listOfItemTypeIdentifier));
    listOfItemTypeIdentifier = getUnique(listOfItemTypeIdentifier, 'identifier');

    // Get all the itemType in the csv file
    const listOfItemTypeFromCSV = [
      ...new Set(
        objectsToCreate.map(item => {
          return item.itemType;
        })
      )
    ];
    this.consoleLogging(listOfItemTypeFromCSV);
    for (let i = 0; i < listOfItemTypeFromCSV.length; i++) {
      let itemType;
      itemType = listOfItemTypeFromCSV[i];
      if (!listOfItemTypeIdentifier.some(obj => obj.identifier === itemType)) {
        await itemTypeClient.createItemType(itemType, 'Asset').then(
          response => {
            listOfItemTypeIdentifier.push({ identifier: response.identifier, id: response.id });
            this.consoleLogging(`createItemType response => ${JSON.stringify(response)}`);
          },
          err => {
            this.consoleLogging(`failed to create itemType`);
            this.consoleLogging(err);
          }
        );
      }
    }
    // Convert into a map
    const listOfItemTypeIdentifierMap = listOfItemTypeIdentifier.reduce(function (map, obj) {
      map[obj.identifier] = obj.id;
      return map;
    });

    // ?? Do check if the sensorProfile exist or just go ahead and create and wait for failure

    // For loops for the following steps (keep track of all the [BadRequest] and console log it
    // the end for debugging purpose for now)

    // objectsToCreate [{}]
    const BadRequest = [];
    let errorCount = 0;

    // objectsToCreate.forEach(async objecToCreate => {
    for (let i = 0; i < objectsToCreate.length; i++) {
      let sensorProfileId, itemId;
      let objecToCreate = objectsToCreate[i];
      let createdObject = {};

      // Create Item and attach to the itemType
      /*
        identifier: string,
        itemClass: string,
        category: string,
        itemTypeId:string,
        name?: string,
        description?: string,
        expiryDate?: number,
        imagePath?: string,
        quantity?:number,
        customFields?:{[name:string]: any}
      */

      this.consoleLogging('Create Item and attach to the itemType');
      await itemClient
        .createAndAttachToItemType(
          objecToCreate.tracker_serial,
          objecToCreate.class,
          objecToCreate.category,
          listOfItemTypeIdentifierMap[objecToCreate.itemType],
          '', // Name is currently not really needed for this case
          '', // Description is not needed as of now
          new Date(objecToCreate['expiry date']).getTime(),
          '', // Image not will use the itemType image_path
          0, // No quantity is needed for this use case
          {
            lot_number: objecToCreate['lot number']
          }
        )
        .then(
          response => {
            itemId = response.id;
            createdObject.itemId = itemId;
            this.consoleLogging(
              `createAndAttachToItemType response => ${JSON.stringify(response)}`
            );
          },
          err => {
            this.consoleLogging(err);
            BadRequest.push(objecToCreate);
          }
        );

      if (BadRequest.length > errorCount) {
        errorCount++;
        this.consoleLogging('Failed to create Item and attach to the itemType');
        continue;
      }

      // Create Sensor Profile
      this.consoleLogging('Create SensorProfile');
      await sensorProfileClient
        .createSensorProfile('RFID Tag', objecToCreate.tracker_serial, 'Brady')
        .then(
          response => {
            sensorProfileId = response.id;
            createdObject.sensorProfileId = sensorProfileId;
          },
          err => {
            this.consoleLogging(err);
            BadRequest.push(objecToCreate);
          }
        );

      if (BadRequest.length > errorCount) {
        errorCount++;
        this.consoleLogging('Failed to create SensorProfile');
        continue;
      }

      // Attach Item to SensorProfile
      this.consoleLogging('Attach Item to SensorProfile');
      await sensorProfileClient.attachSensorProfileToItem(sensorProfileId, itemId).then(
        response => {
          sensorProfileId = response.id;
          this.consoleLogging(`sensorProfileClient response => ${JSON.stringify(response)}`);
        },
        err => {
          this.consoleLogging(err);
          BadRequest.push(objecToCreate);
        }
      );

      if (BadRequest.length > errorCount) {
        errorCount++;
        this.consoleLogging('Failed to attach Item to SensorProfile');
        continue;
      }

      // Start Tracking Session
      this.consoleLogging('Start Tracking Session');
      await trackingSessionClient.startTrackingSession(itemId).then(
        response => {
          this.consoleLogging(`sensorProfileClient response => ${JSON.stringify(response)}`);
        },
        err => {
          this.consoleLogging(err);
          BadRequest.push(objecToCreate);
        }
      );

      if (BadRequest.length > errorCount) {
        errorCount++;
        this.consoleLogging('Failed to start tracking session');
        continue;
      }

      this.consoleLogging(`Success creation on => ${JSON.stringify(objecToCreate)}`);
    }

    this.consoleLogging(`BadRequest => ${JSON.stringify(BadRequest)}`);

    // JELI TODO : Give user some feedback and re-direct back to add csv(either success or failure)
  };

  uploadCSV = event => {
    const self = this;
    if (event && event.target && event.target.files[0]) {
      const objects = [];
      const identifiers = [];
      let csvHeaders;
      const reader = new FileReader();
      reader.onload = function () {
        const rows = reader.result.split('\r\n');
        rows[0] = rows[0].replace('ï»¿', '');
        csvHeaders = rows[0].split(',');
        for (let i = 1; i < rows.length; i++) {
          const currentObject = {};
          self.consoleLogging(`${JSON.stringify(rows)}`);
          if (rows[i]) {
            const currentLine = rows[i].split(',');
            for (let j = 0; j < csvHeaders.length; j++) {
              currentObject[csvHeaders[j]] = currentLine[j];
            }
            objects.push(currentObject);
            identifiers.push(currentObject.tracker_serial);
          }
        }
        if (objects.length > 0) {
          if (new Set(identifiers).size === identifiers.length) {
            // this is a success csv upload, hide banner
            self.setState({ showDialog: false });
            self.setState({ objectsToCreate: objects, showPreview: true, headers: csvHeaders });
          } else {
            self.setState({
              showDialog: true,
              errorMessage: 'Table could not be created: Identifiers should be unique!'
            });
          }
        }
      };
      reader.readAsBinaryString(event.target.files[0]);
      // reset the value so that onchange triggered if the same file uploaded
      event.target.value = null;
    }
  };

  changeHandler = value => {
    this.setState({
      typeInfo: value
    });
  };

  editPayload = () => {
    const { objectsToCreate, headers } = this.state;
    const listHeaders = [];

    this.consoleLogging(`objectsToCreate => ${JSON.stringify(objectsToCreate)}`);
    // BadRow: Any rows without missing the required fields or have fields not listed in the optional fields
    // JELI TODO :  1. Highlight the rows in red for any [BadRow] (might not be needed for now)
    //              2. Disable the button for sumbit if there is any BadRow

    // RequireFields: ['itemTypeIdentifier', 'itemIdentifier']
    // OptionalFields: ['description', 'lotNumber', 'expiryDate']

    headers.forEach(element => listHeaders.push({ id: element, label: element }));
    return (
      <Fragment>
        <ListView dataList={objectsToCreate} headerStructureList={listHeaders} />
        <div className={AddItemStyle.form_submit_button_container}>
          <button
            type="submit"
            onClick={this.submitBatchCreateFromCSV}
            className="request-submit-button"
          >
            Confirm Items
          </button>
        </div>
      </Fragment>
    );
  };

  renderInputFields = () => {
    const buttonTextStyling = { fontSize: '1.2em' };
    const formStyling = { marginTop: '11%' };
    const { typeInfo, formControl, listOfItemTypeIdentifier } = this.state;
    return (
      <div className={AddItemStyle.add_item_form_container}>
        <div>
          <AddObjectForm
            formControl={formControl}
            info={typeInfo}
            changeHandler={returnData => this.changeHandler(returnData)}
          />
          <button type="submit" onClick={this.singleItemSubmit} className="request-submit-button">
            Add Item
          </button>
        </div>
        <form style={formStyling}>
          <p style={buttonTextStyling}> or upload a CSV </p>
          <div>
            <input type="file" onChange={this.uploadCSV} />
          </div>
        </form>
      </div>
    );
  };

  singleItemSubmit = async () => {
    const { typeInfo } = this.state;
    const updatedFormControls = _.cloneDeep(typeInfo);
    const validationResult = inputValidation(updatedFormControls);
    // holds true iff every element in validationResult is true. Otherwise, false.
    const isInputValid = validationResult.reduce((acc, cur) => acc && cur, true);
    if (!isInputValid) {
      this.setState({
        showDialog: true,
        errorMessage: 'Cannot add an item: some inputs are invalid'
      });
    } else {
      this.setState({ showDialog: false });
      const newItem = {
        identifier: typeInfo.itemIdentifier.value,
        itemClass: 'Material',
        category: typeInfo.category.value,
        itemTypeId: typeInfo.itemTypeIdentifier.value,
        name: typeInfo.itemIdentifier.value,
        description: typeInfo.description.value,
        expiryDate: new Date(typeInfo.expiryDate.value).valueOf(),
        customFields: {
          lot_number: typeInfo.lotNumber.value
        }
      };
      let sensorProfileId, itemId, response;

      // Create Item and attach to the itemType
      response = await this.createAndAttachToItemType(newItem);
      if (!response) {
        this.setState( {showDialog: true, errorMessage: "Please select a Item Type Identifier from the list"});
        return;
      }
      itemId = response.id;

      // Create Sensor Profile
      response = await this.createSensorProfile(typeInfo.itemIdentifier.value);
      if (!response) {
        this.setState( {showDialog:true, errorMessage: "Fail to create: Item Identifier already exists"});
        return;
      };
      sensorProfileId = response.id;

      // Attach Item to SensorProfile
      response = await this.attachSensorProfileToItem(sensorProfileId, itemId);
      if (!response) {
        this.setState( {showDialog: true, errorMessage: "There was an error. Please try again."});
        return;
      }
      // Start Tracking Session
      response = await this.startTrackingSession(itemId);
      if (!response) {
        this.setState( {showDialog: true, errorMessage: "There was an error. Please try again."});
        return;
      }
      this.setState({showDialog: true, errorMessage: <ClipLoader/>})
      // navigate back to Track page
      this.handleCancel();
      this.setState({showDialog: false});
      //TODO: notify user that the item added successfully

    }
  };

  startTrackingSession = async (itemId) => {
    return await this.state.trackingSessionClient.startTrackingSession(itemId).then(
      response => {
        return response;
      },
      err => {
        this.consoleLogging(JSON.stringify(err));
        return null;
      }
    );
  }

  attachSensorProfileToItem = async (sensorProfileId, itemId) => {
    return await this.state.sensorProfileClient.attachSensorProfileToItem(sensorProfileId, itemId).then(
      response => {
        return response;
      },
      err => {
        this.consoleLogging(JSON.stringify(err));
        return null;
      }
    );
  }

  createSensorProfile = async (trackerSerial) => {
    return await this.state.sensorProfileClient
      .createSensorProfile('RFID Tag', trackerSerial, 'Brady')
      .then(
        response => {
          return response;
        })
        .catch(err =>
        {
          this.consoleLogging(JSON.stringify(err));
          return null;
        }
      );
  }

  createAndAttachToItemType = async (payload) => {
    return await this.state.itemClient
      .createAndAttachToItemType(
        payload.identifier,
        payload.itemClass,
        payload.category,
        payload.itemTypeId,
        payload.name ? payload.name : '',
        payload.description ? payload.description : '',
        payload.expiryDate,
        payload.imagePath ? payload.imagePath : '',
        payload.quantity ? payload.quantity : 0,
        (payload.customFields && payload.customFields.constructor === Object) ? payload.customFields : undefined
      )
      .then(
        response => {
          return response;
        },
        err => {
          this.consoleLogging(JSON.stringify(err));
          return null;
        }
      );
  }


  handleCancel = () => {
    const { showPreview } = this.state;
    const { history } = this.props;
    showPreview ? this.setState({ showPreview: false, objectsToCreate: [] }) : history.goBack();
  };

  render() {
    const { showDialog: showDialog, showPreview, showSpinner } = this.state;
    return (
      <div className={AddItemStyle.add_item_container}>
        {showDialog && this.displayDialog()}
        <ClipLoader loading={showSpinner}/>
        <Card className={AddItemStyle.add_item_outer_card_styling}>
          <div className={AddItemStyle.add_item_title}>
            <div className={AddItemStyle.add_item_title_content}>Add Item Type</div>
          </div>
          <CardBody>
            <div onClick={this.handleCancel} className={AddItemStyle.add_item_cancel_link}>
              <img src={MoreIcon} alt="more" className={AddItemStyle.add_item_cancel_text} />
              Cancel
            </div>
            {showPreview ? this.editPayload() : this.renderInputFields()}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(AddItemType);
