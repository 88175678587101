/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import TrackPageComponent from '../../components/TrackPageComponents/track-page-component/TrackPageComponent';
import TrackPageDataViewComponent from '../../components/TrackPageComponents/track-page-data-view-component/TrackPageDataViewComponent';
import LoadingCircle from '../../components/loading/LoadingCircle';
import ConfigurationService from '../../services/ConfigurationService';
import GroupbySideBarComponent from '../../components/TrackPageComponents/groupby-sidebar-component/GroupbySidebarComponent';
import GridCardContentDefault from '../../components/TrackPageComponents/Order/grid-card-contents/grid-card-content-default/GridCardContentDefault';
import GridCardComponent from '../../components/grid-card-component/GridCardComponent';
import GridCardContentGroupbyLocation from '../../components/TrackPageComponents/Order/grid-card-contents/grid-card-content-groupby-location/GridCardContentGroupbyLocation';
import GridCardContentParts from '../../components/TrackPageComponents/Order/grid-card-contents/gird-card-content-parts/GridCardContentParts';
import { getCustomerLogo, getFormattedDate, getImage } from '../../common/Utilities';
import { useXemelgoClient } from '../../services/xemelgo-service';

import OrderOverviewComponent from '../../components/order-overview-component/OrderOverviewComponent';
import Style from '../../components/TrackPageComponents/Order/css/TrackPageGridViewComponent.module.css';
import BreadcrumbsComponent from '../../components/breadcrumbs-component/BreadcrumbsComponent';

const OrderTrackPageFeature = ({ history }) => {
  const breadcrumbsDataListRef = useRef();

  const handleBreadCrumbsClick = (id) => {
    const { current } = breadcrumbsDataListRef;
    while (current.length && current[current.length - 1].id !== id) {
      current.pop();
    }
    setBreadcrumbsDataList(current);
  };
  const groupbyStructure = [
    {
      id: 'all-orders',
      display: 'All Orders',
      action: () => {
        handleTabClick('all-orders', overallData.tracksItem);
        calculateOverviewData(overallData.tracksItem, overallData.locations);
        setFocusedTab(null);
        history.replace(`${history.location.pathname}`);
        setBreadcrumbsDataList([]);
      }
    },
    {
      id: 'all-parts',
      display: 'All Parts',
      action: () => {
        handleTabClick('all-parts', partDataList);
        setCurrentTab('all-parts');
        setFocusedTab(null);
        history.replace(`${history.location.pathname}?group=all-parts`);
        setBreadcrumbsDataList([
          {
            id: 'all-parts',
            value: 'All Parts',
            onClick: () => {
              handleBreadCrumbsClick('all-parts');
              handleTabClick('all-parts', partDataList);
              setFocusedTab(null);
            }
          }
        ]);
      }
    },
    {
      id: 'groupby',
      display: 'Group By',
      tabStructure: [
        {
          id: 'location',
          display: 'Location',
          action: () => {
            handleTabClick('location', overallData.locations);
            setFocusedTab(null);
            calculateOverviewData(overallData.tracksItem, overallData.locations);
            history.replace(`${history.location.pathname}?group=location`);
            setBreadcrumbsDataList([
              {
                id: 'location',
                value: 'Location',
                onClick: () => {
                  handleBreadCrumbsClick('location');
                  handleTabClick('location', overallData.locations);
                  calculateOverviewData(overallData.tracksItem, overallData.locations);
                  setFocusedTab(null);
                }
              }
            ]);
          }
        }
      ]
    }
  ];
  const [currentTab, setCurrentTab] = useState('all-orders');
  const [loading, setLoading] = useState(true);
  const [overallData, setOverallData] = useState({});
  const [currentDataList, setCurrentDataList] = useState([]);
  const [logoData, setLogoData] = useState(null);
  const [client] = useState(useXemelgoClient());

  const [overviewData, setOverviewData] = useState({});
  const [partDataList, setPartDataList] = useState([]);
  const [focusedTab, setFocusedTab] = useState('all-orders'); // This is used only when currentTab is null but you want the side bar to focused on specific id
  const [breadcrumbsDataList, setBreadcrumbsDataList] = useState([]);

  const handleTabClick = (tabId, dataList) => {
    setCurrentTab(tabId);
    setCurrentDataList(dataList);
  };

  useEffect(() => {
    breadcrumbsDataListRef.current = breadcrumbsDataList;
  });

  const calculateOverviewData = (tracksItem, locations) => {
    const CRITICAL = 0.8;
    const WARNING = 0.6;
    const tempOverview = {
      numOfDelayed: 0,
      numOfOrders: 0,
      numOfOnTime: 0,
      averageVelocity: 'N/A',
      health: 'Healthy',
      numOfExpedited: 0,
      graphDataList: []
    };
    if (locations) {
      tempOverview.graphDataList = locations.map((each) => {
        const locationSummary = each.tracksItem.reduce(
          (accumulator, current) => {
            current.represents.status_flag.forEach((eachFlag) => {
              if (eachFlag === 'Expedited') {
                accumulator.numOfExpedited += 1;
              } else if (eachFlag === 'Delayed') {
                accumulator.numOfDelayed += 1;
              }
            });
            return accumulator;
          },
          { numOfDelayed: 0, numOfExpedited: 0 }
        );
        let locationHealth = 'Healthy';
        if (
          (locationSummary.numOfExpedited + locationSummary.numOfDelayed) /
            each.tracksItem.length >=
          CRITICAL
        ) {
          locationHealth = 'Critical';
        } else if (
          (locationSummary.numOfExpedited + locationSummary.numOfDelayed) /
            each.tracksItem.length >=
          WARNING
        ) {
          locationHealth = 'Warning';
        }
        return {
          departmentName: each.name,
          simpleName: each.name.substring(0, 4),
          departmentID: each.id,
          numberOfExpeditedOrders: locationSummary.numOfExpedited,
          numberOfNonExpeditedOrders: each.tracksItem.length - locationSummary.numOfExpedited,
          totalOrders: each.tracksItem.length,
          currentThresholdState: locationHealth
        };
      });
      tempOverview.graphDataList.sort((a, b) => a.totalOrders < b.totalOrders);
    }
    tempOverview.numOfOrders = tracksItem.length;
    tracksItem.forEach((each) => {
      if (each.represents.status_flag.includes('Delayed')) {
        tempOverview.numOfDelayed += 1;
      }
      if (each.represents.status_flag.includes('Expedited')) {
        tempOverview.numOfExpedited += 1;
      }
      if (each.represents.status_flag.includes('On-Time')) {
        tempOverview.numOfOnTime += 1;
      }
    });
    if ((tempOverview.numOfExpedited + tempOverview.numOfDelayed) / tracksItem.length >= CRITICAL) {
      tempOverview.health = 'Critical';
    } else if (
      (tempOverview.numOfExpedited + tempOverview.numOfDelayed) / tracksItem.length >=
      WARNING
    ) {
      tempOverview.health = 'Warning';
    }
    setOverviewData(tempOverview);
  };
  const processQueryPayload = (payload, data, partList) => {
    let tabId = null;
    let dataList = [];
    const newBreadcrumbsDataList = [];
    switch (payload.group) {
      case 'location':
        tabId = 'location';
        dataList = data.locations;
        newBreadcrumbsDataList.push({
          id: 'location',
          value: 'Location',
          onClick: () => {
            handleBreadCrumbsClick('location');
            handleTabClick('location', data.locations);
            calculateOverviewData(data.tracksItem, data.locations);
            setFocusedTab(null);
          }
        });

        break;
      case 'all-parts':
        tabId = 'all-parts';
        dataList = partList;
        newBreadcrumbsDataList.push({
          id: 'all-parts',
          value: 'All Parts',
          onClick: () => {
            handleBreadCrumbsClick('all-parts');
            handleTabClick('all-parts', partList);
            setFocusedTab(null);
          }
        });
        break;
      default:
        tabId = null;
        dataList = data.tracksItem;
        break;
    }
    if (payload.id) {
      setFocusedTab(tabId);
      const result = dataList.find((each) => each.id === payload.id);
      if (result) {
        tabId = null;
        dataList = result.tracksItem;
        newBreadcrumbsDataList.push({ id: payload.id, value: result.name });
      } else {
        history.replace(`${history.location.pathname}?group=${payload.group}`);
      }
    }
    setBreadcrumbsDataList(newBreadcrumbsDataList);
    handleTabClick(tabId, dataList);
  };

  const getSortSchema = () => {
    switch (currentTab) {
      case 'all-parts':
        return [
          {
            id: 'partNumber',
            value: 'Part Number',
            compareFunc: (a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
            },
            type: 'character'
          },
          {
            id: 'orderNumber',
            value: 'Order Number',
            compareFunc: (a, b) => {
              return a.orderNumber.localeCompare(b.orderNumber, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
            },
            type: 'character'
          },
          {
            id: 'lastseenlocation',
            value: 'Last Seen Location',
            compareFunc: (a, b, reverse) => {
              if (a.lastKnownLocation === b.lastKnownLocation) {
                return 0;
              }
              if (!a.lastKnownLocation) {
                return reverse ? -1 : 1;
              }
              if (!b.lastKnownLocation) {
                return reverse ? 1 : -1;
              }
              return a.lastKnownLocation < b.lastKnownLocation ? -1 : 1;
            },
            type: 'character'
          },
          {
            id: 'status',
            value: 'Status',
            default: true,
            compareFunc: (a, b) => {
              const statusLevelCalculator = (id) => {
                switch (id) {
                  case 'Delayed':
                    return 4;
                  case 'Expedited':
                    return 2;
                  case 'On-Time':
                    return 1;
                  default:
                    return 0;
                }
              };
              let aLevel = 0;
              let bLevel = 0;
              a.represents.status_flag.forEach((each) => {
                aLevel += statusLevelCalculator(each);
              });
              b.represents.status_flag.forEach((each) => {
                bLevel += statusLevelCalculator(each);
              });
              return aLevel < bLevel ? 1 : -1;
            }
          }
        ];
      case 'location':
        return [
          {
            id: 'locationname',
            value: 'Location Name',
            compareFunc: (a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
            },
            type: 'character',
            default: true
          },
          {
            id: 'numoforders',
            value: '# of Orders',
            compareFunc: (a, b) => {
              return a.tracksItem.length > b.tracksItem.length;
            },
            type: 'number'
          },
          {
            id: 'lastupdated',
            value: 'Last Updated',
            compareFunc: (a, b, reverse) => {
              if (a.lastUpdatedTime === b.lastUpdatedTime) {
                return 0;
              }
              if (!a.lastUpdatedTime) {
                return reverse ? -1 : 1;
              }
              if (!b.lastUpdatedTime) {
                return reverse ? 1 : -1;
              }
              return a.lastUpdatedTime < b.lastUpdatedTime ? -1 : 1;
            },
            type: 'number'
          }
        ];
      default:
        return [
          {
            id: 'ordernumber',
            value: 'Order Number',
            compareFunc: (a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
            },
            type: 'character'
          },
          {
            id: 'duedate',
            value: 'Due Date',
            compareFunc: (a, b, reverse) => {
              if (a.represents.due_date === b.represents.due_date) {
                return 0;
              }
              if (!a.represents.due_date) {
                return reverse ? -1 : 1;
              }
              if (!b.represents.due_date) {
                return reverse ? 1 : -1;
              }
              return a.represents.due_date < b.represents.due_date ? -1 : 1;
            },
            type: 'number'
          },
          {
            id: 'creationdate',
            value: 'Creation Date',
            compareFunc: (a, b) => {
              return +(a.startTime > b.startTime) || -(a.startTime < b.startTime);
            },
            type: 'number'
          },
          {
            id: 'lastupdated',
            value: 'Last Updated Date',
            compareFunc: (a, b) => {
              return (
                +(a.lastUpdatedTime > b.lastUpdatedTime) || -(a.lastUpdatedTime < b.lastUpdatedTime)
              );
            },
            type: 'number'
          },
          {
            id: 'lastseenlocation',
            value: 'Last Seen Location',
            compareFunc: (a, b, reverse) => {
              if (a.lastKnownLocation.name === b.lastKnownLocation.namme) {
                return 0;
              }
              if (!a.lastKnownLocation.name) {
                return reverse ? -1 : 1;
              }
              if (!b.lastKnownLocation.name) {
                return reverse ? 1 : -1;
              }
              return a.lastKnownLocation.name < b.lastKnownLocation.name ? -1 : 1;
            },
            type: 'character'
          },
          {
            id: 'status',
            value: 'Status',
            default: true,
            compareFunc: (a, b) => {
              const statusLevelCalculator = (id) => {
                switch (id) {
                  case 'Delayed':
                    return 4;
                  case 'Expedited':
                    return 2;
                  case 'On-Time':
                    return 1;
                  default:
                    return 0;
                }
              };
              let aLevel = 0;
              let bLevel = 0;
              a.represents.status_flag.forEach((each) => {
                aLevel += statusLevelCalculator(each);
              });
              b.represents.status_flag.forEach((each) => {
                bLevel += statusLevelCalculator(each);
              });
              return aLevel < bLevel ? 1 : -1;
            }
          }
        ];
    }
  };

  useEffect(() => {
    const onLoad = async () => {
      const trackingConfig = await ConfigurationService.getTrackingConfiguration();
      const locationCategory =
        trackingConfig.possibleDetectorLocations &&
        trackingConfig.possibleDetectorLocations.length > 0
          ? trackingConfig.possibleDetectorLocations[0]
          : 'Department';
      const TrackPageClient = await client.getTrackPageClient();
      const data = await TrackPageClient.getTrackPageData(['location'], locationCategory);
      await calculateOverviewData(data.tracksItem, data.locations);
      const logo = await getCustomerLogo();

      const partData = [];

      data.tracksItem.forEach((item) => {
        const { parts } = item.represents;
        parts.forEach((part) => {
          const partObj = part;
          partObj.id = part.identifier;
          partObj.orderId = item.id;
          partObj.orderNumber = item.name;
          partObj.lastKnownLocation = item.lastKnownLocation.name;
          partObj.represents = {
            status_flag: item.represents.status_flag
          };
          partObj.lastUpdatedTime = item.lastUpdatedTime;
          partObj.vid = item.sensorProfile.vid;
          partObj.startTime = item.startTime;
          partObj.due_date = item.represents.due_date;
          partObj.image = getImage(`${part.identifier}.png`) || logo;
          partData.push(partObj);
        });
      });
      setPartDataList(partData);
      setLogoData(logo);
      setOverallData(data);

      const queryPaylod = queryString.parse(history.location.search);
      if (Object.entries(queryPaylod).length) {
        processQueryPayload(queryPaylod, data, partData);
      } else {
        setCurrentDataList(data.tracksItem);
        setCurrentTab(null);
      }
      setLoading(false);
    };
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processStatusFlag = (status_flag) => {
    const statusFlags = status_flag.map((each) => {
      switch (each) {
        case 'Delayed':
          return { id: 'delayed', displayText: 'Delayed', color: '#F62227' };
        case 'Expedited':
          return { id: 'expedited', displayText: 'Expedited', color: 'orange' };
        case 'On-Time':
          return { id: 'on-time', displayText: 'On-Time', color: '#00B200' };
        default:
          return {};
      }
    });

    const ids = {};
    const uniqueFlags = [];

    statusFlags.forEach((flag) => {
      if (!ids[flag.id]) {
        ids[flag.id] = flag.id;
        uniqueFlags.push(flag);
      }
    });

    uniqueFlags.sort((a, b) => a.id > b.id);

    return uniqueFlags;
  };

  const renderGridCardComponentDefault = (eachItemData, id, containerStyle) => {
    const { status_flag } = eachItemData.represents;
    const statusFlags = processStatusFlag(status_flag);
    return (
      <GridCardComponent
        key={id}
        onClick={() => {
          history.push(`${history.location.pathname}/detail?itemId=${eachItemData.id}`);
        }}
        containerStyle={containerStyle}
        color={statusFlags.length ? statusFlags[0].color : 'lightgrey'}
        gridCardContent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <GridCardContentDefault
            orderNumber={eachItemData.name}
            image={logoData}
            rfid={eachItemData.sensorProfile.vid}
            statusFlags={statusFlags}
            location={eachItemData.lastKnownLocation.name}
            expiryDate={eachItemData.represents.due_date}
            lastUpdatedTime={eachItemData.lastUpdatedTime}
          />
        }
      />
    );
  };

  const renderGridCardComponentParts = (eachItemData, id, containerStyle) => {
    const { status_flag } = eachItemData.represents;
    const statusFlags = processStatusFlag(status_flag);
    return (
      <GridCardComponent
        key={`${id}${eachItemData.orderId}`}
        onClick={() => {
          history.push(`${history.location.pathname}/detail?itemId=${eachItemData.orderId}`);
        }}
        containerStyle={containerStyle}
        color={statusFlags.length ? statusFlags[0].color : 'lightgrey'}
        gridCardContent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <GridCardContentParts
            partNumber={
              eachItemData.name
                ? `${eachItemData.identifier} - ${eachItemData.name}`
                : eachItemData.identifier
            }
            orderNumber={eachItemData.orderNumber}
            image={eachItemData.image}
            statusFlags={statusFlags}
            location={eachItemData.lastKnownLocation}
            quantity={eachItemData.quantity}
          />
        }
      />
    );
  };

  const renderGridCardComponentGroupbyLocation = (eachData, id, containerStyle) => {
    const departmentOverview = overviewData.graphDataList.filter(
      (each) => each.departmentID === eachData.id
    );
    let color = '';
    switch (departmentOverview[0].currentThresholdState) {
      case 'Healthy':
        color = '#00B200';
        break;
      case 'Warning':
        color = '#FFCF0F';
        break;
      case 'Critical':
        color = '#F62227';
        break;
      default:
        color = 'lightgrey';
        break;
    }

    return (
      <GridCardComponent
        onClick={() => {
          handleTabClick(null, eachData.tracksItem);
          history.replace(`${history.location.pathname}${history.location.search}&id=${id}`);
          breadcrumbsDataList.push({ id, value: eachData.name });
          setBreadcrumbsDataList(breadcrumbsDataList);
          calculateOverviewData(eachData.tracksItem);
        }}
        key={id}
        color={color}
        containerStyle={containerStyle}
        gridCardContent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <GridCardContentGroupbyLocation
            locationName={eachData.name}
            numOfOrders={eachData.tracksItem.length}
            lastUpdatedTime={eachData.lastUpdatedTime}
            averageVelocity="N/A"
          />
        }
      />
    );
  };

  const renderGridCard = () => {
    switch (currentTab) {
      case 'all-parts':
        return renderGridCardComponentParts;
      case 'location':
        return renderGridCardComponentGroupbyLocation;
      default:
        return renderGridCardComponentDefault;
    }
  };

  const filterFunction = (input, each) => {
    let match = false;
    const lowerCaseInput = input.toLowerCase();
    const { name, sensorProfile, lastKnownLocation, represents, orderNumber } = each;
    const { status_flag } = represents || {};

    switch (currentTab) {
      case 'all-parts':
        if (
          name.toLowerCase().includes(lowerCaseInput) ||
          orderNumber.toLowerCase().includes(lowerCaseInput) ||
          (lastKnownLocation && lastKnownLocation.toLowerCase().includes(lowerCaseInput))
        ) {
          match = true;
        }
        status_flag.forEach((eachflag) => {
          if (eachflag.toLowerCase().includes(lowerCaseInput)) {
            match = true;
          }
        });

        break;
      case 'location':
        if (name.toLowerCase().includes(lowerCaseInput)) {
          match = true;
        }
        break;
      default:
        if (
          name.toLowerCase().includes(lowerCaseInput) ||
          sensorProfile.vid.toLowerCase().includes(lowerCaseInput) ||
          (lastKnownLocation.name && lastKnownLocation.name.toLowerCase().includes(lowerCaseInput))
        ) {
          match = true;
        }
        status_flag.forEach((eachflag) => {
          if (eachflag.toLowerCase().includes(lowerCaseInput)) {
            match = true;
          }
        });
        break;
    }
    return match;
  };
  const listViewFilterFunction = (input, each) => {
    let match = false;
    const lowerCaseInput = input.toLowerCase();
    const { name, vid, lastKnownLocation, statusFlags, orderNumber, partName, partId } = each;
    switch (currentTab) {
      case 'all-parts':
        if (
          orderNumber.toLowerCase().includes(lowerCaseInput) ||
          vid.toLowerCase().includes(lowerCaseInput) ||
          partName.toLowerCase().includes(lowerCaseInput) ||
          partId.toLowerCase().includes(lowerCaseInput) ||
          (lastKnownLocation && lastKnownLocation.toLowerCase().includes(lowerCaseInput))
        ) {
          match = true;
        }
        statusFlags.forEach((eachflag) => {
          if (eachflag.displayText.toLowerCase().includes(input.toLowerCase())) {
            match = true;
          }
        });
        break;
      case 'location':
        if (name.toLowerCase().includes(lowerCaseInput)) {
          match = true;
        }
        if (statusFlags.displayText.toLowerCase().includes(input.toLowerCase())) {
          match = true;
        }
        break;
      default:
        if (
          name.toLowerCase().includes(lowerCaseInput) ||
          vid.toLowerCase().includes(lowerCaseInput) ||
          (lastKnownLocation && lastKnownLocation.toLowerCase().includes(lowerCaseInput))
        ) {
          match = true;
        }
        statusFlags.forEach((eachflag) => {
          if (eachflag.displayText.toLowerCase().includes(input.toLowerCase())) {
            match = true;
          }
        });
        break;
    }
    return match;
  };

  if (loading) {
    return <LoadingCircle />;
  }
  return (
    <TrackPageComponent
      overviewComponent={
        <OrderOverviewComponent
          health={overviewData.health}
          numOfOrders={overviewData.numOfOrders}
          numOfDelayed={overviewData.numOfDelayed}
          numOfExpedited={overviewData.numOfExpedited}
          numOfOnTime={overviewData.numOfOnTime}
          averageVelocity={overviewData.averageVelocity}
          graphDataList={overviewData.graphDataList}
          logoImage={logoData}
        />
      }
      buttonTitle="+ Add Order"
      groupbyComponent={
        <GroupbySideBarComponent
          focusedTab={currentTab || focusedTab}
          tabStructure={groupbyStructure}
        />
      }
      breadcrumbsComponent={
        <BreadcrumbsComponent
          dataList={breadcrumbsDataList}
          onHomeClick={() => {
            handleTabClick('all-orders', overallData.tracksItem);
            calculateOverviewData(overallData.tracksItem, overallData.locations);
            setFocusedTab(null);
            history.replace(`${history.location.pathname}`);
            setBreadcrumbsDataList([]);
          }}
        />
      }
      dataViewComponent={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <TrackPageDataViewComponent
          dataList={currentDataList}
          sortSchema={getSortSchema()}
          filterFunc={filterFunction}
          listViewFilterFunc={listViewFilterFunction} // needs to be refactor
          gridCardComponent={renderGridCard()}
          listViewStructure={listViewHeaderStructureListControl(currentTab)} // needs to be refactor
          listViewDataList={listViewDataListControl(currentTab, currentDataList)} // needs to be refactor
          // needs to be refactor
          handleListViewClick={
            !currentTab || currentTab === 'all-parts'
              ? ({ currentTarget }) => {
                  history.push(`${history.location.pathname}/detail?itemId=${currentTarget.id}`);
                }
              : ({ currentTarget }) => {
                  const location = currentDataList.findIndex(
                    (each) => each.id === currentTarget.id
                  );
                  handleTabClick(null, currentDataList[location].tracksItem);
                  history.replace(
                    `${history.location.pathname}${history.location.search}&id=${currentTarget.id}`
                  );
                  breadcrumbsDataList.push({
                    id: currentTarget.id,
                    value: currentTarget.getAttribute('name')
                  });
                  setBreadcrumbsDataList(breadcrumbsDataList);
                }
          }
        />
      }
    />
  );
};
export default withRouter(OrderTrackPageFeature);

// TODO: this function needs to be change when ListView get refactored
const listViewHeaderStructureListControl = (groupby) => {
  switch (groupby) {
    case 'location':
      return [
        { id: 'name', title: true, label: 'Location' },
        {
          id: 'statusFlags',
          label: 'Status',
          // eslint-disable-next-line react/display-name
          renderComponent: (status) => {
            return (
              <div
                className={Style.list_view_status}
                style={{ backgroundColor: status && status.color }}
              >
                {status ? status.displayText : '-'}
              </div>
            );
          }
        },
        { id: 'numOfOrders', label: 'Total No. of Orders' },
        { id: 'numOfExpedited', label: 'Expedited Orders' },
        { id: 'velocityRate', label: 'Velocity Rate' },
        {
          id: 'lastUpdatedTime',
          label: 'Last Updated',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'hh:mm A MMM D') : '-';
          }
        }
      ];
    case 'all-parts':
      return [
        { id: 'partId', label: 'Part #' },
        { id: 'partName', label: 'Part Name' },
        { id: 'orderNumber', title: true, label: 'Order #' },
        { id: 'vid', label: 'Tag #' },
        {
          id: 'lastKnownLocation',
          label: 'Current Location'
        },
        {
          id: 'statusFlags',
          label: 'Status',
          renderComponent: (statusList = []) => {
            if (statusList.length) {
              return statusList.map((eachStatus) => {
                return (
                  <div
                    className={Style.list_view_status}
                    style={{
                      backgroundColor: eachStatus.color
                    }}
                    key={eachStatus.id}
                  >
                    {eachStatus.displayText}
                  </div>
                );
              });
            }
            return '-';
          }
        },
        {
          id: 'due_date',
          label: 'Due Date',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'MMM Do') : '-';
          }
        },
        {
          id: 'lastUpdatedTime',
          label: 'Last Updated',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'hh:mm A MMM D') : '-';
          }
        },
        {
          id: 'startTime',
          label: 'Created Date',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'MM/DD/YYYY') : '-';
          }
        }
      ];
    default:
      return [
        { id: 'name', title: true, label: 'Order #' },
        { id: 'vid', label: 'Tag #' },
        {
          id: 'lastKnownLocation',
          label: 'Current Location'
        },
        {
          id: 'statusFlags',
          label: 'Status',
          renderComponent: (statusList = []) => {
            if (statusList.length) {
              return statusList.map((eachStatus) => {
                return (
                  <div
                    className={Style.list_view_status}
                    style={{
                      backgroundColor: eachStatus.color
                    }}
                    key={eachStatus.id}
                  >
                    {eachStatus.displayText}
                  </div>
                );
              });
            }
            return '-';
          }
        },
        {
          id: 'due_date',
          label: 'Due Date',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'MMM Do') : '-';
          }
        },
        {
          id: 'lastUpdatedTime',
          label: 'Last Updated',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'hh:mm A MMM D') : '-';
          }
        },
        {
          id: 'startTime',
          label: 'Created Date',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'MM/DD/YYYY') : '-';
          }
        }
      ];
  }
};

const listViewDataListControl = (groupby, dataList) => {
  const CRITICAL = 0.8;
  const WARNING = 0.6;
  switch (groupby) {
    case 'location':
      return dataList.map((each) => {
        const { tracksItem, id, name, lastUpdatedTime } = each;
        let numOfExpedited = 0;
        let numOfDelayed = 0;
        tracksItem.forEach((eachItem) => {
          if (eachItem.represents.status_flag.includes('Expedited')) {
            numOfExpedited += 1;
          } else if (eachItem.represents.status_flag.includes('Delayed')) {
            numOfDelayed += 1;
          }
        });
        const statusFlags = { displayText: 'Healthy', color: '#00B200' };
        if ((numOfExpedited + numOfDelayed) / tracksItem.length >= CRITICAL) {
          statusFlags.displayText = 'Critical';
          statusFlags.color = 'red';
        } else if ((numOfExpedited + numOfDelayed) / tracksItem.length >= WARNING) {
          statusFlags.displayText = 'Warning';
          statusFlags.color = 'orange';
        }

        return {
          id,
          name,
          numOfOrders: tracksItem.length,
          lastUpdatedTime,
          velocityRate: 'N/A',
          statusFlags,
          numOfExpedited
        };
      });

    case 'all-parts':
      return dataList.map((each) => {
        const {
          due_date,
          orderId,
          orderNumber,
          id,
          name,
          quantity,
          startTime,
          vid,
          lastKnownLocation,
          represents,
          lastUpdatedTime
        } = each;
        const { status_flag } = represents;
        const statusFlags = status_flag.map((eachFlag) => {
          switch (eachFlag) {
            case 'Delayed':
              return { id: 'delayed', displayText: 'Delayed', color: '#F62227' };
            case 'Expedited':
              return { id: 'expedited', displayText: 'Expedited', color: 'orange' };
            case 'On-Time':
              return { id: 'on-time', displayText: 'On-Time', color: '#00B200' };
            default:
              return {};
          }
        });

        const ids = {};
        const uniqueFlags = [];

        statusFlags.forEach((flag) => {
          if (!ids[flag.id]) {
            ids[flag.id] = flag.id;
            uniqueFlags.push(flag);
          }
        });

        return {
          key: `${orderId}${id}`,
          id: orderId,
          partId: id,
          partName: name,
          orderNumber,
          quantity,
          startTime,
          due_date,
          vid,
          lastKnownLocation: lastKnownLocation || '-',
          lastUpdatedTime,
          statusFlags: uniqueFlags
        };
      });
    default:
      return dataList.map((each) => {
        const { status_flag } = each.represents;
        const statusFlags = status_flag.map((eachFlag) => {
          switch (eachFlag) {
            case 'Delayed':
              return { id: 'delayed', displayText: 'Delayed', color: '#F62227' };
            case 'Expedited':
              return { id: 'expedited', displayText: 'Expedited', color: 'orange' };
            case 'On-Time':
              return { id: 'on-time', displayText: 'On-Time', color: '#00B200' };
            default:
              return {};
          }
        });

        const ids = {};
        const uniqueFlags = [];

        statusFlags.forEach((flag) => {
          if (!ids[flag.id]) {
            ids[flag.id] = flag.id;
            uniqueFlags.push(flag);
          }
        });

        uniqueFlags.sort((a, b) => a.id > b.id);
        return {
          id: each.id,
          name: each.name,
          vid: each.sensorProfile.vid,
          lastKnownLocation: each.lastKnownLocation.name || '-',
          lastUpdatedTime: each.lastUpdatedTime,
          startTime: each.startTime,
          due_date: each.represents.due_date,
          statusFlags: uniqueFlags
        };
      });
  }
};
