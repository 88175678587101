import React from 'react';
import { Card } from 'mdbreact';
import './UsersPage.css';
import DeleteIcon from '@material-ui/icons/Delete';
import { withRouter } from 'react-router-dom';
import UserService from '../../services/UserService';
import UserBasePage from './UserBasePage';
import LoadingCircle from '../../components/loading/LoadingCircle';
import { LocalCacheService } from '../../services/local-cache-service';

class UsersPage extends UserBasePage {
  constructor(props) {
    super(props);
    this.state = {
      users: undefined,
      superAdminUsers: undefined,
      itemHover: '',
      // inputUserSearch: '',
      switchPage: false,
      customerConfig: undefined,
      loading: true,
      isAdmin: false
    };
  }

  componentDidMount() {
    const context = this;

    super.initializeUserService(UserService).then(async () => {
      await context.fetchUsers();
    });
  }

  async fetchUsers() {
    /**
     * @type {UserProfile}
     */
    const userProfile = LocalCacheService.loadUserProfile();
    const isAdmin = userProfile.isUserSuperAdmin();
    const tenantId = userProfile.getTenantId();
    const users = await UserService.getUsersInGroup(tenantId);
    this.setState({ users, isAdmin, loading: false });
  }

  handleDeleteClick = async (user) => {
    const { isAdmin } = this.state;
    if (!isAdmin) {
      window.alert('Sorry, you are not an admin user.');
    } else {
      const firstName = user.Attributes.find((obj) => {
        return obj.Name === 'given_name';
      });
      const lastName = user.Attributes.find((obj) => {
        return obj.Name === 'family_name';
      });
      const email = user.Attributes.find((obj) => {
        return obj.Name === 'email';
      });
      const username = user.Username;
      let confirmedMessage = 'Are you sure you want to delete user: ';
      if (firstName && lastName) {
        confirmedMessage += `${firstName.Value} ${lastName.Value}?`;
      } else {
        confirmedMessage += `${email.Value}?`;
      }
      const confirmed = window.confirm(confirmedMessage);
      if (confirmed) {
        const context = this;

        UserService.deleteUser(username).then(async () => {
          await context.fetchUsers();
        });
      }
    }
  };

  render() {
    const { loading, itemHover, users } = this.state;
    if (loading) {
      return <LoadingCircle />;
    }
    return (
      <Card className="users-list">
        <div className="users-list-title">Manage Users</div>
        <div
          onClick={() => {
            const { isAdmin } = this.state;
            if (isAdmin) {
              this.props.history.push('/users/create');
            } else {
              window.alert('Sorry, you are not an admin user.');
            }
          }}
          className="btn default-button add"
        >
          + Add New User
        </div>

        <table className="users-table">
          <thead>
            <tr className="users-header">
              <th className="users-header-username">Email</th>
              <th className="users-header-firstName">First Name</th>
              <th className="users-header-lastName">Last Name</th>
              <th className="users-header-deleteButton"> </th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => {
                const email = UserService.getAttribute(user, 'email');
                const firstName = UserService.getAttribute(user, 'given_name');
                const lastName = UserService.getAttribute(user, 'family_name');
                const isSuperAdmin = UserService.getAttribute(user, 'custom:admin');
                const itemRow = [
                  <tr
                    className={
                      itemHover === user.Username ? 'user-row user-view-active' : 'user-row'
                    }
                    onMouseOver={() => this.setState({ itemHover: user.Username })}
                    key={user.Username}
                    id={user.Username}
                  >
                    <td> {email} </td>
                    <td> {firstName ? firstName : '-'} </td>
                    <td> {lastName ? lastName : '-'} </td>

                    <td>
                      {itemHover === user.Username && !(isSuperAdmin === 'true') ? (
                        <div className="deleteButton" onClick={() => this.handleDeleteClick(user)}>
                          <DeleteIcon />
                        </div>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ];
                return itemRow;
              })}
          </tbody>
        </table>
      </Card>
    );
  }
}

export default withRouter(UsersPage);

/*
Search functionality for users is not yet implemented.
Put back under <div className="users-list-title">Manage Users</div> when implemented
<InputGroup className="user-search-bar">
    <FormControl id="search-form" type="text" placeholder="Search users" onChange={this.handleInputUserSearch} value={this.state.inputUserSearch}/>
</InputGroup>
*/
