import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import UsersPage from '../pages/users/UsersPage';
import AlertsPage from '../pages/alert/AlertsPage';
import CreateUserPage from '../pages/users/CreateUser';
import UserProfilePage from '../pages/users/UserProfilePage';
import InventoryComponent from '../components/TrackPageComponents/Inventory/InventoryComponent';
import AddItemType from '../pages/track/AddItemType';
import AssetApp from '../apps/asset-app/AssetApp';
import { UnauthorizedPage } from '../pages/error/UnauthorizedPage';
import AuthorizedProcessAppRoute from './AuthorizedProcessAppRoute';
import InventoryRequestsPage from '../pages/inventory-requests/InventoryRequestsPage';
import OrderApp from '../apps/order-app/OrderApp';
import { MyFacility } from '../apps/my-facility';

// RedirectPath is on temporary used before dashboard page is implemented
const Routes = ({ redirectPath }) => {
  return (
    <Switch>
      <Route path="/error/authorized" component={UnauthorizedPage} />
      <Route exact path="/">
        {/* // RedirectPath is on temporary used before dashboard page is implemented */}
        <Redirect to={redirectPath} />
      </Route>
      <AuthorizedProcessAppRoute path="/work-order" component={OrderApp} />
      <AuthorizedProcessAppRoute path="/inventory/create" component={AddItemType} />
      <AuthorizedProcessAppRoute path="/inventory" component={InventoryComponent} />
      <AuthorizedProcessAppRoute path="/users/create" component={CreateUserPage} />
      <AuthorizedProcessAppRoute path="/users" component={UsersPage} />
      <AuthorizedProcessAppRoute path="/my-facility" component={MyFacility} />
      <AuthorizedProcessAppRoute path="/alerts" component={AlertsPage} />
      <AuthorizedProcessAppRoute path="/profile" component={UserProfilePage} />
      <AuthorizedProcessAppRoute path="/asset" component={AssetApp} />
      <AuthorizedProcessAppRoute path="/inventory-requests" component={InventoryRequestsPage} />
    </Switch>
  );
};

export default Routes;

Routes.propTypes = {
  redirectPath: PropTypes.string.isRequired
};
