/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'mdbreact';
import { Link, withRouter } from 'react-router-dom';
import TrackPageComponentStyle from './TrackPageComponent.module.css';

const TrackPageComponent = ({
  overviewComponent,
  groupbyComponent,
  buttonTitle,
  breadcrumbsComponent,
  dataViewComponent,
  history
}) => {
  return (
    <Card>
      <CardBody className={TrackPageComponentStyle.card_body}>
        {overviewComponent}
        <div className={TrackPageComponentStyle.seperator} />
        <div className={TrackPageComponentStyle.bottom_component_style}>
          <div>
            <Link
              className={TrackPageComponentStyle.create_button}
              to={`${history.location.pathname}/create`}
            >
              {buttonTitle}
            </Link>
            {groupbyComponent}
          </div>
          <div
            className={`${TrackPageComponentStyle.seperator} ${TrackPageComponentStyle.seperator_vertical}`}
          />
          <div className={TrackPageComponentStyle.data_view_group}>
            {breadcrumbsComponent}
            <div className={TrackPageComponentStyle.data_view_container}>{dataViewComponent}</div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default withRouter(TrackPageComponent);

TrackPageComponent.defaultProps = {
  overviewComponent: undefined,
  groupbyComponent: undefined,
  buttonTitle: '',
  breadcrumbsComponent: undefined,
  dataViewComponent: undefined,
  history: {}
};

TrackPageComponent.propTypes = {
  overviewComponent: PropTypes.object,
  groupbyComponent: PropTypes.object,
  buttonTitle: PropTypes.string,
  breadcrumbsComponent: PropTypes.object,
  dataViewComponent: PropTypes.object,
  history: PropTypes.object
};
