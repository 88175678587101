import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import OrderTrackPageFeature from '../../features/order-track-page-feature/OrderTrackPageFeature';
import OrderDetailPage from '../../components/TrackPageComponents/Order/OrderDetailPage';
import AddOrder from '../../pages/track/AddOrder';

const OrderApp = () => {
  return (
    <Fragment>
      <Route exact path="/work-order" component={OrderTrackPageFeature} />
      <Route path="/work-order/create" component={AddOrder} />
      <Route path="/work-order/detail" component={OrderDetailPage} />
    </Fragment>
  );
};

export default OrderApp;
