import React, { Component } from 'react';
import EditableTableComponent from '../../components/editable-table/EditableTableComponent';
import { XemelgoService } from '../../services/XemelgoService';

export default class LowStockRuleContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationDropdownOptions: [],
      itemTypeData: []
    };

    this.handleRuleConditionRequests = this.handleRuleConditionRequests.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }

  componentDidMount() {
    const { ruleConditionsList = [], locationDataList = [], itemTypeData = [] } = this.props;
    this.onLoad(ruleConditionsList, locationDataList, itemTypeData);
  }

  componentWillReceiveProps(newProps) {
    const ruleConditionsList = [...newProps.ruleConditionsList];
    const oldRuleConditionsList = [...this.props.ruleConditionsList];
    if (ruleConditionsList !== oldRuleConditionsList) {
      const { locationDataList = [], itemTypeData = [] } = newProps;
      this.onLoad(ruleConditionsList, locationDataList, itemTypeData);
    }
  }

  onLoad = (ruleConditionsList, locationDataList, itemTypeData) => {
    const conditions = ruleConditionsList.map(ruleCondition => {
      return this.parseConditionData(ruleCondition);
    });

    const locationDropdownOptions = locationDataList.map(eachLocation => {
      return { objectTypeName: eachLocation.name, objectTypeId: eachLocation.id };
    });

    // remove items that already have conditions, from drop down options
    conditions.forEach(eachCondition => {
      itemTypeData = itemTypeData.filter(
        eachOption => eachOption.objectTypeName !== eachCondition.itemType
      );
    });

    this.setState({
      conditions,
      locationDropdownOptions,
      itemTypeData
    });
  };

  parseConditionData = ruleCondition => {
    const { tags } = ruleCondition;
    const conditionId = ruleCondition.id;

    let itemType = '';
    let threshold = '';
    // let locationName = '';
    const ruleName = ruleCondition.name;
    const subscribed = ruleCondition.hasSubscriptions;

    if (tags) {
      itemType = tags.itemType;
      threshold = tags.threshold;
      // locationName = tags.locationName;
    } else {
      itemType = ruleCondition.conditions.find(condition => condition.property === 'itemType')
        .value;
      threshold = ruleCondition.conditions.find(condition => condition.property === 'itemTypeCount')
        .value;
      // locationName = ruleCondition.conditions.find(condition => condition.property === 'locationId')
      // .value;
    }

    return {
      // ruleId,
      id: conditionId, // this is for component to keep track
      conditionId,
      itemType,
      threshold,
      // locationName,
      subscribed,
      ruleName
    };
  };

  buildUpdateRuleConditionPayloadFromRequest = requestPayload => {
    const tags = {};
    const conditions = [];
    const id = requestPayload.conditionId;
    const { ruleName } = requestPayload;
    // tags.locationName = requestPayload.locationName;
    tags.itemType = requestPayload.itemType;
    tags.threshold = requestPayload.threshold * 1;

    conditions.push({ property: 'itemTypeCount', op: '<', value: tags.threshold });

    const updatePayload = {
      id,
      ruleName,
      tags,
      conditions
    };

    return updatePayload;
  };

  buildCreateRuleConditionPayloadFromRequest = requestPayload => {
    // const { locationName, itemType, threshold, locationId } = requestPayload;
    const { itemType, threshold } = requestPayload;

    const createPayload = {
      // name: `lowStock_${locationId}_${itemType}`,
      name: `lowStock_${itemType}`,
      tags: {
        // locationName,
        itemType,
        threshold
      },
      conditions: [
        // {
        //   property: 'locationId',
        //   op: '=',
        //   value: locationId
        // },
        {
          property: 'itemType',
          op: '=',
          value: itemType
        },
        {
          property: 'itemTypeCount',
          op: '<',
          value: threshold * 1
        }
      ]
    };

    return createPayload;
  };

  handleValidation = payloads => {
    // const { conditions } = this.state;

    const inputErrorMap = {};

    const validatedObject = {
      errorExists: false,
      errorMap: inputErrorMap
    };

    payloads.forEach(payloadItem => {
      const inputError = {
        threshold: false,
        // locationName: false,
        itemType: false
      };

      if (payloadItem._event !== 'pending_delete') {
        // const { threshold, locationName, locationId, itemType } = payloadItem;
        const { threshold, itemType } = payloadItem;
        const currentRowId = payloadItem.id;

        // if (!locationName || locationName === '') {
        //   inputError.locationName = true;
        // }
        //
        // if (!locationId || locationId === '') {
        //   inputError.locationName = true;
        // }

        if (!itemType || itemType === '') {
          inputError.itemType = true;
        }

        /**
         * (+threshold === +threshold) is called a 'self check' that is used to check if it is a valid number
         * '+variable' converts the value into a number.
         */

        // eslint-disable-next-line no-self-compare
        if (threshold === '' || !threshold || !(+threshold === +threshold) || +threshold <= 0) {
          inputError.threshold = true;
        }

        inputErrorMap[currentRowId] = inputError;
      }
    });

    const BreakForEachLoop = { exception: 'Error exists.' };

    try {
      Object.keys(inputErrorMap).forEach(key => {
        if (inputErrorMap[key].threshold) {
          validatedObject.errorExists = true;
          throw BreakForEachLoop;
        } else {
          validatedObject.errorExists = false;
        }
      });
    } catch (e) {
      alert('Please enter valid values in the highlighted field(s).');
      if (e !== BreakForEachLoop) throw e;
    }

    validatedObject.errorMap = inputErrorMap;

    return validatedObject;
  };

  handleAutoFill = (headerId, newValue, data) => {
    const { locationDropdownOptions } = this.state;

    const autoFillObject = {
      data,
      changed: true
    };

    if (headerId === 'itemType') {
      const location = locationDropdownOptions.find(
        location => location.objectTypeName === 'Stock Room 1'
      );
      const locationId = location ? location.objectTypeId : '';
      // const itemIndex = locationDropdownOptions
      //   .map(i => {
      //     return i.objectTypeName;
      //   })
      //   .indexOf(newValue);
      // const itemId = locationDropdownOptions[itemIndex].objectTypeId;
      data.locationId = locationId;
      data.locationName = location.objectTypeName;
    }
    autoFillObject.data = data;

    if (data.itemType === '' && data.threshold === '') {
      autoFillObject.changed = false;
    }

    return autoFillObject;
  };

  handleRuleConditionRequests = async requests => {
    console.log('requesst', requests);
    const { ruleId } = this.props;
    if (!requests || requests.length === 0) {
      return;
    }
    const RulePageClient = XemelgoService.getClient().getRulePageClient();

    await Promise.all(
      requests.map(async request => {
        const event = request._event;
        let payload;
        switch (event) {
          case 'pending_new':
            payload = this.buildCreateRuleConditionPayloadFromRequest(request);
            await RulePageClient.createRuleCondition(
              payload.name,
              payload.tags,
              payload.conditions,
              ruleId
            );
            break;
          case 'pending_update':
            payload = this.buildUpdateRuleConditionPayloadFromRequest(request);
            await RulePageClient.updateRuleCondition(
              payload.id,
              payload.ruleName,
              payload.tags,
              payload.conditions
            );
            break;
          case 'pending_delete':
            await RulePageClient.removeRuleCondition(request.conditionId, false);
            break;
          default:
            console.log(`Unsupported Event[Name:${event}]`);
            break;
        }
      })
    );

    await this.props.onLoad();
    const { ruleConditionsList = [] } = this.props;
    const conditions = ruleConditionsList.map(ruleCondition => {
      return this.parseConditionData(ruleCondition);
    });

    return conditions.map(condition => condition.id);
  };

  handleSubscriptionChange = async (ruleConditionId, subscribed) => {
    // const { ruleConditionsList } = this.props;

    const subscribedRuleConditionIdList = [];
    const unsubscribedRuleConditionIdList = [];
    if (subscribed) {
      subscribedRuleConditionIdList.push(ruleConditionId);
    } else {
      unsubscribedRuleConditionIdList.push(ruleConditionId);
    }

    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    await RulePageClient.updateSubscriptionForRuleConditions(
      subscribedRuleConditionIdList,
      unsubscribedRuleConditionIdList
    );
    await this.props.onLoad();
  };

  render() {
    // const { conditions, locationDropdownOptions, itemTypeData } = this.state;
    const { conditions, itemTypeData } = this.state;

    const headers = [
      // {
      //   displayName: '',
      //   id: 'subscribed',
      //   cell: {
      //     input: 'switch',
      //     display: 'switch',
      //     modifiable: true // whether it can be edited after being added
      //   }
      // },
      // {
      //   displayName: 'Location',
      //   id: 'locationName',
      //   cell: {
      //     input: 'dropdown',
      //     data: locationDropdownOptions,
      //     display: 'text',
      //     modifiable: false // whether it can be edited after being added
      //   }
      // },
      {
        displayName: 'Item',
        id: 'itemType',
        cell: {
          input: 'dropdown',
          data: itemTypeData,
          display: 'text',
          modifiable: false // whether it can be edited after being added
        }
      },
      {
        displayName: 'Threshold',
        id: 'threshold',
        cell: {
          input: 'text',
          display: 'text',
          modifiable: true // whether it can be edited after being added
        }
      }
    ];

    return (
      <div>
        <div>
          <p className="tabbedSectionComponent-contentTitle">Low Stock</p>
          <p style={{ color: '#343434' }}>
            Sends a notification if the count of items at any location falls below the set
            threshold.
          </p>
        </div>
        <div className="note">
          <span>Receive notifications for the items listed:</span>
        </div>
        <EditableTableComponent
          headers={headers}
          dataList={conditions}
          handleChangesFn={this.handleRuleConditionRequests}
          handleValidationFn={this.handleValidation}
          handleSubscriptionChange={this.handleSubscriptionChange}
          // handleAutoFillFn={this.handleAutoFill}
        />
      </div>
    );
  }
}
