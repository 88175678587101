import { createVerify } from 'crypto';
import { AbstractApi } from '../abstract-api';

const RequestToResourceMap = {
  rules: 'rule',
  ruleConditions: 'rule-condition',
  locations: 'location',
  detectors: 'detector',
  activeTrackingSessionsState: 'active-tracking-sessions-state',
  trackingSessions: 'tracking-sessions',
  itemTypes: 'item-types',
  items: 'item',
  subscriptionProfiles: 'subscription-profiles'
};

/**
 * @param requestResource
 * @returns { null| AbstractResourceManager }
 */
const getResourceManager = (requestResource) => {
  const resourceName = RequestToResourceMap[requestResource];
  if (!resourceName) {
    return null;
  }

  try {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const module = require(`./resource-managers/${resourceName}-resource-manager`);
    if (!module) {
      return null;
    }

    return module.resourceManager;
  } catch (e) {
    console.error(e);
    return null;
  }
};

// eslint-disable-next-line no-unused-vars
const getFilterPropertyMap = (payload) => {
  const { arguments: args } = payload;
  if (!args) {
    return false;
  }

  const { filter } = args;
  if (!filter) {
    return false;
  }

  const { propertyFilters } = filter;
  if (!propertyFilters) {
    return false;
  }

  const filterPropertyMap = { ...propertyFilters };
  Object.keys(propertyFilters).forEach((propertyName) => {
    const filterInfo = propertyFilters[propertyName];
    const value = filterInfo.values[0];
    filterPropertyMap[propertyName] = value;
  });

  return filterPropertyMap;
};

// eslint-disable-next-line no-unused-vars
const getIdPayload = (payload) => {
  return payload.arguments && payload.arguments.ids && payload.arguments.ids[0];
};

// eslint-disable-next-line import/prefer-default-export
class QueryApi extends AbstractApi {
  // eslint-disable-next-line class-methods-use-this
  canMock(payload, headers) {
    const requestActions = Object.keys(payload);

    // all the requested resources must be mock-able for this to return true
    const canMockRequest = requestActions.reduce((mockable, requestAction) => {
      const rm = getResourceManager(requestAction);
      if (!rm) {
        return false;
      }

      const requestResourcePayload = payload[requestAction];
      return mockable && rm.supportMock('query', requestAction, requestResourcePayload, headers);
    }, true);

    return canMockRequest;
  }

  // eslint-disable-next-line class-methods-use-this
  execute(payload) {
    const requestedResources = Object.keys(payload);

    const responses = {};
    // all the requested resources must be mock-able for this to return true
    requestedResources.forEach((rr) => {
      const rm = getResourceManager(rr);
      const filterPropertyMap = getFilterPropertyMap(payload[rr]);
      if (filterPropertyMap) {
        responses[rr] = rm.filter(filterPropertyMap, payload[rr]);
      } else if (getIdPayload(payload[rr])) {
        responses[rr] = rm.get(payload[rr]);
      } else {
        responses[rr] = rm.list(payload[rr]);
      }
    });
    return responses;
  }
}

const api = new QueryApi();
// eslint-disable-next-line import/prefer-default-export
export { api };
