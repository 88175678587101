import { v4 as uuidV4 } from 'uuid';
import { AbstractResourceManager } from '../../abstract-resource-manager';

export const assetTypeArray = [
  {
    id: uuidV4(),
    name: 'Magentic Plug Weld Tool',
    identifier: 'WT'
  },
  {
    id: uuidV4(),
    name: 'Pliers',
    identifier: 'P'
  },
  {
    id: uuidV4(),
    name: 'Weld Fixture',
    identifier: 'WF'
  },
  {
    id: uuidV4(),
    name: 'Newacalox Welding Repair Tool',
    identifier: 'WR'
  },
  {
    id: uuidV4(),
    name: 'Wire Dispenser',
    identifier: 'WD'
  },
  {
    id: uuidV4(),
    name: 'Angle Grinder',
    identifier: 'G'
  },
  {
    id: uuidV4(),
    name: 'Glass Polishing Tool',
    identifier: 'GP'
  },
  {
    id: uuidV4(),
    name: 'Sanding Pad',
    identifier: 'SP'
  }
];

export const itemTypesArray = {
  labCoatType: {
    id: '500',
    name: 'Lab Coat',
    identifier: 'Lab Coat'
  },
  safetyGlassesType: {
    id: '501',
    name: 'Safety Glasses',
    identifier: 'Safety Glass'
  },
  weldinGogglesType: {
    id: '502',
    name: 'Welding Goggles',
    identifier: 'Welding Goggles'
  },
  shoeCoversType: {
    id: '503',
    name: 'Shoe Covers',
    identifier: 'Shoe Covers'
  },
  paintType: {
    id: '504',
    name: 'Paint - Silver',
    identifier: 'Paint Can'
  },
  loctiteType: {
    id: '505',
    name: 'Loctite Sealant',
    identifier: 'Loctite Sealant'
  },
  abrasivesType: {
    id: '506',
    name: '3M Abrasives',
    identifier: '3M Abrasive'
  },
  sandingBeltType: {
    id: '507',
    name: 'Sanding Belt',
    identifier: 'Sanding Belt'
  }
};

const init = (addItemFn) => {
  Object.keys(itemTypesArray).forEach((itemType) => {
    addItemFn(itemTypesArray[itemType]);
  });
};

// eslint-disable-next-line import/prefer-default-export
export class ItemTypesResourceManager extends AbstractResourceManager {
  constructor() {
    super('id');
    init(this.addItem.bind(this));
  }

  supportMock(api, action, payload, headers) {
    if (api === 'query') {
      return true;
    }
    return super.supportMock(api, payload, headers);
  }

  list() {
    const list = super.list();

    return list;
  }
}

const resourceManager = new ItemTypesResourceManager();
export { resourceManager };
