import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../../../components/div';
import { XButton } from '../../../../../components/xemelgo-button/x-button';
import './style.css';

export const FormFooter = ({ onCancel, onSave }) => {
  return (
    <Div className="edit-form-footer">
      <Div>
        <XButton onClick={onSave} className="save-button" label="Save" />
      </Div>
      <Div>
        <XButton label="Cancel" onClick={onCancel} />
      </Div>
    </Div>
  );
};

FormFooter.defaultProps = {
  onCancel: () => {},
  onSave: () => {}
};

FormFooter.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};
