import React from 'react';
import PropTypes from 'prop-types';
import GridCardContentPartsStyle from './GridCardContentParts.module.css';

const GridCardContentParts = ({
  image,
  orderNumber,
  partNumber,
  statusFlags,
  location,
  quantity
}) => {
  const renderStatus = () => {
    const ids = {};
    const uniqueFlags = [];

    statusFlags.forEach((flag) => {
      if (!ids[flag.id]) {
        ids[flag.id] = flag.id;
        uniqueFlags.push(flag);
      }
    });

    return (
      <div className={GridCardContentPartsStyle.status_group}>
        {uniqueFlags &&
          uniqueFlags.map((eachFlag) => (
            <p
              key={eachFlag.id}
              className={GridCardContentPartsStyle.status_style}
              style={{ color: eachFlag.color }}
            >
              {eachFlag.displayText}
            </p>
          ))}
      </div>
    );
  };
  return (
    <div className={GridCardContentPartsStyle.container}>
      <div className={GridCardContentPartsStyle.top_section}>
        <img className={GridCardContentPartsStyle.image} src={image} alt="failed to load img" />
        <div className={GridCardContentPartsStyle.top_left_container}>
          {renderStatus()}
          <div className={GridCardContentPartsStyle.top_title}>{partNumber}</div>
          <div className={GridCardContentPartsStyle.top_rfid_group}>
            Order#:
            <p className={GridCardContentPartsStyle.top_rfid_data}>{orderNumber}</p>
          </div>
        </div>
      </div>
      <div className={GridCardContentPartsStyle.bottom_section}>
        <div className={GridCardContentPartsStyle.bottom_data_group}>
          Last Seen
          <p className={GridCardContentPartsStyle.bottom_data_content}>{location || 'Unknown'}</p>
        </div>
        <div className={GridCardContentPartsStyle.bottom_data_group}>
          Quantity
          <p className={GridCardContentPartsStyle.bottom_data_content}>{quantity}</p>
        </div>
      </div>
    </div>
  );
};

export default GridCardContentParts;

GridCardContentParts.defaultProps = {
  location: null,
  lastUpdatedTime: null
};

GridCardContentParts.propTypes = {
  statusFlags: PropTypes.arrayOf(PropTypes.object).isRequired,
  image: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  location: PropTypes.string,
  lastUpdatedTime: PropTypes.number
};
