import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useXemelgoClient } from '../../services/xemelgo-service';
import { Div } from '../../components/div';
import { FloorPlan } from '../../components/floor-plan';
import { MissingImageBlock } from '../../components/missing-image-block';
import './style.css';

/**
 * This callback helps to construct a custom pin head
 * @callback BuildPinFunc
 * @param { string } id - pin's id. In our case, it is location id
 * @param { string } name - name of the location.
 * @param { string } color - color in hex.
 * @param { object } location - full location object if additional information is required.
 */

/**
 * This callback constructs tooltip for pin.
 * @callback BuildPinTooltipFn
 * @param { string } id - location id
 * @param { string } name - name of the location
 */

/**
 * Given a location, this feature will query for all of its (contained) children locations and filter out
 *  locations with pin coordinate information to display on the floor plan map.
 * @param [imageUrl] { string } - this property may be deprecated in the future since it may cause re-rendering issue. The image url can always be acquired through location object.
 * @param location { object } - location object.
 * @param buildPinFn { BuildPinFunc } - callback to construct custom pin head.
 * @param buildPinTooltipFn { BuildPinTooltipFn } - callback to construct pin's tooltip
 * @param [className] { string } - styling class name.
 * @returns {*}
 * @constructor
 */
export const LocationFloorPlan = ({
  imageUrl,
  location,
  buildPinFn,
  buildPinTooltipFn,
  onDimensionRatioChanged,
  className,
  pinType,
  showName,
  showTooltip
}) => {
  const [client] = useState(useXemelgoClient());
  const [innerLocations, setInnerLocations] = useState([]);
  const [imageHasResized, setImageHasResized] = useState(false);
  const [pins, setPins] = useState([]);
  const [floorPlanImageUrl, setFloorPlanImageUrl] = useState(imageUrl);

  /**
   * Future-proof support in case imageUrl will be deprecated in the future.
   * This effect will set floorPlanImageUrl if no imageUrl provided initially.
   */
  useEffect(() => {
    let isEffectCancelled = false;
    const url = location.getImagePath() || imageUrl;

    if (!isEffectCancelled) {
      setFloorPlanImageUrl(url);
    }

    return () => {
      isEffectCancelled = true;
    };
  }, [location, floorPlanImageUrl, imageUrl]);

  /**
   * This effect query for children locations from the given parent location property. The effect will filter out
   *  locations with pin information and construct the pin package for FloorPlan component
   */
  useEffect(() => {
    let isEffectCancelled = false;

    const locationClient = client.getLocationClient();
    const { id: parentLocId } = location;

    locationClient.getLocationChildrenByParentId(parentLocId).then((containedLocations) => {
      const hasPinLocations = containedLocations.filter((loc) => {
        const { pin } = loc.getResponseData();
        return pin;
      });

      if (!isEffectCancelled) {
        setInnerLocations(hasPinLocations);
      }
    });

    return () => {
      isEffectCancelled = true;
    };
  }, [client, location]);

  useEffect(() => {
    let isEffectCancelled = false;

    const locPins = innerLocations.map((loc) => {
      const { pin } = loc.getResponseData();
      const { x, y, color } = pin;
      const id = loc.getId();
      const name = loc.getName();
      const pinComponent = buildPinFn(id, name, color, loc);
      const tooltip = buildPinTooltipFn(id, name);
      return {
        id,
        x,
        y,
        color,
        tooltip: showTooltip ? tooltip : null,
        component: pinComponent,
        pinType,
        name: showName ? name : null
      };
    });

    if (!isEffectCancelled) {
      setPins(locPins);
      setImageHasResized(false);
    }

    return () => {
      isEffectCancelled = true;
    };
  }, [
    innerLocations,
    imageHasResized,
    buildPinFn,
    buildPinTooltipFn,
    pinType,
    showName,
    showTooltip
  ]);

  const onDimensionRatioChangedCallback = useCallback(
    (ratio) => {
      onDimensionRatioChanged(ratio);
      setImageHasResized(true);
    },
    [onDimensionRatioChanged]
  );

  return (
    <Fragment>
      {floorPlanImageUrl && (
        <FloorPlan
          imageUrl={floorPlanImageUrl}
          pins={pins}
          className={className}
          onDimensionRatioChanged={onDimensionRatioChangedCallback}
        />
      )}
      {!floorPlanImageUrl && (
        <Div className="no-image-block">
          <MissingImageBlock className="no-floor-plan-image" width="2.5em" height="2.5em" />
          <Div className="no-floor-plan-message">No floor plan available</Div>
        </Div>
      )}
    </Fragment>
  );
};

LocationFloorPlan.defaultProps = {
  buildPinFn: (id, name, color) => <Div style={{ color }}>{name}</Div>,
  buildPinTooltipFn: () => null,
  onDimensionRatioChanged: () => {},
  showName: false,
  showTooltip: true,
  className: '',
  imageUrl: null,
  pinType: null
};

LocationFloorPlan.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    getImagePath: PropTypes.func.isRequired
  }).isRequired,
  onDimensionRatioChanged: PropTypes.func,
  imageUrl: PropTypes.string,
  buildPinFn: PropTypes.func,
  buildPinTooltipFn: PropTypes.func,
  className: PropTypes.string,
  pinType: PropTypes.string,
  showName: PropTypes.bool,
  showTooltip: PropTypes.bool
};
