import React from 'react';
import PropTypes from 'prop-types';
import GridCardContentDefaultStyle from './GridCardContentDefault.module.css';
import { getFormattedDate } from '../../../../../common/Utilities';

const GridCardContentDefault = ({
  image,
  assetNumber,
  rfid,
  statusFlags,
  location,
  expiryDate,
  lastUpdatedTime
}) => {
  const renderStatus = () => {
    return (
      <div className={GridCardContentDefaultStyle.status_group}>
        {statusFlags &&
          statusFlags.map(eachFlag => (
            <p
              key={eachFlag.id}
              className={GridCardContentDefaultStyle.status_style}
              style={{ color: eachFlag.color }}
            >
              {eachFlag.displayText}
            </p>
          ))}
      </div>
    );
  };
  return (
    <div className={GridCardContentDefaultStyle.container}>
      <div className={GridCardContentDefaultStyle.top_section}>
        <img className={GridCardContentDefaultStyle.image} src={image} alt="failed to load img" />
        <div className={GridCardContentDefaultStyle.top_left_container}>
          {renderStatus()}
          <div className={GridCardContentDefaultStyle.top_title}>{assetNumber}</div>
          <div className={GridCardContentDefaultStyle.top_rfid_group}>
            Tag:
            <p className={GridCardContentDefaultStyle.top_rfid_data}>{rfid}</p>
          </div>
        </div>
      </div>
      <div className={GridCardContentDefaultStyle.bottom_section}>
        <div className={GridCardContentDefaultStyle.bottom_data_group}>
          Last Seen
          <p className={GridCardContentDefaultStyle.bottom_data_content}>{location || 'Unknown'}</p>
        </div>
        <div className={GridCardContentDefaultStyle.bottom_data_group}>
          Calibration Date
          <p className={GridCardContentDefaultStyle.bottom_data_content}>
            {expiryDate ? getFormattedDate(expiryDate, 'MMM DD, YYYY') : '-'}
          </p>
        </div>
        <div className={GridCardContentDefaultStyle.bottom_data_group}>
          Last Updated
          <p className={GridCardContentDefaultStyle.bottom_data_content}>
            {lastUpdatedTime ? getFormattedDate(lastUpdatedTime, 'hh:mm A - MMM D') : '-'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GridCardContentDefault;

GridCardContentDefault.defaultProps = {
  location: null,
  expiryDate: null,
  lastUpdatedTime: null
};

GridCardContentDefault.propTypes = {
  statusFlags: PropTypes.arrayOf(PropTypes.object).isRequired,
  image: PropTypes.string.isRequired,
  assetNumber: PropTypes.string.isRequired,
  rfid: PropTypes.string.isRequired,
  location: PropTypes.string,
  expiryDate: PropTypes.number,
  lastUpdatedTime: PropTypes.number
};
