import React, { PureComponent } from 'react';
import SortIcon from '@material-ui/icons/Sort';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Style from './css/SortingIcon.module.css';

export default class SortingIcon extends PureComponent {
  render() {
    const { sortFunction, sortSchema, attribute } = this.props;

    return (
      <Dropdown>
        <DropdownToggle className={Style.drop_down_toggle}>
          <SortIcon className={Style.icon_button} />
        </DropdownToggle>
        <DropdownMenu basic>
          {sortSchema.map(sort => {
            return (
              <DropdownItem
                key={sort.attribute}
                onClick={() => {
                  sortFunction(sort.attribute);
                }}
                active={sort.attribute === attribute}
                toggle={false}
              >
                {`${sort.name} `}
                <FontAwesomeIcon icon={sort.icon} />
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
