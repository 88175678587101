import React, { Fragment } from 'react';
import queryString from 'query-string';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { XemelgoService } from '../../../services/XemelgoService';
import ConfigurationService from '../../../services/ConfigurationService';
import Style from './css/OrderDetailPage.module.css';
import DetailCard from '../DetailCard';
import DataTable from '../DataTable';
import ProgressGraph from '../../RouteTracker/ProgressGraph';
import { getFormattedDate, msToTime } from '../../../common/Utilities';
import PartCrudTable from '../../../pages/track/PartCrudTable';
import LoadingCircle from '../../loading/LoadingCircle';
import { FloorPlan } from '../../floor-plan';
import { GpsPinHead } from '../../interactable-pin/gps-pin-head';

const floorPlanImageUrl = 'https://xemelgo-logo.s3-us-west-2.amazonaws.com/Alstom_Facility.png';

export default class OrderDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titleStructure: {},
      contentStructureList: [],
      orderState: {},
      route: [],
      locations: [],
      associatedParts: [],
      routeTitleList: [],
      partTitleList: [],
      nodeStructureList: [],
      itemId: '',
      hide: false,
      editPart: false,
      partCrudTableData: [],
      addLocation: false,
      locationIdToAdd: '',
      partInfo: [],
      partConstraint: {},
      showMap: false,
      loading: true
    };
  }

  componentWillUpdate() {
    const values = queryString.parse(window.location.search);
    const { itemId } = this.state;
    const newItemId = values.itemId;
    if (newItemId !== itemId) {
      this.onLoad(newItemId);
    }
  }

  componentDidMount() {
    ConfigurationService.getItemConfiguration().then((itemConfig) => {
      const { itemRelationshipConstraints, trackedItemTypes } = itemConfig;
      let part;
      trackedItemTypes.forEach((eachType) => {
        const { contains } = itemRelationshipConstraints[eachType];
        part = contains.part;
      });
      let partTitleList;
      if (part.quantity === 'many') {
        partTitleList = [
          { key: 'partNumber', value: 'Part Number' },
          { key: 'name', value: 'Name' },
          { key: 'quantity', value: 'Quantity' }
        ];
      } else {
        partTitleList = [
          { key: 'partNumber', value: 'Part Number' },
          { key: 'quantity', value: 'Quantity' }
        ];
      }
      this.setState({
        partConstraint: { quantity: part.quantity, delimiter: part.delimiter },
        partTitleList
      });
    });
    const values = queryString.parse(window.location.search);
    const { itemId } = values;
    this.onLoad(itemId);
  }

  onLoad = async (itemId) => {
    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();
    const { possibleDetectorLocations } = await ConfigurationService.getCustomerConfigInfo();
    const trackingLocation = possibleDetectorLocations.length
      ? possibleDetectorLocations[0]
      : 'Department';

    // TODO: remove 'Facility' in the second milestone
    const data = await WorkOrderClient.getWorkOrderById(itemId, trackingLocation);

    const { orderState, associatedParts, route, partTypes, locations } = data;
    const partInfo = associatedParts.map((eachPart) => {
      return {
        id: eachPart.id,
        number: eachPart.partNumber,
        name: eachPart.name,
        qty: eachPart.quantity
      };
    });

    const titleStructure = {
      key: 'identifier',
      name: 'Order Number',
      value: orderState.identifier,
      editable: true
    };
    const contentStructureList = [
      {
        key: 'sensorProfileVid',
        name: 'Tag#',
        value: orderState.sensorProfileVid
      },

      {
        key: 'dueDate',
        name: 'Due Date',
        value: orderState.dueDate,
        editable: true,
        type: 'date'
      },
      {
        key: 'lastUpdatedLocation',
        name: 'Location',
        value: orderState.currentLocation
      },
      {
        key: 'lastUpdateTime',
        name: 'Last Updated',
        value: orderState.lastUpdatedTime,
        type: 'date',
        format: 'MM/DD/YYYY hh:mm a'
      },
      {
        key: 'expedited',
        name: 'Expedited',
        value: orderState.statusFlags.includes('Expedited'),
        type: 'switch',
        editable: true
      }
    ];

    const { statusFlags } = orderState;

    if (!statusFlags.includes('Completed')) {
      contentStructureList.push({
        key: 'markAsCompleted',
        name: 'Mark As Completed',
        type: 'button',
        onClick: () => {
          this.setState({ showDeleteModal: true });
        }
      });
    }
    const routeTitleList = [
      {
        key: 'location',
        value: 'Location'
      },
      {
        key: 'entryTime',
        value: 'Entry Time'
      },
      { key: 'exitTime', value: 'Exit Time' },
      { key: 'timeDuration', value: 'Time Duration' }
    ];

    const nodeStructureList = [];

    route.forEach((each, index) => {
      nodeStructureList.push({
        route: each.location,
        sequence_number: index,
        done: true,
        processing: !each.exitTime
      });
      const duration = each.exitTime
        ? msToTime(each.exitTime - each.entryTime)
        : msToTime(Date.now() - each.entryTime);

      each.timeDuration = duration;
      each.entryTime = getFormattedDate(each.entryTime, 'hh:mm A MMM D');
      each.exitTime = each.exitTime ? getFormattedDate(each.exitTime, 'hh:mm A MMM D') : '-';
    });

    const newLocations = locations.filter(
      (eachLocation) => eachLocation.id !== orderState.currentLocationId
    );

    const locationClient = XemelgoService.getClient().getLocationClient();

    const orderPin = await locationClient
      .getLocationById(orderState.currentLocationId)
      .then((location) => {
        const { pin } = location.getResponseData();
        const { x, y, color } = pin;
        const id = location.getId();
        const name = `${location.getName()}`;
        return {
          id,
          x,
          y,
          color: 'green',
          component: <GpsPinHead color="green" canClick={false} scale={4} />,
          name
        };
      });

    this.setState({
      itemId,
      orderState,
      route,
      partTypes,
      associatedParts,
      partInfo,
      routeTitleList,
      titleStructure,
      contentStructureList,
      nodeStructureList,
      locations: newLocations,
      partCrudTableData: partInfo,
      editPart: false,
      addLocation: false,
      locationIdToAdd: '',
      showDeleteModal: false,
      showMap: false,
      locationPin: [orderPin],

      loading: false
    });
  };

  getAdditionalMoreButtonOptions = () => {
    return null;
  };

  renderDeleteConfirmationModal = () => {
    const { showDeleteModal, orderState } = this.state;
    return (
      <Modal centered backdrop="static" backdropClassName={Style.backdrop} show={showDeleteModal}>
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">{`Mark ${orderState.identifier} As Completed?`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You're about to mark
            <b style={{ fontWeight: 'bold' }}>{` ${orderState.identifier} `}</b>
            as completed, are you sure you want to do this?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({ showDeleteModal: false });
            }}
          >
            Cancel
          </button>
          <button className="confirm-delete-button" onClick={this.markAsComplete}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  markAsComplete = () => {
    const { itemId } = this.state;
    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();
    WorkOrderClient.markWorkOrderAsComplete(itemId).then((data) => {
      this.onLoad(itemId);
    });
  };

  handleEditPart = (data) => {
    this.setState({ partCrudTableData: data });
  };

  renderEditPartModal = (editPart) => {
    const { partInfo, partConstraint } = this.state;
    return (
      <Modal
        centered
        size={partConstraint.quantity === 'many' ? 'xl' : 's'}
        show={editPart}
        backdrop="static"
        backdropClassName={Style.backdrop}
      >
        <Modal.Header>
          <Modal.Title>Edit Associated Part(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={partConstraint.quantity === 'many' ? Style.modal : Style.modal_small}>
            <PartCrudTable
              partConstraint={partConstraint}
              partInfo={partInfo}
              changeHandler={this.handleEditPart}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={Style.cancel_button}
            onClick={() =>
              this.setState({
                editPart: !editPart
              })
            }
          >
            Cancel
          </button>
          <button type="button" className={Style.save_button} onClick={this.handleSubmitPart}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderAddLocationModal = (addLocation) => {
    const { locations, locationIdToAdd } = this.state;
    return (
      <Modal centered show={addLocation} backdrop="static" backdropClassName={Style.backdrop}>
        <Modal.Header>
          <Modal.Title>Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputLabel>Select A Location</InputLabel>
          <Select
            variant="outlined"
            className={Style.location_dropdown}
            value={locationIdToAdd}
            onChange={(event) => {
              this.setState({
                locationIdToAdd: event.target.value
              });
            }}
          >
            {locations.map((each) => {
              return (
                <MenuItem key={each.id} value={each.id}>
                  {each.name}
                </MenuItem>
              );
            })}
          </Select>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={Style.cancel_button}
            onClick={() =>
              this.setState({
                locationIdToAdd: '',
                addLocation: !addLocation
              })
            }
          >
            Cancel
          </button>
          <button
            disabled={!locationIdToAdd}
            type="button"
            className={Style.save_button}
            onClick={this.handleAddLocation}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleAddLocation = () => {
    const { itemId, locationIdToAdd } = this.state;
    const publishClient = XemelgoService.getClient().getPublishClient();
    publishClient.publishEventAtLocation(locationIdToAdd, [itemId]).then(() => {
      this.onLoad(itemId);
    });
  };

  handleSubmitPart = () => {
    const { partCrudTableData = [], partInfo, itemId, partTypes } = this.state;
    const quantitiesToUpdate = [];
    const partsToAssociate = {
      createAndAssociate: [],
      associate: []
    };
    const partsToDisassociate = [];
    partCrudTableData.forEach((eachPartData) => {
      let obj = partInfo.find((eachAssociatedPart) => {
        return eachAssociatedPart.id === eachPartData.id;
      });

      if (obj) {
        if (obj.quantity !== eachPartData.qty * 1) {
          quantitiesToUpdate.push({
            id: eachPartData.id,
            quantity: eachPartData.qty * 1
          });
        }
      } else {
        obj = partTypes.find((eachTypes) => {
          return eachTypes.getId() === eachPartData.id;
        });
        if (obj) {
          partsToAssociate.associate.push({
            id: eachPartData.id,
            quantity: eachPartData.qty * 1
          });
        } else {
          partsToAssociate.createAndAssociate.push({
            partNumber: eachPartData.number,
            quantity: eachPartData.qty * 1,
            name: eachPartData.name,
            imagePath: undefined
          });
        }
      }
    });
    partInfo.forEach((eachData) => {
      const obj = partCrudTableData.find((eachPartData) => {
        return eachPartData.id === eachData.id;
      });
      if (!obj) {
        partsToDisassociate.push(eachData.id);
      }
    });
    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();
    WorkOrderClient.updateAssociatedParts(itemId, {
      quantitiesToUpdate,
      partsToAssociate,
      partsToDisassociate
    }).then(async () => {
      await this.onLoad(itemId);
    });
  };

  handleSubmitDetail = (dataForm) => {
    const { itemId, orderState } = this.state;
    const { identifier, dueDate, expedited } = dataForm;
    const { statusFlags } = orderState;
    const newStatusFlag = _.cloneDeep(statusFlags);
    let setUpDate;
    if (expedited !== undefined) {
      setUpDate = { flagsToAdd: [], flagsToRemove: [] };
      if (expedited) {
        setUpDate.flagsToAdd.push('Expedited');
        newStatusFlag.push('Expedited');
      } else {
        setUpDate.flagsToRemove.push('Expedited');
        const index = newStatusFlag.findIndex((a) => a === 'Expedited');
        newStatusFlag.splice(index, 1);
      }
    }

    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();
    WorkOrderClient.updateWorkOrder(
      itemId,
      identifier || undefined,
      dueDate || null,
      undefined,
      setUpDate,
      dataForm.imagePath || undefined
    )
      .then(() => {})
      .catch((err) => window.alert(err))
      .finally(async () => {
        await this.onLoad(itemId);
      });
  };

  renderRouteTableOrMap = () => {
    const { showMap, route, routeTitleList, locationPin, nodeStructureList } = this.state;

    return showMap ? (
      floorPlanImageUrl && (
        <FloorPlan
          imageUrl={floorPlanImageUrl}
          pins={locationPin}
          className={Style.details_page_render_map}
        />
      )
    ) : (
      <Fragment>
        {nodeStructureList.length > 0 && <ProgressGraph stages={nodeStructureList} currStage={0} />}
        <DataTable titleList={routeTitleList} dataList={route} />
      </Fragment>
    );
  };

  render() {
    const {
      hide,
      titleStructure,
      contentStructureList,
      orderState,
      associatedParts,
      partTitleList,
      editPart,
      addLocation,
      showMap,
      loading
    } = this.state;
    const completed =
      orderState.statusFlags &&
      orderState.statusFlags.find((eachFlag) => eachFlag.toLowerCase() === 'completed');
    if (loading) {
      return <LoadingCircle />;
    }
    return (
      <Fragment>
        <div className={Style.content_holder}>
          <div className={Style.detail_group}>
            <DetailCard
              isActive={!completed}
              statusList={orderState.statusFlags}
              titleStructure={titleStructure}
              detailStructureList={contentStructureList}
              onSubmit={this.handleSubmitDetail}
            />
          </div>
          <div className={Style.table_group}>
            <div className={Style.top_table}>
              <div className={Style.title_group}>
                <div className={Style.title_label}>
                  Associated Part(s)
                  <div
                    role="button"
                    tabIndex={-1}
                    className={Style.hide_btn}
                    onClick={() => this.setState({ hide: !hide })}
                    onKeyDown={() => this.setState({ hide: !hide })}
                  >
                    {!hide ? 'Hide' : 'Show'}
                  </div>
                </div>
                <div
                  role="button"
                  tabIndex={-1}
                  onClick={() => this.setState({ editPart: !editPart })}
                  className={Style.part_edit_btn}
                >
                  Edit Associated Part(s)
                </div>
              </div>
              {hide || <DataTable titleList={partTitleList} dataList={associatedParts} />}
            </div>
            <div className={Style.bottom_table}>
              <div className={Style.title_group}>
                <div className={Style.title_label}>
                  Order route
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => this.setState({ showMap: !showMap })}
                    className={Style.toggle_map_btn}
                  >
                    View Map
                  </div>
                </div>
                {!completed && (
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => this.setState({ addLocation: !addLocation })}
                    className={Style.part_edit_btn}
                  >
                    Add Location
                  </div>
                )}
              </div>
              {this.renderRouteTableOrMap()}
            </div>
          </div>
        </div>
        {this.renderEditPartModal(editPart)}
        {this.renderAddLocationModal(addLocation)}
        {this.renderDeleteConfirmationModal()}
      </Fragment>
    );
  }
}
