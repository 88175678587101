import React, { PureComponent } from 'react';
import { Tabs, Tab, TabList } from 'react-web-tabs';
import SideFilterStyle from './css/SideFilter.module.css';

export default class SideFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focusId: ''
    };
  }

  componentWillUpdate(props) {
    if (props.tabStructure !== this.props.tabStructure) {
      this.setState({ focusId: props.defaultTabId || '' });
    }
  }

  RenderTabList = ({ name = null, action = null, tabStructure }) => {
    return (
      <TabList className={SideFilterStyle.tab_list}>
        <p className={SideFilterStyle.groupby_label}>{name}</p>
        {tabStructure.map((structure) => {
          const { name, action, tabStructure } = structure;
          if (tabStructure) {
            return (
              <this.RenderTabList
                key={name}
                name={name}
                action={action}
                tabStructure={tabStructure}
              />
            );
          }
          return <this.RenderTab key={name} name={name} action={action} />;
        })}
      </TabList>
    );
  };

  RenderTab = ({ name, action }) => {
    const { focusId } = this.state;
    const { defaultTabId } = this.props;
    const className = (name === focusId || (focusId === '' && defaultTabId === name)
      ? `${SideFilterStyle.filter_tab_style} ${SideFilterStyle.selected_tab_style}`
      : `${SideFilterStyle.filter_tab_style} ${SideFilterStyle.not_selected_tab_style}`);
    return (
      <Tab
        tabFor={name}
        name={name}
        className={className}
        onClick={(event) => {
          this.setState({ focusId: event.currentTarget.name });
          action();
        }}
      >
        {name}
      </Tab>
    );
  };

  render() {
    const { tabStructure = [] } = this.props;
    return (
      <Tabs
        defaultTab={tabStructure.length > 0 ? tabStructure[0].name : null}
        className={SideFilter.tabs}
      >
        {tabStructure.map((structure) => {
          const { name, action, tabStructure } = structure;
          if (tabStructure) {
            return (
              <this.RenderTabList
                key={name}
                name={name}
                action={action}
                tabStructure={tabStructure}
              />
            );
          }
          return <this.RenderTab key={name} name={name} action={action} />;
        })}
      </Tabs>
    );
  }
}
