export const Generator = {
  getRandomNumber(from, to) {
    const startFrom = from || 0;
    const endAt = to || 1000;

    return startFrom + Math.floor(Math.random() * (endAt - startFrom));
  },

  getRandomFrom(list) {
    const index = Math.floor(Math.random() * list.length);
    return list[index];
  },

  getRandomCurrencyValue(from, to) {
    const lowest = from || 1;
    const highest = to || 1000;

    const left = this.getRandomNumber(lowest, highest);
    const right = this.getRandomNumber(0, 100);

    return parseFloat(`${left}.${right}`);
  },

  getRandomPastDate(fromDay, toDay) {
    const now = new Date();
    const randomDay = this.getRandomNumber(fromDay, toDay);
    now.setDate(now.getDate() - randomDay);

    const randomHours = this.getRandomNumber(0, 23);
    const randomMinutes = this.getRandomNumber(0, 59);
    const randomSeconds = this.getRandomNumber(0, 59);
    now.setHours(randomHours, randomMinutes, randomSeconds);

    return now;
  },

  getRandomDateBetween(daysBefore, daysAfer) {
    const now = new Date();

    const randomDay = this.getRandomNumber(0, daysAfer);
    now.setDate(randomDay - daysBefore);

    const randomHours = this.getRandomNumber(0, 23);
    const randomMinutes = this.getRandomNumber(0, 59);
    const randomSeconds = this.getRandomNumber(0, 59);
    now.setHours(randomHours, randomMinutes, randomSeconds);

    return now;
  }
};
