import React, { Component, Fragment } from 'react';
import Switch from 'react-switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import './RowWithActionComponent.css';

const RevertEvent = 'revert';
const DeleteEvent = 'delete';
const UpdateSuccessEvent = 'approve';
const ContentChangedEvent = 'contentChanged';
const ToggleEditEvent = 'editToggled';
const SubscrptionChangedEvent = 'subscriptionChanged';

export default class RowWithActionComponent extends Component {
  constructor(props) {
    super(props);

    this.toggleEdit = this.toggleEdit.bind(this);
    this.updateSuccess = this.updateSuccess.bind(this);
    this.revert = this.revert.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.signalEvent = this.signalEvent.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.handleDropdownOptionsFn = this.handleDropdownOptionsFn.bind(this);

    /**
     * Register event callback function, this supports use case of parent component calling child component to undo the editing.
     */
    if (props.registerEventCallbacksFn) {
      props.registerEventCallbacksFn(RevertEvent, props.id, this.revert);
      props.registerEventCallbacksFn(UpdateSuccessEvent, props.id, this.updateSuccess);
    }

    this.state = {
      readOnly: props.readOnly,
      markForDelete: false,
      data: { ...props.data },
      id: props.id,
      location: null
    };
  }

  componentWillUnmount() {
    if (this.unregisterEventCallbacksFn) {
      this.unregisterEventCallbacksFn(RevertEvent, this.props.id);
      this.unregisterEventCallbacksFn(UpdateSuccessEvent, this.props.id);
    }
  }

  signalEvent(event, payload) {
    if (this.props.signalEventFn) {
      this.props.signalEventFn(event, this.state.id, payload);
    }
  }

  /**
   * Toggle edit signal 'revert' action if user toggle 'off' the edit mode. This should return row value to default
   */
  toggleEdit() {
    const isCurrentReadOnly = this.state.readOnly;

    if (!isCurrentReadOnly) {
      this.signalEvent(RevertEvent);
    }

    const data = { ...this.state.data };
    data._event = 'pending_edit';
    this.signalEvent(ToggleEditEvent, {
      changed: isCurrentReadOnly,
      data
    });

    this.setState({ readOnly: !isCurrentReadOnly });
  }

  toggleDelete() {
    const markedForDelete = !this.state.markForDelete;

    if (markedForDelete) {
      this.signalEvent(RevertEvent);
    }

    const data = { ...this.state.data };
    data._event = 'pending_delete';
    this.signalEvent(DeleteEvent, {
      changed: markedForDelete,
      data
    });

    if (this.props.handleOptionsResetFn) {
      this.props.handleOptionsResetFn('unselect-one', data);
    }

    this.setState({ markForDelete: markedForDelete });
  }

  updateSuccess() {
    this.setState({
      readOnly: true
    });
  }

  /**
   * This function is register as callback for parent component.
   *  This gives parent component a way to call child component to undo edit.
   *  This supports a use case for parent component to signal children to undo all editing.
   */
  revert() {
    this.setState({
      data: { ...this.props.data },
      readOnly: true,
      markForDelete: false
    });
  }

  handleEdit(headerId, newValue, callBySwitch) {
    // indicate value has not been changed
    const data = { ...this.state.data };
    data[headerId] = newValue;
    data._event = data._pendingNew ? 'pending_new' : 'pending_update';

    let signalPayload = {
      data,
      changed: true
    };

    if (this.props.handleAutoFillFn) {
      signalPayload = this.props.handleAutoFillFn(headerId, newValue, data);
    } else {
      const defaultValue = this.props.data[headerId] && this.props.data[headerId].toString();
      if (defaultValue === newValue) {
        signalPayload.changed = false;
      }
    }

    if (!callBySwitch) {
      this.signalEvent(ContentChangedEvent, signalPayload);
      this.setState({ data });
    } else {
      this.signalEvent(SubscrptionChangedEvent, newValue);
    }
  }

  handleDropdownOptionsFn(event, data, headerId) {
    const selectedValue = event.target.value;
    data.forEach(item => {
      if (item.objectTypeName === selectedValue) {
        item._selected = true;
      }
      if (item.objectTypeName === this.state.location) {
        item._selected = false;
      }
    });
    this.setState({ location: selectedValue });
    this.handleEdit(headerId, selectedValue);
  }

  render() {
    let className = 'row-with-action-component';
    const additionClassName = this.props.className;

    if (additionClassName) {
      className += ` ${additionClassName}`;
    }

    const hideEdit = !this.props.enabledEditAction || this.state.markForDelete;
    const isEditMode = !this.state.readOnly;
    const hideDelete = this.props.disableDeleteAction;

    return (
      <Fragment>
        <tr className={`${className} ${this.state.markForDelete ? 'pending-delete' : ''}`}>
          {this.props.headers.map((header, headerIndex) => {
            let pendingNew = false;
            if (this.state.data._pendingNew) {
              pendingNew = true;
            }

            const { input, data, display, modifiable, unit } = { ...header.cell };
            let componentName = display;
            if (isEditMode && (modifiable || pendingNew)) {
              componentName = input;
            }

            if (componentName === 'dropdown') {
              return (
                <td className="editable-table-data-row-cell" key={`data-cell-${headerIndex}`}>
                  <select
                    disabled={isEditMode && !modifiable && !pendingNew}
                    className={
                      this.props.inputError
                        ? this.props.inputError[header.id]
                          ? 'input-error'
                          : ''
                        : ''
                    }
                    value={this.state.data[header.id] || ''}
                    onChange={event => {
                      this.handleDropdownOptionsFn(event, data, header.id);
                    }}
                  >
                    <option disabled value="">
                      -- Select --
                    </option>
                    {data.map(item => {
                      return (
                        <option disabled={item._selected} key={item.objectTypeName}>
                          {item.objectTypeName}
                        </option>
                      );
                    })}
                  </select>
                </td>
              );
            }
            if (componentName === 'text') {
              return (
                <td className="editable-table-data-row-cell" key={`data-cell-${headerIndex}`}>
                  <input
                    type="text"
                    className={
                      this.props.inputError
                        ? this.props.inputError[header.id]
                          ? 'input-error'
                          : ''
                        : ''
                    }
                    value={this.state.data[header.id] + (unit && !isEditMode ? ` ${unit}` : '')}
                    disabled={!isEditMode || !modifiable}
                    onChange={event => this.handleEdit(header.id, event.target.value)}
                  />
                </td>
              );
            }

            if (componentName === 'switch') {
              return (
                <td className="editable-table-data-row-cell" key={`data-cell-${headerIndex}`}>
                  <Switch
                    onChange={event => this.handleEdit(header.id, event, true)}
                    checked={this.state.data[header.id]}
                    id={this.state.data[header.id]}
                    className="react_switch"
                    width={40}
                    height={21}
                    disabled={isEditMode}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#4d8605"
                  />
                </td>
              );
            }

            return <td />;
          })}

          <td className="icons-block">
            <EditIcon
              onClick={this.toggleEdit}
              className={`clickable ${hideEdit ? 'hidden' : ''}`}
              style={{
                color: '#166ed5',
                marginRight: '.7em',
                marginTop: '.15em'
              }}
            />
            <DeleteIcon
              onClick={this.toggleDelete}
              className={`clickable ${hideDelete ? 'hidden' : ''}`}
              style={{
                color: '#F62227',
                marginTop: '.15em'
              }}
            />
          </td>
        </tr>
      </Fragment>
    );
  }
}
