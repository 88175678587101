import { AbstractApi } from '../abstract-api';

const RequestToResourceMap = {
  createRule: 'rule',
  createRuleCondition: 'rule-condition',
  retireRule: 'rule',
  locations: 'location',
  createLocation: 'location',
  createDetector: 'detector',
  update: 'location', // for testing update on location only,
  attach: 'detector', // for testing attach detector to location,
  retire: 'location' // for testing delete on location
};

/**
 * @param requestResource
 * @returns { null| AbstractResourceManager }
 */
const getResourceManager = (requestResource) => {
  const resourceName = RequestToResourceMap[requestResource];
  if (!resourceName) {
    return null;
  }

  try {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const module = require(`./resource-managers/${resourceName}-resource-manager`);
    if (!module) {
      return null;
    }

    return module.resourceManager;
  } catch (e) {
    return null;
  }
};

// eslint-disable-next-line import/prefer-default-export
class MutateApi extends AbstractApi {
  // eslint-disable-next-line class-methods-use-this
  canMock(payload, headers) {
    const requestActions = Object.keys(payload);

    // all the requested resources must be mock-able for this to return true
    const canMockRequest = requestActions.reduce((mockable, requestAction) => {
      const rm = getResourceManager(requestAction);
      if (!rm) {
        return false;
      }

      const requestResourcePayload = payload[requestAction];
      return mockable && rm.supportMock('mutate', requestAction, requestResourcePayload, headers);
    }, true);

    return canMockRequest;
  }

  // eslint-disable-next-line class-methods-use-this
  execute(payload) {
    const requestActions = Object.keys(payload);

    const responses = {};
    // all the requested resources must be mock-able for this to return true
    requestActions.forEach((requestedAction) => {
      const rm = getResourceManager(requestedAction);

      const requestedActionPayload = payload[requestedAction];
      responses[requestedAction] = rm[requestedAction](requestedActionPayload);
    });
    return responses;
  }
}

const api = new MutateApi();
// eslint-disable-next-line import/prefer-default-export
export { api };
