import { ConfigClientProvider } from 'xemelgo-config-client';
import CONFIGURATION from '../configuration.json';
import { LocalCacheService } from './local-cache-service';
import { AmplifyConfigurationProvider } from '../domains/amplify-configuration-provider';

const CustomerConfigurationKey = 'CustomerConfigurationKey';
const ConfigurationExpirationTimeKey = 'Configuration_Exp';
const CustomerConfigurationMaxValidDuration = 43200000; // 12 hours

const ClientConfigurationService = {
  getClient() {
    const configServiceApiConfig = CONFIGURATION.ConfigurationService_endpoints;
    const authConfig = CONFIGURATION.AuthConfiguration;
    const userConfig = LocalCacheService.loadUserConfig();
    const config = AmplifyConfigurationProvider.buildApiConfiguration(
      userConfig,
      authConfig,
      configServiceApiConfig
    );

    return ConfigClientProvider.getClient(config);
  },

  getConfig() {
    const ConfigurationServiceClient = ClientConfigurationService.getClient();
    const configClient = ConfigurationServiceClient.getConfigClient();
    return configClient.getConfig();
  },

  configure() {
    return new Promise(function(resolve, reject) {
      const ConfigurationServiceClient = ClientConfigurationService.getClient();
      const configClient = ConfigurationServiceClient.getConfigClient();
      configClient
        .getConfig()
        .then((clientConfiguration) => {
          const currentDate = new Date();
          const currentTime = currentDate.getTime();
          const cacheExpirationTime = currentTime + CustomerConfigurationMaxValidDuration;
          sessionStorage.setItem(ConfigurationExpirationTimeKey, cacheExpirationTime);
          sessionStorage.setItem(CustomerConfigurationKey, JSON.stringify(clientConfiguration));
          resolve(clientConfiguration);
        })
        .catch((err) => {
          console.log('Error!', err);
          reject(err);
        });
    });
  }
};

export { ClientConfigurationService };
