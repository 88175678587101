import React, { Fragment, Component } from 'react';
import { Card, CardBody } from 'mdbreact';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import AddObjectForm from './AddObjectForm';
import ConfigurationService from '../../services/ConfigurationService';
import { XemelgoService } from '../../services/XemelgoService';
import {
  useInputField,
  useDateField,
  useSearchableDropdown
} from '../../components/Forms/BasicAddForm';
import { inputValidation, isRequired, validateDate } from '../../components/Forms/Validation';
import LoadingCircle from '../../components/loading/LoadingCircle';
import ListView from '../../components/TrackPageComponents/ListView';
import MoreIcon from '../../img/More.png';

import AddItemStyle from './css/AddItem.module.css';

class AddAsset extends Component {
  constructor(props) {
    super(props);
    const formControl = {
      number: {
        tabIndex: 1,
        render: useInputField,
        id: 'number',
        label: 'Asset Number',
        value: '',
        valid: true,
        errorMessage: '',
        helperText: '',
        isRequired: true,
        validationRules: [isRequired]
      },
      name: {
        tabIndex: 1,
        render: useSearchableDropdown,
        id: 'name',
        label: 'Asset Type',
        value: '',
        valid: true,
        errorMessage: '',
        helperText: '',
        validationRules: [],
        containerStyle: { width: 210 }
      },
      rfid: {
        tabIndex: 2,
        render: useInputField,
        id: 'rfid',
        label: 'RFID Tag Number',
        value: '',
        valid: true,
        errorMessage: '',
        helperText: '',
        isRequired: true,
        validationRules: [isRequired]
      },
      date: {
        tabIndex: 2,
        render: useDateField,
        id: 'date',
        label: 'Calibration Date', // temp: changed to calibration from expiry for PNAA (potentially get it from config)
        value: null,
        valid: true,
        placeholder: 'MM/DD/YYYY',
        errorMessage: '',
        validationRules: [validateDate]
      }
    };
    this.state = {
      formControl,
      showBanner: false,
      uploadingCSV: false,
      invalidateCSV: false,
      submittedAndNotOk: false,
      errorMessage: '',
      objectInfo: {},
      reuseSensorProfile: {},
      loading: true,
      status: 'Loading...'
    };
  }

  componentDidMount = async () => {
    const { formControl } = this.state;
    const trackingFeature = await ConfigurationService.getTrackingFeature();
    const { reuseSensorProfile = {} } = trackingFeature;
    const assetTypes = [];
    const xemelgoClient = await XemelgoService.getClient();
    const itemTypeClient = xemelgoClient.getItemTypeClient();
    const data = await itemTypeClient.listAssetTypes();
    data.forEach((item) => {
      assetTypes.push({
        id: item.identifier,
        key: [item.identifier, item.id],
        displayString: `Asset Type: <span class='list-group-item-highlight'>${item.identifier}</span>`
      });
    });

    formControl.name.searchList = assetTypes;
    this.setState({
      reuseSensorProfile,
      formControl,
      loading: false
    });
  };

  displayBanner = () => {
    const { errorMessage } = this.state;
    if (!errorMessage) {
      return (
        <Alert variant="success" onClose={() => this.setState({ showBanner: false })} dismissible>
          <h4>Success!</h4>
          <p>Asset(s) created</p>
        </Alert>
      );
    }

    const errorTitle = errorMessage.includes('csv')
      ? 'Cannot import csv file: '
      : 'Asset(s) could not be created: ';
    return (
      <Alert variant="danger" onClose={() => this.setState({ showBanner: false })} dismissible>
        <h4>Error </h4>
        <p>{errorTitle + errorMessage}</p>
      </Alert>
    );
  };

  changeHandler = (controller, value) => {
    this.setState({
      [controller]: value
    });
  };

  checkCSVHeaders = (csvHeaders) => {
    const validHeaders = ['identifier', 'type', 'rfid', 'expiry'];
    const error = 'Upload failed';
    let invalid = false;
    if (!csvHeaders.includes('identifier') || !csvHeaders.includes('rfid')) {
      invalid = true;
    }
    csvHeaders.forEach((header) => {
      if (!validHeaders.includes(header)) {
        invalid = true;
      }
    });
    if (invalid) {
      this.setState({
        showBanner: true,
        invalidateCSV: true,
        errorMessage: 'Please check the headers in the csv file and upload again'
      });
      return error;
    }
  };

  /* CSV needs to have the following columns: identifier, type, rfid, and expiry */
  uploadCSV = (event) => {
    if (event && event.target && event.target.files[0]) {
      const self = this;
      self.setState({
        invalidateCSV: false,
        showBanner: false,
        errorMessage: ''
      });
      const objects = [];
      const identifiers = [];
      const rfids = [];
      let csvHeaders;
      const reader = new FileReader();
      reader.onload = function() {
        const rows = reader.result.split('\r\n');
        rows[0] = rows[0].replace('ï»¿', '');
        csvHeaders = rows[0].split(',');
        const err = self.checkCSVHeaders(csvHeaders);
        if (err) {
          return;
        }
        for (let i = 1; i < rows.length; i++) {
          let currentObject = {};
          if (rows[i]) {
            const currentLine = rows[i].split(',');
            for (let j = 0; j < csvHeaders.length; j++) {
              currentObject[csvHeaders[j]] = currentLine[j];
            }
            currentObject = self.validateRow(currentObject);
            if (currentObject.error) {
              self.setState({ invalidateCSV: true });
            }
            objects.push(currentObject);
            identifiers.push(currentObject.identifier);
            rfids.push(currentObject.rfid);
          }
        }
        if (objects.length > 0) {
          if (new Set(identifiers).size === identifiers.length) {
            if (new Set(rfids).size === rfids.length) {
              self.setState({ objectsToCreate: objects, uploadingCSV: true, headers: csvHeaders });
            } else {
              self.setState({
                showBanner: true,
                errorMessage: 'RFID Tag Numbers should be unique'
              });
            }
          } else {
            self.setState({ showBanner: true, errorMessage: 'Asset Numbers should be unique' });
          }
        }
      };
      reader.readAsBinaryString(event.target.files[0]);
    }
  };

  resetForm = () => {
    this.setState({
      invalidateCSV: false,
      uploadingCSV: false,
      showBanner: false,
      submittedAndNotOk: false
    });
  };

  handleDeleteRow = (row) => {
    const { objectsToCreate } = this.state;
    const updatedObjectsToCreate = objectsToCreate.filter(
      (object) => object.identifier !== row.identifier
    );
    this.setState({ objectsToCreate: updatedObjectsToCreate });
    this.checkForBadRows(updatedObjectsToCreate);
  };

  validateRow = (objectToCreate) => {
    if (!objectToCreate.identifier | !objectToCreate.rfid) {
      objectToCreate.error = true;
    }
    return objectToCreate;
  };

  checkForBadRows = (objectsToCreate) => {
    let invalidateCSV = false;
    objectsToCreate.forEach((objectToCreate) => {
      if (objectToCreate.error) {
        invalidateCSV = true;
      }
    });
    this.setState({ invalidateCSV });
  };

  // *** Asset Form Handler *** //
  submitAssetHandler = async () => {
    const { objectInfo, reuseSensorProfile } = this.state;
    const updatedFormControls = _.cloneDeep(objectInfo);
    const validationResult = inputValidation(updatedFormControls);
    this.setState({ objectInfo: updatedFormControls });

    if (!validationResult.includes(false)) {
      this.setState({
        loading: true,
        status: 'Creating Asset...'
      });
      const xemelgoClient = XemelgoService.getClient();
      const assetClient = xemelgoClient.getAssetClient();

      const assetPayload = {
        identifier: objectInfo.number.value,
        itemType: objectInfo.name.value,
        sensorProfileVid: objectInfo.rfid.value,
        startTrackingSession: true,
        expirationDate:
          objectInfo.date.value === null ? undefined : new Date(objectInfo.date.value).getTime(),
        state: 'Active'
      };

      try {
        // await assetClient.createAsset(
        //   reuseSensorProfile.allow,
        //   reuseSensorProfile.autoDisassoicationOnReuse,
        //   assetPayload.identifier,
        //   assetPayload.sensorProfileVid,
        //   assetPayload.startTrackingSession,
        //   assetPayload.itemType,
        //   assetPayload.expirationDate,
        //   assetPayload.state
        // );
        this.setState({
          showBanner: true,
          errorMessage: false,
          objectInfo: {},
          loading: false
        });
      } catch (err) {
        this.setState({
          showBanner: true,
          loading: false,
          errorMessage: err.message.toString() // converting to string in case of a network error
        });
      }
    }
  };

  submitBatchCreateFromCSV = async () => {
    const { objectsToCreate, reuseSensorProfile } = this.state;
    const xemelgoClient = XemelgoService.getClient();
    const assetClient = xemelgoClient.getAssetClient();
    const failedAssets = [];
    for (let i = 0; i < objectsToCreate.length; i++) {
      this.setState({
        loading: true,
        status: `Creating Asset ${i + 1} of ${objectsToCreate.length}...`
      });

      const assetPayload = {
        identifier: objectsToCreate[i].identifier,
        itemType: objectsToCreate[i].type,
        sensorProfileVid: objectsToCreate[i].rfid,
        startTrackingSession: true,
        expirationDate:
          objectsToCreate[i].expiry === ''
            ? undefined
            : new Date(objectsToCreate[i].expiry).getTime(),
        state: 'Active'
      };
      try {
        await assetClient.createAsset(
          reuseSensorProfile.allow,
          reuseSensorProfile.autoDisassoicationOnReuse,
          assetPayload.identifier,
          assetPayload.sensorProfileVid,
          assetPayload.startTrackingSession,
          assetPayload.itemType,
          assetPayload.expirationDate,
          assetPayload.state
        );
      } catch (err) {
        objectsToCreate[i].errorMessage = err.message ? err.message.toString() : err.toString();
        failedAssets.push(objectsToCreate[i]);
      }
    }
    if (!failedAssets.length) {
      this.setState({
        showBanner: true,
        errorMessage: false,
        loading: false,
        uploadingCSV: false
      });
    } else {
      this.setState({
        objectsToCreate: failedAssets,
        submittedAndNotOk: true,
        showBanner: true,
        loading: false,
        errorMessage: `Please check the following Assets and retry`
      });
    }
  };

  renderEditPayload = () => {
    const { objectsToCreate, headers, invalidateCSV, submittedAndNotOk } = this.state;
    const listHeaders = [];
    headers.forEach((element) => listHeaders.push({ id: element, label: element }));
    return (
      <Fragment>
        {!submittedAndNotOk && (
          <div onClick={this.resetForm} className={AddItemStyle.add_item_cancel_link} tabIndex={-1}>
            <img src={MoreIcon} alt="more" className={AddItemStyle.add_item_cancel_text} />
            Cancel
          </div>
        )}
        <ListView
          onboarding
          handleDelete={submittedAndNotOk ? undefined : this.handleDeleteRow}
          dataList={objectsToCreate}
          headerStructureList={listHeaders}
        />
        <div className={AddItemStyle.form_submit_button_container}>
          <button
            type="submit"
            disabled={invalidateCSV}
            onClick={() => {
              submittedAndNotOk ? this.resetForm() : this.submitBatchCreateFromCSV();
            }}
            className="request-submit-button"
          >
            {submittedAndNotOk ? ` OK` : `Confirm Assets`}
          </button>
        </div>
      </Fragment>
    );
  };

  renderSimpleAssetForm = () => {
    const { formControl, objectInfo } = this.state;
    const { history } = this.props;
    return (
      <div>
        <div
          onClick={() => {
            history.goBack();
          }}
          className={AddItemStyle.add_item_cancel_link}
          tabIndex={-1}
        >
          <img src={MoreIcon} alt="more" className={AddItemStyle.add_item_cancel_text} />
          Cancel
        </div>
        <div className={AddItemStyle.add_item_form_container_asset}>
          <AddObjectForm
            formControl={formControl}
            info={objectInfo}
            changeHandler={(returnData) => this.changeHandler('objectInfo', returnData)}
          />
          <form className={AddItemStyle.upload_csv_container}>
            <p className={AddItemStyle.upload_csv_text}> or upload a CSV </p>
            <div>
              <input
                type="file"
                onChange={this.uploadCSV}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </div>
            <p className={AddItemStyle.upload_csv_sub_text}>
              Please upload a csv file with the following column headers:
              <br />
              identifier*, type, rfid*, expiry
            </p>
            <p className={AddItemStyle.upload_csv_sub_text}>* - required</p>
          </form>
        </div>
        <div className={AddItemStyle.form_submit_button_container}>
          <button type="submit" onClick={this.submitAssetHandler} className="request-submit-button">
            Create Asset
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { showBanner, loading, uploadingCSV, status } = this.state;
    return (
      <div className={AddItemStyle.add_item_container}>
        {showBanner && this.displayBanner()}
        {loading ? (
          <div>
            <LoadingCircle />
            <div className={AddItemStyle.add_item_loading_text}>{status}</div>
          </div>
        ) : (
          <Card className={AddItemStyle.add_item_outer_card_styling}>
            <div className={AddItemStyle.add_item_title}>
              <div className={AddItemStyle.add_item_title_content}>New Asset</div>
            </div>
            <CardBody>
              {uploadingCSV ? this.renderEditPayload() : this.renderSimpleAssetForm()}
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default withRouter(AddAsset);
