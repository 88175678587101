import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { Div } from '../../../../../components/div';
import { AddResourceFormSection } from '../add-resource-form-section';
import './style.css';

export const AddResourceFormBody = ({
  properties,
  numberOfSections,
  submitTicket,
  onValidationPassed
}) => {
  const [hasError, setHasError] = useState(false);
  const [sectionIds, setSectionIds] = useState([v4()]);
  const [payloads, setPayloads] = useState([]);
  const [validateTicket, setValidateTicket] = useState(null);

  /**
   * Name: resetPayloadsAndIssueValidationTicket
   * This effect is a way to ensure orders, it first clean up all the collected payloads before issue a new validation
   *  ticket
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!submitTicket) {
      return cancelCallback;
    }

    if (!cancelled) {
      setPayloads([]);
      setValidateTicket(submitTicket);
    }

    return cancelCallback;
  }, [submitTicket]);

  /**
   * Name: handle section changes
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (numberOfSections === sectionIds.length) {
      return cancelCallback;
    }

    const diff = numberOfSections - sectionIds.length;
    const addNew = diff > 0;
    const clonedSectionIds = [...sectionIds];
    let op = (list) => list.push(v4());
    if (!addNew) {
      op = (list) => list.pop();
    }

    for (let i = 0; i < Math.abs(diff); i++) {
      op(clonedSectionIds);
    }

    if (!cancelled) {
      setSectionIds(clonedSectionIds);
    }

    return cancelCallback;
  }, [numberOfSections, sectionIds]);

  /**
   * Name: handleSubmit
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (payloads.length !== numberOfSections) {
      return cancelCallback;
    }

    if (!cancelled) {
      onValidationPassed(payloads);
    }

    return cancelCallback;
  }, [payloads, numberOfSections, onValidationPassed]);

  const onSectionValidationPassedCallback = useCallback((sectionId, payload) => {
    setPayloads((list) => {
      const cloned = [...list];
      cloned.push(payload);
      return cloned;
    });
  }, []);

  const onSectionValidationErrorCallback = useCallback(() => {
    setHasError(true);
    setValidateTicket(null);
  }, []);

  return (
    <Div className="add-location-form-body">
      {hasError && (
        <Div className="error-message-group">
          <Div className="error-title">Error</Div>
          <Div className="error-message">
            Action was not completed. Please review highlighted items and try again.
          </Div>
        </Div>
      )}
      {sectionIds.map((sectionId) => {
        return (
          <AddResourceFormSection
            sectionId={sectionId}
            key={sectionId}
            properties={properties}
            submitTicket={validateTicket}
            onValidationPassed={onSectionValidationPassedCallback}
            onValidationError={onSectionValidationErrorCallback}
          />
        );
      })}
    </Div>
  );
};

AddResourceFormBody.defaultProps = {
  properties: [],
  numberOfSections: 1,
  submitTicket: null,
  onValidationPassed: () => {}
};

AddResourceFormBody.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      optional: PropTypes.bool,
      optionallyDependsOn: PropTypes.arrayOf(PropTypes.string),
      input: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          flag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string,
            defaultValue: PropTypes.bool
          })
        })
      )
    })
  ),
  numberOfSections: PropTypes.number,
  submitTicket: PropTypes.string,
  onValidationPassed: PropTypes.func
};
