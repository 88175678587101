const uuid = require('uuid');

// eslint-disable-next-line import/prefer-default-export
export class AbstractResourceManager {
  constructor(idField) {
    this.idField = idField || 'id';
    this.itemMap = {};
  }

  /**
   * Given url: https://<base-url>/dev/mutate
   * payload: { createObject: { fields: { ... } } }
   * @param api - is 'mutate' in the above url
   * @param action - is 'createObject' in the payload
   * @param payload - is 'fields' object in the above payload
   * @param headers - headers map
   * @param apiSegments - {string[]} for rest api, use this to get resourceId like /resources/{resourceId}
   * @returns {boolean}
   */
  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  supportMock(api, action, payload, headers, apiSegments) {
    return true;
  }

  addItem(item) {
    const clonedItem = { ...item };
    const id = clonedItem[this.idField] || uuid.v4();
    clonedItem[this.idField] = id;

    this.itemMap[id] = clonedItem;
    return clonedItem;
  }

  list() {
    return Object.values(this.itemMap);
  }

  // eslint-disable-next-line no-unused-vars
  filter(fieldName, value) {
    return Object.values(this.itemMap).filter((item) => {
      return item[fieldName] === value;
    });
  }

  get(id) {
    return this.itemMap[id];
  }

  update(id, item) {
    const previous = this.itemMap[id] || {};
    const updated = { ...previous, ...item };
    this.itemMap[id] = updated;
    return updated;
  }

  remove(id) {
    const deletedItem = this.itemMap[id];
    delete this.itemMap[id];
    return deletedItem;
  }

  getSolutionSpecificRequest(keyword, payload) {
    const searchPhaseRegex = new RegExp(keyword, 'gi');

    const toJsonString = JSON.stringify(payload);
    const isExpectedRequest = toJsonString.search(searchPhaseRegex) !== -1;
    return isExpectedRequest;
  }
}
