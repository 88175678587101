import React from 'react';
import Style from './css/Metrics.module.css';
import { uuidv4 } from 'uuid'

export default function Metrics({ title, dataList }) {
  return (
    <div className={Style.metrics_category}>
      <div className={Style.metrics_category_label}>{title}</div>
      <div className={Style.metrics_group}>
        {dataList &&
          dataList.map(each => {
            return renderGroup(each);
          })}
      </div>
    </div>
  );
}

const renderGroup = ({ name, value, unit, color = null, key = uuidv4()}) => (
  <div className={Style.metrics_detail_group} key={key}>
    <h2 className={Style.metrics_label}>{name}</h2>
    <div className={Style.value_with_unit_group}>
      <p style={{ color }} className={Style.metrics_value}>
        {value}
      </p>
      <p className={Style.unit}>{unit}</p>
    </div>
  </div>
);
