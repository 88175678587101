import React, { useState, useRef, Fragment } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FormatListBulleted, ViewModule } from '@material-ui/icons';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getFormattedDate } from 'common/Utilities';
import ListView from '../ListView';
import TrackPageDataViewStyle from './TrackPageDataViewComponent.module.css';
import GridViewComponent from '../../grid-view-component/GridViewComponent';
import SortIconDropDown from '../../sort-icon-dropdown/SortIconDropDown';

const TrackPageDataViewComponent = ({
  gridCardComponent,
  dataList,
  listViewStructure,
  listViewDataList,
  handleListViewClick,
  filterFunc,
  listViewFilterFunc,
  sortSchema
}) => {
  const [listViewMode, setListViewMode] = useState(false);
  const [filterInput, setFilterInput] = useState('');
  const [sortCompareFunc, setSortCompareFunc] = useState(undefined);

  const prevDataListRef = useRef();

  const filteredDataList = dataList.filter(each => {
    return filterFunc ? filterFunc(filterInput, each) : true;
  });
  if (dataList !== prevDataListRef.current) {
    setSortCompareFunc(undefined);
    prevDataListRef.current = dataList;
  } else {
    filteredDataList.sort(sortCompareFunc || undefined);
  }
  const filteredListViewDataList = listViewDataList.filter(each => {
    return listViewFilterFunc ? listViewFilterFunc(filterInput, each) : true;
  });

  // TODO: this function also needs to be change after refactor listview component
  const exportCsv = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.csv';
    const headerStructureList = listViewStructure;
    const data = filteredListViewDataList;
    const dataJson = data.map(each => {
      const one = {};
      headerStructureList.forEach(eachHeader => {
        if (Array.isArray(each[eachHeader.id])) {
          if (each[eachHeader.id].length === 0) {
            one[eachHeader.label] = '-';
          } else {
            let flag = '';
            each[eachHeader.id].forEach((eachFlag, index) => {
              flag += eachFlag.displayText;
              if (index < each[eachHeader.id].length - 1) {
                flag += ', ';
              }
            });
            one[eachHeader.label] = flag;
          }
        } else if (typeof each[eachHeader.id] === 'object' && each[eachHeader.id]) {
          one[eachHeader.label] = each[eachHeader.id].displayText;
        } else if (
          typeof each[eachHeader.id] !== 'string' &&
          new Date(each[eachHeader.id]).getTime() > 0 &&
          each[eachHeader.id].toString().length > 8
        ) {
          one[eachHeader.label] = getFormattedDate(each[eachHeader.id], 'hh:mm A MMM D YYYY');
        } else if (each[eachHeader.id]) {
          one[eachHeader.label] = each[eachHeader.id];
        } else {
          one[eachHeader.label] = '-';
        }
      });
      return one;
    });
    const temp = XLSX.utils.json_to_sheet(
      dataJson,
      headerStructureList.map(eachHeader => {
        return eachHeader.label;
      })
    );
    const wb = { Sheets: { data: temp }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const file = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(file, `DataSheet${fileExtension}`);
  };

  const renderGridView = () => {
    return (
      <GridViewComponent>
        {filteredDataList.map(eachData => {
          return gridCardComponent(
            eachData,
            eachData.id,
            TrackPageDataViewStyle.grid_card_container_style
          );
        })}
      </GridViewComponent>
    );
  };

  const renderListView = () => {
    // TODO: Listview needs to be refactor
    return (
      <ListView
        headerStructureList={listViewStructure}
        dataList={filteredListViewDataList}
        handleClick={handleListViewClick}
      />
    );
  };

  return (
    <div>
      <div className={TrackPageDataViewStyle.actions_container}>
        {filterFunc && (
          <input
            className={TrackPageDataViewStyle.filter_bar}
            title="Filter Input Field"
            value={filterInput}
            placeholder="Type to Filter Results"
            onChange={({ currentTarget }) => {
              setFilterInput(currentTarget.value);
            }}
          />
        )}
        <GetAppIcon className={TrackPageDataViewStyle.icon_button} onClick={exportCsv} />
        {listViewMode ? (
          <ViewModule
            className={TrackPageDataViewStyle.icon_button}
            onClick={() => {
              setListViewMode(false);
            }}
          />
        ) : (
          <Fragment>
            <SortIconDropDown
              sortSchema={sortSchema}
              onClick={compareFunc => {
                setSortCompareFunc(() => compareFunc);
              }}
            />
            <FormatListBulleted
              className={TrackPageDataViewStyle.icon_button}
              onClick={() => {
                setListViewMode(true);
              }}
            />
          </Fragment>
        )}
      </div>
      {listViewMode ? renderListView() : renderGridView()}
    </div>
  );
};

export default TrackPageDataViewComponent;
