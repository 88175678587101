// eslint-disable-next-line import/prefer-default-export
export const ResourceManagerLoader = {
  get: (resourceName) => {
    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      const module = require(`./resource-managers/${resourceName}-resource-manager`);
      if (!module) {
        return null;
      }

      return module.resourceManager;
    } catch (e) {
      return null;
    }
  }
};
