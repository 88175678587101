import React from 'react';
import PropTypes, { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import MainAppComponent from '../../components/main-app-component/MainAppComponent';
import HeaderComponent from '../../components/header-component/HeaderComponent';
import SideNavigationBar from '../side-navigation-bar/SideNavigationBar';
import RenderComponent from '../../components/render-component/RenderComponent';
import MainAppFeatureStyle from './MainAppFeature.module.css';

const MainAppFeature = ({ render, tabStructure, history }) => {
  const defaultTab = history.location.pathname.split('/')[1];
  return (
    <MainAppComponent
      containerStyle={MainAppFeatureStyle.main_app_component_container}
      headerComponent={() => (
        <HeaderComponent containerStyle={MainAppFeatureStyle.header_component_container} />
      )}
      sideNavigationComponent={() => {
        return (
          <SideNavigationBar
            tabStructure={tabStructure}
            containerStyle={MainAppFeatureStyle.side_navigation_container}
            defaultTab={defaultTab}
          />
        );
      }}
      renderComponent={() => {
        return (
          <RenderComponent
            containerStyle={MainAppFeatureStyle.render_component_container}
            renderer={render}
          />
        );
      }}
    />
  );
};

export default withRouter(MainAppFeature);

MainAppFeature.propTypes = {
  render: PropTypes.element.isRequired,
  tabStructure: PropTypes.arrayOf(object).isRequired
};
