import { AbstractResourceManager } from '../../abstract-resource-manager';
import { assetArray, orderArray } from './item-resource-manager';
import { Generator } from '../../../utilities/generator';
import { itemTypesArray } from './item-types-resource-manager';

const orderActiveTrackingSessionResponse = () => {
  const response = orderArray.map((order) => {
    const trackingSession = order['associatedWithSession#edge'][0]['associatedWithSession#node'][0];
    const lastUpdatedTime = trackingSession.last_updated_time;
    const startTime = trackingSession.start_time;
    const events = trackingSession.includesEvent;
    const lastKnownLocation = events[events.length - 1].detectedAt[0];
    return generateOrderTrackingSessionEvent(order, startTime, lastUpdatedTime, lastKnownLocation);
  });

  return response;
};

const generateOrderTrackingSessionEvent = (
  order,
  startTime,
  lastUpdatedTime,
  lastKnownLocation
) => {
  return {
    start_time: startTime,
    last_updated_time: lastUpdatedTime,
    tracksItem: [order],
    lastKnownLocation: [lastKnownLocation]
  };
};

// const inventoryActiveTrackingSessionResponse = () => {
//   const inventoryActiveTrackingSessionsSchemaMap = [
//     {
//       name: 'Kent Warehouse',
//       associatedWithEvent: [
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.labCoatType.identifier
//         },
//         {
//           total: 0,
//           identifier: itemTypesArray.safetyGlassesType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.weldinGogglesType.identifier
//         },
//         {
//           total: 0,
//           identifier: itemTypesArray.shoeCoversType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.paintType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.loctiteType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.abrasivesType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.sandingBeltType.identifier
//         }
//       ]
//     },
//     {
//       name: 'Auburn Warehouse',
//       associatedWithEvent: [
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.labCoatType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.safetyGlassesType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.weldinGogglesType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.shoeCoversType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.paintType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.loctiteType.identifier
//         },
//         {
//           total: 0,
//           identifier: itemTypesArray.abrasivesType.identifier
//         },
//         {
//           total: Generator.getRandomNumber(1, 50),
//           identifier: itemTypesArray.sandingBeltType.identifier
//         }
//       ]
//     }
//   ];

//   const result = [];
//   inventoryActiveTrackingSessionsSchemaMap.forEach((location) => {
//     location.associatedWithEvent.forEach((itemType) => {
//       const { identifier, total } = itemType;
//       const { name } = location;
//       for (let i = 0; i < total; i++) {
//         result.push(generateInventoryTrackingSessionEvent(identifier, name));
//       }
//     });
//   });

//   return result;
// };

// const generateInventoryTrackingSessionEvent = (identifier, locationName) => {
//   return {
//     last_updated_time: Generator.getRandomPastDate(1, 7).getTime(),
//     tracksItem: [
//       {
//         identifier: `${identifier}-${Generator.getRandomNumber(100, 999)}`,
//         lot_number: Generator.getRandomNumber(1, 20),
//         expiry_date: Generator.getRandomDateBetween(10, 90).getTime(),
//         ofType: [
//           {
//             identifier
//           }
//         ],
//         hasSensorProfile: [
//           {
//             vid: Generator.getRandomNumber(202002030000, 202002039999)
//           }
//         ]
//       }
//     ],
//     lastKnownLocation:
//       Math.random() < 0.8
//         ? [
//             {
//               name: locationName
//             }
//           ]
//         : []
//   };
// };

const assetActiveTrackingSessionResponse = () => {
  const response = assetArray.map((asset) => {
    const trackingSession = asset['associatedWithSession#edge'][0]['associatedWithSession#node'][0];
    const lastUpdatedTime = trackingSession.last_updated_time;
    const startTime = trackingSession.start_time;
    const events = trackingSession.includesEvent;
    const lastKnownLocation = events[events.length - 1].detectedAt[0];
    return generateAssetTrackingSessionEvent(asset, startTime, lastUpdatedTime, lastKnownLocation);
  });

  return response;
};

const generateAssetTrackingSessionEvent = (
  asset,
  startTime,
  lastUpdatedTime,
  lastKnownLocation
) => {
  return {
    is_missing: asset['associatedWithSession#edge'][0]['associatedWithSession#node'][0].is_missing,
    start_time: startTime,
    last_updated_time: lastUpdatedTime,
    tracksItem: [asset],
    lastKnownLocation: [lastKnownLocation]
  };
};

const keywordMap = {
  Traveller: orderActiveTrackingSessionResponse,
  // Material: inventoryActiveTrackingSessionResponse,
  Asset: assetActiveTrackingSessionResponse
};

// eslint-disable-next-line import/prefer-default-export
export class ActiveTrackingSessionsResourceManager extends AbstractResourceManager {
  constructor() {
    super('id');
  }

  supportMock(api, action, payload, headers) {
    if (api === 'query') {
      return true;
    }
    return super.supportMock(api, payload, headers);
  }

  list(payload) {
    let response = [];
    const foundMatchingKeyword = Object.keys(keywordMap).filter((keyword) => {
      return super.getSolutionSpecificRequest(keyword, payload);
    });

    if (foundMatchingKeyword.length > 0) {
      response = keywordMap[foundMatchingKeyword]();
    }

    return response;
  }
}

const resourceManager = new ActiveTrackingSessionsResourceManager();
export { resourceManager };
