import React from 'react';
import PropTypes from 'prop-types';
import TabListComponent from '../../components/tab-list-component/TabListComponent';
import SideNavigationBarStyle from './SideNavigationBar.module.css';

const SideNavigationBar = ({ containerStyle, tabStructure, defaultTab }) => {
  return (
    <TabListComponent
      tabStructure={tabStructure}
      containerStyle={containerStyle}
      tabStyle={SideNavigationBarStyle.tab_style}
      focusedTabStyle={SideNavigationBarStyle.focused_tab_style}
      tabListStyle={SideNavigationBarStyle.tab_list_style}
      defaultTab={defaultTab}
    />
  );
};

export default SideNavigationBar;

SideNavigationBar.defaultProps = {
  containerStyle: null,
  defaultTab: null
};

SideNavigationBar.propTypes = {
  containerStyle: PropTypes.string,
  tabStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultTab: PropTypes.string
};
