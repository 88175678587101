import React, { Component } from 'react';
import EditableTableComponent from '../../components/editable-table/EditableTableComponent';
import { XemelgoService } from '../../services/XemelgoService';

export default class IdleRuleContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownOptions: [],
      conditions: []
    };
    this.handleRuleConditionRequests = this.handleRuleConditionRequests.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }

  componentDidMount() {
    const { ruleConditionsList = [], locationDataList = [] } = this.props;
    this.onLoad(ruleConditionsList, locationDataList);
  }

  componentWillReceiveProps(newProps) {
    const ruleConditionsList = [...newProps.ruleConditionsList];
    const oldRuleConditionsList = [...this.props.ruleConditionsList];
    if (ruleConditionsList !== oldRuleConditionsList) {
      const { ruleConditionsList = [], locationDataList = [] } = newProps;
      this.onLoad(ruleConditionsList, locationDataList);
    }
  }

  onLoad = (ruleConditionsList, locationDataList) => {
    const conditions = this.buildRuleConditions(ruleConditionsList);
    let dropDownOptions = locationDataList.map((eachLocation) => {
      return { objectTypeName: eachLocation.name, objectTypeId: eachLocation.id };
    });

    conditions.forEach((eachCondition) => {
      const locationInfo = locationDataList.find(
        (location) => location.id === eachCondition.locationId
      );
      if (locationInfo) {
        eachCondition.locationName = locationInfo.name;
      }
      dropDownOptions = dropDownOptions.filter(
        (eachOption) => eachOption.objectTypeId !== eachCondition.locationId
      );
    });
    this.setState({
      conditions,
      dropDownOptions
    });
  };

  buildUpdateRuleConditionPayloadFromRequest = (requestPayload) => {
    const tags = {
      duration: requestPayload.duration * 1,
      locationId:
        requestPayload.locationId === 'All Locations' ? undefined : requestPayload.locationId,
      locationName:
        requestPayload.locationName === 'All Locations' ? undefined : requestPayload.locationName
    };
    const conditions = [];
    conditions.push({ property: 'duration', op: '>=', value: tags.duration });
    if (tags.locationId) {
      conditions.push({ property: 'locationId', op: '=', value: tags.locationId });
    }
    return { id: requestPayload.id, tags, conditions };
  };

  buildCreateRuleConditionPayloadFromRequest = (requestPayload) => {
    const tags = {
      duration: requestPayload.duration * 1,
      locationId: requestPayload.locationId,
      locationName: requestPayload.locationName
    };
    const conditions = [
      {
        property: 'duration',
        op: '>=',
        value: tags.duration
      },
      {
        property: 'locationId',
        op: '=',
        value: tags.locationId
      }
    ];
    return { tags, conditions };
  };

  buildRuleConditions = (ruleConditions) => {
    const { locationDataList } = this.props;
    let condition = ruleConditions.map((eachCondition) => {
      const locationData = locationDataList.find((a) => a.id === eachCondition.tags.locationId);
      return {
        id: eachCondition.id,
        currentValue: locationData ? locationData.associatedWithEvent.length : '',
        locationName: eachCondition.tags.locationName || 'All Locations',
        locationId: eachCondition.tags.locationId || 'All Locations',
        duration: eachCondition.tags.duration,
        subscribed: eachCondition.hasSubscriptions,
        disableDelete: !eachCondition.tags.locationName
      };
    });
    condition = condition.sort((a, b) => {
      return a.locationName.localeCompare(b.locationName);
    });
    return condition;
  };

  handleValidation = (payloads) => {
    let duration;
    let currentRowId;
    const inputErrorMap = {};

    const validatedObject = {
      errorExists: false,
      errorMap: inputErrorMap
    };
    payloads.forEach((payloadItem) => {
      const inputError = {
        duration: false
      };
      if (payloadItem._event !== 'pending_delete') {
        duration = payloadItem.duration;
        currentRowId = payloadItem.id;
        if (duration === '' || !duration || !(+duration === +duration) || +duration <= 0) {
          inputError.duration = true;
        } else {
          inputError.duration = false;
        }

        inputErrorMap[currentRowId] = inputError;
      }
    });

    const BreakForEachLoop = { exception: 'Error exists.' };

    try {
      Object.keys(inputErrorMap).forEach((key) => {
        if (inputErrorMap[key].duration) {
          validatedObject.errorExists = true;
          throw BreakForEachLoop;
        } else {
          validatedObject.errorExists = false;
        }
      });
    } catch (e) {
      alert('Please enter valid values in the highlighted field(s).');
      if (e !== BreakForEachLoop) throw e;
    }

    validatedObject.errorMap = inputErrorMap;

    return validatedObject;
  };

  handleAutoFill = (headerId, newValue, data) => {
    const { dropDownOptions } = this.state;

    const autoFillObject = {
      data,
      changed: true
    };

    if (headerId === 'locationName') {
      const itemIndex = dropDownOptions
        .map((i) => {
          return i.objectTypeName;
        })
        .indexOf(newValue);
      const itemId = dropDownOptions[itemIndex].objectTypeId;
      data.locationId = itemId;
    }
    autoFillObject.data = data;

    if (data.name === '' && data.threshold === '') {
      autoFillObject.changed = false;
    }

    return autoFillObject;
  };

  handleRuleConditionRequests = async (requests) => {
    const { ruleId } = this.props;
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    if (!requests || requests.length === 0) {
      return;
    }

    await Promise.all(
      requests.map(async (request) => {
        const event = request._event;
        let payload;
        switch (event) {
          case 'pending_new':
            payload = this.buildCreateRuleConditionPayloadFromRequest(request);
            await RulePageClient.createRuleCondition(
              `idle_${payload.tags.locationName.replace(/[^\w\s]/gi, '_')}_${
                payload.tags.locationId
              }`,
              payload.tags,
              payload.conditions,
              ruleId
            );
            break;
          case 'pending_update':
            payload = this.buildUpdateRuleConditionPayloadFromRequest(request);
            await RulePageClient.updateRuleCondition(
              payload.id,
              undefined,
              payload.tags,
              payload.conditions
            );
            break;
          case 'pending_delete':
            await RulePageClient.removeRuleCondition(request.id, false);
            break;
          default:
            console.log(`Unsupported Event[Name:${event}]`);
            break;
        }

        console.log(`==> Request: ${JSON.stringify(request)}`);
      })
    );

    const { ruleConditionsList = [] } = this.props;
    await this.props.onLoad();
    const conditions = await this.buildRuleConditions(ruleConditionsList);
    await this.setState({ conditions });
    return conditions.map((condition) => condition.id);
  };

  handleSubscriptionChange = async (ruleConditionId, subscribed) => {
    const { ruleConditionsList } = this.props;
    const ruleCondition = ruleConditionsList.find((condition) => condition.id === ruleConditionId);
    const isLocationCondition = ruleCondition.tags.locationId;
    const subscribedRuleConditionIdList = [];
    const unsubscribedRuleConditionIdList = [];
    if (subscribed) {
      subscribedRuleConditionIdList.push(ruleConditionId);
      if (isLocationCondition) {
        const allLocationRuleCondition = ruleConditionsList.find((each) => {
          return !each.tags.locationId;
        });
        unsubscribedRuleConditionIdList.push(allLocationRuleCondition.id);
      } else {
        const listOfCondition = ruleConditionsList.filter((each) => each.id !== ruleConditionId);
        listOfCondition.forEach((each) => {
          unsubscribedRuleConditionIdList.push(each.id);
        });
      }
    } else {
      unsubscribedRuleConditionIdList.push(ruleConditionId);
    }
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    await RulePageClient.updateSubscriptionForRuleConditions(
      subscribedRuleConditionIdList,
      unsubscribedRuleConditionIdList
    );
    await this.props.onLoad();
  };

  render() {
    const { conditions, dropDownOptions } = this.state;

    const headers = [
      {
        displayName: '',
        id: 'subscribed',
        cell: {
          input: 'switch',
          display: 'switch',
          modifiable: true // whether it can be edited after being added
        }
      },
      {
        displayName: 'Location',
        id: 'locationName',
        cell: {
          input: 'dropdown',
          data: dropDownOptions,
          display: 'text',
          modifiable: false // whether it can be edited after being added
        }
      },
      {
        displayName: 'Order Idle Time',
        id: 'duration',
        cell: {
          input: 'text',
          display: 'text',
          modifiable: true, // whether it can be edited after being added
          unit: 'hour(s)'
        }
      }
    ];
    return (
      <div>
        <div>
          <p className="tabbedSectionComponent-contentTitle">Idle</p>
          <p style={{ color: '#343434' }}>
            Sends a notification if a work order stays in a location over the set threshold time.
          </p>
        </div>
        <div className="note">
          <span>Receive notifications for the locations listed:</span>
        </div>
        <EditableTableComponent
          handleSubscriptionChange={this.handleSubscriptionChange}
          headers={headers}
          dataList={conditions}
          handleChangesFn={this.handleRuleConditionRequests}
          handleValidationFn={this.handleValidation}
          handleAutoFillFn={this.handleAutoFill}
        />
      </div>
    );
  }
}
