import './mocks/axios-interceptor';
import 'babel-polyfill'; // IE11 Compatibility Import for 'Promise' and 'Object'
import React from 'react';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginPage from './pages/login';

import AuthorizedProcessAppRoute from './routes/AuthorizedProcessAppRoute';

import MainApp from './MainApp';
import registerServiceWorker from './registerServiceWorker';
import ConfigurationService from './services/ConfigurationService';
import LoginService from './services/LoginService';
import AuthService from './services/AuthService';
import RestService from './services/RestService';

if (LoginService.isCustomerAccountRegistered()) {
  AuthService.configure(ConfigurationService.getAuthConfiguration());
  ConfigurationService.getRestServiceConfiguration().then((restConfiguration) => {
    RestService.configure(restConfiguration);
    ReactDOM.render(
      <Router>
        <Route exact path="/login" component={LoginPage} />
        <AuthorizedProcessAppRoute path="/" component={MainApp} />
      </Router>,
      document.getElementById('root')
    );
  });
} else {
  ReactDOM.render(
    <Router>
      <Route exact path="/login" component={LoginPage} />
      <AuthorizedProcessAppRoute path="/" component={MainApp} />
    </Router>,
    document.getElementById('root')
  );
}
registerServiceWorker();
