import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../../components/div';
import { CollapsibleListView } from '../../../../components/collapsible-list-view';
import { LocationRowsWithOperations } from '../location-rows-with-operations';
import { LocationGroupHeader } from './location-group-header';
import { AddLocationForm } from '../add-location-form';
import './style.css';
import { FeatureConfigurationProvider } from '../../../../domains/feature-configuration-provider';

export const ListLocationGroupsPanel = ({
  configuration,
  locationModelIds,
  locationModelConfigurationMap,
  locationsMap,
  appId,
  featureId,
  onSelectLocation,
  onLocationRecordChanged
}) => {
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [addingModelId, setAddingModelId] = useState(null);
  const [addLocationConfiguration, setAddLocationConfiguration] = useState(null);

  /**
   * Name: prepareChildrenConfiguration
   */
  useEffect(() => {
    let cancelled = false;
    const cancelEffectCallback = () => {
      cancelled = true;
    };

    const configurationProvider = FeatureConfigurationProvider.parse(
      'ListLocationGroupsPanel',
      configuration
    );

    const addFormConfig = configurationProvider.getFeatureConfiguration('addResource');
    if (!cancelled) {
      // setConfigProvider(configurationProvider);
      setAddLocationConfiguration(addFormConfig);
    }

    return cancelEffectCallback;
  }, [configuration]);

  const onSelectLocationCallback = useCallback(
    (location, groupId) => {
      setSelectedGroupId(groupId);
      onSelectLocation(location, groupId);
    },
    [onSelectLocation]
  );

  const onAddClicked = (modelId) => {
    setAddingModelId(modelId);
    setShowAddModal(true);
  };

  const onAddSubmit = () => {
    onLocationRecordChanged(addingModelId);
    setShowAddModal(false);
    setAddingModelId(null);
  };

  const onAddFormClose = useCallback(() => {
    setShowAddModal(false);
  }, []);

  return (
    <Div className="my-facility-locations">
      {showAddModal && (
        <AddLocationForm
          modelId={addingModelId}
          onFormClosed={onAddFormClose}
          show={showAddModal}
          onSubmit={onAddSubmit}
          configuration={addLocationConfiguration}
        />
      )}
      <Div className="my-facility-locations-title-section">
        <Div className="my-facility-locations-title-header">Locations</Div>
        <Div className="my-facility-locations-title-subtitle">
          Set up and edit the structure of your organization
        </Div>
      </Div>
      <Div>
        {locationModelIds.map((modelId) => {
          const modelConfiguration = locationModelConfigurationMap[modelId];
          const { category } = modelConfiguration;
          const {
            displayName: categoryDisplayName,
            groupDisplayName: categoryGroupDisplayName
          } = category;

          const groupId = modelId;
          const locations = locationsMap[groupId] || [];
          return (
            <CollapsibleListView
              className="category-detail-view"
              key={groupId}
              title={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <LocationGroupHeader
                  modelId={modelId}
                  groupName={categoryGroupDisplayName}
                  singleResourceName={categoryDisplayName}
                  resourceCount={locations.length}
                  onAddClick={onAddClicked}
                />
              }
              content={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <LocationRowsWithOperations
                  locations={locations}
                  appId={appId}
                  featureId={featureId}
                  onSelectLocation={(location) => onSelectLocationCallback(location, groupId)}
                  isChildSelected={selectedGroupId === groupId}
                  onLocationRecordChanged={(locationId) =>
                    onLocationRecordChanged(groupId, locationId)
                  }
                />
              }
            />
          );
        })}
      </Div>
    </Div>
  );
};

ListLocationGroupsPanel.defaultProps = {
  locationModelConfigurationMap: {},
  locationsMap: {},
  onSelectLocation: () => {},
  onLocationRecordChanged: () => {},
  locationModelIds: [],
  configuration: {}
};

ListLocationGroupsPanel.propTypes = {
  configuration: PropTypes.shape({
    modelMap: PropTypes.objectOf(
      PropTypes.shape({
        category: PropTypes.shape({
          name: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired
        }),
        parentLocationCategory: PropTypes.shape({
          name: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired
        }),
        propertyOrders: PropTypes.arrayOf(PropTypes.string),
        properties: PropTypes.objectOf(
          PropTypes.shape({
            __addable: PropTypes.bool,
            __optional: PropTypes.bool,
            __optionallyDependsOn: PropTypes.arrayOf(PropTypes.string),
            displayName: PropTypes.string,
            input: PropTypes.oneOfType([
              PropTypes.bool,
              PropTypes.string,
              PropTypes.arrayOf(
                PropTypes.shape({
                  key: PropTypes.string.isRequired,
                  value: PropTypes.string.isRequired
                })
              )
            ])
          })
        )
      })
    )
  }),
  appId: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  locationModelIds: PropTypes.arrayOf(PropTypes.string),
  locationModelConfigurationMap: PropTypes.objectOf(
    PropTypes.shape({
      category: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        groupDisplayName: PropTypes.string.isRequired
      }).isRequired
    })
  ),
  locationsMap: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        getName: PropTypes.func,
        getId: PropTypes.func
      })
    )
  ),
  onSelectLocation: PropTypes.func,
  onLocationRecordChanged: PropTypes.func
};
