import { v4 as uuidV4 } from 'uuid';
import { AbstractMockPlanExecutor } from './abstract-mock-plan-executor';
import { AssociatedWithEventMockPlanExecutor } from './associated-with-event-mock-plan-executor';

const ConnectionFieldMap = {
  isLocatedIn: 'containsLocations'
};

const referencingRegex = /#\{(.*)\}/;

const generateDetector = () => {
  return {
    id: uuidV4(),
    vid: uuidV4(),
    actions: null
  };
};

const getReferencesFields = (locationPlan) => {
  return Object.keys(ConnectionFieldMap).filter((referencingField) => {
    const value = locationPlan[referencingField];
    return value;
  });

  // return ConnectionFields.filter((referencingField) => {
  //   const value = locationPlan[referencingField];
  //   return value;
  // });
};

const createLocationFromPlan = (planKey, locationPlan, addItemFn, createdLocationMap) => {
  // in case they have already created, skip it
  const location = createdLocationMap[planKey];
  if (location) {
    return location;
  }

  const referencingFields = getReferencesFields(locationPlan);
  const payload = { ...locationPlan };

  const reverseConnectionPlanMap = {};
  referencingFields.forEach((connectionField) => {
    const referencingValue = locationPlan[connectionField];
    const match = referencingRegex.exec(referencingValue);

    if (!match) {
      return;
    }
    const referencingPlanKey = match[1];
    const created = createLocationFromPlan(
      referencingPlanKey,
      locationPlan,
      addItemFn,
      createdLocationMap
    );

    // register the other location which is referencing for this location so we can have a back reference from the other
    //  location. We cannot do this right away since this location is yet registered and has no id assigned.
    const reversedConnectionName = ConnectionFieldMap[connectionField];
    const reverseList = reverseConnectionPlanMap[reversedConnectionName] || [];
    reverseList.push(referencingPlanKey);
    reverseConnectionPlanMap[reversedConnectionName] = reverseList;

    payload[connectionField] = created.id;
  });

  const { associatedWithEvent } = payload;
  payload.associatedWithEvent = AssociatedWithEventMockPlanExecutor.execute(associatedWithEvent);
  payload.hasDetectors = [generateDetector()];

  const thisCreatedLocation = addItemFn(payload);
  // eslint-disable-next-line no-param-reassign
  createdLocationMap[planKey] = thisCreatedLocation;

  // now that this location is registered and assigned an ID, it is time to get back to other locations it has references
  //  to and create a back reference.
  Object.keys(reverseConnectionPlanMap).forEach((connectionName) => {
    const referencedIds = reverseConnectionPlanMap[connectionName];
    referencedIds.forEach((locationId) => {
      const referencedLocation = createdLocationMap[locationId];
      const backReferences = referencedLocation[connectionName] || [];
      backReferences.push(thisCreatedLocation.id);

      referencedLocation[connectionName] = backReferences;
      // eslint-disable-next-line no-param-reassign
      createdLocationMap[locationId] = referencedLocation;
    });
  });

  return thisCreatedLocation;
};

const createLocation = (payload) => {
  const id = payload.id || uuidV4();
  return {
    id,
    ...payload
  };
};

export class LocationMockPlanExecutor extends AbstractMockPlanExecutor {
  static execute(schema) {
    const executor = new LocationMockPlanExecutor(schema);
    return executor.generateData();
  }

  generateData() {
    const schema = { ...this.schema };

    const createdLocationMap = {};
    Object.keys(schema).forEach((locationRefId) => {
      const description = schema[locationRefId];
      createLocationFromPlan(locationRefId, description, createLocation, createdLocationMap);
    });

    return Object.values(createdLocationMap);
  }
}
