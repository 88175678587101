import React from 'react';
import Overview from '../../Overview';
import AssetOverviewComponentStyle from './AssetOverviewComponent.module.css';

const AssetOverviewComponent = ({
  leftComponent,
  logoImage,
  numOfAsset,
  numOfMissing,
  numOfWeeklyMissing
}) => {
  const LeftComponent = () => {
    if (leftComponent) {
      return leftComponent;
    }

    return (
      <img className={AssetOverviewComponentStyle.image} src={logoImage} alt="failed to load img" />
    );
  };

  const RightComponent = () => (
    <div>
      <p className={AssetOverviewComponentStyle.header_style}>Overview</p>
      <div className={AssetOverviewComponentStyle.group}>
        <p className={AssetOverviewComponentStyle.label_group}>
          Total No. of Assets
          <br />
          <b className={AssetOverviewComponentStyle.value}>{numOfAsset}</b>
        </p>
        <p className={AssetOverviewComponentStyle.label_group}>
          No. of Missing Assets
          <br />
          <b className={AssetOverviewComponentStyle.value}>{numOfMissing}</b>
        </p>
      </div>

      <p className={AssetOverviewComponentStyle.header_style}>Weekly Metrics</p>
      <div className={AssetOverviewComponentStyle.group}>
        <p className={AssetOverviewComponentStyle.label_group}>
          Missing Assets
          <br />
          <b className={AssetOverviewComponentStyle.value}>{numOfWeeklyMissing}</b>
        </p>
      </div>
    </div>
  );

  return <Overview leftComponent={<LeftComponent />} rightComponent={<RightComponent />} />;
};

export default AssetOverviewComponent;
