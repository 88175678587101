import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SortIcon from '@material-ui/icons/Sort';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountUpAlt,
  faSortAmountDownAlt
} from '@fortawesome/free-solid-svg-icons';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownItem, MDBDropdownMenu } from 'mdbreact';
import './SortIconDropDown.css';

const iconSelection = (type, reverse) => {
  switch (type) {
    case 'number':
      if (reverse) {
        return faSortNumericUp;
      }
      return faSortNumericDown;
    case 'character':
      if (reverse) {
        return faSortAlphaUp;
      }
      return faSortAlphaDown;
    default:
      if (reverse) {
        return faSortAmountUpAlt;
      }
      return faSortAmountDownAlt;
  }
};

const SortIconDropDown = ({ sortSchema, onClick }) => {
  const [focusedId, setFocusedId] = useState('');
  const [reverse, setReverse] = useState(false);
  useEffect(() => {
    if (sortSchema) {
      sortSchema.forEach(each => {
        if (each.default) {
          setFocusedId(each.id);
          setReverse(false);
          onClick(each.compareFunc);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortSchema]);
  return (
    <MDBDropdown>
      <MDBDropdownToggle className="dropdown_toggle">
        <SortIcon className="icon_button" />
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        {sortSchema &&
          sortSchema.map(each => {
            return (
              <MDBDropdownItem
                id={each.id}
                key={each.id}
                className={`dropdown_item ${focusedId === each.id && 'active'}`}
                onClick={() => {
                  if (focusedId === each.id) {
                    const newReverseValue = !reverse;
                    onClick((a, b) => {
                      return newReverseValue
                        ? -each.compareFunc(a, b, newReverseValue)
                        : each.compareFunc(a, b, newReverseValue);
                    });
                    setReverse(newReverseValue);
                  } else {
                    setReverse(false);
                    setFocusedId(each.id);
                    onClick(each.compareFunc);
                  }
                }}
                toggle={false}
              >
                <div className="title">{each.value}</div>
                <FontAwesomeIcon
                  icon={iconSelection(each.type, reverse && focusedId === each.id)}
                />
              </MDBDropdownItem>
            );
          })}
      </MDBDropdownMenu>
    </MDBDropdown>
  );
};

export default SortIconDropDown;

SortIconDropDown.defaultProps = {
  sortSchema: [],
  onClick: () => {}
};

SortIconDropDown.propTypes = {
  sortSchema: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func
};
