import { AbstractResourceManager } from '../../abstract-resource-manager';
import { Generator } from '../../../utilities/generator';
import { inventoryArray } from './item-resource-manager';

const generateInventoryTrackingSession = (inventory, trackingSession) => {
  return {
    end_time: trackingSession.end_time,
    start_time: trackingSession.start_time,
    last_updated_time: trackingSession.last_updated_time,
    'tracksItem#edge': [
      {
        'tracksItem#node': [inventory]
      }
    ],
    includesEvent: trackingSession.includesEvent
  };
};

const inventoryTrackingSessionResponse = () => {
  const response = inventoryArray.map((inventory) => {
    const trackingSession =
      inventory['associatedWithSession#edge'][0]['associatedWithSession#node'][0];
    return generateInventoryTrackingSession(inventory, trackingSession);
  });

  return response;
};

const init = (addItemFn) => {
  inventoryTrackingSessionResponse().forEach((item) => addItemFn(item));
};

// eslint-disable-next-line import/prefer-default-export
export class TrackingSessionsResourceManager extends AbstractResourceManager {
  constructor() {
    super('id');
    init(this.addItem.bind(this));
  }

  supportMock(api, action, payload, headers) {
    if (api === 'query') {
      return true;
    }
    return super.supportMock(api, payload, headers);
  }

  filter(propertyMap, payload) {
    return super.list();
  }

  list() {
    return super.list();
  }
}

const resourceManager = new TrackingSessionsResourceManager();
export { resourceManager };
