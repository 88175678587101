import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../../../components/div';
import './style.css';
import { FlexibleInput } from '../../../../../components/flexible-input';
import { checkPayloadAgainstPropertiesForError } from '../../../../domains/validations/validate-for-input-requirement';

export const AddResourceFormSection = ({
  sectionId,
  properties,
  submitTicket,
  onValidationPassed,
  onValidationError
}) => {
  const [propertyErrorMap, setPropertyErrorMap] = useState({});
  const [payload, setPayload] = useState({});

  const onPayloadChangedCallback = useCallback((propertyName, value) => {
    setPayload((currentPayload) => {
      const cloned = { ...currentPayload };
      cloned[propertyName] = value;
      return cloned;
    });
  }, []);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!submitTicket || !properties || properties.length === 0) {
      return cancelCallback;
    }

    const report = checkPayloadAgainstPropertiesForError(payload, properties);
    const { hasError, errorResultMap } = report;
    if (!hasError && !cancelled) {
      onValidationPassed(sectionId, payload);
    } else if (hasError && !cancelled) {
      setPropertyErrorMap({ ...errorResultMap });
      onValidationError(sectionId);
    }

    return cancelCallback;
  }, [submitTicket, properties, onValidationPassed, payload, sectionId, onValidationError]);

  return (
    <Div className="add-location-form-section">
      {properties.map((prop) => {
        const { input, displayName, name, editable, optional } = prop;
        const label = `${displayName}`;
        const className = propertyErrorMap[name] ? 'error-require' : '';
        return (
          <FlexibleInput
            label={label}
            propertyName={name}
            editable={editable}
            optional={optional}
            options={input}
            className={className}
            key={name}
            onPayloadChanged={onPayloadChangedCallback}
          />
        );
      })}
    </Div>
  );
};

AddResourceFormSection.defaultProps = {
  properties: [],
  submitTicket: null,
  onValidationPassed: () => {},
  onValidationError: () => {}
};

AddResourceFormSection.propTypes = {
  sectionId: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      optional: PropTypes.bool,
      optionallyDependsOn: PropTypes.arrayOf(PropTypes.string),
      input: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          flag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string,
            defaultValue: PropTypes.bool
          })
        })
      )
    })
  ),
  submitTicket: PropTypes.string,
  onValidationPassed: PropTypes.func,
  onValidationError: PropTypes.func
};
