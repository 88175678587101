import { Component } from 'react';
import AuthService from '../../services/AuthService';
import ConfigurationService from '../../services/ConfigurationService';

export default class UserBasePage extends Component {
  async initializeUserService(userService) {
    const authConfig = ConfigurationService.getAuthConfiguration();
    const credentials = await AuthService.retrieveEssentialCredentials();

    const config = {
      region: authConfig.Auth.region,
      userPoolId: authConfig.Auth.userPoolId,
      credentials
    };

    await userService.configure(config);
  }
}
