import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DropDownInput } from '../drop-down-input';
import { TextInput } from '../text-input';
import { LabelWithOptionalText } from './label-with-optional-text';
import { DropDownWithOptionControl } from './dropdown-with-option-control';

const dropdownHasOptionControl = (options) => {
  const foundCheckOptions = options.filter((opt) => {
    return opt.properties;
  });
  return foundCheckOptions.length > 0;
};

export const FlexibleInput = ({
  propertyName,
  onPayloadChanged,
  options,
  optional,
  label,
  className,
  editable,
  defaultValue
}) => {
  const onPayloadChangedCallback = useCallback(
    (value) => {
      onPayloadChanged(propertyName, value);
    },
    [propertyName, onPayloadChanged]
  );

  const onSubOptionSelectCallback = useCallback(
    (name, value) => {
      onPayloadChanged(name, value);
    },
    [onPayloadChanged]
  );

  if (options && editable) {
    const hasDropdownCheckOption = dropdownHasOptionControl(options);
    if (!hasDropdownCheckOption) {
      return (
        <DropDownInput
          label={<LabelWithOptionalText label={label} isOptional={optional} />}
          dropdownOptions={options}
          key={propertyName}
          includeEmptyOption
          onSelect={(key) => onPayloadChangedCallback(key)}
          onUnselect={() => onPayloadChangedCallback(null)}
          dropdownClassName={className}
          defaultSelectedKey={defaultValue}
        />
      );
    }

    return (
      <DropDownWithOptionControl
        label={<LabelWithOptionalText label={label} isOptional={optional} />}
        dropdownOptions={options}
        key={propertyName}
        includeEmptyOption
        onSelect={(key) => onPayloadChangedCallback(key)}
        onUnselect={() => onPayloadChangedCallback(null)}
        dropdownClassName={className}
        onSubOptionSelect={onSubOptionSelectCallback}
        defaultSelectKey={defaultValue}
      />
    );
  }
  return (
    <TextInput
      label={<LabelWithOptionalText label={label} isOptional={optional} />}
      key={propertyName}
      readonly={!editable}
      onChange={(value) => onPayloadChangedCallback(value)}
      inputClassName={className}
      placeholder={defaultValue}
      initialValue={defaultValue}
    />
  );
};

FlexibleInput.defaultProps = {
  onPayloadChanged: () => {},
  className: '',
  editable: false,
  options: null,
  optional: false,
  defaultValue: null
};

FlexibleInput.propTypes = {
  propertyName: PropTypes.string.isRequired,
  onPayloadChanged: PropTypes.func,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  optional: PropTypes.bool,
  className: PropTypes.string,
  editable: PropTypes.bool,
  defaultValue: PropTypes.string
};
