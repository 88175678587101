import { AbstractApi } from '../abstract-api';
import { resourceManager as restockRequestRM } from './resource-managers/restock-request-resource-manager';

// eslint-disable-next-line import/prefer-default-export
class RestockRequests extends AbstractApi {
  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  canMock(payload, headers, method, apiSegments) {
    return method === 'get';
  }

  // eslint-disable-next-line class-methods-use-this
  execute(payload, headers, method, apiSegments) {
    if (method === 'get') {
      if (apiSegments.length === 1) {
        return restockRequestRM.list();
      }
      return restockRequestRM.get(apiSegments[1]);
    }

    return null;
  }
}

const api = new RestockRequests();
// eslint-disable-next-line import/prefer-default-export
export { api };
