import { UserProfile } from '../domains/user-profile';
import { UserConfiguration } from '../domains/user-configuration';

const CacheKeys = {
  userConfigKey: 'Xemelgo-user-configuration',
  userProfileKey: 'Xemelgo-user-profile'
};

const retrieveCachedObject = (keyName, newDomainFn) => {
  const content = localStorage.getItem(keyName);

  if (content) {
    return newDomainFn(content);
  }

  return null;
};

export const LocalCacheService = {
  saveUserProfile(userProfile) {
    localStorage.setItem(CacheKeys.userProfileKey, userProfile.toJson());
  },

  loadUserProfile() {
    return retrieveCachedObject(CacheKeys.userProfileKey, (content) => new UserProfile(content));
  },

  clearUserProfile() {
    localStorage.removeItem(CacheKeys.userProfileKey);
  },

  saveUserConfig(userConfiguration) {
    localStorage.setItem(CacheKeys.userConfigKey, userConfiguration.toJson());
  },

  loadUserConfig() {
    return retrieveCachedObject(CacheKeys.userConfigKey, (config) =>
      UserConfiguration.parse(config)
    );
  },

  clearUserConfig() {
    localStorage.removeItem(CacheKeys.userConfigKey);
  },

  clearAllCaches() {
    localStorage.removeItem(CacheKeys.userConfigKey);
    localStorage.removeItem(CacheKeys.userProfileKey);
  }
};
