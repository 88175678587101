import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { Tooltip } from '@material-ui/core';

const RgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;

const adjustBorderColorCode = (colorValue) => {
  if (colorValue >= 255) {
    return 255;
  }

  if (colorValue < 1) {
    return 0;
  }

  return colorValue;
};

export const BubblePinHeadWithTooltip = ({ name, color, bubbleScale, tooltip }) => {
  const [textColorStyle, setTextColorStyle] = useState('rgb(0, 0, 0)');
  const [backgroundColorStyle, setBackgroundColorStyle] = useState('rgb(255, 255, 255)');
  const [borderColorStyle, setBorderColorStyle] = useState(backgroundColorStyle);

  const radius = bubbleScale * 13;
  const tooltipDescription = tooltip || name;
  const onRef = useCallback(
    (ref) => {
      if (!ref) {
        return;
      }

      const rgbColorString = window
        .getComputedStyle(ref, null)
        .getPropertyValue('background-color');
      if (backgroundColorStyle === rgbColorString) {
        return;
      }

      const match = RgbRegex.exec(rgbColorString);
      if (match.length === 4) {
        const r = parseInt(match[1], 10);
        const g = parseInt(match[2], 10);
        const b = parseInt(match[3], 10);

        // text color
        const contrastR = 255 - r;
        const contrastG = 255 - g;
        const contrastB = 255 - b;
        const colorStyle = `rgb(${contrastR}, ${contrastG}, ${contrastB})`;

        // border color
        const borderR = adjustBorderColorCode(parseInt((r + 1) * 5.5, 10));
        const borderG = adjustBorderColorCode(parseInt((g + 1) * 5.5, 10));
        const borderB = adjustBorderColorCode(parseInt((b + 1) * 5.5, 10));
        const borderStyle = `rgb(${borderR}, ${borderG}, ${borderB})`;

        setTextColorStyle(colorStyle);
        setBackgroundColorStyle(rgbColorString);
        setBorderColorStyle(borderStyle);
      }
    },
    [backgroundColorStyle]
  );

  return (
    <Tooltip title={tooltipDescription} interactive>
      <div
        className="bubble-pin-badge"
        ref={onRef}
        style={{
          backgroundColor: color,
          width: `${2 * radius}px`,
          height: `${2 * radius}px`,
          fontSize: `${bubbleScale * 11}px`,
          color: 'white',
          border: `${bubbleScale}px solid`,
          lineHeight: `${2 * radius}px`,
          textAlign: 'center'
        }}
      >
        <span>{name}</span>
      </div>
    </Tooltip>
  );
};

BubblePinHeadWithTooltip.defaultProps = {
  color: '#000000',
  bubbleScale: 1.5,
  tooltip: null
};

BubblePinHeadWithTooltip.propTypes = {
  name: PropTypes.string.isRequired,
  bubbleScale: PropTypes.number,
  color: PropTypes.string,
  tooltip: PropTypes.node
};
