export class AbstractMockPlanExecutor {
  constructor(schema) {
    this.schema = schema;
  }

  getSchema() {
    return this.schema;
  }

  // eslint-disable-next-line class-methods-use-this
  generateData() {
    return [];
  }
}
