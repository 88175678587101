import React, { Component } from 'react';
// import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { Card, CardTitle, CardBody, Row, Col } from 'mdbreact';
import { XemelgoService } from 'services/XemelgoService';
import Autosuggest from 'react-autosuggest';
import TrieSearch from 'trie-search';
import { getFormattedDate } from 'common/Utilities';

import Style from './SearchBar.module.css';

const StringMaxLength = 35;

export default class SearchBarAndResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResults: this.props.searchResults,
      placeholder: this.props.placeholder || 'Search',
      keys: this.props.keys,
      suggestions: [],
      query: '',
      value: '',
      searchFocused: false
    };
  }

  componentDidMount = () => {
    this.populateData();
  };

  /* Set this.state.items to full list of items
   * Indeces will be created every time the page is rendered
   */
  populateData = async () => {
    const XemelgoClient = XemelgoService.getClient();
    const SearchClient = XemelgoClient.getSearchClient();
    const items = await SearchClient.getAllItems();
    const { keys } = this.state;

    if (!items || items.length < 1) {
      return;
    }

    // Build the prefix tree search
    const trie_search = new TrieSearch(keys, { ignoreDuplicates: true });
    trie_search.addAll(items);

    // Build indeces to search parts and sensorProfiles since trie_search cannot handle arrays
    const nonTrieIndices = {};

    items.forEach((item, i) => {
      if (item.identifier) {
        nonTrieIndices[item.identifier.toUpperCase()] = [i];
      }
      if (item.customFields) {
        const { searchData } = item.customFields;
        if (searchData.status_flags && searchData.status_flags.length > 0) {
          const { status_flags } = searchData;
          status_flags.forEach(flag => {
            const indexArray = nonTrieIndices[flag.displayText.toUpperCase()] || [];
            if (indexArray && indexArray.length > -1) {
              indexArray.push(i);
              nonTrieIndices[flag.displayText.toUpperCase()] = indexArray;
            }
          });
        }

        if (searchData.parts && searchData.parts.length > 0) {
          const relatedParts = searchData.parts;
          relatedParts.length > 0 &&
            relatedParts.forEach(part => {
              // array of indices for the part
              const indexArray =
                nonTrieIndices[part.identifier.toUpperCase()] &&
                nonTrieIndices[part.identifier.toUpperCase()].length > 0
                  ? nonTrieIndices[part.identifier.toUpperCase()]
                  : [];
              indexArray.push(i);
              if (part.identifier) nonTrieIndices[part.identifier.toUpperCase()] = indexArray;
              if (part.name) nonTrieIndices[part.name.toUpperCase()] = indexArray;
            });
        }
        if (searchData.sensorProfile) {
          // RFID Tags - unique to each order
          nonTrieIndices[searchData.sensorProfile.toUpperCase()] = [i];
        }
      }
    });

    this.setState({ items, trie_search, nonTrieIndices });
  };

  /* Given a query, define how to search over the indeces. Intended to search all parts of string */
  getSubstringSuggestions = (query, inputLength) => {
    const results = [];
    const setOfSuggestionIds = new Set();
    const { items, nonTrieIndices } = this.state;
    const nonTrieIndexKeys = Object.keys(nonTrieIndices);

    if (inputLength > 0 && nonTrieIndexKeys.length > 0) {
      for (const k in nonTrieIndexKeys) {
        const key = nonTrieIndexKeys[k];

        if (key.toUpperCase().includes(query)) {
          nonTrieIndices[nonTrieIndexKeys[k]].forEach(index => {
            // check to make sure there are no duplicates in suggestions
            if (!setOfSuggestionIds.has(items[index].id)) {
              setOfSuggestionIds.add(items[index].id);
              results.push(items[index]);
            }
          });
        }
      }
    }
    return results;
  };

  /* Given a query, define how to search (use both prefix tree and index). Search start of strings */
  getSuggestions = query => {
    const { trie_search } = this.state;
    const inputValue = query.trim().toUpperCase();
    const inputLength = inputValue.length;

    const trieSearchResults = trie_search.get(inputValue);
    const extended = this.getSubstringSuggestions(inputValue, inputLength);

    const suggestionIds = new Set(trieSearchResults.map(d => d.id));

    // merging suggestions from trie_search and nonTrieIndices without duplicates
    const allResults = [...trieSearchResults, ...extended.filter(d => !suggestionIds.has(d.id))];
    return allResults;
  };

  /* What to display in search bar once the user clicks a suggestion */
  getSuggestionValue = suggestion => `${this.getItemClass(suggestion)}: ${suggestion.identifier}`;

  onSuggestionsFetchRequested = ({ value }) => {
    let suggestions = [];
    const { trie_search } = this.state;

    if (trie_search) {
      suggestions = this.getSuggestions(value);
    }
    this.setState({ suggestions });
  };

  getItemClassForDetailPage = item => {
    switch (item.itemClass) {
      case 'Asset':
        return 'asset';
      case 'Traveller':
        return 'work-order';
      default:
        return 'inventory';
    }
  };

  getItemClass = item => {
    switch (item.itemClass) {
      case 'Asset':
        return 'Asset';
      case 'Traveller':
        return 'Order';
      default:
        return 'Item';
    }
  };

  /* Shorten display values to StringMaxLength. Add ... at end if shortened */
  shortenName(name) {
    return name.length > StringMaxLength ? `${name.substring(0, StringMaxLength - 3)}...` : name;
  }

  /* How to render each suggestion in dropdown */
  renderSuggestion = suggestion => {
    const { value } = this.state;
    const parts = suggestion.customFields.searchData.parts.filter(
      part =>
        (part.name && part.name.toUpperCase().includes(value.toUpperCase())) ||
        (part.identifier && part.identifier.toUpperCase().includes(value.toUpperCase()))
    );
    const { searchData } = suggestion.customFields;
    const sensorProfile = searchData.sensorProfile || '-Not Provided-';
    const { status_flags } = searchData;
    const deadlineDate =
      searchData.deadlineDate &&
      searchData.readableFormatDate &&
      searchData.readableFormatDate.toUpperCase().includes(value.toUpperCase())
        ? searchData.deadlineDate
        : null;
    const detailsPageLink = this.getDetailsPageLink(suggestion);

    return (
      <a href={detailsPageLink}>
        <Card className={Style.search_suggestion_card}>
          <div className={Style.inside_suggestion_card}>
            <CardTitle>
              <Row className={Style.search_card_title}>
                <Col size="7" className={Style.suggestion_title}>
                  {`${this.getItemClass(suggestion)}: ${this.shortenName(suggestion.identifier)}`}
                </Col>
                <Col size="5" className={Style.search_card_flags_results}>
                  {status_flags.map((flag, index) => (
                    <font className={Style[`${flag.color}_text`]}>
                      {`${flag.displayText}${index < status_flags.length - 1 ? ',' : ''}`}{' '}
                    </font>
                  ))}
                </Col>
              </Row>
            </CardTitle>
            <CardBody className={Style.search_card_body}>
              <div>{`Tag: ${sensorProfile}`}</div>
              <div style={{ fontWeight: 'bolder' }}>
                {parts.length > 0 &&
                  `Contains part(s):
                ${parts.map(part =>
                  part.name && part.name.toUpperCase().includes(value.toUpperCase())
                    ? part.name
                    : part.identifier
                )}`}
              </div>
              {deadlineDate && (
                <div style={{ fontWeight: 'bolder' }}>
                  {`${deadlineDate.label}: ${getFormattedDate(deadlineDate.value, 'MMM Do YYYY')}`}
                </div>
              )}
            </CardBody>
          </div>
        </Card>
      </a>
    );
  };

  getDetailsPageLink = suggestion =>
    `/${this.getItemClassForDetailPage(suggestion)}/detail?itemId=${suggestion.id}`;

  // go to details page of first suggestion in searach results
  suggestionSelectedFn = (event, { suggestion }) => {
    window.location.href = this.getDetailsPageLink(suggestion);
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onFocus = event => {
    this.setState({ searchFocused: true });
  };

  onBlur = event => {
    this.setState({ searchFocused: false });
  };

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    if (this.state.searchFocused && query.trim().length > 0) {
      return (
        <div {...containerProps}>
          {children ? (
            <div className={Style.search_results_overlay}>{children}</div>
          ) : (
            <div className={Style.no_results_overlay}>No Matches Found</div>
          )}
          <div />
        </div>
      );
    }
  };

  render() {
    const { suggestions, value, placeholder } = this.state;

    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      tabIndex: -1
    };

    return (
      <div className={Style.search_container}>
        <Autosuggest
          suggestions={suggestions}
          highlightFirstSuggestion
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => this.setState({ suggestions: [] })}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          onSuggestionSelected={this.suggestionSelectedFn}
          inputProps={inputProps}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          theme={{
            container: Style.auto_suggest_container,
            input: Style.auto_suggest_input,
            suggestionsContainer: Style.auto_suggest_suggestion_container,
            suggestionHighlighted: Style.auto_suggest_highlight_background,
            suggestionsList: Style.auto_suggest_list_style
          }}
        />
      </div>
    );
  }
}
