import React, { Component } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

import './DepartmentsOrdersGraph.css';

export default class DepartmentsOrdersGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxColumns: 9,
      threshold: 5,
      departmentOrders: this.props.departmentOrders.slice(0, this.maxColumns),
      colors: ['#F62227', '#FFCF0F', '#00B200', '#E45A00'],
      width: window.innerWidth
    };
  }

  componentWillReceiveProps(nextProps) {
    const { departmentOrders, maxColumns } = this.state;
    // You don’t have to do this check first, but it can help prevent an unneeded render
    if (nextProps.departmentOrders.length !== departmentOrders.length) {
      this.setState({
        departmentOrders: nextProps.departmentOrders.slice(0, maxColumns)
      });
    }
  }

  render() {
    const { width, threshold, colors, departmentOrders } = this.state;
    const isMobile = width <= 768;

    const getResponsiveContainerAspect = () => {
      if (!isMobile) {
        return 4 / 2;
      }

      return 2.5 / 2;
    };
    return (
      <ResponsiveContainer aspect={getResponsiveContainerAspect()} width="100%">
        <BarChart
          data={departmentOrders}
          height={200}
          margin={{ top: 5, right: 10, bottom: 5, left: -10 }}
          isAnimationActive={false}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={departmentOrders.length > threshold ? 'simpleName' : 'departmentName'}
            name="departmentName"
          />
          <YAxis />
          <Tooltip
            labelFormatter={name => {
              return name;
            }}
          />
          <Legend
            payload={[
              { id: 'Critical', value: 'Critical', type: 'rect', color: colors[0] },
              { id: 'Warning', value: 'Warning', type: 'rect', color: colors[1] },
              { id: 'Healthy', value: 'Healthy', type: 'rect', color: colors[2] },
              {
                id: 'ExpeditedOrders',
                value: 'Expedited',
                type: 'rect',
                color: colors[3]
              }
            ]}
            align="center"
          />
          <Bar
            dataKey="numberOfNonExpeditedOrders"
            stackId="a"
            name="Non-Expedited Orders"
            isAnimationActive={false}
          >
            {departmentOrders.map((entry, index) => {
              if (entry.currentThresholdState.toLowerCase() === 'warning') {
                return <Cell fill={colors[1]} key={index} />;
              }
              if (entry.currentThresholdState.toLowerCase() === 'critical') {
                return <Cell fill={colors[0]} key={index} />;
              }

              return <Cell fill={colors[2]} key={index} />;
            })}
          </Bar>
          <Bar
            dataKey="numberOfExpeditedOrders"
            stackId="a"
            fill={colors[3]}
            name="Expedited Orders"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
