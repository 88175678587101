/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import ConfigurationService from '../../services/ConfigurationService';
import LoadingCircle from '../../components/loading/LoadingCircle';
import AssetOverViewComponent from '../../components/TrackPageComponents/Asset/asset-overview-component/AssetOverviewComponent';
import TrackPageComponent from '../../components/TrackPageComponents/track-page-component/TrackPageComponent';
import GroupbySideBarComponent from '../../components/TrackPageComponents/groupby-sidebar-component/GroupbySidebarComponent';
import { getSmallCustomerLogo, getFormattedDate, getImage } from '../../common/Utilities';
import TrackPageDataViewComponent from '../../components/TrackPageComponents/track-page-data-view-component/TrackPageDataViewComponent';
import GridCardContentDefault from '../../components/TrackPageComponents/Asset/grid-card-contents/grid-card-content-default/GridCardContentDefault';
import GridCardContentGroupby from '../../components/TrackPageComponents/Asset/grid-card-contents/grid-card-content-groupby/GridCardContentGroupby';
import GridCardComponent from '../../components/grid-card-component/GridCardComponent';
import Style from '../../components/TrackPageComponents/Order/css/TrackPageGridViewComponent.module.css';
import { useXemelgoClient } from '../../services/xemelgo-service';
import BreadcrumbsComponent from '../../components/breadcrumbs-component/BreadcrumbsComponent';
import './style.css';
import { useAppConfigProvider } from '../../services/soft-cache-service';
import { LocationFloorMapWithTooltip } from './features/location-floor-map-with-tooltip';
import { XemelgoService } from '../../services/XemelgoService';

const WarningThreshold = 0.5;
const CriticalThreshold = 0.8;

const AssetTrackPageFeature = ({ history }) => {
  const breadcrumbsDataListRef = useRef();

  const handleBreadCrumbsClick = (id) => {
    const { current } = breadcrumbsDataListRef;
    while (current.length && current[current.length - 1].id !== id) {
      current.pop();
    }
    setBreadcrumbsDataList(current);
  };
  const groupbyStructure = [
    {
      id: 'all-assets',
      display: 'All Assets',
      action: () => {
        handleTabClick('all-assets', overallData.tracksItem);
        setFocusedTab(null);
        setBreadcrumbsDataList([]);
        history.replace(`${history.location.pathname}`);
      }
    },
    {
      id: 'groupby',
      display: 'Group By',
      tabStructure: [
        {
          id: 'type',
          display: 'Type',
          action: () => {
            handleTabClick('type', overallData.types);
            setFocusedTab(null);
            setBreadcrumbsDataList([
              {
                id: 'type',
                value: 'Type',
                onClick: () => {
                  handleBreadCrumbsClick('type');
                  handleTabClick('type', overallData.types);
                  setFocusedTab(null);
                }
              }
            ]);
            history.replace(`${history.location.pathname}?group=type`);
          }
        },
        {
          id: 'location',
          display: 'Location',
          action: () => {
            handleTabClick('location', overallData.locations);
            setFocusedTab(null);
            setBreadcrumbsDataList([
              {
                id: 'location',
                value: 'Location',
                onClick: () => {
                  handleBreadCrumbsClick('location');
                  handleTabClick('location', overallData.locations);
                  setFocusedTab(null);
                }
              }
            ]);
            history.replace(`${history.location.pathname}?group=location`);
          }
        }
      ]
    }
  ];

  const [currentTab, setCurrentTab] = useState('all-assets');
  const [overviewData, setOverviewData] = useState({});
  const [overallData, setOverallData] = useState({});
  const [currentDataList, setCurrentDataList] = useState([]);
  const [logoData, setLogoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [client] = useState(useXemelgoClient());
  const [focusedTab, setFocusedTab] = useState('all-assets'); // This is used only when currentTab is null but you want the side bar to focused on specific id
  const [breadcrumbsDataList, setBreadcrumbsDataList] = useState([]);
  const [configProvider] = useState(useAppConfigProvider('assetTrackPage'));
  const [floorMapCategory, setFloorMapCategory] = useState(null);
  const [selectedLocationForFloorMap, setSelectedLocationForFloorMap] = useState(null);
  const [floorMapImageUrl, setFloorMapImageUrl] = useState(null);

  useEffect(() => {
    if (!configProvider) {
      return;
    }

    const category = configProvider.getValue('floorMapCategory', 'string', 'Plant');
    setFloorMapCategory(category);
  }, [configProvider]);

  useEffect(() => {
    if (!floorMapCategory) {
      return;
    }

    const locationClient = XemelgoService.getClient().getLocationClient();
    locationClient.getLocationsOfCategory(floorMapCategory).then((result) => {
      const location = result[0];
      if (location) {
        const imageUrl = location.getImagePath();
        setSelectedLocationForFloorMap(location);
        setFloorMapImageUrl(imageUrl);
      }
    });
  }, [floorMapCategory]);

  const handleTabClick = (tabId, dataList) => {
    setCurrentTab(tabId);
    setCurrentDataList(dataList);
  };

  const processQueryPayload = (payload, data) => {
    let tabId = null;
    let dataList = [];
    const newBreadcrumbsDataList = [];
    switch (payload.group) {
      case 'location':
        tabId = 'location';
        dataList = data.locations;
        newBreadcrumbsDataList.push({
          id: 'location',
          value: 'Location',
          onClick: () => {
            handleBreadCrumbsClick('location');
            handleTabClick('location', data.locations);
            setFocusedTab(null);
          }
        });
        break;
      case 'type':
        tabId = 'type';
        dataList = data.types;
        newBreadcrumbsDataList.push({
          id: 'type',
          value: 'Type',
          onClick: () => {
            handleBreadCrumbsClick('type');
            handleTabClick('type', data.types);
            setFocusedTab(null);
          }
        });
        break;
      default:
        tabId = null;
        dataList = data.tracksItem;
        break;
    }
    if (payload.id) {
      setFocusedTab(tabId);
      const result = dataList.find((each) => each.id === payload.id);
      if (result) {
        tabId = null;
        dataList = result.tracksItem;
        newBreadcrumbsDataList.push({ id: payload.id, value: result.name });
      } else {
        history.replace(`${history.location.pathname}?group=${payload.group}`);
      }
    }
    setBreadcrumbsDataList(newBreadcrumbsDataList);
    handleTabClick(tabId, dataList);
  };

  useEffect(() => {
    breadcrumbsDataListRef.current = breadcrumbsDataList;
  });

  useEffect(() => {
    const onLoad = async () => {
      const trackingConfig = await ConfigurationService.getTrackingConfiguration();
      const locationCategory =
        trackingConfig.possibleDetectorLocations &&
        trackingConfig.possibleDetectorLocations.length > 0
          ? trackingConfig.possibleDetectorLocations[0]
          : 'Department';
      const TrackPageClient = await client.getTrackPageClient();
      const data = await TrackPageClient.getAssetPageData(['location', 'type'], locationCategory);
      const logo = await getSmallCustomerLogo();

      const tempOverview = {
        numOfMissing: 0,
        numOfAsset: 0,
        numOfWeeklyMissing: 'N/A'
      };
      tempOverview.numOfAsset = data.tracksItem.length;
      data.tracksItem.forEach((each) => {
        if (each.isMissing) {
          tempOverview.numOfMissing += 1;
        }
      });

      setOverviewData(tempOverview);
      setLogoData(logo);
      setOverallData(data);

      const queryPaylod = queryString.parse(history.location.search);
      if (Object.entries(queryPaylod).length) {
        processQueryPayload(queryPaylod, data);
      } else {
        setCurrentDataList(data.tracksItem);
        setCurrentTab(null);
      }
      setLoading(false);
    };

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const getSortSchema = () => {
    switch (currentTab) {
      case 'type':
        return [
          {
            id: 'typename',
            value: 'Type Name',
            compareFunc: (a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
            },
            type: 'character',
            default: true
          },
          {
            id: 'lastupdated',
            value: 'Last Updated',
            compareFunc: (a, b, reverse) => {
              if (a.lastUpdatedTime === b.lastUpdatedTime) {
                return 0;
              }
              if (!a.lastUpdatedTime) {
                return reverse ? -1 : 1;
              }
              if (!b.lastUpdatedTime) {
                return reverse ? 1 : -1;
              }
              return a.lastUpdatedTime < b.lastUpdatedTime ? -1 : 1;
            },
            type: 'number'
          }
        ];
      case 'location':
        return [
          {
            id: 'locationname',
            value: 'Location Name',
            compareFunc: (a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
            },
            type: 'character',
            default: true
          },
          {
            id: 'lastupdated',
            value: 'Last Updated',
            compareFunc: (a, b, reverse) => {
              if (a.lastUpdatedTime === b.lastUpdatedTime) {
                return 0;
              }
              if (!a.lastUpdatedTime) {
                return reverse ? -1 : 1;
              }
              if (!b.lastUpdatedTime) {
                return reverse ? 1 : -1;
              }
              return a.lastUpdatedTime < b.lastUpdatedTime ? -1 : 1;
            },
            type: 'number'
          }
        ];
      default:
        return [
          {
            id: 'assetnumber',
            value: 'Asset Number',
            compareFunc: (a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
            },
            type: 'character'
          },
          {
            id: 'calibrationdate',
            value: 'Calibration Date',
            compareFunc: (a, b, reverse) => {
              if (a.expiry_date === b.expiry_date) {
                return 0;
              }
              if (!a.expiry_date) {
                return reverse ? -1 : 1;
              }
              if (!b.expiry_date) {
                return reverse ? 1 : -1;
              }
              return a.expiry_date < b.expiry_date ? -1 : 1;
            },
            type: 'number'
          },
          {
            id: 'creationdate',
            value: 'Creation Date',
            compareFunc: (a, b) => {
              return +(a.startTime > b.startTime) || -(a.startTime < b.startTime);
            },
            type: 'number'
          },
          {
            id: 'lastupdated',
            value: 'Last Updated Date',
            compareFunc: (a, b) => {
              return (
                +(a.lastUpdatedTime > b.lastUpdatedTime) || -(a.lastUpdatedTime < b.lastUpdatedTime)
              );
            },
            type: 'number'
          },
          {
            id: 'lastseenlocation',
            value: 'Last Seen Location',
            compareFunc: (a, b, reverse) => {
              if (a.lastKnownLocation.name === b.lastKnownLocation.namme) {
                return 0;
              }
              if (!a.lastKnownLocation.name) {
                return reverse ? -1 : 1;
              }
              if (!b.lastKnownLocation.name) {
                return reverse ? 1 : -1;
              }
              return a.lastKnownLocation.name < b.lastKnownLocation.name ? -1 : 1;
            },
            type: 'character'
          },
          {
            id: 'status',
            value: 'Status',
            default: true,
            compareFunc: (a, b) => {
              const statusLevelCalculator = (id) => {
                switch (id) {
                  case 'missing':
                    return 4;
                  case 'expired':
                    return 2;
                  case 'expiringSoon':
                    return 1;
                  default:
                    return 0;
                }
              };
              let aLevel = 0;
              let bLevel = 0;
              a.statusFlags.forEach((each) => {
                aLevel += statusLevelCalculator(each.id);
              });
              b.statusFlags.forEach((each) => {
                bLevel += statusLevelCalculator(each.id);
              });
              return aLevel < bLevel ? 1 : -1;
            }
          }
        ];
    }
  };

  const filterFunction = (input, each) => {
    let match = false;
    const lowerCaseInput = input.toLowerCase();
    const { name, sensorProfile, lastKnownLocation, statusFlags } = each;
    switch (currentTab) {
      case 'type':
      case 'location':
        if (name.toLowerCase().includes(lowerCaseInput)) {
          match = true;
        }
        break;
      default:
        if (
          name.toLowerCase().includes(lowerCaseInput) ||
          sensorProfile.vid.toLowerCase().includes(lowerCaseInput) ||
          (lastKnownLocation.name && lastKnownLocation.name.toLowerCase().includes(lowerCaseInput))
        ) {
          match = true;
        }
        statusFlags.forEach((eachflag) => {
          if (eachflag.displayText.toLowerCase().includes(lowerCaseInput)) {
            match = true;
          }
        });
        break;
    }
    return match;
  };

  // TODO: this function needs to be change when ListView get refactored
  const listViewFilterFunction = (input, each) => {
    let match = false;
    const lowerCaseInput = input.toLowerCase();
    // TODO: need to address this, what is the status flags and who provides the flags? When the statusFlags is null
    //  it breaks in the code below.
    const { name, vid, lastKnownLocation, statusFlags = [] } = each;
    switch (currentTab) {
      case 'type':
      case 'location':
        if (name.toLowerCase().includes(lowerCaseInput)) {
          match = true;
        }
        break;
      default:
        if (
          name.toLowerCase().includes(lowerCaseInput) ||
          vid.toLowerCase().includes(lowerCaseInput) ||
          (lastKnownLocation && lastKnownLocation.toLowerCase().includes(lowerCaseInput))
        ) {
          match = true;
        }
        statusFlags.forEach((eachflag) => {
          if (eachflag.displayText.toLowerCase().includes(input.toLowerCase())) {
            match = true;
          }
        });
        break;
    }
    return match;
  };

  const renderGridCardComponentDefault = (eachItemData, id, containerStyle) => {
    const imgName = `${eachItemData.name.slice(0, eachItemData.name.length - 4)}.png`;
    return (
      <GridCardComponent
        key={id}
        onClick={() => {
          history.push(`${history.location.pathname}/detail?itemId=${eachItemData.id}`);
        }}
        containerStyle={containerStyle}
        color={
          eachItemData.statusFlags && eachItemData.statusFlags.length
            ? eachItemData.statusFlags[0].color
            : '#00B200'
        }
        gridCardContent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <GridCardContentDefault
            assetNumber={eachItemData.name}
            image={getImage(imgName) || logoData}
            rfid={eachItemData.sensorProfile.vid}
            statusFlags={eachItemData.statusFlags}
            location={eachItemData.lastKnownLocation.name}
            expiryDate={eachItemData.expiry_date}
            lastUpdatedTime={eachItemData.lastUpdatedTime}
          />
        }
      />
    );
  };

  const renderGridCardComponentGroupby = (eachData, id, containerStyle) => {
    const { numOfMissing, numOfExpired, numOfExpiringSoon, tracksItem } = eachData;
    let healthColor = '#00B200';
    if ((numOfMissing + numOfExpiringSoon + numOfExpired) / tracksItem.length > CriticalThreshold) {
      healthColor = '#F62227';
    } else if (
      (numOfMissing + numOfExpiringSoon + numOfExpired) / tracksItem.length >
      WarningThreshold
    ) {
      healthColor = 'orange';
    }
    // const numOfMissing = eachData.tracksItem.filter(e => e.isMissing).length;
    return (
      <GridCardComponent
        onClick={() => {
          history.replace(`${history.location.pathname}${history.location.search}&id=${id}`);
          handleTabClick(null, eachData.tracksItem);
          breadcrumbsDataList.push({ id, value: eachData.name });
          setBreadcrumbsDataList(breadcrumbsDataList);
        }}
        key={id}
        color={healthColor}
        containerStyle={containerStyle}
        gridCardContent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <GridCardContentGroupby
            locationName={eachData.name}
            numOfTotal={eachData.tracksItem.length}
            numOfMissing={numOfMissing}
            image={logoData}
            lastUpdatedTime={eachData.lastUpdatedTime}
          />
        }
      />
    );
  };

  const renderGridCard = () => {
    switch (currentTab) {
      case 'location':
      case 'type':
        return renderGridCardComponentGroupby;
      default:
        return renderGridCardComponentDefault;
    }
  };

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <TrackPageComponent
      overviewComponent={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <AssetOverViewComponent
          leftComponent={
            floorMapImageUrl && (
              <LocationFloorMapWithTooltip
                imageUrl={floorMapImageUrl}
                location={selectedLocationForFloorMap}
                className="asset-floor-plan"
              />
            )
          }
          image={logoData}
          numOfAsset={overviewData.numOfAsset}
          numOfMissing={overviewData.numOfMissing}
          numOfWeeklyMissing={overviewData.numOfWeeklyMissing}
        />
      }
      buttonTitle="+ Add Asset"
      groupbyComponent={(
        <GroupbySideBarComponent
          tabStructure={groupbyStructure}
          focusedTab={currentTab || focusedTab}
        />
      )}
      breadcrumbsComponent={(
        <BreadcrumbsComponent
          dataList={breadcrumbsDataList}
          onHomeClick={() => {
            handleTabClick('all-assets', overallData.tracksItem);
            setFocusedTab(null);
            setBreadcrumbsDataList([]);
            history.replace(`${history.location.pathname}`);
          }}
        />
      )}
      dataViewComponent={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <TrackPageDataViewComponent
          dataList={currentDataList}
          sortSchema={getSortSchema()}
          filterFunc={filterFunction}
          listViewFilterFunc={listViewFilterFunction} // needs to be refactor
          gridCardComponent={renderGridCard()}
          listViewStructure={listViewHeaderStructureListControl(currentTab)} // needs to be refactor
          listViewDataList={listViewDataListControl(currentTab, currentDataList)} // needs to be refactor
          // needs to be refactor
          handleListViewClick={
            currentTab
              ? ({ currentTarget }) => {
                  const location = currentDataList.findIndex(
                    (each) => each.id === currentTarget.id
                  );
                  handleTabClick(null, currentDataList[location].tracksItem);
                  history.replace(
                    `${history.location.pathname}${history.location.search}&id=${currentTarget.id}`
                  );
                  breadcrumbsDataList.push({
                    id: currentTarget.id,
                    value: currentTarget.getAttribute('name')
                  });
                  setBreadcrumbsDataList(breadcrumbsDataList);
                }
              : ({ currentTarget }) => {
                  history.push(`${history.location.pathname}/detail?itemId=${currentTarget.id}`);
                }
          }
        />
      }
    />
  );
};

export default withRouter(AssetTrackPageFeature);

// TODO: the functions below need to be refactor!!!!!!!!!!!!!!!!!!!!!!!!

const listViewHeaderStructureListControl = (groupby) => {
  switch (groupby) {
    case 'location':
      return [
        { id: 'name', title: true, label: 'Location' },
        {
          id: 'status',
          label: 'Status',
          renderComponent: (status) => {
            return (
              <div
                className={Style.list_view_status}
                style={{ backgroundColor: status && status.color }}
              >
                {status ? status.displayText : '-'}
              </div>
            );
          }
        },
        { id: 'numOfAsset', label: 'Total No. of Assets' },
        { id: 'numOfMissing', label: 'Missing Assets' },
        {
          id: 'lastUpdatedTime',
          label: 'Last Updated',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'hh:mm A MMM D') : '-';
          }
        }
      ];
    case 'type':
      return [
        { id: 'name', title: true, label: 'Asset Type' },
        {
          id: 'status',
          label: 'Status',
          renderComponent: (status) => {
            return (
              <div
                className={Style.list_view_status}
                style={{ backgroundColor: status && status.color }}
              >
                {status ? status.displayText : '-'}
              </div>
            );
          }
        },
        { id: 'numOfAsset', label: 'Total No. of Assets' },
        { id: 'numOfMissing', label: 'Missing Assets' },
        {
          id: 'lastUpdatedTime',
          label: 'Last Updated',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'hh:mm A MMM D') : '-';
          }
        }
      ];
    default:
      return [
        { id: 'name', title: true, label: 'Asset #' },
        { id: 'vid', label: 'Tag #' },
        {
          id: 'lastKnownLocation',
          label: 'Current Location'
        },
        {
          id: 'statusFlags',
          label: 'Status',
          renderComponent: (statusList = []) => {
            statusList.sort().reverse();

            if (statusList.length) {
              return statusList.map((eachStatus) => {
                return (
                  <div
                    className={Style.list_view_status}
                    style={{
                      backgroundColor: eachStatus.color
                    }}
                    key={eachStatus.id}
                  >
                    {eachStatus.displayText}
                  </div>
                );
              });
            }
            return '-';
          }
        },
        {
          id: 'expiry_date',
          label: 'Expiration Date',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'MMM Do') : '-';
          }
        },
        {
          id: 'lastUpdatedTime',
          label: 'Last Updated',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'hh:mm A MMM D') : '-';
          }
        },
        {
          id: 'startTime',
          label: 'Created Date',
          renderComponent: (data) => {
            return data ? getFormattedDate(data, 'MM/DD/YYYY') : '-';
          }
        }
      ];
  }
};

const listViewDataListControl = (groupby, dataList) => {
  switch (groupby) {
    case 'location':
    case 'type':
      return dataList.map((each) => {
        const {
          tracksItem,
          id,
          name,
          lastUpdatedTime,
          numOfMissing,
          numOfExpired,
          numOfExpiringSoon
        } = each;
        const status = { displayText: 'Healthy', color: '#00B200' };
        if (
          (numOfMissing + numOfExpiringSoon + numOfExpired) / tracksItem.length >
          CriticalThreshold
        ) {
          status.displayText = 'Critical';
          status.color = 'red';
        } else if (
          (numOfMissing + numOfExpiringSoon + numOfExpired) / tracksItem.length >
          WarningThreshold
        ) {
          status.displayText = 'Warning';
          status.color = 'orange';
        }
        return {
          id,
          name,
          numOfAsset: tracksItem.length,
          lastUpdatedTime,
          status,
          numOfMissing
        };
      });
    default:
      return dataList.map((each) => {
        return {
          id: each.id,
          name: each.name,
          vid: each.sensorProfile.vid,
          lastKnownLocation: each.lastKnownLocation.name || '-',
          lastUpdatedTime: each.lastUpdatedTime,
          startTime: each.startTime,
          expiry_date: each.expiry_date,
          statusFlags: each.statusFlags
        };
      });
  }
};
