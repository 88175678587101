import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import AssetTrackPageFeature from '../../features/asset-track-page-feature/AssetTrackPageFeature';
import AssetDetailPage from '../../components/TrackPageComponents/Asset/AssetDetailPage';
import AddAsset from '../../pages/track/AddAsset';

const AssetApp = () => {
  return (
    <Fragment>
      <Route exact path="/asset" component={AssetTrackPageFeature} />
      <Route path="/asset/create" component={AddAsset} />
      <Route path="/asset/detail" component={AssetDetailPage} />
    </Fragment>
  );
};

export default AssetApp;
