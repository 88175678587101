import React from 'react';
import GridCardContentDefaultStyle from './GridCardContentGroupby.module.css';
import { getFormattedDate } from '../../../../../common/Utilities';

const GridCardContentGroupby = ({
  image,
  locationName,
  numOfMissing,
  numOfTotal,
  lastUpdatedTime
}) => {
  return (
    <div className={GridCardContentDefaultStyle.container}>
      <div className={GridCardContentDefaultStyle.top_section}>
        <img className={GridCardContentDefaultStyle.image} src={image} alt="failed to load img" />
        <div className={GridCardContentDefaultStyle.top_left_container}>
          <div className={GridCardContentDefaultStyle.top_title}>{locationName}</div>
          <div className={GridCardContentDefaultStyle.top_metric_container}>
            <div className={GridCardContentDefaultStyle.top_metric_group}>
              Total:
              <p className={GridCardContentDefaultStyle.top_metric_data}>{numOfTotal}</p>
            </div>
            <div className={GridCardContentDefaultStyle.top_metric_group}>
              Missing:
              <p className={GridCardContentDefaultStyle.top_metric_data}>{numOfMissing}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={GridCardContentDefaultStyle.bottom_section}>
        <div className={GridCardContentDefaultStyle.bottom_data_group}>
          Last Updated
          <p className={GridCardContentDefaultStyle.bottom_data_content}>
            {lastUpdatedTime ? getFormattedDate(lastUpdatedTime, 'hh:mm A - MMM D') : '-'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GridCardContentGroupby;
