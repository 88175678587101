import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'mdbreact';
import queryString from 'query-string';
import { XemelgoService } from '../../../services/XemelgoService';
import ConfigurationService from '../../../services/ConfigurationService';
import Overview from '../Overview';
import Style from './css/InventoryComponent.module.css';
import InventoryPageViewComponent from './InventoryPageViewComponent';
import SideFilter from '../SideFilter';
import Chart from './Chart';
import Metrics from '../Metrics';
import LoadingCircle from '../../loading/LoadingCircle';

const healthColor = {
  Healthy: '#00B200',
  Warning: '#FFCF0F',
  Critical: '#F62227'
};

class InventoryComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.tabStructureDefault = [
      {
        name: 'Group By',
        tabStructure: [
          {
            name: 'Item Type',
            action: () => {
              const { overallData } = this.state;
              window.history.pushState('', '', '?groupby=type');
              this.calculateMetricsData(overallData, 'Location');
              this.setState({
                currentDataList: overallData.itemType,
                navigationPath: [{ name: 'Item Types', propertyName: 'itemType', groupby: 'type' }],
                selectedLocation: '',
                selectedType: '',
                groupby: 'type'
              });
            }
          },
          {
            name: 'Location',
            action: () => {
              const { overallData } = this.state;
              window.history.pushState('', '', '?groupby=locations');
              this.calculateMetricsData(overallData, 'Location');
              this.setState({
                currentDataList: overallData.locations,
                navigationPath: [
                  { name: 'Locations', propertyName: 'locations', groupby: 'locations' }
                ],
                selectedLocation: '',
                selectedType: '',
                groupby: 'locations'
              });
            }
          }
        ]
      }
    ];

    const values = queryString.parse(window.location.search);
    const { selectedLocation, selectedType } = values;
    let { groupby } = values;
    let defaultTabId = 'Item Type';
    if (groupby === 'locations' || selectedLocation) {
      defaultTabId = 'Location';
    }
    // 4 Cases
    // 1.) Groupby Exists, then use existing groupby
    // 2.) Selected Location: then use type
    // 3.) Selected Type: no groupby
    // 4.) No Query: default to type
    if (!groupby) {
      if (selectedLocation || (!selectedLocation && !selectedType)) {
        groupby = 'type';
      }
    }
    this.state = {
      overallData: {},
      currentDataList: [],
      groupby: 'type',
      displayBack: false,
      filterString: '',
      defaultTabId,
      tabStructure: this.tabStructureDefault,
      topMetricsDataList: {},
      bottomMetricsDataList: {},
      locationDataList: [],
      chartDataList: [],
      navigationPath: [{ name: 'Item Types', propertyName: 'itemType', groupby: 'type' }],
      loading: true,
      selectedLocation,
      selectedType
    };
  }

  async componentDidMount() {
    const TrackPageClient = XemelgoService.getClient().getTrackPageClient();
    // Todo: get customer configuration?
    // Hard coded to be stock room for now as we're only testing with stock room (I think)
    const trackingConfig = await ConfigurationService.getTrackingConfiguration();
    const locationCategory =
      trackingConfig.possibleDetectorLocations &&
      trackingConfig.possibleDetectorLocations.length > 0
        ? trackingConfig.possibleDetectorLocations[1]
        : 'department';
    const overallData = await TrackPageClient.getInventoryPageData(['location'], locationCategory);
    this.calculateMetricsData(overallData, 'location');
    this.setState({
      overallData,
      currentDataList: overallData.itemType || [],
      resetItemsFn: TrackPageClient.resetInactiveTrackingSessions,
      loading: false
    });

    window.onpopstate = () => {
      const values = queryString.parse(window.location.search);
      const { groupby, selectedType, selectedLocation } = values;
      this.setState({ groupby, selectedType, selectedLocation });
    };
  }

  buildChartData = (overallData) => {
    let overallLowStock = 0;
    let overallOutOfStock = 0;
    let overallHealthy = 0;

    overallData.itemType.forEach((itemType) => {
      const physcialCount = itemType.stock - itemType.incoming;
      if (physcialCount === 0) {
        overallOutOfStock++;
      } else if (physcialCount <= itemType.threshold) {
        overallLowStock++;
      } else {
        overallHealthy++;
      }
    });

    let health = 'Healthy';
    if (overallOutOfStock > 0) {
      health = 'Critical';
    } else if (overallLowStock > 0) {
      health = 'Warning';
    }

    const chartDataList = [
      ['Status', 'Items'],
      ['Out of Stock', overallOutOfStock],
      ['Low Stock', overallLowStock],
      ['Good', overallHealthy]
    ];
    this.setState({
      chartDataList
    });

    return health;
  };

  calculateMetricsData = (overallData, locationOrPlant) => {
    const health = this.buildChartData(overallData);
    let totalUnits = 0;
    const totalTypes = overallData.itemType.length;

    overallData.itemType.forEach((type) => {
      totalUnits += type.stock;
    });

    const topMetricsDataList = {
      name: 'Overview',
      dataList: [
        {
          name: `${locationOrPlant} Health`,
          value: health,
          color: healthColor[health],
          key: 'topMetricsDataList row1'
        },
        {
          name: 'Total # of Units',
          value: totalUnits,
          key: 'topMetricsDataList row2'
        },
        {
          name: 'Total # of Items',
          value: totalTypes,
          key: 'topMetricsDataList row3'
        }
      ]
    };
    const bottomMetricsDataList = {
      name: 'Weekly Metrics',
      dataList: [
        {
          name: 'Avg. Consumption Rate',
          // value: `${(overallData.consumedItems.length / 7).toFixed(2)} / Day`,
          value: `13 / Day`,
          key: 'bottomMetricsDataList row1'
        },
        {
          name: 'Units Added',
          value: overallData.recentlyAdded,
          key: 'bottomMetricsDataList row2'
        },
        {
          name: 'Units Consumed',
          // value: overallData.consumedItems.length,
          value: 46,
          key: 'bottomMetricsDataList row3'
        }
      ]
    };

    this.setState({ topMetricsDataList, bottomMetricsDataList });
  };

  overviewLeftComponent = () => {
    const { chartDataList } = this.state;
    const plant = 'plant';
    const location = null;
    return (
      <div className="chart">
        <Chart
          plant={plant}
          location={location}
          data={
            chartDataList || [
              ['', ''],
              ['', '']
            ]
          }
        />
      </div>
    );
  };

  overviewRightComponent = () => {
    const { topMetricsDataList, bottomMetricsDataList } = this.state;
    return (
      <Fragment>
        <Metrics title={topMetricsDataList.name} dataList={topMetricsDataList.dataList} />
        <Metrics title={bottomMetricsDataList.name} dataList={bottomMetricsDataList.dataList} />
      </Fragment>
    );
  };

  handleClickLocation = ({ currentTarget }) => {
    // this.calculateMetricsData()[location].tracksItem, 'Location');
    // sud todo: what happens with multiple location hierarchy?

    const { overallData } = this.state;
    // location should be >= 0
    const location = overallData.locations.findIndex((each) => each.id === currentTarget.id);
    // update Overview metrics
    this.calculateMetricsData(overallData.locations[location], 'Location');
    // sud todo: what happens with multiple location hierarchy?

    window.history.pushState('', '', `?selectedLocation=${currentTarget.id}`);
    this.setState({
      filterString: '',
      selectedLocation: currentTarget.id
    });
  };

  handleClickType = ({ currentTarget }) => {
    const values = queryString.parse(window.location.search);
    const { selectedLocation } = values;

    let queryParams = `?selectedType=${currentTarget.id}`;
    if (selectedLocation) {
      queryParams += `&selectedLocation=${selectedLocation}`;
    }

    window.history.pushState('', '', queryParams);
    this.setState({
      filterString: '',
      selectedType: currentTarget.id
    });
  };

  renderBreadcrumbNavigation = () => {
    const navigationPath = this.getNavigationPath();
    const buttons = [];
    if (navigationPath.length > 0) {
      // the last item should be unclickable
      buttons.push(
        <div
          role="button"
          className={Style.navigation_button_end}
          tabIndex={-1}
          key={navigationPath[navigationPath.length - 1].name}
        >
          {`${navigationPath[navigationPath.length - 1].name}`}
        </div>
      );
      for (let i = navigationPath.length - 2; i >= 0; i--) {
        buttons.unshift(
          <div
            role="button"
            className={Style.navigation_button}
            onClick={() => this.back(navigationPath[i])}
            onKeyDown={() => this.back(navigationPath[i])}
            key={i}
            tabIndex={-1}
          >
            {`${navigationPath[i].name} >`}
          </div>
        );
      }
    }
    return buttons;
  };

  getNavigationPath = () => {
    const { overallData } = this.state;
    const path = [];
    const values = queryString.parse(window.location.search);
    const { groupby, selectedLocation, selectedType } = values;
    if (groupby === 'locations' || selectedLocation) {
      path.push({ name: 'Location', type: 'groupbyLocation' });
    } else if (!groupby || groupby === 'type' || selectedType) {
      path.push({ name: 'Item Type', type: 'groupbyType' });
    }

    let currentLocation;
    if (overallData.locations) {
      if (selectedLocation) {
        currentLocation = overallData.locations.find(
          (location) => location.id === selectedLocation
        );
        path.push({ name: currentLocation.name, type: 'selectedLocation' });
      }
    }

    if (overallData.itemType) {
      if (selectedType) {
        let currentType;
        if (selectedLocation) {
          currentType = currentLocation.itemType.find((type) => type.id === selectedType);
        } else {
          currentType = overallData.itemType.find((type) => type.id === selectedType);
        }
        path.push({ name: currentType.identifier, type: 'selectedType' });
      }
    }
    return path;
  };

  back = (path) => {
    // as of now, selectedType is the last of the hierarchy
    switch (path.type) {
      case 'selectedLocation':
        const values = queryString.parse(window.location.search);
        const { selectedLocation } = values;
        window.history.pushState('', '', `?selectedLocation=${selectedLocation}`);
        this.setState({ selectedType: '' });
        break;
      case 'selectedType':
        break;
      case 'groupbyType':
        window.history.pushState('', '', '?groupby=type');
        this.setState({ selectedLocation: '', selectedType: '' });
        break;
      case 'groupbyLocation':
        this.calculateMetricsData(this.state.overallData, 'location');
        window.history.pushState('', '', '?groupby=locations');
        this.setState({ selectedLocation: '', selectedType: '' });
        break;
    }
  };

  filterHandler = (event) => {
    this.setState({ filterString: event.target.value });
  };

  resetItems = async () => {
    const confirmResult = window.confirm('Are you sure you want to reset all items?');

    if (confirmResult) {
      const TrackPageClient = XemelgoService.getClient().getTrackPageClient();
      const result = await TrackPageClient.resetInactiveTrackingSessions();

      if (result.errorItemIds.length > 0 || result.errorTrackingSessionIds.length > 0) {
        window.alert('Error resetting items!');
      } else {
        window.alert('Success resetting items!');
      }
      window.location.reload();
    }
  };

  filterDataList = () => {
    const { overallData } = this.state;
    const values = queryString.parse(window.location.search);
    const { groupby, selectedLocation, selectedType } = values;
    if (selectedLocation) {
      if (overallData.locations) {
        const currentLocation = overallData.locations.find(
          (location) => location.id === selectedLocation
        );
        if (currentLocation) {
          if (selectedType) {
            const currentType = currentLocation.itemType.find((type) => type.id === selectedType);
            if (currentType) {
              return currentType.tracksItem;
            }
            window.history.replaceState('', '', `?selectedLocation=${selectedLocation}`);
            return currentLocation.itemType;
          }
          return currentLocation.itemType;
        }
        window.history.replaceState('', '', `?groupby=locations`);
        return overallData.locations;
      }
    } else if (selectedType) {
      // only check if the data is loaded in. Otherwise, do nothing
      if (overallData.itemType) {
        const currentType = overallData.itemType.find((type) => {
          return type.id === selectedType;
        });
        if (currentType) {
          return currentType.tracksItem;
        }
        window.history.replaceState('', '', '?groupby=type');
        return overallData.itemType;
      }
    } else {
      if (!groupby || groupby === 'type') {
        return overallData.itemType;
      }
      if (groupby === 'locations') {
        return overallData.locations;
      }
    }
  };

  render() {
    const { tabStructure, defaultTabId, filterString, loading } = this.state;
    const currentDataList = this.filterDataList();
    const values = queryString.parse(window.location.search);
    const { selectedType, selectedLocation } = values;
    let { groupby } = values;
    if (selectedType) {
      groupby = null;
    } else if (selectedLocation || !groupby) {
      groupby = 'type';
    }

    if (loading) {
      return <LoadingCircle />;
    }

    return (
      <Card className={Style.content_holder}>
        <CardBody className={Style.override_padding}>
          <div className={Style.top_component}>
            <Overview
              leftComponent={this.overviewLeftComponent()}
              rightComponent={this.overviewRightComponent()}
            />
          </div>
          <div className={Style.navigation_path}>{this.renderBreadcrumbNavigation()}</div>
          <div className={Style.bottom_component}>
            <div className={Style.side_bar}>
              <Link className="btn default-button add" to="/inventory/create">
                + Add Items
              </Link>
              <SideFilter tabStructure={tabStructure} defaultTabId={defaultTabId} />
            </div>
            <div className={Style.data_view}>
              {currentDataList && (
                <InventoryPageViewComponent
                  handleClickLocation={this.handleClickLocation}
                  handleClickType={this.handleClickType}
                  groupby={groupby}
                  dataList={currentDataList}
                  filterString={filterString}
                  filterHandler={this.filterHandler}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default InventoryComponent;
