import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import uuid from 'uuid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HomeIcon from '@material-ui/icons/Home';
import BreadcrumbsComponentStyle from './BreadcrumbsComponent.module.css';

const renderBreadcrumbsItem = dataList => {
  return dataList.map((each, index) => {
    return (
      <button
        className={`${BreadcrumbsComponentStyle.breadcrumbs_item} ${index === dataList.length - 1 &&
          BreadcrumbsComponentStyle.breadcrumbs_item_last}`}
        type="button"
        id={each.id}
        key={uuid.v4()}
        disabled={!each.onClick || index === dataList.length - 1}
        onClick={each.onClick}
      >
        {each.value}
      </button>
    );
  });
};

const BreadcrumbsComponent = ({
  containerStyle,
  componentStyle,
  componentList,
  dataList,
  onHomeClick
}) => {
  const handleClick = event => {
    if (dataList && dataList.length) {
      onHomeClick(event);
    }
  };
  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon classes={{ root: BreadcrumbsComponentStyle.breadcrumbs_item_last }} />
      }
      classes={{
        root: containerStyle,
        ol: componentStyle
      }}
    >
      <HomeIcon
        classes={{
          root:
            !onHomeClick || !dataList || !dataList.length
              ? BreadcrumbsComponentStyle.breadcrumbs_item_last
              : BreadcrumbsComponentStyle.icon_clickable
        }}
        onClick={handleClick}
      />
      {Array.isArray(componentList) && componentList.length
        ? componentList.map(each => each)
        : Array.isArray(dataList) && renderBreadcrumbsItem(dataList)}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
BreadcrumbsComponent.defaultProps = {
  containerStyle: BreadcrumbsComponentStyle.root,
  componentStyle: BreadcrumbsComponentStyle.ol,
  componentList: [],
  dataList: [],
  onHomeClick: () => {}
};

BreadcrumbsComponent.propTypes = {
  containerStyle: PropTypes.string,
  componentStyle: PropTypes.string,
  componentList: PropTypes.arrayOf(PropTypes.object),
  dataList: PropTypes.arrayOf(PropTypes.object),
  onHomeClick: PropTypes.func
};
