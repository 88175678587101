import { AbstractApi } from '../abstract-api';
import { resourceManager } from './resource-managers/stock-item-resource-manager';

// eslint-disable-next-line import/prefer-default-export
class StockItems extends AbstractApi {
  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  canMock(payload, headers, method, apiSegments) {
    return method === 'get';
  }

  // eslint-disable-next-line class-methods-use-this
  execute(payload, headers, method, apiSegments) {
    if (method === 'get') {
      if (apiSegments.length === 1) {
        return resourceManager.list();
      }
      return resourceManager.get(apiSegments[1]);
    }

    return null;
  }
}

const api = new StockItems();
// eslint-disable-next-line import/prefer-default-export
export { api };
