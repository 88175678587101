import React, { Component } from 'react';
import { Card, CardBody } from 'mdbreact';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';
import AddObjectForm from './AddObjectForm';
import PartCrudTable from './PartCrudTable';
import LoadingCircle from '../../components/loading/LoadingCircle';
import ConfigurationService from '../../services/ConfigurationService';
import { XemelgoService } from '../../services/XemelgoService';
import { inputValidation } from '../../components/Forms/Validation';

import MoreIcon from '../../img/More.png';

import AddItemStyle from './css/AddItem.module.css';

class AddOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBanner: false,
      loading: false,
      errorMessage: '',
      orderInfo: undefined,
      partInfo: [],
      partConstraint: {},
      reuseSensorProfile: {}
    };
  }

  componentDidMount = async () => {
    const itemConfig = await ConfigurationService.getItemConfiguration();
    const trackingFeature = await ConfigurationService.getTrackingFeature();

    const { reuseSensorProfile = {} } = trackingFeature;
    const { itemRelationshipConstraints, trackedItemTypes } = itemConfig;
    let part;
    trackedItemTypes.forEach((eachType) => {
      const { contains } = itemRelationshipConstraints[eachType];
      part = contains.part;
    });
    this.setState({
      partConstraint: {
        quantity: part.quantity,
        delimiter: part.delimiter
      },
      reuseSensorProfile
    });
  };

  displayBanner = () => {
    const { errorMessage } = this.state;
    if (!errorMessage) {
      return (
        <Alert variant="success" onClose={() => this.setState({ showBanner: false })} dismissible>
          <h4>Success!</h4>
          <p>Work Order has been created</p>
        </Alert>
      );
    }
    return (
      <Alert variant="danger" onClose={() => this.setState({ showBanner: false })} dismissible>
        <h4>Error </h4>
        <p>{`Work Order could not be created: ${errorMessage}`}</p>
      </Alert>
    );
  };

  changeHandler = (controller, value) => {
    this.setState({
      [controller]: value
    });
  };

  // *** Order Form Handlers *** //
  submitOrderHandler = async () => {
    const xemelgoClient = XemelgoService.getClient();
    const workOrderClient = xemelgoClient.getWorkOrderClient();
    const { orderInfo, partInfo, reuseSensorProfile } = this.state;
    const updatedFormControls = _.cloneDeep(orderInfo);
    const validationResult = inputValidation(updatedFormControls);
    this.setState({
      orderInfo: updatedFormControls
    });

    const createAndAssociate = [];
    const associate = [];

    partInfo.forEach((partEntry) => {
      if (partEntry.new) {
        createAndAssociate.push({
          partNumber: partEntry.number,
          quantity: partEntry.qty * 1,
          name: partEntry.name
        });
      } else {
        associate.push({
          id: partEntry.id,
          quantity: partEntry.qty * 1
        });
      }
    });

    const partsToAssociate = {
      createAndAssociate,
      associate
    };

    const workOrderPayload = {
      identifier: orderInfo.name.value,
      sensorProfileVid: orderInfo.rfid.value,
      startTrackingSession: true,
      dueDate: orderInfo.date.value === null ? undefined : new Date(orderInfo.date.value).getTime(),
      state: 'Active',
      statusFlags: orderInfo.status.value,
      partsToAssociate
    };

    if (!validationResult.includes(false)) {
      this.setState({ loading: true, showBanner: false });
      try {
        // await workOrderClient.createWorkOrder(
        //   reuseSensorProfile.allow,
        //   reuseSensorProfile.autoDisassoicationOnReuse,
        //   workOrderPayload.identifier,
        //   workOrderPayload.sensorProfileVid,
        //   workOrderPayload.startTrackingSession,
        //   workOrderPayload.dueDate,
        //   workOrderPayload.state,
        //   workOrderPayload.statusFlags,
        //   workOrderPayload.partsToAssociate
        // );
        this.setState({
          loading: false,
          showBanner: true,
          errorMessage: false,
          orderInfo: undefined,
          partInfo: []
        });
      } catch (err) {
        this.setState({
          loading: false,
          showBanner: true,
          errorMessage: err.message.toString()
        });
        console.log(err);
      }
    }
  };

  render() {
    const { showBanner, orderInfo, partInfo, partConstraint, loading } = this.state;
    const { history } = this.props;

    return (
      <div className={AddItemStyle.add_item_container}>
        {showBanner && this.displayBanner()}
        {loading ? (
          <LoadingCircle />
        ) : (
          <Card className={AddItemStyle.add_item_outer_card_styling}>
            <div className={AddItemStyle.add_item_title}>
              <div className={AddItemStyle.add_item_title_content}> New Order </div>
            </div>
            <CardBody>
              <div
                onClick={() => {
                  history.goBack();
                }}
                className={AddItemStyle.add_item_cancel_link}
                tabIndex={-1}
              >
                <img src={MoreIcon} alt="more" className={AddItemStyle.add_item_cancel_text} />
                Cancel
              </div>
              <div className={AddItemStyle.add_item_form_container}>
                <div>
                  <AddObjectForm
                    info={orderInfo}
                    changeHandler={(returnData) => this.changeHandler('orderInfo', returnData)}
                  />
                </div>
                <div className={AddItemStyle.add_part_table_container}>
                  <PartCrudTable
                    partConstraint={partConstraint}
                    partInfo={partInfo}
                    changeHandler={(returnData) => this.changeHandler('partInfo', returnData)}
                  />
                </div>
              </div>
              <div className={AddItemStyle.form_submit_button_container}>
                <button
                  type="submit"
                  onClick={this.submitOrderHandler}
                  className="request-submit-button"
                >
                  Create Order
                </button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default withRouter(AddOrder);
