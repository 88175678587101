import React, { Component, Fragment } from 'react';
import { CardBody } from 'mdbreact';
import Loading from 'components/loading/LoadingWord';
import DataTableComponent from 'components/DataTableComponent/DataTableComponent';
import greendot from 'img/green_circle.png';
import yellowdot from 'img/yellow_circle.png';
import reddot from 'img/red_circle.png';
import greydot from 'img/grey_circle.png';
import { getFormattedDate, dateComparatorSort, getMockAlerts } from '../../common/Utilities';

const updatePayload = {
  op: 'replace',
  path: '/alert_status',
  value: 'READ'
};

export default class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      gridOptions: {
        defaultColDef: {
          comparator(a, b) {
            if (typeof a === 'string') {
              return a.localeCompare(b);
            }
            return a - b;
          }
        },
        suppressPropertyNamesCheck: true,
        localeText: { noRowsToShow: 'No Notifications To Show.' },
        getRowHeight(params) {
          return 38 * (Math.floor(params.data.alert_message.length / 35) + 1);
        }
      }
    };
  }

  async getLatestAlerts(numberOfAlerts) {
    const latestAlerts = [];

    // latestAlerts = await RestService.get('/alerts');
    return latestAlerts.slice(0, numberOfAlerts);
  }

  async getAllAlerts() {
    let allAlerts = [];

    allAlerts = getMockAlerts();
    return allAlerts;
  }

  updateReadStatus(alertId) {
    try {
      // RestService.patch(`/alerts/${alertId}`, updatePayload);
      return;
    } catch (e) {
      console.error('Error!', e);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.miniVersion && newProps.dropdownToggle) {
      this.onLoad(newProps.miniVersion, newProps.dropdownToggle);
    }
  }

  componentDidMount() {
    this.onLoad();
  }

  onLoad(miniVersion, dropdownToggle) {
    let headers = [];
    let gridOptions = {};
    let unreadAlertIds = [];

    // if rendering the mini version (notification bell icon) and the dropdown is toggled (notification icon clicked)
    if (miniVersion && dropdownToggle) {
      this.getAllAlerts().then((data) => {
        this.setState({
          data,
          loading: false
        });

        // get alert_object_id of all unread alerts
        unreadAlertIds = data
          .filter((object) => {
            return object.alert_user_status === 'UNREAD';
          })
          .map((unreadAlert) => {
            return unreadAlert.alert_object_id;
          });

        // update unread alerts' alert_user_status to "READ"
        if (unreadAlertIds.length > 0) {
          unreadAlertIds.forEach((alertId) => {
            this.updateReadStatus(alertId);
          });
        }
      });

      gridOptions = this.state.gridOptions;
      gridOptions.headerHeight = 0; // hide headers
      gridOptions.localeText = {
        noRowsToShow: 'You have no new notifications.'
      };
      gridOptions.suppressCellSelection = true; // disable clicking of the cells
      gridOptions.getRowStyle = function(params) {
        if (params.data.alert_user_status === 'UNREAD') {
          return {
            disabled: true,
            'font-weight': 'normal',
            'background-color': 'rgb(242, 247, 255, 0.5)'
          };
        }
        return { disabled: true };
      };

      this.setState({ gridOptions });

      headers = [
        {
          displayName: 'Alert Name',
          id: 'alert_name',
          autoHeight: false
        },
        {
          displayName: 'Message',
          id: 'alert_message',
          autoHeight: true,
          cellStyle: {
            textAlign: 'left',
            'white-space': 'normal',
            'line-height': '1.6',
            'padding-top': '10px'
          }
        },
        {
          displayName: 'Sent at',
          id: 'alert_trigger_time',
          sort: 'asc',
          autoHeight: true,
          cellStyle: {
            'text-align': 'right',
            'white-space': 'normal',
            'line-height': '1.6',
            'padding-top': '10px',
            'padding-right': '2em'
          },
          cellRenderer: this.displayDate,
          comparator: dateComparatorSort
        }
      ];
    } else if (!this.props.miniVersion && !this.props.dropdownToggle) {
      // Notifications tab on Alerts page

      this.getAllAlerts().then((data) => {
        this.setState({ data, loading: false });
      });

      headers = [
        {
          displayName: 'Alert Name',
          id: 'alert_name',
          autoHeight: false
        },
        {
          displayName: 'Severity',
          id: 'alert_type',
          autoHeight: false,
          cellRenderer: this.severityRenderer
        },
        {
          displayName: 'Message',
          id: 'alert_message',
          autoHeight: true,
          cellStyle: {
            'white-space': 'normal',
            'line-height': '1.6',
            'padding-top': '10px'
          }
        },
        {
          displayName: 'Sent at',
          id: 'alert_trigger_time',
          sort: 'asc',
          autoHeight: true,
          cellStyle: {
            'white-space': 'normal',
            'line-height': '1.6',
            'padding-top': '10px'
          },
          cellRenderer: this.displayDate,
          comparator: dateComparatorSort
        }
      ];
    } // end else (full version)

    const sort1 = [];
    const columnDefs1 = headers.map((header) => {
      if (header.sort) {
        sort1.push({
          colId: header.id,
          sort: header.sort
        });
      }
      return {
        headerName: header.displayName,
        field: header.id,
        filter: 'agTextColumnFilter',
        autoHeight: header.autoHeight,
        cellStyle: header.cellStyle,
        cellRenderer: header.cellRenderer,
        comparator: header.comparator,
        filterParams: {
          clearButton: false
        }
      };
    });

    this.setState({
      columnDefs: [...columnDefs1],
      sort: sort1
    });
  }

  severityRenderer = function(params) {
    let cellContent = '';
    let imgSrc = null;

    switch (params.value) {
      case 'Info':
        imgSrc = greendot;
        break;
      case 'Warning':
        imgSrc = yellowdot;
        break;
      case 'Critical':
        imgSrc = reddot;
        break;
      default:
        imgSrc = greydot;
        break;
    }

    cellContent += `<img height=15px width=15px src=${imgSrc} alt="img">`;

    return cellContent;
  };

  displayDate = function(params) {
    const time = getFormattedDate(params.value, 'hh:mm A');
    const date = getFormattedDate(params.value, 'ddd, MMM DD');
    const alert_date = `${date} at \t ${time}`;

    return alert_date;
  };

  render() {
    let customClassName;
    let customlink = {
      displayText: 'Notification Settings',
      link: 'alerts?activetab=2'
    };
    if (this.props.miniVersion) customlink = null;

    customClassName = 'data-table-body-long';
    return (
      <Fragment>
        <CardBody id="main-card-body">
          <div className={this.props.miniVersion ? 'mini-title' : 'title'}>
            {this.props.miniVersion ? <div>Notifications</div> : <h2>Notifications</h2>}
          </div>

          <div className={this.props.miniVersion ? 'page-data-table-short' : 'page-data-table'}>
            {this.state.loading ? (
              <Loading />
            ) : (
              <DataTableComponent
                tableName=""
                customlink={customlink}
                columnDefs={this.state.columnDefs}
                rowData={this.state.data}
                sort={this.state.sort}
                gridOptions={this.state.gridOptions}
                customClassName={customClassName}
                searchBar={!this.props.miniVersion}
              />
            )}
          </div>
        </CardBody>
      </Fragment>
    );
  }
}

export async function getNumberOfUnreadAlerts() {
  const allAlerts = [];

  // allAlerts = await RestService.get('/alerts');
  const unreadAlerts = allAlerts.filter((alert) => {
    return alert.alert_user_status === 'UNREAD';
  });

  return unreadAlerts.length;
}
