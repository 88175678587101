import React from 'react';
import queryString from 'query-string';
import { Modal } from 'react-bootstrap';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextareaAutosize from 'react-textarea-autosize';
import { DropdownItem } from 'mdbreact';
import { XemelgoService } from '../../../services/XemelgoService';
import ConfigurationService from '../../../services/ConfigurationService';
import LoadingCircle from '../../loading/LoadingCircle';
import Style from './css/AssetDetailPage.module.css';
import DetailCard from '../DetailCard';
import DataTable from '../DataTable';
import {
  getFormattedDate,
  msToTime,
  getNumberOfDaysToDate,
  getImage
} from '../../../common/Utilities';
import { FloorPlan } from '../../floor-plan';
import { GpsPinHead } from '../../interactable-pin/gps-pin-head';

const floorPlanImageUrl = 'https://xemelgo-logo.s3-us-west-2.amazonaws.com/Alstom_Facility.png';

export default class AssetDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titleStructure: {},
      contentStructureList: [],
      assetState: {},
      route: [],
      locations: [],
      markAsFoundOptions: [],
      routeTitleList: [],
      itemId: '',
      addLocation: false,
      showMap: false,
      locationIdToAdd: '',
      commentBoxText: '',
      loading: true
    };

    this.renderDeleteAssetModal = this.renderDeleteAssetModal.bind(this);
  }

  componentDidMount() {
    const values = queryString.parse(window.location.search);
    const { itemId } = values;
    this.onLoad(itemId);
  }

  onLoad = async (itemId) => {
    const AssetClient = XemelgoService.getClient().getAssetClient();
    const { possibleDetectorLocations } = await ConfigurationService.getCustomerConfigInfo();
    const trackingLocation = possibleDetectorLocations.length
      ? possibleDetectorLocations[0]
      : 'Department';

    const data = await AssetClient.getAssetDetailsById(itemId, trackingLocation);

    const { assetState, route, locations } = data;

    const titleStructure = {
      key: 'identifier',
      name: 'Asset Number',
      value: assetState.identifier,
      editable: true
    };
    const contentStructureList1 = [
      {
        key: 'sensorProfileVid',
        name: 'Tag#',
        value: assetState.sensorProfileVid
      },
      {
        key: 'expiryDate',
        name: 'Calibration Date',
        value: assetState.expiryDate,
        editable: true,
        type: 'date',
        format: 'MM/DD/YYYY'
      }
    ];

    if (assetState.expiryDate) {
      const daysToExpiry = getNumberOfDaysToDate(assetState.expiryDate);

      contentStructureList1.push({
        key: 'countdownToExpiry',
        name: daysToExpiry > 0 ? 'Calibration Due In' : 'Calibration Expired',
        value:
          daysToExpiry > 0
            ? `${daysToExpiry} day(s)`
            : daysToExpiry === 0
            ? 'Today'
            : `${Math.abs(daysToExpiry)} day(s) ago`,
        editable: false,
        color:
          daysToExpiry > 0 && daysToExpiry <= 7
            ? 'orange_text'
            : daysToExpiry <= 0
            ? 'red_text'
            : null
      });
    }

    const contentStructureList2 = [
      {
        key: 'lastUpdatedLocation',
        name: 'Last Seen At',
        value: assetState.currentLocation
      },
      {
        key: 'lastUpdateTime',
        name: 'Last Updated',
        value: assetState.lastUpdatedTime,
        type: 'date',
        format: 'MM/DD/YYYY hh:mm a'
      },
      {
        key: 'markAsMissingOrFound',
        name: assetState.isMissing ? 'Mark As Found' : 'Mark As Missing',
        type: 'button',
        onClick: () => {
          this.setState({ showMissingOrFoundModal: true });
        }
      }
    ];

    const contentStructureList = [...contentStructureList1, ...contentStructureList2];

    const routeTitleList = [
      {
        key: 'location',
        value: 'Location'
      },
      {
        key: 'entryTime',
        value: 'Entry Time'
      },
      { key: 'exitTime', value: 'Exit Time' },
      { key: 'timeDuration', value: 'Time Duration' }
    ];

    route.forEach((each, index) => {
      const duration = each.exitTime
        ? msToTime(each.exitTime - each.entryTime)
        : msToTime(Date.now() - each.entryTime);

      each.timeDuration = duration;
      each.entryTime = getFormattedDate(each.entryTime, 'hh:mm A MMM D');
      each.exitTime = each.exitTime ? getFormattedDate(each.exitTime, 'hh:mm A MMM D') : '-';
    });

    const newLocations = locations.filter(
      (eachLocation) => eachLocation.id !== assetState.currentLocationId
    );

    const locationClient = XemelgoService.getClient().getLocationClient();

    const locationPin = await locationClient
      .getLocationById(assetState.currentLocationId)
      .then((location) => {
        const { pin } = location.getResponseData();
        if (pin) {
          const { x, y, color } = pin;
          const id = location.getId();
          const name = location.getName();
          return {
            id,
            x,
            y,
            color: 'green',
            component: <GpsPinHead color="green" canClick={false} scale={4} />,
            name
          };
        }
        return null;
      });

    this.setState({
      itemId,
      assetState,
      route,
      routeTitleList,
      titleStructure,
      contentStructureList,
      markAsFoundOptions: [
        ...locations,
        { id: 'untrackedLocation', name: 'Other (Untracked Location)' }
      ],
      locations: newLocations,
      addLocation: false,
      showMap: false,
      locationIdToAdd: '',
      showMissingOrFoundModal: false,
      showDeleteAssetModal: false,
      loading: false,
      locationPin: [locationPin]
    });
  };

  renderDeleteAssetModal = () => {
    const { showDeleteAssetModal, assetState } = this.state;

    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showDeleteAssetModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">
            {`Delete Asset ${assetState.identifier}?`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Deleting an asset would permanently remove the asset from X-Track.</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({ showDeleteAssetModal: false });
            }}
          >
            No, Keep Asset
          </button>
          <button className="confirm-delete-button" onClick={this.deleteAsset}>
            Yes, Delete Asset
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleCommentBox = ({ currentTarget }) => {
    this.setState({ [currentTarget.id]: currentTarget.value });
  };

  renderMarkAsMissingOrFoundModal = () => {
    const {
      showMissingOrFoundModal,
      assetState,
      locationIdToAdd,
      markAsFoundOptions,
      commentBoxText
    } = this.state;

    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showMissingOrFoundModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">
            {`Mark ${assetState.identifier} As ${assetState.isMissing ? 'Found' : 'Missing'}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!assetState.isMissing && (
            <p>
              You're about to mark
              <b style={{ fontWeight: 'bold' }}>{` ${assetState.identifier} `}</b>
              as Missing, are you sure you want to do this?
            </p>
          )}

          {assetState.isMissing && (
            <div>
              <InputLabel>{`Select location where ${assetState.identifier} was found`}</InputLabel>
              <Select
                variant="outlined"
                className={Style.location_dropdown}
                value={locationIdToAdd}
                onChange={(event) => {
                  this.setState({
                    locationIdToAdd: event.target.value
                  });
                }}
              >
                {markAsFoundOptions.map((each) => {
                  return (
                    <MenuItem key={each.id} value={each.id}>
                      {each.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {locationIdToAdd === 'untrackedLocation' && (
                <div className={Style.commentBoxContainer}>
                  <InputLabel>Specify where asset was found (Optional)</InputLabel>
                  <TextareaAutosize
                    id="commentBoxText"
                    className={Style.detail_value_input}
                    value={commentBoxText}
                    onChange={this.handleCommentBox}
                  />
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({
                showMissingOrFoundModal: false,
                locationIdToAdd: '',
                commentBoxText: ''
              });
            }}
          >
            Cancel
          </button>
          <button className="confirm-delete-button" onClick={this.markAsMissingOrFound}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  markAsMissingOrFound = async () => {
    const { itemId, locationIdToAdd, commentBoxText, assetState } = this.state;

    const contextPayload = {
      missing: {
        assetNumber: assetState.identifier,
        value: !assetState.isMissing,
        comment: commentBoxText
      }
    };

    if (!assetState.isMissing) {
      contextPayload.missing.flipConditions = 'ManualOrAnyDetectionAtLocation';
    }

    const AssetClient = XemelgoService.getClient().getAssetClient();

    try {
      await AssetClient.markAssetAsMissingOrFound(
        itemId,
        locationIdToAdd === 'untrackedLocation' ? null : locationIdToAdd,
        contextPayload
      );
    } catch (e) {
      window.alert(e);
    } finally {
      await this.onLoad(itemId);
    }
  };

  deleteAsset = async () => {
    const { itemId } = this.state;
    const AssetClient = XemelgoService.getClient().getAssetClient();

    try {
      await AssetClient.deleteAsset(itemId);
    } catch (e) {
      console.log(e);
    } finally {
      await this.onLoad(itemId);
    }
  };

  renderAddLocationModal = (addLocation) => {
    const { locations, locationIdToAdd } = this.state;
    return (
      <Modal centered show={addLocation} backdrop="static" backdropClassName={Style.backdrop}>
        <Modal.Header>
          <Modal.Title>Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputLabel>Select A Location</InputLabel>
          <Select
            variant="outlined"
            className={Style.location_dropdown}
            value={locationIdToAdd}
            onChange={(event) => {
              this.setState({
                locationIdToAdd: event.target.value
              });
            }}
          >
            {locations.map((each) => {
              return (
                <MenuItem key={each.id} value={each.id}>
                  {each.name}
                </MenuItem>
              );
            })}
          </Select>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`${Style.button} ${Style.cancel_button}`}
            onClick={() =>
              this.setState({
                locationIdToAdd: '',
                addLocation: !addLocation
              })
            }
          >
            Cancel
          </button>
          <button
            disabled={!locationIdToAdd}
            type="button"
            className={`${Style.button} ${Style.save_button}`}
            onClick={this.handleAddLocation}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleAddLocation = async () => {
    const { itemId, locationIdToAdd } = this.state;
    const publishClient = XemelgoService.getClient().getPublishClient();
    try {
      await publishClient.publishUserEvent(itemId, locationIdToAdd);
    } catch (e) {
      console.log(e);
    } finally {
      await this.onLoad(itemId);
    }
  };

  handleSubmitDetail = async (dataForm, event) => {
    const { itemId } = this.state;
    const { identifier, expiryDate } = dataForm;

    const AssetClient = XemelgoService.getClient().getAssetClient();

    try {
      await AssetClient.updateAsset(
        itemId,
        identifier || undefined,
        expiryDate,
        dataForm.imagePath || undefined
      );
    } catch (e) {
      window.alert(e);
    } finally {
      await this.onLoad(itemId);
    }
  };

  getAdditionalMoreButtonOptions = () => {
    return (
      <DropdownItem
        className="dropdown-item"
        onClick={() => {
          this.setState({ showDeleteAssetModal: true });
        }}
      >
        <div className={Style.red_text}>Delete Asset</div>
      </DropdownItem>
    );
  };

  renderRouteTableOrMap = () => {
    const { showMap, route, routeTitleList, locationPin } = this.state;
    return showMap ? (
      floorPlanImageUrl && (
        <FloorPlan
          imageUrl={floorPlanImageUrl}
          pins={locationPin}
          className={Style.details_page_render_map}
        />
      )
    ) : (
      <DataTable titleList={routeTitleList} dataList={route} />
    );
  };

  render() {
    const {
      titleStructure,
      contentStructureList,
      assetState,
      addLocation,
      showMap,
      loading
    } = this.state;

    let imgName;
    if (assetState.identifier)
      imgName = `${assetState.identifier.slice(0, assetState.identifier.length - 4)}.png`;

    if (loading) {
      return <LoadingCircle />;
    }
    return (
      <>
        <div className={Style.content_holder}>
          <div className={Style.detail_group}>
            <DetailCard
              imagePath={getImage(imgName)}
              isActive={assetState.isActive}
              statusList={assetState.statusFlags}
              titleStructure={titleStructure}
              detailStructureList={contentStructureList}
              onSubmit={this.handleSubmitDetail}
              getAdditionalMoreButtonOptions={this.getAdditionalMoreButtonOptions}
            />
          </div>
          <div className={Style.table_group}>
            <div className={Style.bottom_table}>
              <div className={Style.title_group}>
                <div className={Style.title_label}>
                  Asset route
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => this.setState({ showMap: !showMap })}
                    className={Style.toggle_map_btn}
                  >
                    View Map
                  </div>
                </div>
                {assetState.isActive && (
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => this.setState({ addLocation: !addLocation })}
                    className={Style.part_edit_btn}
                  >
                    Add Location
                  </div>
                )}
              </div>
              {this.renderRouteTableOrMap()}
            </div>
          </div>
        </div>
        {this.renderAddLocationModal(addLocation)}
        {this.renderMarkAsMissingOrFoundModal()}
        {this.renderDeleteAssetModal()}
      </>
    );
  }
}
