import { AbstractResourceManager } from '../../abstract-resource-manager';
import { ResourceManagerLoader } from '../resource-manager-loader';
import { Generator } from '../../../utilities/generator';
import { itemTypesArray } from './item-types-resource-manager';

const ruleConditionResponse = () => {
  const ruleConditionSchemaMap = [
    {
      tags: {
        threshold: 16,
        itemType: itemTypesArray.labCoatType.identifier
      }
    },
    {
      tags: {
        threshold: 15,
        itemType: itemTypesArray.safetyGlassesType.identifier
      }
    },
    {
      tags: {
        threshold: 11,
        itemType: itemTypesArray.weldinGogglesType.identifier
      }
    },
    {
      tags: {
        threshold: 4,
        itemType: itemTypesArray.shoeCoversType.identifier
      }
    },
    {
      tags: {
        threshold: 6,
        itemType: itemTypesArray.paintType.identifier
      }
    },
    {
      tags: {
        threshold: 10,
        itemType: itemTypesArray.loctiteType.identifier
      }
    },
    {
      name: 'lowStock_Kent Warehouse-3M Abrasive',
      conditions: [
        {
          property: 'locationId',
          op: '=',
          value: 'Kent Warehouse'
        },
        {
          property: 'itemType',
          op: '=',
          value: itemTypesArray.abrasivesType.identifier
        },
        {
          property: 'itemTypeCount',
          op: '<=',
          value: 10
        }
      ],
      tags: {
        threshold: 13,
        itemType: itemTypesArray.abrasivesType.identifier
      }
    },
    {
      tags: {
        threshold: 5,
        itemType: itemTypesArray.sandingBeltType.identifier
      }
    }
  ];

  return ruleConditionSchemaMap;
};

// eslint-disable-next-line import/prefer-default-export
export class RuleConditionResourceManager extends AbstractResourceManager {
  constructor() {
    super('id');
    this.conditionIdToRulIdMap = {};
  }

  createRuleCondition(payload) {
    const { fields: item, connections } = payload;
    const condition = this.addItem(item);

    const { belongsToRule } = connections;
    const context = this;
    const ruleManager = ResourceManagerLoader.get('rule');

    belongsToRule.forEach((ruleId) => {
      context.conditionIdToRulIdMap[condition.id] = ruleId;
      if (ruleManager) {
        ruleManager.registerRuleCondition(ruleId, condition.id);
      }
    });

    return condition;
  }

  get(id) {
    const condition = super.get(id);
    const subscriptionRM = ResourceManagerLoader.get('subscription');

    const transformedCondition = { ...condition };
    transformedCondition.hasSubscriptions = [];
    if (subscriptionRM) {
      const subscriptions = subscriptionRM.list();
      transformedCondition.hasSubscriptions.push(...subscriptions);
    }

    return transformedCondition;
  }

  filter() {
    return ruleConditionResponse();
  }

  list() {
    return ruleConditionResponse();
  }
}

const resourceManager = new RuleConditionResourceManager();
export { resourceManager };
