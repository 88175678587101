// TODO: where does this information fit into the workflow??? should this information moved into xemelgo-client?
const DetectorTypeVendorMap = {
  Handheld: {
    vendor: 'TSL',
    protocol: 'API'
  },
  Mounted: {
    vendor: 'Brady',
    protocol: 'MQTT'
  }
};

export const getDetectorResourceManager = (xemelgoClient) => ({
  createDetectorAndAttachToLocation: (payload) => {
    const { vid, class: detectorType, location: locationId, action: actionKey } = payload;
    let actions = null;

    // TODO: this should be moved into xemelgo client
    if (actionKey === 'endTrackingSession') {
      actions = {
        endTrackingSession: {
          detachSensorProfiles: true
        }
      };
    } else if (actionKey === 'sameDetectorEndTrackingSession') {
      actions = {
        sameDetectorEndTrackingSession: true
      };
    }

    const vendorInfo = DetectorTypeVendorMap[detectorType];
    const { vendor, protocol } = vendorInfo;
    const detectorClient = xemelgoClient.getDetectorClient();
    if (locationId) {
      return detectorClient.createAndAttachDetectorToLocation(
        locationId,
        detectorType,
        vendor,
        vid,
        protocol,
        actionKey === 'endTrackingSession',
        vid,
        actions
      );
    }

    return detectorClient.createDetector(
      detectorType,
      vendor,
      vid,
      protocol,
      actionKey === 'endTrackingSession',
      vid,
      actions
    );
  }
});
