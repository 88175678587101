import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/loading/LoadingWord';

import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { ThemeProvider } from '@material-ui/core/styles';

import theme from './MaterialTheme';

export default class CrudTable extends PureComponent {
  render() {
    const { formController, changeHandler } = this.props;
    return (
      <ThemeProvider theme={theme}>
        {formController ? (
          <MaterialTable
            title={formController.title}
            columns={formController.columnHeaders}
            data={formController.data}
            options={formController.options}
            icons={{
              Add: props => {
                return <AddIcon {...props} color="primary" fontSize="large" />;
              },
              Edit: props => <EditIcon {...props} color="primary" />,
              Delete: props => <DeleteIcon {...props} color="error" />
            }}
            components={{
              Container: props => (
                <div className={formController.containerStyle}>
                  <Paper {...props} />
                </div>
              )
            }}
            localization={formController.localization}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  if (changeHandler('add', newData)) {
                    resolve();
                  } else {
                    reject();
                  }
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  if (changeHandler('edit', { new: newData, old: oldData })) {
                    resolve();
                  } else {
                    reject();
                  }
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  changeHandler('remove', oldData);
                  resolve();
                })
            }}
          />
        ) : (
          <Loading />
        )}
      </ThemeProvider>
    );
  }
}

CrudTable.propTypes = {
  formController: PropTypes.shape({
    title: PropTypes.element,
    columnHeaders: PropTypes.array,
    data: PropTypes.array,
    options: PropTypes.any,
    containerStyle: PropTypes.string,
    localization: PropTypes.any
  }).isRequired,
  changeHandler: PropTypes.func.isRequired
};
