import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useXemelgoClient } from '../../services/xemelgo-service';
import { useFeatureConfigProvider } from '../../services/soft-cache-service';
import './style.css';
import { TwoColumnsPaneView } from '../../components/two-columns-pane-view';
import { ListResourceGroupPanel } from './features/list-resource-group-panel';
import { AddResourceForm } from './features/add-resource-form';
import { ResourceDetailPane } from './features/resource-detail-pane';
import { DeleteResourceForm } from './features/delete-resource-form';
import { EditResourceForm } from './features/edit-resource-form';
import { getDetectorResourceManager } from '../domains/resource-managers/detector-resource-manager';
import { DefaultConfiguration } from './configuration';
import LoadingCircle from '../../components/loading/LoadingCircle';
import { FeatureConfigurationProvider } from '../../domains/feature-configuration-provider';

const prepareLocationArguments = (locations) => {
  const sorted = locations.sort((loc1, loc2) => loc1.getName().localeCompare(loc2.getName()));
  const locationArguments = sorted.map((loc) => {
    const nameSegments = [loc.getName()];
    const parentLoc = loc.getParent();
    if (parentLoc) {
      nameSegments.push(parentLoc.getName());
    }

    return {
      key: loc.getId(),
      value: nameSegments.join(' - ')
    };
  });
  return locationArguments;
};

/**
 * ModelMap has to be merged separately due to the fact that empty provided config will always return { modelMap: {}},
 *  which will result in the provided empty model map to wipe out the model map from default configuration.
 *
 * This helper function will merge model map onto a separate variable, and assign it back after the full configuration merged.
 * @param providedConfig
 * @param defaultConfig
 * @returns {{modelMap: {}}}
 */
const mergeConfigs = (providedConfig, defaultConfig) => {
  const { modelMap: providerModelMap = {} } = providedConfig;
  const { modelMap: defaultModelMap = {} } = defaultConfig;
  const mergeModelMap = { ...defaultModelMap, ...providerModelMap };
  const mergedConfig = { ...defaultConfig, ...providedConfig, modelMap: mergeModelMap };
  return mergedConfig;
};

const FeatureId = 'listDetectors';
export const ListDetectors = ({ appId }) => {
  const [xemelgoClient] = useState(useXemelgoClient());
  const [providedConfigProvider] = useState(useFeatureConfigProvider(appId, FeatureId));
  const [configProvider, setConfigProvider] = useState(null);
  const [resourceMap, setResourceMap] = useState({});
  const [needRefreshData, setNeedRefreshData] = useState(true);
  const [selectedResource, setSelectedResource] = useState(null);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [addFormArgument, setAddFormArgument] = useState(null);
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [editFormArgument, setEditFormArgument] = useState(null);
  const [resourceForEdit, setResourceForEdit] = useState(null);

  /**
   * Name: mergedProvideWithDefaultConfig.
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!providedConfigProvider) {
      return cancelCallback;
    }

    const providedConfig = providedConfigProvider.getConfiguration();
    const mergedConfig = mergeConfigs(providedConfig, DefaultConfiguration);
    const mergedConfigProvider = FeatureConfigurationProvider.parse(FeatureId, mergedConfig);

    if (!cancelled) {
      setConfigProvider(mergedConfigProvider);
    }

    return cancelCallback;
  }, [providedConfigProvider]);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configProvider || !xemelgoClient || !needRefreshData) {
      return cancelCallback;
    }

    const detectorClient = xemelgoClient.getDetectorClient();
    detectorClient.listDetectors().then((results) => {
      const sorted = results.sort((detector1, detector2) =>
        detector1.getSerial().localeCompare(detector2.getSerial())
      );

      const transformedMap = sorted
        .map((detector) => {
          const location = detector.getLocation();
          const serial = detector.getSerial();
          const nameSegments = [serial];
          let locName = null;
          let locId = null;
          if (location) {
            locName = location.getName();
            locId = location.getId();
            nameSegments.push(locName);
          }

          return {
            id: detector.getId(),
            name: nameSegments.join(' - '),
            vid: serial,
            class: `${detector.getClass()} Reader`,
            vendor: detector.getVendor(),
            location: locName,
            locationId: locId
          };
        })
        .reduce((map, resource) => {
          const clonedMap = { ...map };
          const { id } = resource;
          clonedMap[id] = resource;
          return clonedMap;
        }, {});

      if (!cancelled) {
        setResourceMap(transformedMap);
        setNeedRefreshData(false);
      }
    });

    return cancelCallback;
  }, [configProvider, xemelgoClient, needRefreshData]);

  const refreshData = useCallback(() => {
    setSelectedResource(null);
    setNeedRefreshData(true);
  }, []);

  const onRecordSelected = useCallback(
    (id) => {
      const resource = resourceMap[id];

      // override the name used for displaying on the detail pane.
      const cloned = { ...resource };
      cloned.name = cloned.vid;
      setSelectedResource(cloned);
    },
    [resourceMap]
  );

  const onDeleteButtonClicked = useCallback(() => {
    setOpenDeleteForm(true);
  }, []);

  const onCancelDeleteFormCallback = useCallback(() => {
    setOpenDeleteForm(false);
  }, []);

  const onSubmitDeleteForm = useCallback(
    (id) => {
      const detectorClient = xemelgoClient.getDetectorClient();
      // TODO: need to handle when delete fails
      detectorClient.removeDetector(id).then(() => {
        setOpenDeleteForm(false);
        refreshData();
      });
    },
    [xemelgoClient, refreshData]
  );

  const onAddButtonClicked = useCallback(() => {
    // find out from configuration for which location arguments we need to fetch
    const featureArgumentsMap = configProvider.getValue('featureArgumentsMap', 'object', {
      addResource: { location: 'department' }
    });
    const { addResource } = featureArgumentsMap;
    const { location: locationModelId } = addResource;

    // find out from the model the category name
    const locationModelProvider = configProvider.getModel(locationModelId);
    const locationCategory = locationModelProvider.getValue('category', 'object', {
      name: locationModelId
    });
    const { name: categoryName } = locationCategory;

    // fetch the data
    const locationClient = xemelgoClient.getLocationClient();
    locationClient.getLocationsOfCategory(categoryName).then((results) => {
      const locationArguments = prepareLocationArguments(results);

      const providedArgument = { location: locationArguments };
      setAddFormArgument(providedArgument);
      setOpenAddForm(true);
    });
  }, [configProvider, xemelgoClient]);

  const onCancelAddFormCallback = useCallback(() => {
    setOpenAddForm(false);
  }, []);

  const onAddFormSubmit = useCallback(
    (payloads) => {
      const detectorRM = getDetectorResourceManager(xemelgoClient);
      const promises = payloads.map((payload) =>
        detectorRM.createDetectorAndAttachToLocation(payload)
      );

      // TODO: how to handle submit failure of any of the payload
      Promise.all(promises).then(() => {
        setOpenAddForm(false);
        refreshData();
      });
    },
    [xemelgoClient, refreshData]
  );

  const onEditButtonClicked = useCallback(() => {
    const featureArgumentsMap = configProvider.getValue('featureArgumentsMap', 'string', {
      editResource: { location: 'department' }
    });
    const { location: locationModelId } = featureArgumentsMap.editResource;
    const locationModelConfigProvider = configProvider.getModel(locationModelId);
    const locationCategory = locationModelConfigProvider.getValue('category', 'object', {
      name: 'Department'
    });

    const { name: categoryName } = locationCategory;
    const locationClient = xemelgoClient.getLocationClient();
    const editResource = { ...selectedResource };

    locationClient.getLocationsOfCategory(categoryName).then((results) => {
      const locationArguments = prepareLocationArguments(results);
      setEditFormArgument({ location: locationArguments });
      setResourceForEdit(editResource);
      setOpenEditForm(true);
    });
  }, [xemelgoClient, selectedResource, configProvider]);

  const onEditCancel = useCallback(() => {
    setOpenEditForm(false);
  }, []);

  const onEditSave = useCallback(
    (payload) => {
      // not much we are allowing user to change on detector beside location
      const { id, locationId } = payload;

      if (!locationId) {
        return;
      }

      const detectorClient = xemelgoClient.getDetectorClient();
      detectorClient.changeDetectorLocation(id, locationId).then(() => {
        setOpenEditForm(false);
        refreshData();
      });
    },
    [xemelgoClient, refreshData]
  );

  return (
    <Fragment>
      {!configProvider && <LoadingCircle />}
      {configProvider && (
        <Fragment>
          {openEditForm && (
            <EditResourceForm
              resource={resourceForEdit}
              modelId="detector"
              configuration={configProvider.getFeatureConfiguration('editResource')}
              show={openEditForm}
              providedArgument={editFormArgument}
              onCancel={onEditCancel}
              onSave={onEditSave}
            />
          )}
          {openAddForm && (
            <AddResourceForm
              modelId="detector"
              configuration={configProvider.getFeatureConfiguration('addResource')}
              show={openAddForm}
              providedArgument={addFormArgument}
              onSubmit={onAddFormSubmit}
              onCancel={onCancelAddFormCallback}
            />
          )}
          {openDeleteForm && (
            <DeleteResourceForm
              resource={selectedResource}
              show={openDeleteForm}
              onSubmit={onSubmitDeleteForm}
              onCancel={onCancelDeleteFormCallback}
            />
          )}
          <TwoColumnsPaneView
            className="list-detectors"
            leftPane={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <ListResourceGroupPanel
                modelId="detector"
                resources={Object.values(resourceMap)}
                configuration={configProvider.getConfiguration()}
                focus={!needRefreshData}
                onRecordSelected={onRecordSelected}
                onAddClicked={onAddButtonClicked}
              />
            }
            rightPane={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <ResourceDetailPane
                modelId="detector"
                configuration={configProvider.getFeatureConfiguration('resourceDetailView')}
                resource={selectedResource}
                onDeleteClicked={onDeleteButtonClicked}
                onEditClicked={onEditButtonClicked}
              />
            }
          />
        </Fragment>
      )}
    </Fragment>
  );
};

ListDetectors.propTypes = {
  appId: PropTypes.string.isRequired
};
