import { v4 as uuidV4 } from 'uuid';
import { AbstractResourceManager } from '../../abstract-resource-manager';
import { LocationMockPlanExecutor } from '../mock-plan-executors/location-mock-plan-executor';
import { assetArray, orderArray, inventoryArray } from './item-resource-manager';

export const locationSchemaMap = {
  demoFacility: {
    id: '000',
    category: 'Plant',
    name: 'Alstom Plant 3 - Hornell',
    image_path: 'https://xemelgo-logo.s3-us-west-2.amazonaws.com/Alstom_Facility.png',
    image_meta: {
      width: 1368,
      height: 1197
    },
    description: 'The plant in Seattle is responsible for making everything and shipping it out.'
  },
  incomingDepartment: {
    id: '001',
    category: 'Department',
    name: 'Incoming',
    // reference to demoFacility schema above
    isLocatedIn: '#{demoFacility}',
    pin: {
      x: 100,
      y: 600,
      color: 'green'
    }
  },
  weldingDepartment: {
    id: '002',
    category: 'Department',
    name: 'Shot Blast',
    isLocatedIn: '#{demoFacility}',
    pin: {
      x: 320,
      y: 70,
      color: 'green'
    }
  },
  qualityInspectionDepartment: {
    id: '004',
    category: 'Department',
    name: 'Quality Inspection',
    isLocatedIn: '#{demoFacility}',
    pin: {
      x: 970,
      y: 500,
      color: 'green'
    }
  },
  gPDepartment: {
    id: '003',
    category: 'Department',
    name: 'Assembly',
    isLocatedIn: '#{demoFacility}',
    pin: {
      x: 500,
      y: 350,
      color: 'green'
    }
  },
  kentWarehouse: {
    id: '005',
    category: 'Warehouse',
    name: 'Kent Warehouse'
  },
  auburnWarehouse: {
    id: '006',
    category: 'Warehouse',
    name: 'Auburn Warehouse'
  }
};

const ConnectionFields = ['isLocatedIn', 'containsLocations'];
const ConnectionPairs = {
  isLocatedIn: 'containsLocations',
  containsLocations: 'isLocatedIn'
};

const init = (addItemFn) => {
  const createdLocations = LocationMockPlanExecutor.execute(locationSchemaMap);
  createdLocations.forEach((location) => addItemFn(location));
};

const orderTrackPageLocationResponse = () => {
  const locations = {};

  orderArray.forEach((order) => {
    const trackingSession = order['associatedWithSession#edge'][0]['associatedWithSession#node'][0];
    const events =
      order['associatedWithSession#edge'][0]['associatedWithSession#node'][0].includesEvent;

    const lastEvent = events[events.length - 1];
    const detectedLocation = lastEvent.detectedAt[0];
    if (!locations[detectedLocation.name]) {
      locations[detectedLocation.name] = {
        name: detectedLocation.name,
        id: detectedLocation.id,
        category: 'Department',
        hasDetectors: detectedLocation.hasDetectors,
        associatedWithEvent: []
      };
    }

    locations[detectedLocation.name].associatedWithEvent.push({
      id: uuidV4(),
      partOfTrackingSession: [
        {
          last_updated_time: trackingSession.last_updated_time,
          tracksItem: [order]
        }
      ]
    });
  });

  const trackPageResponseSchemaMap = Object.keys(locations).map((data) => {
    return locations[data];
  });

  return trackPageResponseSchemaMap;
};

const inventoryTrackPageLocationResponse = () => {
  const locations = {};
  inventoryArray.forEach((item) => {
    const location = item.hasTargetLocation[0];
    if (!locations[location.id]) {
      locations[location.id] = {
        location,
        isTargetLocationOf: []
      };
    }

    locations[location.id].isTargetLocationOf.push(item);
  });

  const trackPageResponseSchemaMap = Object.keys(locations).map((data) => {
    const payload = {
      ...locations[data].location,
      isTargetLocationOf: locations[data].isTargetLocationOf
    };

    return payload;
  });

  return trackPageResponseSchemaMap;
};

const assetTrackPageLocationResponse = () => {
  const locations = {};

  assetArray.forEach((asset) => {
    const trackingSession = asset['associatedWithSession#edge'][0]['associatedWithSession#node'][0];
    const events =
      asset['associatedWithSession#edge'][0]['associatedWithSession#node'][0].includesEvent;

    const lastEvent = events[events.length - 1];
    const detectedLocation = lastEvent.detectedAt[0];
    if (!locations[detectedLocation.name]) {
      locations[detectedLocation.name] = {
        ...detectedLocation,
        associatedWithEvent: []
      };
    }

    locations[detectedLocation.name].associatedWithEvent.push({
      partOfTrackingSession: [
        {
          last_updated_time: trackingSession.last_updated_time,
          is_missing: trackingSession.is_missing,
          tracksItem: [asset]
        }
      ]
    });
  });

  const trackPageResponseSchemaMap = Object.keys(locations).map((data) => {
    return locations[data];
  });

  return trackPageResponseSchemaMap;
};

const keywordMap = {
  Traveller: orderTrackPageLocationResponse,
  isTargetLocationOf: inventoryTrackPageLocationResponse,
  Asset: assetTrackPageLocationResponse
};

// eslint-disable-next-line import/prefer-default-export
export class LocationResourceManager extends AbstractResourceManager {
  constructor() {
    super('id');
    init(this.addItem.bind(this));
  }

  retire(payload) {
    const { id } = payload;
    const removed = this.get(id);
    super.remove(id);
    return removed;
  }

  get(payload) {
    const location = super.get(
      payload.arguments && payload.arguments.ids && payload.arguments.ids[0]
    );

    const getFn = super.get.bind(this);

    ConnectionFields.forEach((connectionName) => {
      let refIds = location[connectionName];
      if (!refIds) {
        return;
      }

      if (!Array.isArray(refIds)) {
        refIds = [refIds];
      }

      const refs = [];
      refIds.forEach((refId) => {
        if (typeof refId === 'string') {
          const refLocation = getFn(refId);
          if (refLocation) {
            refs.push(refLocation);
          }
        }
      });

      if (refs.length > 1) {
        location[connectionName] = refs;
      }
    });

    return [location];
  }

  list(payload) {
    const foundMatchingKeyword = Object.keys(keywordMap).filter((keyword) => {
      return super.getSolutionSpecificRequest(keyword, payload);
    });

    if (foundMatchingKeyword.length > 0) {
      return keywordMap[foundMatchingKeyword]();
    }

    const assetsByLocation = assetTrackPageLocationResponse();
    const locations = super.list();
    assetsByLocation.forEach((assetLocation) => {
      const matchingLocation = locations.find((location) => {
        return location.name === assetLocation.name;
      });

      if (matchingLocation) {
        matchingLocation.associatedWithEvent = assetLocation.associatedWithEvent;
      }
    });

    const getFn = super.get.bind(this);

    const transformed = locations.map((location) => {
      const cloned = { ...location };
      ConnectionFields.forEach((connectionName) => {
        let refIds = cloned[connectionName];
        if (!refIds) {
          return;
        }

        if (!Array.isArray(refIds)) {
          refIds = [refIds];
        }

        const refs = [];
        refIds.forEach((refId) => {
          const refLocation = getFn(refId);
          if (refLocation) {
            refs.push(refLocation);
          }
        });

        cloned[connectionName] = refs;
      });
      return cloned;
    });

    return transformed;
  }

  update(payload) {
    const { id, fields } = payload;
    const item = Object.keys(fields).reduce((map, fieldName) => {
      const clonedMap = { ...map };
      const valueInfo = fields[fieldName];
      const { value } = valueInfo;
      clonedMap[fieldName] = value;
      return clonedMap;
    }, {});

    return super.update(id, item);
    // return super.update(id, item);
  }

  filter(propertyMap, payload) {
    const locations = this.list(payload);
    return locations.filter((location) => {
      let match = true;
      Object.keys(propertyMap).forEach((propertyName) => {
        match = match && location[propertyName] === propertyMap[propertyName];
      });
      return match;
    });
  }
}

const resourceManager = new LocationResourceManager();
export { resourceManager };
