import { v4 as uuidV4 } from 'uuid';
import { AbstractResourceManager } from '../../abstract-resource-manager';
import {
  assetDataArray,
  assetRoute,
  orderDataArray,
  orderRoute,
  inventoryDataArray,
  inventoryRoute
} from './item-resource-data';
import { assetTypeArray } from './item-types-resource-manager';
import { Generator } from '../../../utilities/generator';

export const assetArray = [];
export const orderArray = [];
export const inventoryArray = [];

let assetCounter = 200;
let orderCounter = 52132;
let inventoryCounter = 1000;

const randomizeEvents = (route) => {
  return route.slice(0, Generator.getRandomNumber(0, route.length - 1) + 2);
};

const generateAsset = (typeId, typeName, typeIdentifier, identifier) => {
  assetCounter++;
  return {
    id: assetCounter,
    class: 'Asset',
    identifier: `${typeIdentifier}${identifier}-${assetCounter}`,
    expiry_date: new Date().setDate((assetCounter % 30) + 10),
    hasSensorProfile: [
      {
        vid: `${Generator.getRandomNumber(202002030000, 202002039999)}`
      }
    ],
    ofType: [
      {
        id: typeId,
        name: typeName,
        identifier: typeIdentifier
      }
    ],
    'associatedWithSession#edge': [
      {
        'associatedWithSession#node': [
          {
            start_time: Generator.getRandomPastDate(30, 20).getTime(),
            is_missing: assetCounter % 10 > 8,
            last_updated_time: Generator.getRandomPastDate(1, 7).getTime(),
            is_active: true,
            includesEvent: randomizeEvents(assetRoute)
          }
        ]
      }
    ],
    associatedWithSession: []
  };
};

const generateOrder = (hasOutput) => {
  orderCounter++;
  const orderIdentifier = orderCounter.toString();
  return {
    class: 'Traveller',
    identifier: orderIdentifier,
    id: orderCounter,
    represents: [
      {
        identifier: orderIdentifier,
        due_date: new Date().setDate((orderCounter % 30) + 6),
        status_flags: orderCounter % 10 > 7 ? ['Expedited'] : [],
        hasOutput
      }
    ],
    hasSensorProfile: [
      {
        vid: `${Generator.getRandomNumber(202002030000, 202002039999)}`
      }
    ],
    'associatedWithSession#edge': [
      {
        'associatedWithSession#node': [
          {
            id: uuidV4(),
            start_time: Generator.getRandomPastDate(10, 20).getTime(),
            last_updated_time: Generator.getRandomPastDate(1, 7).getTime(),
            is_active: true,
            includesEvent: randomizeEvents(orderRoute)
          }
        ]
      }
    ],
    associatedWithSession: []
  };
};

const generateInventory = (location, item, hasDetectors) => {
  inventoryCounter++;
  const inventoryIdentifier = inventoryCounter.toString();
  return {
    class: 'Inventory',
    id: inventoryCounter,
    name: `${item.ofType.identifier}-${inventoryIdentifier}`,
    identifier: inventoryIdentifier,
    node_start_time: Generator.getRandomPastDate(5, 20).getTime(),
    expiry_date: item.expiry && Generator.getRandomDateBetween(10, 90).getTime(),
    lot_number: Generator.getRandomNumber(100, 120),
    hasSensorProfile: [
      {
        vid: `${Generator.getRandomNumber(202002030000, 202002039999)}`
      }
    ],
    'associatedWithSession#edge': [
      {
        'associatedWithSession#node': [
          {
            id: uuidV4(),
            is_active: true,
            is_missing: null,
            start_time: Generator.getRandomPastDate(5, 12).getTime(),
            end_time: 32503680000000,
            last_updated_time: Generator.getRandomPastDate(1, 7).getTime(),
            includesEvent: [inventoryRoute[location.id]]
          }
        ]
      }
    ],
    hasTargetLocation: [
      {
        ...location,
        hasDetectors
      }
    ],
    ofType: [item.ofType]
  };
};

const init = (addItemFn) => {
  orderDataArray.forEach((order) => {
    for (let i = 0; i < order.total; i++) {
      const generatedOrder = generateOrder(order.hasOutput);
      orderArray.push(generatedOrder);
    }
  });

  const assetTypeMap = assetTypeArray.reduce((map, item) => {
    map[item.identifier] = item;
    return map;
  }, {});

  assetDataArray.forEach((asset) => {
    for (let i = 0; i < asset.total; i++) {
      const assetType = assetTypeMap[asset.ofType.identifier];
      const generatedAsset = generateAsset(
        assetType.id,
        assetType.name,
        assetType.identifier,
        asset.data.identifier
      );
      assetArray.push(generatedAsset);
    }
  });

  inventoryDataArray.forEach((locationData) => {
    const { location, associatedWithEvent, hasDetector } = locationData;
    for (let j = 0; j < associatedWithEvent.length; j++) {
      const item = associatedWithEvent[j];
      for (let k = 0; k < item.total; k++) {
        const generatedInventory = generateInventory(location, item, hasDetector);
        inventoryArray.push(generatedInventory);
      }
    }
  });

  const allItems = orderArray.concat(assetArray).concat(inventoryArray);
  allItems.forEach((element) => {
    addItemFn(element);
  });
};

// eslint-disable-next-line import/prefer-default-export
export class ItemResourceManager extends AbstractResourceManager {
  constructor() {
    super('id');
    init(this.addItem.bind(this));
  }

  supportMock(api, action, payload, headers) {
    if (api === 'query') {
      return true;
    }
    return super.supportMock(api, payload, headers);
  }

  get(payload) {
    return [super.get(payload.arguments && payload.arguments.ids && payload.arguments.ids[0])];
  }

  list() {
    const list = super.list();

    return list;
  }
}

const resourceManager = new ItemResourceManager();
export { resourceManager };
