import React, { Component } from 'react';
import SearchBarAndResults from 'components/SearchBarAndResults';

// import './SearchBar.css';

export default class TrackedObjectSearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // searchResults: [],
      // suggestions: [],
      query: '',
      value: '',
      searchFocused: false,
      placeholder: 'Start typing to search',
      items: [],
      keys: [
        'identifier',
        ['customFields', 'searchData', 'sensorProfile'],
        ['customFields', 'searchData', 'readableFormatDate']
      ]
    };
  }

  componentDidMount = () => {
    this.populateData();
  };

  /* Set this.state.items to full list of items
   * Indeces will be created every time the page is rendered
   * TODO: Maybe we can store indeces on server side
   * and just download them when the user enters search?
   * or have the indeces load on async when user types searches */
  populateData = async () => {
    // const XemelgoClient = XemelgoService.getClient();
    // const SearchClient = XemelgoClient.getSearchClient();
    // const items = await SearchClient.getAllItems();
    //
    // if (!items || items.length < 1) {
    //   return;
    // }
    //
    // const { keys } = this.state;
    //
    // // Build the prefix tree search
    // const ts = new TrieSearch(keys, { ignoreDuplicates: true });
    // ts.addAll(items);
    //
    // // Build indeces to search substrings that are not at the start
    // const nonTrieIndices = {};
    //
    // for (const i in items) {
    //   const item = items[i];
    //
    //   if (item.customFields) {
    //     if (item.customFields.searchData.parts && item.customFields.searchData.parts.length > 0) {
    //       const relatedParts = item.customFields.searchData.parts;
    //       relatedParts.length > 0 &&
    //         relatedParts.forEach(part => {
    //           // array of indices for the part
    //           const indexArray =
    //             nonTrieIndices[part.identifier.toLowerCase()] &&
    //             nonTrieIndices[part.identifier.toLowerCase()].length > 0
    //               ? nonTrieIndices[part.identifier.toLowerCase()]
    //               : [];
    //           indexArray.push(i);
    //           nonTrieIndices[part.identifier.toLowerCase()] = indexArray;
    //           nonTrieIndices[part.name.toLowerCase()] = indexArray;
    //         });
    //     }
    //   }
    //   if (item.sensorProfile) {
    //     // RFID Tags - unique to each order
    //     nonTrieIndices[item.sensorProfile.toLowerCase()] = [i];
    //   }
    // }
    //
    // this.setState({ items, trie_search: ts, nonTrieIndices });
  };

  render() {
    const { placeholder, keys, items } = this.state;

    return (
      <SearchBarAndResults
        placeholder={placeholder}
        renderSuggestionsContainerFn={null}
        renderSuggestionFn={null}
        keys={keys}
        items={items}
      />
    );
  }
}
