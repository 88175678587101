import { AbstractResourceManager } from '../../abstract-resource-manager';
import { resourceManager as stockItemRM } from './stock-item-resource-manager';
import { Generator } from '../../../utilities/generator';
const uuid = require('uuid');

const generateRandomRestockRequest = (stockItem, numberOfRequests) => {
  // eslint-disable-next-line no-undef
  const sequences = [];
  const possibleStatuses = ['New', 'Backorder', 'Arriving', 'Arrived'];
  const possibleVendors = ['Amazon', 'Anda', 'AX', 'Costco'];
  return sequences.map(index => ({
    stock_item_id: stockItem.stock_item_id,
    size: `${Generator.getRandomNumber(10, 1000)} EA`,
    status: Generator.getRandomFrom(possibleStatuses),
    vendor: Generator.getRandomFrom(possibleVendors),
    cost: Generator.getRandomCurrencyValue(100, 200),
    order_number: `Order-${index + 1}`,
    comment: null,
    stock_item_name: stockItem.stock_item_name,
    restock_request_details: [
      {
        quantity: Generator.getRandomNumber(10),
        restock_last_updated_timestamp: Generator.getRandomPastDate(5, 15).getTime(),
        restock_request_line_id: uuid.v4(),
        restock_requested_timestamp: Generator.getRandomPastDate(1, 3).getTime(),
        user_id: '29d685ea-9c48-4f9f-aa5d-37ee8086a1cc'
      }
    ]
  }));
};

// eslint-disable-next-line import/prefer-default-export
class RestockRequestResourceManager extends AbstractResourceManager {
  init() {
    const items = stockItemRM.list();
    const item = items[0];
    const requests = generateRandomRestockRequest(item, 10);
    // const addItemFn = this.addItem;
    requests.forEach(r => super.addItem(r));
  }

  constructor() {
    super('restock_request_id');
    this.init();
  }
}

const resourceManager = new RestockRequestResourceManager();
export { resourceManager };
