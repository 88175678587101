/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  LocalShippingOutlined,
  AccountCircleOutlined,
  NotificationsOutlined,
  CalendarTodayOutlined,
  BusinessCenterOutlined,
  AssignmentOutlined,
  HomeWorkOutlined
} from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { useStore } from 'react-hookstore';
import Routes from './routes/Routes';
import MainAppFeature from './features/main-app-feature/MainAppFeature';
import ConfigurationService from './services/ConfigurationService';
import { userProfileStore } from './state-managements/stores/user-profile-store';
import { LocalCacheService } from './services/local-cache-service';

const getAdminTabsStructure = (history) => {
  return [
    {
      id: 'my-facility',
      display: () => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 40
            }}
          >
            <HomeWorkOutlined fontSize="default" />
            <div style={{ marginLeft: 20 }}>My Facility</div>
          </div>
        );
      },
      action: () => {
        history.push('/my-facility');
      }
    },
    {
      id: 'users',
      display: () => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 40
            }}
          >
            <AccountCircleOutlined fontSize="default" />
            <div style={{ marginLeft: 20 }}>Users</div>
          </div>
        );
      },
      action: () => {
        history.push('/users');
      }
    }
  ];
};

const getDefaultTabStructure = (history) => {
  return [
    {
      id: 'alerts',
      display: () => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 40
            }}
          >
            <NotificationsOutlined fontSize="default" />
            <div style={{ marginLeft: 20 }}>Alerts</div>
          </div>
        );
      },
      action: () => {
        history.push('/alerts');
      }
    }
  ];
};

const MainApp = ({ history }) => {
  const [tabStructure, setTabStructure] = useState(getDefaultTabStructure(history));
  const [redirectPath, setRedirectPath] = useState('/'); // RedirectPath is on temporary used before dashboard page is implemented

  /**
   * @type {./domains/user-profile.UserProfile}
   */
  const [userProfile] = useStore(userProfileStore);
  useEffect(() => {
    const isAdmin = userProfile.isUserSuperAdmin();
    let newTabStructure = tabStructure;
    if (isAdmin) {
      newTabStructure = newTabStructure.concat(getAdminTabsStructure(history));
    }

    ConfigurationService.getSolutionType().then((unProcessedSolutionType) => {
      let solutionType;
      if (!Array.isArray(unProcessedSolutionType)) {
        solutionType = [unProcessedSolutionType];
      } else {
        solutionType = unProcessedSolutionType.sort().reverse();
      }

      solutionType.forEach((eachSolution) => {
        switch (eachSolution) {
          case 'order':
            newTabStructure.unshift({
              id: 'work-order',
              display: () => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 40
                    }}
                  >
                    <CalendarTodayOutlined fontSize="default" />
                    <div
                      style={{
                        marginLeft: 20
                      }}
                    >
                      Work Orders
                    </div>
                  </div>
                );
              },
              action: () => {
                history.push('/work-order');
              }
            });
            break;

          case 'inventory':
            newTabStructure.unshift({
              id: 'inventory',
              display: () => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 40
                    }}
                  >
                    <AssignmentOutlined fontSize="default" />
                    <div
                      style={{
                        marginLeft: 20
                      }}
                    >
                      Inventory
                    </div>
                  </div>
                );
              },
              action: () => {
                history.push('/inventory');
              }
            });
            break;
          case 'asset':
            newTabStructure.unshift({
              id: 'asset',
              display: () => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 40
                    }}
                  >
                    <BusinessCenterOutlined fontSize="default" />
                    <div
                      style={{
                        marginLeft: 20
                      }}
                    >
                      Asset
                    </div>
                  </div>
                );
              },
              action: () => {
                history.push('/asset');
              }
            });
            break;
          case 'shipment':
            newTabStructure.unshift({
              id: 'shipment',
              display: () => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 40
                    }}
                  >
                    <LocalShippingOutlined fontSize="default" />
                    <div
                      style={{
                        marginLeft: 20
                      }}
                    >
                      Shipments
                    </div>
                  </div>
                );
              },
              action: () => {
                history.push('/shipments');
              }
            });
            break;

          default:
            break;
        }
      });
      const landingPage = LocalCacheService.loadUserConfig().getLandingPage();
      const landingURL = landingPage !== '/' ? landingPage : `/${newTabStructure[0].id}`;
      setRedirectPath(landingURL); // RedirectPath is on temporary used before dashboard page is implemented
      setTabStructure(newTabStructure);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // RedirectPath is on temporary used before dashboard page is implemented
    <MainAppFeature render={<Routes redirectPath={redirectPath} />} tabStructure={tabStructure} />
  );
};

export default withRouter(MainApp);

MainApp.propTypes = {
  history: PropTypes.object.isRequired
};
