import { Generator } from '../../../utilities/generator';
// eslint-disable-next-line import/no-cycle
import { locationSchemaMap } from './location-resource-manager';
import { detectorSchemaMap } from './detector-resource-manager';
import { itemTypesArray } from './item-types-resource-manager';

const timeBefore = Generator.getRandomPastDate(18, 20).getTime();

export const assetDataArray = [
  {
    data: {
      name: 'Magentic Plug Weld Tool',
      identifier: '-Mag020'
    },
    ofType: {
      identifier: 'WT'
    },
    total: 8
  },
  {
    data: {
      name: 'Pliers',
      identifier: '-Med030'
    },
    ofType: {
      identifier: 'P'
    },
    total: 5
  },
  {
    data: {
      name: 'Weld Fixture S',
      identifier: '-S052'
    },
    ofType: {
      identifier: 'WF'
    },
    total: 10
  },
  {
    data: {
      name: 'Weld Fixture M',
      identifier: '-M051'
    },
    ofType: {
      identifier: 'WF'
    },
    total: 7
  },
  {
    data: {
      name: 'Weld Fixture L',
      identifier: '-L050'
    },
    ofType: {
      identifier: 'WF'
    },
    total: 3
  },
  {
    data: {
      name: 'Welding Repair Tool',
      identifier: '-Newa070'
    },
    ofType: {
      identifier: 'WR'
    },
    total: 12
  },
  {
    data: {
      name: 'Wire Dispenser',
      identifier: '-Speed040'
    },
    ofType: {
      identifier: 'WD'
    },
    total: 27
  },
  {
    data: {
      name: 'Milwaukee Angle Grinder',
      identifier: '-Mil010'
    },
    ofType: {
      identifier: 'G'
    },
    total: 4
  },
  {
    data: {
      name: 'Glass Polishing Tool',
      identifier: '-S062'
    },
    ofType: {
      identifier: 'GP'
    },
    total: 6
  },
  {
    data: {
      name: 'Hand Tool Die Grinder',
      identifier: '-Die011'
    },
    ofType: {
      identifier: 'G'
    },
    total: 5
  },
  {
    data: {
      name: 'Makita Angle Grinder',
      identifier: '-Mak012'
    },
    ofType: {
      identifier: 'G'
    },
    total: 2
  },
  {
    data: {
      name: 'Pneumatic Micro Grinder',
      identifier: '-Micro013'
    },
    ofType: {
      identifier: 'G'
    },
    total: 3
  },
  {
    data: {
      name: 'Portable Eletric Grinder',
      identifier: '-Port014'
    },
    ofType: {
      identifier: 'G'
    },
    total: 8
  },
  {
    data: {
      name: 'Sanding Pad',
      identifier: '-M081'
    },
    ofType: {
      identifier: 'SP'
    },
    total: 23
  }
];

export const orderDataArray = [
  {
    total: 18,
    hasOutput: [
      {
        quantity: Generator.getRandomNumber(1, 10),
        ofType: [
          {
            name: 'Brackets',
            identifier: 'BR115A4990',
            image_path: null
          }
        ],
        location: locationSchemaMap.weldingDepartment
      }
    ]
  },
  {
    total: 10,
    hasOutput: [
      {
        quantity: Generator.getRandomNumber(1, 10),
        ofType: [
          {
            name: 'Bearings',
            identifier: 'BEAR859B391',
            image_path: null
          }
        ],
        location: locationSchemaMap.weldingDepartment
      }
    ]
  },
  {
    total: 15,
    hasOutput: [
      {
        quantity: Generator.getRandomNumber(1, 10),
        ofType: [
          {
            name: 'Clamp',
            identifier: 'CL984756J3',
            image_path: null
          }
        ],
        location: locationSchemaMap.weldingDepartment
      },
      {
        quantity: Generator.getRandomNumber(10, 10),
        ofType: [
          {
            name: 'Collar',
            identifier: 'CO91857SV',
            image_path: null
          }
        ],
        location: locationSchemaMap.weldingDepartment
      }
    ]
  },
  {
    total: 12,
    hasOutput: [
      {
        quantity: Generator.getRandomNumber(1, 10),
        ofType: [
          {
            name: 'Hinges',
            identifier: 'HI10581AAE',
            image_path: null
          }
        ],
        location: locationSchemaMap.weldingDepartment
      }
    ]
  }
];

export const inventoryDataArray = [
  {
    location: locationSchemaMap.kentWarehouse,
    associatedWithEvent: [
      {
        total: Generator.getRandomNumber(1, 50),
        ofType: itemTypesArray.labCoatType,
        expiry: false
      },
      {
        total: 0,
        ofType: itemTypesArray.safetyGlassesType,
        expiry: false
      },
      {
        total: 18,
        ofType: itemTypesArray.weldinGogglesType,
        expiry: false
      },
      {
        total: 0,
        ofType: itemTypesArray.shoeCoversType,
        expiry: false
      },
      {
        total: Generator.getRandomNumber(1, 50),
        ofType: itemTypesArray.paintType,
        expiry: true
      },
      {
        total: 2,
        ofType: itemTypesArray.loctiteType,
        expiry: true
      },
      {
        total: Generator.getRandomNumber(1, 50),
        ofType: itemTypesArray.abrasivesType,
        expiry: true
      },
      {
        total: Generator.getRandomNumber(1, 50),
        ofType: itemTypesArray.sandingBeltType,
        expiry: false
      }
    ],
    hasDetector: [detectorSchemaMap.kentDetector]
  },
  {
    location: locationSchemaMap.auburnWarehouse,
    associatedWithEvent: [
      {
        total: Generator.getRandomNumber(1, 50),
        ofType: itemTypesArray.labCoatType,
        expiry: false
      },
      {
        total: Generator.getRandomNumber(1, 50),
        ofType: itemTypesArray.safetyGlassesType,
        expiry: false
      },
      {
        total: Generator.getRandomNumber(1, 50),
        ofType: itemTypesArray.weldinGogglesType,
        expiry: false
      },
      {
        total: Generator.getRandomNumber(1, 50),
        ofType: itemTypesArray.shoeCoversType,
        expiry: false
      },
      {
        total: Generator.getRandomNumber(1, 50),
        ofType: itemTypesArray.paintType,
        expiry: true
      },
      {
        total: 1,
        ofType: itemTypesArray.loctiteType,
        expiry: true
      },
      {
        total: 0,
        ofType: itemTypesArray.abrasivesType,
        expiry: true
      },
      {
        total: Generator.getRandomNumber(1, 50),
        ofType: itemTypesArray.sandingBeltType,
        expiry: false
      }
    ],
    hasDetector: [detectorSchemaMap.auburnDetector]
  }
];

export const orderRoute = [
  {
    class: 'AssociationManual',
    is_active: false,
    start_time: timeBefore,
    end_time: timeBefore,
    triggers: [],
    detectedAt: []
  },
  {
    class: 'Detection',
    is_active: false,
    start_time: Generator.getRandomPastDate(17, 15).getTime(),
    end_time: Generator.getRandomPastDate(14, 12).getTime(),
    triggers: [],
    detectedAt: [
      {
        ...locationSchemaMap.incomingDepartment,
        hasDetectors: [
          {
            actions: null
          }
        ]
      }
    ]
  },
  {
    class: 'Detection',
    is_active: false,
    start_time: Generator.getRandomPastDate(11, 9).getTime(),
    end_time: Generator.getRandomPastDate(8, 7).getTime(),
    triggers: [],
    detectedAt: [
      {
        ...locationSchemaMap.weldingDepartment,
        hasDetectors: [
          {
            actions: null
          }
        ]
      }
    ]
  },
  {
    class: 'Detection',
    is_active: false,
    start_time: Generator.getRandomPastDate(6, 4).getTime(),
    end_time: Generator.getRandomPastDate(3, 2).getTime(),
    triggers: [],
    detectedAt: [
      {
        ...locationSchemaMap.gPDepartment,
        hasDetectors: [
          {
            actions: null
          }
        ]
      }
    ]
  },
  {
    class: 'Detection',
    is_active: false,
    start_time: Generator.getRandomPastDate(2, 1).getTime(),
    end_time: undefined,
    triggers: [],
    detectedAt: [
      {
        ...locationSchemaMap.qualityInspectionDepartment,
        hasDetectors: [
          {
            actions: null
          }
        ]
      }
    ]
  }
];

export const assetRoute = [
  {
    class: 'AssociationManual',
    is_active: false,
    start_time: timeBefore,
    end_time: timeBefore,
    triggers: [],
    detectedAt: []
  },
  {
    class: 'Detection',
    is_active: false,
    start_time: Generator.getRandomPastDate(13, 11).getTime(),
    end_time: Generator.getRandomPastDate(10, 8).getTime(),
    triggers: [],
    detectedAt: [locationSchemaMap.weldingDepartment]
  },
  {
    class: 'Detection',
    is_active: false,
    start_time: Generator.getRandomPastDate(7, 5).getTime(),
    end_time: Generator.getRandomPastDate(4, 2).getTime(),
    triggers: [],
    detectedAt: [locationSchemaMap.gPDepartment]
  },
  {
    class: 'Detection',
    is_active: false,
    start_time: Generator.getRandomPastDate(0, 1).getTime(),
    end_time: undefined,
    triggers: [],
    detectedAt: [locationSchemaMap.qualityInspectionDepartment]
  }
];

export const inventoryRoute = {
  [locationSchemaMap.kentWarehouse.id]: {
    class: 'AssociationManual',
    is_active: false,
    start_time: Generator.getRandomPastDate(5, 12).getTime(),
    end_time: undefined,
    triggers: [],
    detectedAt: [locationSchemaMap.kentWarehouse]
  },
  [locationSchemaMap.auburnWarehouse.id]: {
    class: 'Detection',
    is_active: false,
    start_time: Generator.getRandomPastDate(5, 12).getTime(),
    end_time: undefined,
    triggers: [],
    detectedAt: [locationSchemaMap.auburnWarehouse]
  }
};
