import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { XemelgoService } from '../../services/XemelgoService';
import CrudTable from '../../components/Forms/CrudTable';
import BasicAddForm, { useInputField } from '../../components/Forms/BasicAddForm';
import SearchAndSubmit from '../../components/SearchAndSubmit';
import { StyledTextField } from '../../components/Forms/InputField';

import {
  isRequired,
  valueDoesNotExistinObjectSet,
  inputValidation
} from '../../components/Forms/Validation';

import PartCrudTableStyle from './css/PartCrudTable.module.css';

export default class PartCrudTable extends PureComponent {
  constructor(props) {
    super(props);

    const columnHeaders = [
      {
        title: 'Part Number',
        field: 'number',
        editComponent: (tableProp) => {
          const { partsSearchMap } = this.state;
          return (
            <SearchAndSubmit
              value={tableProp.value}
              searchMap={partsSearchMap}
              selectItem={(event) => this.partSearchHandler(event, tableProp)}
              searchStyle="fixed"
              showSearchList
              placeholder="Search for a part by # or name"
              postAction={(fn) => this.getPostAction(fn, tableProp)}
              containerStyle={{ width: 235 }}
              styledTextField={StyledTextField}
            />
          );
        },
        required: true
      },
      {
        title: 'Part Name',
        field: 'name',
        editable: 'never',
        required: true
      },
      { title: 'Quantity', field: 'qty', type: 'numeric', required: true }
    ];

    // Schema for parts table
    const partsFormController = {
      title: (
        <Fragment>
          <span>Parts</span>
          <span className={PartCrudTableStyle.crud_table_title_optional}> - optional</span>
        </Fragment>
      ),
      columnHeaders,
      data: props.partInfo,
      options: {
        search: false, // Remove table filter capability
        paging: false, // Remove paging ability
        actionsColumnIndex: columnHeaders.length, // Which column the actions will be displayed at
        maxBodyHeight: '22em',
        addRowPosition: 'first' // Where the add row will go (first or last)
      },
      localization: {
        body: {
          emptyDataSourceMessage: 'No parts to add' // Message shows when there are no parts
        }
      },
      containerStyle: PartCrudTableStyle.crud_table_container
    };

    // Schema for adding new parts
    const addPartFormController = {
      number: {
        render: useInputField,
        id: 'number',
        label: 'Part Number',
        value: '',
        valid: true,
        errorMessage: '',
        helperText: '',
        isRequired: true,
        validationRules: [
          isRequired,
          (data) => {
            const { partsList } = this.state;
            return valueDoesNotExistinObjectSet(data.value, partsList);
          }
        ]
      },
      name: {
        render: useInputField,
        id: 'name',
        label: 'Part Name',
        value: '',
        valid: true,
        errorMessage: '',
        helperText: '',
        isRequired: true,
        validationRules: [isRequired]
      }
    };

    this.state = {
      partsFormController,
      addPartFormController,
      showModal: false
    };
  }

  async componentDidMount() {
    const xemelgoClient = XemelgoService.getClient();
    const partTypeClient = await xemelgoClient.getItemTypeClient();

    const listPartTypeResponse = await partTypeClient.listItemTypes().then((result) => {
      return result.map((part) => {
        return {
          class: part.getClass(),
          identifier: part.getIdentifier(),
          name: part.getName(),
          id: part.getId()
        };
      });
    });

    const partsSearchMap = [];

    // Create search schema for SearchAndSubmit component
    listPartTypeResponse.forEach((object) => {
      partsSearchMap.push({
        id: object.id,
        key: [object.identifier, object.name],
        displayString: `Part Number: <span class='list-group-item-highlight'>${object.identifier}</span>, Part Name: <span class='list-group-item-highlight'>${object.name}</span>`
      });
    });

    this.setState({
      partsSearchMap,
      partsList: listPartTypeResponse
    });
  }

  componentDidUpdate(prevProps) {
    const { partInfo } = this.props;
    // Typical usage (don't forget to compare props):
    if (partInfo !== prevProps.partInfo) {
      if (partInfo.length === 0) {
        this.resetForm();
      }
    }
  }

  getPostAction = (fn, props) => {
    const { showModal } = this.state;
    return (
      <Fragment>
        Don&apos;t see what you&apos;re looking for?
        <button
          type="submit"
          className="add-new-item"
          onClick={() => {
            fn();
            this.setState({
              showModal: !showModal,
              rowChangeData: props.onRowDataChange
            });
          }}
        >
          <div>Add a new Part</div>
        </button>
      </Fragment>
    );
  };

  // *** Part Table Handlers *** //
  // When searched part type is seleted
  partSearchHandler = (id, props) => {
    const { partsList } = this.state;

    const foundPart = partsList.find((part) => {
      return part.id === id;
    });

    const data = {
      ...props.rowData,
      number: foundPart.identifier,
      name: foundPart.name,
      id: foundPart.id
    };

    props.onRowDataChange(data);
  };

  // For CRUD Table to add, edit, and remove row
  partListChangeHandler = (changeType, value) => {
    const { changeHandler } = this.props;
    const { partsFormController } = this.state;
    const data = [...partsFormController.data];

    let validChange = false;
    switch (changeType) {
      case 'add': {
        validChange = this.partListValidator(value);
        if (validChange) {
          const idx = data.findIndex((eachData) => eachData.id === value.id);
          if (idx === -1) {
            data.push(value);
          } else {
            // choice 1: data[idx].qty = parseInt(data[idx].qty) + parseInt(value.qty);
            data[idx].qty = value.qty; // choice 2
          }
          const updatedPartsFormController = {
            ...partsFormController,
            data
          };

          this.setState({
            partsFormController: updatedPartsFormController
          });
          changeHandler(data);
        }
        break;
      }
      case 'edit': {
        validChange = this.partListValidator(value.new);
        if (validChange) {
          const index = data.indexOf(value.old);
          data[index] = value.new;

          const updatedPartsFormController = {
            ...partsFormController,
            data
          };
          this.setState({
            partsFormController: updatedPartsFormController
          });

          changeHandler(data);
        }
        break;
      }
      case 'remove': {
        const index = data.indexOf(value);
        data.splice(index, 1);

        const updatedPartsFormController = {
          ...partsFormController,
          data
        };
        this.setState({
          partsFormController: updatedPartsFormController
        });
        changeHandler(data);
        break;
      }
      default: {
        console.log(`Unsupported Event[Name:${changeType}]`);
        break;
      }
    }

    return validChange;
  };

  // Handler for add new part type modal
  handleAddPartModalChange = (name, value) => {
    const { addPartFormController } = this.state;
    const updatePartFormControls = _.cloneDeep(addPartFormController);

    const updatedFormObject = {
      ...updatePartFormControls[name],
      value
    };

    updatePartFormControls[name] = updatedFormObject;

    this.setState({
      addPartFormController: updatePartFormControls
    });
  };

  // Handler for submitting new part type. Validates input
  handleAddNewPartSubmit = () => {
    const { addPartFormController, rowChangeData } = this.state;
    const data = {
      number: addPartFormController.number.value,
      name: addPartFormController.name.value,
      new: true
    };

    const updatedPartFormController = _.cloneDeep(addPartFormController);

    const validationResult = inputValidation(updatedPartFormController);
    this.setState({
      addPartFormController: updatedPartFormController
    });

    if (!validationResult.includes(false)) {
      rowChangeData(data);
      this.handleModalClose();
    }
  };

  // Validator when adding new part type
  partListValidator = (payload) => {
    const { partsFormController } = this.state;
    const results = partsFormController.columnHeaders.map((header) => {
      if (header.required) {
        return !!payload[header.field];
      }
      return false;
    });

    return !results.includes(false);
  };

  // Handle add new part type modal
  handleModalClose = () => {
    const { addPartFormController } = this.state;

    const updatedFormController = {};

    Object.keys(addPartFormController).forEach((key) => {
      const updatedFormEntry = addPartFormController[key];
      updatedFormController[key] = {
        ...updatedFormEntry,
        value: '',
        valid: true,
        errorMessage: ''
      };
    });

    this.setState({
      showModal: false,
      addPartFormController: updatedFormController
    });
  };

  // Modal display
  displayAddPartModal = () => {
    const { addPartFormController } = this.state;
    return (
      <Modal size="lg" show onHide={this.handleModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className={PartCrudTableStyle.add_order_part_modal_title}>
            Add New Part
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BasicAddForm
            formControls={addPartFormController}
            changeHandler={this.handleAddPartModalChange}
            formContainerStyle={PartCrudTableStyle.basic_form_row}
          />
        </Modal.Body>
        <Modal.Footer>
          <div id="footer-container">
            <div className={PartCrudTableStyle.add_new_part_footer_buttons}>
              <button type="button" className="cancel-button" onClick={this.handleModalClose}>
                Cancel
              </button>
              <button
                type="submit"
                className="default-button"
                onClick={() => this.handleAddNewPartSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  resetForm() {
    const { partsFormController } = this.state;
    this.setState({
      partsFormController: {
        ...partsFormController,
        data: []
      }
    });
  }

  render() {
    const { partConstraint, changeHandler } = this.props;
    const { partsFormController, showModal } = this.state;
    if (partConstraint.quantity === 'many') {
      return (
        <div>
          <CrudTable
            formController={partsFormController}
            changeHandler={this.partListChangeHandler}
          />
          {showModal && this.displayAddPartModal()}
        </div>
      );
    }
    return useInputField(
      {
        tabIndex: 1,
        id: 'number',
        label: 'Part Number',
        value: partsFormController.data.length ? partsFormController.data[0].number : '',
        valid: true,
        errorMessage: '',
        helperText: '',
        isRequired: false,
        validationRules: [
          isRequired,
          (data) => {
            const { partsList } = this.state;
            return valueDoesNotExistinObjectSet(data.value, partsList);
          }
        ]
      },
      (id, value) => {
        const { partsList = [] } = this.state;
        const existPart = partsList.find((a) => a.identifier === value);
        const data = [];
        if (value) {
          data.push({
            number: value,
            name: '',
            qty: 1,
            new: !existPart,
            id: existPart && existPart.id
          });
        }
        const updatedPartsFormController = {
          ...partsFormController,
          data
        };

        this.setState({
          partsFormController: updatedPartsFormController
        });
        changeHandler(data);
      }
    );
  }
}

PartCrudTable.propTypes = {
  partInfo: PropTypes.any.isRequired,
  changeHandler: PropTypes.func.isRequired
};
