import React, { PureComponent } from 'react';
import Style from './LoadingCircle.module.css';

export default class LoadingCircle extends PureComponent {
  render() {
    return (
      <div className={Style.container}>
        <div className={Style.lds_roller}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
}
