import React from 'react';
import TabbedSectionComponent from '../../components/vertical-tab-with-contents/TabbedSectionComponent';
import TabWithSwitchControlComponent from '../../components/vertical-tab-with-contents/TabWithSwitchControlComponent';
import './RuleConfigurationSection.css';
import AlertService, { ruleInfoMap, temporaryComponent } from '../../services/AlertService';
import { XemelgoService } from '../../services/XemelgoService';

function generateTabName(tabContent, className, setActiveComponentFn) {
  const tabName = tabContent.name;
  const { component } = tabContent;

  const id = `${tabContent.name}-subscription-switch`;
  return (
    <TabWithSwitchControlComponent
      key={id}
      tabName={tabName}
      toggleSwitchFn={tabContent.notification.toggleSubscribe}
      isOn={tabContent.notification.subscribed}
      enableRuleSubscription={tabContent.enableRuleSubscription}
      className={className}
      handleOnClick={() => setActiveComponentFn(tabName, component)}
    />
  );
}

export default class RuleConfigurationSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rules: props.rules,
      ruleNotificationMap: {}
    };
  }

  componentDidMount() {
    this.onLoad();
  }

  onLoad = () => {
    AlertService.fetchRules().then((result) => {
      const { ruleNotificationMap } = result;
      const { notificationFeatureEnabled } = result;

      const tabs = [];
      Object.keys(ruleNotificationMap).forEach((ruleName) => {
        const rule = ruleNotificationMap[ruleName];
        const renderComponent = ruleInfoMap[ruleName].component;
        const { enableRuleSubscription } = ruleInfoMap[ruleName];
        const tabContent = {
          name: ruleName,
          component: renderComponent
            ? renderComponent(
                rule.ruleId,
                rule.ruleConditions,
                result.locationDataList,
                result.itemTypeData || [],
                this.onLoad
              )
            : temporaryComponent,
          enableRuleSubscription,
          notification: {
            ruleId: rule.ruleId,
            available: notificationFeatureEnabled,
            subscribed: rule.enabled,
            eventId: rule.eventId,
            async toggleSubscribe(on) {
              const { ruleId } = rule;
              const RulePageClient = XemelgoService.getClient().getRulePageClient();
              if (on) await RulePageClient.subscribeForRule(ruleId);
              else await RulePageClient.unsubscribeForRule(ruleId);
              this.subscribed = rule.enabled;
            }
          }
        };
        tabs.push(tabContent);
      });
      this.setState({
        tabContents: tabs,
        notificationFeatureEnabled
      });
    });
  };

  render() {
    return (
      <div className="rule-configuration-section">
        <div className="title">
          <h2>Rules Configuration</h2>
          <div>Set up rules to send to notifications when an event has been triggered</div>
        </div>
        <TabbedSectionComponent
          tabContents={this.state.tabContents}
          className="rule-detail-tab"
          generateTabNameFn={generateTabName}
        />
      </div>
    );
  }
}
