const AWS = require('aws-sdk');
const UserPropertyMap = [
  {
    label: 'given_name',
    property: 'givenName'
  },
  {
    label: 'family_name',
    property: 'familyName'
  },
  {
    label: 'custom:tenantId',
    property: 'tenantId'
  },
  {
    label: 'custom:admin',
    property: 'isAdmin'
  },
  {
    label: 'custom:title',
    property: 'title'
  },
  {
    label: 'phone_number',
    property: 'phone'
  },
  {
    label: 'email',
    property: 'email'
  }
];

function fetchUsersRecursively(client, userPoolId, paginationToken) {
  const params = {
    UserPoolId: userPoolId /* required */,
    PaginationToken: paginationToken
  };

  const request = client.listUsers(params);
  return request.promise().then(async result => {
    const paginationToken = result.PaginationToken;

    let users = [...result.Users];
    if (paginationToken) {
      const moreUsers = await fetchUsersRecursively(client, userPoolId, paginationToken);
      users = [...users, ...moreUsers];
    }

    return users;
  });
}

function fetchUsersInGroupRecursively(client, userPoolId, group, nextToken) {
  const params = {
    UserPoolId: userPoolId /* required */,
    GroupName: group,
    NextToken: nextToken
  };

  const request = client.listUsersInGroup(params);
  return request.promise().then(async result => {
    const nextToken = result.NextToken;

    let users = [...result.Users];
    if (nextToken) {
      const moreUsers = fetchUsersInGroupRecursively(client, userPoolId, group, nextToken);
      users = [...users, ...moreUsers];
    }

    return users;
  });
}

function getVerifyMethodAttribute(verifyMethod) {
  if (!verifyMethod) {
    verifyMethod = 'email';
  }

  let attribute = null;
  switch (verifyMethod) {
    case 'email':
      attribute = {
        Name: 'email_verified',
        Value: 'True'
      };
      break;
    case 'phone':
      attribute = {
        Name: 'phone_number_verified',
        Value: 'True'
      };
      break;
    default:
      attribute = null;
  }

  return attribute;
}

function buildCreateUserParams(payload, userPoolId) {
  let userAttributes = [];

  UserPropertyMap.forEach(object => {
    if(payload[object.property])
      userAttributes.push({ Name: object.label, Value: payload[object.property]});
  });

  const { verifyMethod, username } = payload;

  const verifyMethodAttribute = getVerifyMethodAttribute(payload.verifyMethod);
  userAttributes.push(verifyMethodAttribute);

  // desiredDeliveryMedium is either 'EMAIL' or 'SMS'
  let desiredDeliveryMedium = verifyMethod.toUpperCase();
  if (verifyMethod === 'phone'){
      desiredDeliveryMedium = 'SMS';
  }

  const params = {
      UserPoolId: userPoolId, /* required */
      Username: username, /* required */
      DesiredDeliveryMediums: [desiredDeliveryMedium],
      UserAttributes: userAttributes
  };

  return params;

}

class UserService {
  client = null;

  config = null;

  /**
   *
   * @param config
   *   {
   *       region: <region>,
   *       userPoolId: <cognito-userpool-id>,
   *       credentials: {
   *           accessKeyId: <access-key-id>,
   *           secretAccessKey: <secret-key>,
   *           sessionToken: <session-token>
   *       }
   *
   *   }
   */
  configure(config) {
    const credentials = config.credentials;
    AWS.config.update({
      credentials: new AWS.Credentials(
        credentials.accessKeyId,
        credentials.secretAccessKey,
        credentials.sessionToken
      )
    });

    const client = new AWS.CognitoIdentityServiceProvider({ region: config.region });

    this.client = client;
    this.config = config;
  }

  async getUsers() {
    const userPoolId = this.config.userPoolId;
    const users = await fetchUsersRecursively(this.client, userPoolId);

    return users;
  }

  async getUsersInGroup(group) {
    const userPoolId = this.config.userPoolId;
    const users = await fetchUsersInGroupRecursively(this.client, userPoolId, group);

    return users;
  }

  /**
   * Create new user
   * @param payload
   * {
   *     username: string,
   *     email: string (optional if phone is provided),
   *     phone: string (optional if email is provided),
   *     verifyMethod: ['email'|'sms']    //default 'email'
   *     givenName: string,
   *     familyName: string,
   *     title: string - optional
   * }
   * @return {Promise<void>}
   */
  /* var params = {
GroupName: 'STRING_VALUE', -- required
UserPoolId: 'STRING_VALUE', -- required
Username: 'STRING_VALUE' -- required
};
cognitoidentityserviceprovider.adminAddUserToGroup(params, function(err, data) {
if (err) console.log(err, err.stack);  an error occurred
else     console.log(data);            successful response
}); after we create the user , then need to use this function to put the user in the superadmin group
we do this by adding the isAdmin parameter, to do this you would need to have if statement that calls make admin function here in the
create user page, here is where the request would be made, probably wouldn't modify the create user method */
  async makeAdmin(username) {
    const userPoolId = this.config.userPoolId;
    const params = {
      GroupName: 'SuperAdmin',
      Username: username,
      UserPoolId: userPoolId
    };
    const request = this.client.adminAddUserToGroup(params);
    return request.promise();
  }

  /*
   * Function to update user's custom attribute 'custom:admin' to 'true'.
   * Not currently being used but can be used when feature to provide admin
   * privileges to an existing user is implemtend. Params ->
   *  {
   *    "UserAttributes": [
   *       {
   *          "Name": "string",
   *          "Value": "string"
   *       }
   *    ],
   *    "Username": "string",
   *    "UserPoolId": "string"
   *  }
   */

  addAdminPrivliege(username) {
    const userPoolId = this.config.userPoolId;
    const params = {
      UserAttributes:[
        {
          'Name': 'custom:admin',
          'Value': 'true' // this is of type 'string' not 'boolean' since boolean isn't supported for custom attributes
        }
      ],
      Username: username,
      UserPoolId: userPoolId
    };
    const request = this.client.AdminUpdateUserAttributes(params);
    return request.promise();
  }

  /*
   * function used to add a newly created user to the respective tenant group
   */
  addUserToTenantGroup(tenantId, username) {
    const userPoolId = this.config.userPoolId;
    const params = {
      GroupName: tenantId,
      Username: username,
      UserPoolId: userPoolId
    };
    const request = this.client.adminAddUserToGroup(params);
    return request.promise();
  }

  async createUser(payload) {
    const userPoolId = this.config.userPoolId;
    const params = buildCreateUserParams(payload, userPoolId);
    const request = this.client.adminCreateUser(params);
    return request.promise();
  }

  /**
   * Delete existing user
   * @param username
   * @return {Promise<*>}
   */
  async deleteUser(username) {
    const userPoolId = this.config.userPoolId;

    const params = {
      UserPoolId: userPoolId /* required */,
      Username: username /* required */
    };

    const request = this.client.adminDeleteUser(params);
    return request.promise();
  }

  getUserId(user) {
    return this.getAttribute(user, 'sub');
  }

  getEmail(user) {
    return this.getAttribute(user, 'email');
  }

  getFullName(user) {
    return `${this.getAttribute(user, 'given_name')} ${this.getAttribute(user, 'family_name')}`;
  }

  getUsername(user) {
    return user.Username;
  }

  getAttribute(user, attribute) {
    const foundAttriubte = user.Attributes.find(obj => {
      return obj.Name === attribute;
    });
    return foundAttriubte && foundAttriubte.Value;
  }
}

export default new UserService();
