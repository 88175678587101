import { AbstractResourceManager } from '../../abstract-resource-manager';

// eslint-disable-next-line import/prefer-default-export
class StockItemResourceManager extends AbstractResourceManager {
  initialize() {
    const now = Date.now();
    this.addItem({
      order_number: `Order-${now}`,
      vendor: 'Loco',
      size: 'L',
      stock_item_name: `Gloves`
    });
  }

  constructor() {
    super('stock_item_id');
    this.initialize();
  }
}

const resourceManager = new StockItemResourceManager();
export { resourceManager };
