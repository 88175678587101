import React, { Fragment } from 'react';

import GridCardStyle from './css/GridCard.module.css';

export default function GridCard({ topComponent, bottomComponent, id, style, onClick = null, solution}) {
  let cardStyle = `${GridCardStyle.card_container}`;
  let lineStyle = `${GridCardStyle.horizontal_line2}`;
  let bottomStyle = `${GridCardStyle.bottom} ${GridCardStyle.flex_direction_row}`;
  if(solution==='inventory') {
    cardStyle += ` ${GridCardStyle.progress_bar_container}`;
    lineStyle += ` ${GridCardStyle.progress_bar_line}`;
    bottomStyle += ` ${GridCardStyle.progress_bar_bottom}`;
  }
  return (
    <div
      role="button"
      id={id}
      className={cardStyle}
      style={style}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={-1}
    >
      <div className={GridCardStyle.top}>{topComponent}</div>
      {bottomComponent && renderBottomComponent(bottomComponent, lineStyle, bottomStyle)}
    </div>
  );
}

const renderBottomComponent = (bottomComponent, lineStyle, bottomStyle) => (
  <Fragment>
    <hr className={lineStyle} />
    <div className={bottomStyle}>
      {bottomComponent}
    </div>
  </Fragment>
);
