import React, { Component } from 'react';

export default class MissingAssetRuleContent extends Component {
  render() {
    return (
      <div>
          <p className="tabbedSectionComponent-contentTitle">Missing Asset</p>
          <p style={{ color: '#343434' }}>
            Sends a notification when an asset is marked as missing or a missing asset is marked as
            found.
          </p>
      </div>
    );
  }
}
