import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const ModalForm = ({ title, body, footer, show, size, className }) => {
  return (
    <Modal show={show} backdrop="static" size={size} dialogClassName={className}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};

ModalForm.defaultProps = {
  size: null,
  className: ''
};

ModalForm.propTypes = {
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  className: PropTypes.string
};
