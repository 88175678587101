import { AbstractResourceManager } from '../../abstract-resource-manager';
import { ResourceManagerLoader } from '../resource-manager-loader';
import { locationSchemaMap } from './location-resource-manager';
import { itemTypesArray } from './item-types-resource-manager';

const ruleResponse = () => [
  {
    name: 'Missing Asset',
    includesConditions: [
      {
        id: '8ab86b17-cecd-aede-44b5-a74690dba500',
        name: 'asset_missing',
        tags: {},
        hasSubscriptions: [],
        conditions: [
          {
            property: 'state',
            op: '=',
            value: 'missing'
          }
        ]
      },
      {
        id: '16b86b17-cf7b-3d5a-2fbb-7732c607d9fe',
        name: 'asset_found',
        tags: {},
        hasSubscriptions: [],
        conditions: [
          {
            property: 'state',
            op: '=',
            value: 'found'
          }
        ]
      }
    ],
    hasSubscriptions: [
      {
        emails: ['francis.wu@xemelgo.com'],
        sms: []
      }
    ]
  },
  {
    name: 'Low Stock',
    includesConditions: [
      {
        tags: {
          threshold: 19,
          itemType: itemTypesArray.labCoatType.name
        },
        hasSubscriptions: []
      },
      {
        tags: {
          threshold: 15,
          itemType: itemTypesArray.safetyGlassesType.name
        },
        hasSubscriptions: []
      },
      {
        tags: {
          threshold: 11,
          itemType: itemTypesArray.weldinGogglesType.name
        },
        hasSubscriptions: []
      },
      {
        tags: {
          threshold: 4,
          itemType: itemTypesArray.shoeCoversType.name
        },
        hasSubscriptions: []
      },
      {
        tags: {
          threshold: 6,
          itemType: itemTypesArray.paintType.name
        },
        hasSubscriptions: []
      },
      {
        tags: {
          threshold: 10,
          itemType: itemTypesArray.loctiteType.name
        },
        hasSubscriptions: []
      },
      {
        tags: {
          threshold: 13,
          itemType: itemTypesArray.abrasivesType.name
        },
        hasSubscriptions: []
      },
      {
        tags: {
          threshold: 5,
          itemType: itemTypesArray.sandingBeltType.name
        },
        hasSubscriptions: []
      }
    ],
    hasSubscriptions: [
      {
        emails: ['francis.wu@xemelgo.com'],
        sms: []
      }
    ]
  },
  {
    name: 'Backlog',
    includesConditions: [
      {
        tags: {
          criticalThreshold: 16,
          threshold: 16,
          locationName: locationSchemaMap.weldingDepartment.name,
          locationId: '16b77b94-4a60-fe50-4d48-e579f4bee73a'
        },
        hasSubscriptions: []
      },
      {
        tags: {
          stage: 'warning',
          criticalThreshold: 14,
          threshold: 12,
          warningThreshold: 10,
          locationName: locationSchemaMap.gPDepartment.name,
          locationId: 'f6b77b94-88c8-68e1-1865-22e335f02174'
        },
        hasSubscriptions: []
      },
      {
        tags: {
          stage: 'critical',
          criticalThreshold: 14,
          threshold: 14,
          locationName: locationSchemaMap.gPDepartment.name,
          locationId: 'f6b77b94-88c8-68e1-1865-22e335f02174'
        },
        hasSubscriptions: []
      },
      {
        tags: {
          stage: 'warning',
          criticalThreshold: 20,
          threshold: 20,
          warningThreshold: 15,
          locationName: locationSchemaMap.incomingDepartment.name,
          locationId: '0eb77b94-73ee-5ead-fe5a-43a6d33c54c6'
        },
        hasSubscriptions: []
      },
      {
        tags: {
          stage: 'critical',
          criticalThreshold: 20,
          threshold: 20,
          locationName: locationSchemaMap.incomingDepartment.name,
          locationId: '0eb77b94-73ee-5ead-fe5a-43a6d33c54c6'
        },
        hasSubscriptions: []
      },
      {
        tags: {
          stage: 'warning',
          criticalThreshold: 15,
          threshold: 15,
          warningThreshold: 12
        },
        hasSubscriptions: [
          {
            emails: ['francis.wu@xemelgo.com'],
            sms: []
          }
        ]
      },
      {
        tags: {
          stage: 'critical',
          criticalThreshold: 15,
          threshold: 15
        },
        hasSubscriptions: [
          {
            emails: ['francis.wu@xemelgo.com'],
            sms: []
          }
        ]
      },
      {
        tags: {
          stage: 'warning',
          criticalThreshold: 16,
          threshold: 16,
          warningThreshold: 12,
          locationName: locationSchemaMap.weldingDepartment.name,
          locationId: '16b77b94-4a60-fe50-4d48-e579f4bee73a'
        },
        hasSubscriptions: []
      },
      {
        tags: {
          stage: 'warning',
          criticalThreshold: 16,
          threshold: 16,
          warningThreshold: 12,
          locationName: locationSchemaMap.qualityInspectionDepartment.name,
          locationId: 'ecb77b94-63f8-5749-527b-e131f1d6a69e'
        },
        hasSubscriptions: []
      },
      {
        tags: {
          stage: 'critical',
          criticalThreshold: 16,
          threshold: 16,
          locationName: locationSchemaMap.qualityInspectionDepartment.name,
          locationId: 'ecb77b94-63f8-5749-527b-e131f1d6a69e'
        },
        hasSubscriptions: []
      }
    ],
    hasSubscriptions: []
  },
  {
    name: 'Idle',
    includesConditions: [
      {
        id: 'feb7d596-17ff-90f5-0f49-a7ad1b6234bc',
        name: 'idle_allLocations',
        tags: {
          duration: 4
        },
        hasSubscriptions: []
      }
    ],
    hasSubscriptions: []
  }
];

// eslint-disable-next-line import/prefer-default-export
export class RuleResourceManager extends AbstractResourceManager {
  constructor() {
    super('id');
    this.ruleIdToConditionIdsMap = {};
  }

  supportMock(api, action, payload, headers) {
    return super.supportMock(api, action, payload, headers);
  }

  createRule(payload) {
    const { fields: item } = payload;
    return this.addItem(item);
  }

  registerRuleCondition(ruleId, conditionId) {
    const conditionIds = this.ruleIdToConditionIdsMap[ruleId] || [];
    conditionIds.push(conditionId);

    this.ruleIdToConditionIdsMap[ruleId] = conditionIds;
  }

  list() {
    // const rules = super.list();
    // const context = this;
    // const ruleConditionRM = ResourceManagerLoader.get('rule-condition');
    // const transformedRules = rules.map((rule) => {
    //   const conditionIds = context.ruleIdToConditionIdsMap[rule.id] || [];
    //   const conditions = conditionIds
    //     .map((conditionId) => {
    //       if (ruleConditionRM) {
    //         return ruleConditionRM.get(conditionId);
    //       }
    //       return null;
    //     })
    //     .filter((condition) => condition !== null);

    //   const clonedRule = { ...rule };
    //   clonedRule.includesConditions = conditions;
    //   return clonedRule;
    // });

    return ruleResponse();
  }
}

const resourceManager = new RuleResourceManager();
export { resourceManager };
