import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogTitle} from '@material-ui/core';

const BasicDialog = ({isOpen, title, bodyComponent, onClose, showCloseButton = true}) => {
  const [open, setOpen] = React.useState(false);

  useEffect( () => {
    setOpen(isOpen);
  }, [isOpen]); 

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleOnClick = () => {
    // setOpen(false);
    onClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <React.Fragment>
          {bodyComponent}
        </React.Fragment>
        {showCloseButton && (
          <DialogActions>
            <Button onClick={handleOnClick} color="primary"> Close </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

export default BasicDialog;

BasicDialog.propTypes = {
  title: PropTypes.string.isRequired,
  bodyComponent: PropTypes.element.isRequired,
  onClose: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired
};
