import React, { Fragment } from 'react';
import { Redirect } from 'react-router';
import { Card, CardBody } from 'mdbreact';
import { Alert } from 'react-bootstrap';
import './CreateUser.css';
import './UsersPage.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import UserBasePage from './UserBasePage';
import UserService from '../../services/UserService';
import ProfileForm from '../../components/ProfileForm';
import { LocalCacheService } from '../../services/local-cache-service';

export default class CreateUser extends UserBasePage {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      phone: null,
      givenName: '',
      familyName: '',
      switchPage: false,
      newUser: undefined,
      initialized: false,
      touched: {
        givenName: false,
        familyName: false,
        username: false,
        email: false,
        phone: false
      },
      createUserError: false,
      createUserErrorMessage: '',
      isValidPhoneNumber: true,
      isAdmin: false
    };
  }

  switchPages = () => {
    this.setState({
      switchPage: true
    });
  };

  handleChange = (id, value) => {
    this.setState({
      [id]: value,
      clean: false
    });
  };

  handleBlur = (field) => {
    const touched = { ...this.state.touched };
    touched[field] = true;
    this.setState({ touched });
  };

  canBeSubmitted = () => {
    const errors = this.validateCreateUserForm();
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return !isDisabled;
  };

  shouldMarkError = (field) => {
    const errors = this.validateCreateUserForm();
    const hasError = errors[field];
    const shouldShow = this.state.touched[field];
    return hasError && shouldShow;
  };

  validatePhoneNumber = () => {
    const { phone } = this.state;
    this.handleBlur('phone');
    let valid = true;
    if (phone) {
      valid = isValidPhoneNumber(phone);
    }
    this.setState({ isValidPhoneNumber: valid });
    return valid;
  };

  validateCreateUserForm = () => {
    const { givenName, familyName, email, phone, isValidPhoneNumber } = this.state;
    return {
      givenName: givenName.length === 0,
      familyName: familyName.length === 0,
      username:
        email.length === 0 ||
        !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/g.test(email),
      email:
        email.length === 0 ||
        !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/g.test(email),
      phone: phone && !isValidPhoneNumber
    };
  };

  componentDidMount() {
    const context = this;
    super.initializeUserService(UserService).then((_) => {
      // get details of user logged in
      const userProfile = LocalCacheService.loadUserProfile();
      const tenantId = userProfile.getTenantId();
      context.setState({ initialized: true, tenantId });
    });
  }

  /**
   * * {
   *     username: string,
   *     email: string (optional if phone is provided),
   *     phone: string (optional if email is provided),
   *     verifyMethod: ['email'|'sms']    //default 'email'
   *     givenName: string,
   *     familyName: string,
   *     title: string - optional
   *     tenantId: string - required (inherited from current user)
   * }
   * @param event
   * @return {Promise<void>}
   */
  handleSubmit = async (event) => {
    event.preventDefault();
    /* username is not exposed to the user but is a required property for Cognito
     * for user creation. username in this case is the same as email since that
     * is what users will use to login.
     */
    const { email, phone, givenName, familyName, isAdmin, tenantId } = { ...this.state };
    const username = email;

    const payload = {
      username: username.toLowerCase(),
      email: email.toLowerCase(),
      phone,
      verifyMethod: 'email', // change to 'sms' if we support verify through text message
      givenName,
      familyName,
      /* isAdmin has to be string 'true' and not boolean since only string and
       * number are supported by Cognito for custom attributes
       */
      isAdmin: isAdmin ? 'true' : 'false',
      tenantId
    };

    const context = this;
    UserService.createUser(payload).then(
      (_) => {
        context.setState({
          username: '',
          email: '',
          phone: null,
          givenName: '',
          familyName: '',
          newUser: username,
          touched: {
            givenName: false,
            familyName: false,
            username: false,
            email: false
          },
          createUserError: false,
          createUserErrorMessage: '',
          clean: true
        });
        // required - tenantId, username
        UserService.addUserToTenantGroup(tenantId, username);
      },
      (err) => {
        err.message += '! Please try a different one.';
        this.setState({ createUserError: true, createUserErrorMessage: err.message });
      }
    );
  };

  createUserSuccessBanner = () => {
    const { newUser } = this.state;
    return (
      <Alert
        className="add-user-alerts"
        variant="success"
        onDismiss={this.handleDismiss}
        dismissible
      >
        <h4>Success!</h4>
        <p>{`User ${newUser} has been created!`}</p>
      </Alert>
    );
  };

  handleDismiss = () => {
    this.setState({ newUser: undefined });
  };

  renderCreateUserErrorMessage = () => {
    const { createUserError, createUserErrorMessage } = this.state;
    if (createUserError) {
      return (
        <div className="error-message-group">
          <p className="error-message-text">{createUserErrorMessage}</p>
        </div>
      );
    }
    return null;
  };

  render() {
    const { clean, switchPage, newUser } = this.state;
    if (switchPage) {
      return (
        <Redirect
          push
          to={{
            pathname: '/users'
          }}
        />
      );
    }

    const style = {
      width: '10px',
      verticalAlign: 'baseline'
    };
    return (
      <Fragment>
        {newUser && this.createUserSuccessBanner()}
        <Card className="content_holder">
          <div className="back-button">
            <p onClick={this.switchPages} style={{ width: 'fit-content', cursor: 'pointer' }}>
              <img src={require('../../img/More.png')} alt="more" style={style} /> Back
            </p>
          </div>
          <Card className="add-user">
            <div className="users-title">Add User</div>
            <CardBody>
              {this.renderCreateUserErrorMessage()}
              <ProfileForm
                clean={clean}
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                shouldMarkError={this.shouldMarkError}
                validatePhoneNumber={this.validatePhoneNumber}
                create
              />
              <button
                disabled={!this.canBeSubmitted()}
                onClick={this.handleSubmit}
                className="add-user-button"
              >
                Create
              </button>
            </CardBody>
          </Card>
        </Card>
      </Fragment>
    );
  }
}
