import React, { Fragment } from 'react';
import Style from './css/GridView.module.css';

export default class GridView extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props !== nextProps;
  }

  render() {
    const { dataList, renderComponent } = this.props;
    if (!dataList.length) {
      return <div className={Style.empty_data_message}>No Items</div>;
    }
    return (
      <Fragment>
        {dataList && (
          <div className={Style.grid_view}>{dataList.map(each => renderComponent(each))}</div>
        )}
      </Fragment>
    );
  }
}
