const getTierOneLocationConfiguration = ({
  categoryName = 'Plant',
  categoryDisplayName = 'Plant',
  categoryGroupDisplayName = 'Plants',
  canAttachDetector = false,
  propertyOrders = ['name', 'description']
}) => {
  return {
    category: {
      name: categoryName,
      displayName: categoryDisplayName,
      groupDisplayName: categoryGroupDisplayName
    },
    canAttachDetector,
    propertyOrders,
    properties: {
      name: {
        __addable: true,
        displayName: `${categoryDisplayName} Name`
      },
      description: {
        __addable: true,
        optional: true,
        displayName: `${categoryDisplayName} Description`
      }
    }
  };
};

const getTierTwoLocationConfiguration = ({
  categoryName = 'Department',
  categoryDisplayName = 'Department',
  categoryGroupDisplayName = 'Departments',
  canAttachDetector = false,
  propertyOrders = ['parentId', 'name', 'description'],
  locatedInModel = 'plant',
  overrideProperties = {}
}) => {
  return {
    category: {
      name: categoryName,
      displayName: categoryDisplayName,
      groupDisplayName: categoryGroupDisplayName
    },
    locatedInModel,
    canAttachDetector,
    propertyOrders,
    properties: {
      ...{
        parentId: {
          __addable: true,
          displayName: `Located In`,
          input: []
        },
        name: {
          __addable: true,
          displayName: `${categoryDisplayName} Name`
        },
        description: {
          __addable: true,
          optional: true,
          displayName: `${categoryDisplayName} Description`
        }
      },
      ...overrideProperties
    }
  };
};

const modelMap = {
  facility: getTierOneLocationConfiguration({
    categoryName: 'Facility',
    categoryDisplayName: 'Facility',
    categoryGroupDisplayName: 'Facilities',
    canAttachDetector: false
  }),
  plant: getTierOneLocationConfiguration({
    categoryName: 'Plant',
    categoryDisplayName: 'Plant',
    categoryGroupDisplayName: 'Plants',
    canAttachDetector: false
  }),
  HiscoCustomer: getTierOneLocationConfiguration({
    categoryName: 'Customer',
    categoryDisplayName: 'Customer',
    categoryGroupDisplayName: 'Customers',
    canAttachDetector: false
  }),
  HiscoPlant: getTierTwoLocationConfiguration({
    categoryName: 'Plant',
    categoryDisplayName: 'Plant',
    categoryGroupDisplayName: 'Plants',
    locatedInModel: 'HiscoCustomer',
    canAttachDetector: false
  }),
  demoDepartment: getTierTwoLocationConfiguration({
    categoryName: 'Department',
    categoryDisplayName: 'Department',
    categoryGroupDisplayName: 'Departments',
    locatedInModel: 'facility',
    canAttachDetector: true
  }),
  department: getTierTwoLocationConfiguration({
    categoryName: 'Department',
    categoryDisplayName: 'Department',
    categoryGroupDisplayName: 'Departments',
    locatedInModel: 'plant',
    canAttachDetector: true,
    overrideProperties: {
      parentId: {
        displayName: 'Plant Name',
        options: [],
        optionsProvided: true,
        __addable: true
      }
    }
  }),
  HiscoStockRoom: getTierTwoLocationConfiguration({
    categoryName: 'StockRoom',
    categoryDisplayName: 'Stock Room',
    categoryGroupDisplayName: 'Stock Rooms',
    parentLocationCategoryName: 'Plant',
    parentLocationCategoryDisplayName: 'Plant',
    parentLocationCategoryGroupDisplayName: 'Plants',
    canAttachDetector: true
  }),
  zone: getTierTwoLocationConfiguration({
    categoryName: 'Zone',
    categoryDisplayName: 'Zone',
    categoryGroupDisplayName: 'Zones',
    parentLocationCategoryName: 'Department',
    parentLocationCategoryDisplayName: 'Department',
    parentLocationCategoryGroupDisplayName: 'Departments',
    canAttachDetector: true
  }),
  detector: {
    displayName: 'Detector',
    groupDisplayName: 'Detectors',
    propertyOrders: ['vid', 'class'],
    properties: {
      vid: {
        __addable: true,
        optional: true,
        optionallyDependsOn: ['class'],
        displayName: 'Detector #'
      },
      class: {
        __addable: true,
        optional: true,
        optionallyDependsOn: ['vid'],
        displayName: 'Detector Type',
        options: [
          { key: 'Handheld', value: 'Handheld Reader' },
          {
            key: 'Mounted',
            value: 'Mounted Reader',
            properties: {
              action: {
                displayName: 'Detector Actions',
                options: [
                  {
                    key: 'default_mounted_reader',
                    value: 'Default'
                  },
                  {
                    key: 'endTrackingSession',
                    value: 'Mark as Complete'
                  },
                  {
                    key: 'sameDetectorEndTrackingSession',
                    value: 'Entry / Exit doorway'
                  }
                ]
              }
            }
          }
        ]
      },
      vendor: {
        displayName: 'Vendor'
      },
      location: {
        __addable: true,
        __updatable: true,
        displayName: 'Location',
        options: [],
        optionsProvided: true,
        optional: true
      }
    }
  }
};

export const DefaultConfiguration = {
  modelMap,
  featureArgumentsMap: {
    addResource: {
      location: 'department'
    },
    editResource: {
      location: 'department'
    }
  },
  featureMap: {
    resourceDetailView: {
      propertyOrders: ['vid', 'class', 'vendor']
    },
    addResource: {
      modelMap: {
        detector: {
          __ref__: 'detector',
          override: {
            properties: {
              vid: {
                optional: false
              },
              class: {
                optional: false
              },
              location: {
                displayName: 'Location',
                __addable: true,
                optional: true,
                options: [],
                optionsProvided: true
              }
            }
          }
        }
      },
      propertyOrders: ['location', 'vid', 'class']
    },
    editResource: {
      modelMap: {
        detector: {
          __ref__: 'detector',
          override: {
            properties: {
              location: {
                name: 'locationId',
                __updatable: true,
                displayName: 'Location',
                options: [],
                optionsProvided: true
              },
              vid: {
                optional: false
              },
              class: {
                optional: false
              }
            }
          }
        }
      },
      propertyOrders: ['location', 'vid', 'class']
    }
  }
};
