// eslint-disable-next-line import/prefer-default-export
export class AbstractApi {
  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  canMock(payload, headers, method, apiSegments) {
    return true;
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  execute(payload, headers, method, apiSegments) {
    return null;
  }
}
