import React from 'react';
import Loading from 'components/loading/LoadingWord';
import LowStockRuleContent from '../pages/alert/LowStockRuleContent';
import BacklogRuleContent from '../pages/alert/BacklogRuleContent';
import IdleRuleContent from '../pages/alert/IdleRuleContent';
import MissingAssetRuleContent from '../pages/alert/MissingAssetRuleContent';

// import OverstockRuleContent from '../pages/alert/OverstockRuleContent';
// import ExpeditedRuleContent from '../pages/alert/ExpeditedRuleContent';
// import ArrivedAtRuleContent from '../pages/alert/ArrivedAtRuleContent';
import { XemelgoService } from './XemelgoService';
import ConfigurationService from './ConfigurationService';
import AuthService from './AuthService';

export const temporaryComponent = () => (
  <div>
    Under Construction
    <Loading />
  </div>
);

// eslint-disable-next-line no-template-curly-in-string
const lowStockRuleTemplate =
  'Stock count for ${this.itemType} is low at ${this.locationName}. You have ${this.itemTypeCount} left in stock, which is below the threshold of ${this.threshold}.';
// // eslint-disable-next-line no-template-curly-in-string
const lowStockRuleTemplateEmail =
  'Stock count for <strong>${this.itemType}</strong> is low at ${this.locationName}. You have <strong>${this.itemTypeCount}</strong> left in stock, which is below the threshold of <strong>${this.threshold}</strong>.';
// // eslint-disable-next-line no-template-curly-in-string
// const OverstockRuleTemplate =
//   'Stock count for ${this.object_name} is high. You have ${this.value} in stock, which is above the threshold of ${this.threshold_value}.';
// // eslint-disable-next-line no-template-curly-in-string
// const OverstockRuleTemplateEmail =
//   'Stock count for <strong>${this.object_name}</strong> is high. You have <strong>${this.value}</strong> in stock, which is above the threshold of <strong>${this.threshold_value}</strong>.';
// // eslint-disable-next-line no-template-curly-in-string
// const expeditedRuleTemplate = '${this.object_name} has been expedited.';
// // eslint-disable-next-line no-template-curly-in-string
// const orderArrivedAtRuleTemplate =
//   'Order ${this.tracked_object.object_name} has arrived at ${this.department.department_name} department.';
// eslint-disable-next-line no-template-curly-in-string
const backlogRuleTemplate =
  'Order backlog at location ${this.locationName} has exceeded ${this.stage} backlog threshold of ${this.threshold} order(s). Current backlog: ${this.backlogCount} order(s)';
// eslint-disable-next-line no-template-curly-in-string
const backlogRuleEmailTemplate =
  'Order backlog at location <strong>${this.locationName}</strong> has exceeded a <strong>${this.stage}</strong> backlog threshold of <strong>${this.threshold} order(s)</strong>. Current backlog: <strong>${this.backlogCount} order(s)</strong>.';
// eslint-disable-next-line no-template-curly-in-string
const idleRuleTemplate =
  'Order#: ${this.orderNumber} has been at location ${this.locationName} for more than ${this.duration} hours.';
// eslint-disable-next-line no-template-curly-in-string
const idleRuleEmailTemplate =
  'Order#: <strong>${this.orderNumber}</strong> has been at location <strong>${this.locationName}</strong> for more than <strong>${this.duration}</strong> hours.';
// asset rules template
const missingRuleTemplate =
  'Asset#: ${this.assetNumber} has been marked as ${this.state.toUpperCase()}${this.location && (" at " + this.location)}.';
const missingRuleEmailTemplate =
  'Asset#: <strong>${this.assetNumber}</strong> has been marked as <strong>${this.state.toUpperCase()}</strong>${this.location && " at "}<strong>${this.location}</strong>.';

export const ruleInfoMap = {
  // 'Low Stock': {
  //   eventId: 'track-object-type-count-updated',
  //   notification_template: {
  //     email: lowStockRuleTemplateEmail,
  //     sms: lowStockRuleTemplate,
  //     alert: lowStockRuleTemplate
  //   },
  //   component: ruleId => {
  //     return () => <LowStockRuleContent ruleId={ruleId} />;
  //   }
  // },
  Backlog: {
    eventId: 'detection_event',
    notification_template: {
      email: backlogRuleEmailTemplate,
      sms: backlogRuleTemplate,
      alert: backlogRuleTemplate
    },
    enableRuleSubscription: false, // to show/hide rule subscription switch on alerts page
    component: (ruleId, ruleConditionsList, locationDataList, [], onLoad) => {
      return () => {
        return (
          <BacklogRuleContent
            ruleId={ruleId}
            ruleConditionsList={ruleConditionsList}
            locationDataList={locationDataList}
            onLoad={onLoad}
          />
        );
      };
    }
  },
  'Low Stock': {
    eventId: 'detection_event',
    notification_template: {
      email: lowStockRuleTemplateEmail,
      sms: lowStockRuleTemplate,
      alert: lowStockRuleTemplate
    },
    enableRuleSubscription: true, // to show/hide rule subscription switch on alerts page
    component: (ruleId, ruleConditionsList, locationDataList, itemTypeData, onLoad) => {
      return () => {
        return (
          <LowStockRuleContent
            ruleId={ruleId}
            ruleConditionsList={ruleConditionsList}
            locationDataList={locationDataList}
            itemTypeData={itemTypeData}
            onLoad={onLoad}
          />
        );
      };
    }
  },
  'Missing Asset': {
    eventId: 'detection_event',
    notification_template: {
      email: missingRuleEmailTemplate,
      sms: missingRuleTemplate,
      alert: missingRuleTemplate
    },
    enableRuleSubscription: true,
    component: ruleId => {
      return () => {
        return <MissingAssetRuleContent ruleId={ruleId} />;
      };
    }
  },
  Idle: {
    eventId: 'idle_event',
    notification_template: {
      email: idleRuleEmailTemplate,
      sms: idleRuleTemplate,
      alert: idleRuleTemplate
    },
    enableRuleSubscription: false, // to show/hide rule subscription switch on alerts page
    component: (ruleId, ruleConditionsList, locationDataList, [], onLoad) => {
      return () => (
        <IdleRuleContent
          ruleId={ruleId}
          ruleConditionsList={ruleConditionsList}
          locationDataList={locationDataList}
          onLoad={onLoad}
        />
      );
    }
  }
  // Overstock: {
  //   eventId: 'track-object-type-count-updated',
  //   notification_template: {
  //     email: OverstockRuleTemplateEmail,
  //     sms: OverstockRuleTemplate,
  //     alert: OverstockRuleTemplate
  //   },
  //   component: ruleId => {
  //     return () => <OverstockRuleContent ruleId={ruleId} />;
  //   }
  // },
  // 'Arrived At': {
  //   eventId: 'analyzed-tracker-event-historical-count-by-department',
  //   notification_template: {
  //     email: orderArrivedAtRuleTemplate,
  //     sms: orderArrivedAtRuleTemplate,
  //     alert: orderArrivedAtRuleTemplate
  //   },
  //   component: ruleId => {
  //     return () => <ArrivedAtRuleContent ruleId={ruleId} />;
  //   }
  // },
  // Expedited: {
  //   eventId: 'tracked-object-expedited',
  //   notification_template: {
  //     email: expeditedRuleTemplate,
  //     sms: expeditedRuleTemplate,
  //     alert: expeditedRuleTemplate
  //   },
  //   component: () => {
  //     return () => <ExpeditedRuleContent />;
  //   },
  //   conditions: [
  //     {
  //       rule_condition_name: 'expedite',
  //       tags: {
  //         target: 'tracked_object'
  //       }
  //     }
  //   ]
  // }
};

class AlertService {
  getNotificationRecipients = async () => {
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    const response = await RulePageClient.getSubscriptionProfile();
    return response;
  };

  updateNotificationSubscription = async payload => {
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    const result = await RulePageClient.updateSubscriptionProfile(
      payload.email,
      payload.phoneNumber
    );
    return result;
  };

  fetchRules = async () => {
    const ruleNotificationMap = {};
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    const trackingConfig = await ConfigurationService.getTrackingConfiguration();
    const locationCategory =
      trackingConfig.possibleDetectorLocations &&
      trackingConfig.possibleDetectorLocations.length > 0
        ? trackingConfig.possibleDetectorLocations[0]
        : 'Department';

    let result = await RulePageClient.fetchRules(locationCategory);

    // get list of rules that apply to the customer from customer configuration
    const customerRules = await ConfigurationService.getCustomerRules();

    const uniqueItemTypes = [];
    const uniqueItemTypeMap = {};

    // if customer has Low Stock in their rules, get all item types
    if (customerRules.indexOf('Low Stock') !== -1) {
      const itemTypeClient = XemelgoService.getClient().getItemTypeClient();
      const itemTypes = await itemTypeClient.listItemTypes();

      itemTypes.forEach(itemType => {
        const itemTypeObject = {};
        const itemTypeIdentifier = itemType.getIdentifier();
        const itemTypeId = itemType.getId();

        // get only unique item types since duplicates are allowed in the backend
        if (!uniqueItemTypeMap[itemTypeIdentifier]) {
          uniqueItemTypeMap[itemTypeIdentifier] = itemTypeId;
          itemTypeObject.objectTypeId = itemTypeId;
          itemTypeObject.objectTypeName = itemTypeIdentifier;
          uniqueItemTypes.push(itemTypeObject);
        }
      });
    }

    // get user's subscription profile, create if doesn't already exist
    const profile = await RulePageClient.getSubscriptionProfile();
    if (!profile) {
      const sessionInfo = await AuthService.getSessionInfo();
      await RulePageClient.createSubscriptionProfile(
        [sessionInfo.attributes.email],
        [sessionInfo.attributes.phone_number]
      );
    }
    let fetchAgain = false;
    for (const ruleName of customerRules) {
      const rule = result.rules.find(a => a.name === ruleName);
      if (!rule) {
        fetchAgain = true;
        if (ruleName === 'Backlog') {
          const ruleId = await RulePageClient.createRule(
            'Backlog',
            'detection_event',
            ruleInfoMap.Backlog.notification_template.email,
            ruleInfoMap.Backlog.notification_template.sms
          );
          const ruleConditionId = [];
          ruleConditionId.push(
            await RulePageClient.createRuleCondition(
              'backlog_allLocations_warning',
              { warningThreshold: 50, criticalThreshold: 80, stage: 'warning', threshold: 50 },
              [
                { property: 'backlogCount', op: '<=', value: 80 },
                { property: 'backlogCount', op: '>', value: 50 }
              ],
              ruleId
            )
          );
          ruleConditionId.push(
            await RulePageClient.createRuleCondition(
              'backlog_allLocations_critical',
              { criticalThreshold: 80, stage: 'critical', threshold: 80 },
              [{ property: 'backlogCount', op: '>', value: 80 }],
              ruleId
            )
          );
          await ruleConditionId.forEach(async each => {
            await RulePageClient.subscribeForRuleCondition(each);
          });
        } else if (ruleName === 'Idle') {
          const ruleId = await RulePageClient.createRule(
            'Idle',
            'idle_event',
            ruleInfoMap.Idle.notification_template.email,
            ruleInfoMap.Idle.notification_template.sms
          );
          const ruleConditionId = await RulePageClient.createRuleCondition(
            'idle_allLocations',
            { duration: 4 },
            [{ property: 'duration', op: '>=', value: 4 }],
            ruleId
          );
          await RulePageClient.subscribeForRuleCondition(ruleConditionId);
          const RestClient = XemelgoService.getClient().getRestClient();
          await RestClient.post('/subscribeIdleSNS');
        } else if (ruleName === 'Low Stock') {
          await RulePageClient.createRule(
            'Low Stock',
            'detection_event',
            ruleInfoMap['Low Stock'].notification_template.email,
            ruleInfoMap['Low Stock'].notification_template.sms
          );
        } else if (ruleName === 'Missing Asset') {
          const ruleId = await RulePageClient.createRule(
            'Missing Asset',
            'detection_event',
            ruleInfoMap['Missing Asset'].notification_template.email,
            ruleInfoMap['Missing Asset'].notification_template.sms
          );

          const ruleConditionId = [];
          ruleConditionId.push(
            await RulePageClient.createRuleCondition(
              'asset_missing',
              {},
              [{ property: 'state', op: '=', value: 'missing' }],
              ruleId
            )
          );
          ruleConditionId.push(
            await RulePageClient.createRuleCondition(
              'asset_found',
              {},
              [{ property: 'state', op: '=', value: 'found' }],
              ruleId
            )
          );
          await ruleConditionId.forEach(async each => {
            await RulePageClient.subscribeForRuleCondition(each);
          });
        }
      }
    }

    if (fetchAgain) {
      result = await RulePageClient.fetchRules(locationCategory);
    }

    // get rid of all other rules that aren't part of customerRules
    const rulesofInterest = result.rules.filter(rule => customerRules.indexOf(rule.name) !== -1);
    // need to create rule doesn't exist
    rulesofInterest.forEach(rule => {
      ruleNotificationMap[rule.name] = {
        ruleId: rule.id,
        enabled: rule.hasSubscriptions,
        notification_template: rule.notification_template,
        ruleConditions: rule.ruleConditions,
        eventId: rule.eventId
      };
    });

    const notificationSubscriptionFeatureEnabled = true;
    return {
      locationDataList: result.locations,
      itemTypeData: uniqueItemTypes,
      ruleNotificationMap,
      notificationFeatureEnabled: notificationSubscriptionFeatureEnabled
    };
  };
}

export default new AlertService();
