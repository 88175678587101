import React, { Component } from 'react';
import { Card, CardBody } from 'mdbreact';
import Style from './summaryTile.module.css';

export default class SummaryTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tileData: this.props.data,    //{ title: 'Total Requests', totalOrders: totalCount, status: 'Total' }
      filterEnabled: this.props.filterEnabled,
      visible: this.props.visible
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      tileData: newProps.data,
      filterEnabled: newProps.filterEnabled,
      visible: newProps.visible
    });
  }

  renderRightColumnContent(tileData) {
    if (tileData.length < 1) return null;

    const rightColumnContent = tileData.map(data => {
      if (data.count > 0) {
        return (
          <div className={Style.right_column_content}>
            <p className={Style.status_count}>{data.count}</p>
            <p className={`${Style.status_name} ${Style[`${data.type}_text`]}`}>{data.text}</p>
          </div>
        );
      }
      return null;
    });

    return rightColumnContent;
  }

  handleClick() {
    // to handle filter view
    // const tileNameClicked = this.state.tileData.name;
    const tileNameClicked = this.state.tileData.id;
    this.props.callbackFn(tileNameClicked,"summary");
  }

  render() {
    const { tileData, filterEnabled, visible } = this.state;
    const color = tileData.color;
    if (visible) {
      return (
        <Card
          title="Click to view orders in this department."
          className={`${Style.summary_tile} ${Style[`summary_tile_border_${color}`]} ${
            filterEnabled ? null : Style.filter_disabled
          }`}
          onClick={() => this.handleClick()}
        >
          <CardBody className={Style.summary_tile_body}>
            <div
              className={`${Style.summary_tile_title} ${Style[`summary_tile_background_${color}`]}`}
            >
              {tileData.title}
            </div>
            <div className={Style.summary_tile_content}>
              <div className={Style.summary_tile_left_column}>
                <p className={Style.left_column_number}>{tileData.totalOrders}</p>
                <p className={Style.left_column_text}> Total Orders </p>
              </div>
              <div className={Style.summary_tile_right_column}>
                {this.renderRightColumnContent(tileData.data)}
              </div>
            </div>
          </CardBody>
        </Card>
      );
    }
    return null;
  }
}
