import React from 'react';
import PropTypes from 'prop-types';
import Overview from '../TrackPageComponents/Overview';
import DepartmentsOrdersGraph from '../../pages/report/DepartmentsOrdersGraph';
import OrderOverviewComponentStyle from './OrderOverviewComponent.module.css';

const HealthColor = {
  Healthy: '#00B200',
  Warning: '#FFCF0F',
  Critical: '#F62227'
};

const OrderOverviewComponent = ({
  logoImage,
  graphDataList,
  health,
  numOfOrders,
  numOfExpedited,
  numOfOnTime,
  numOfDelayed,
  averageVelocity
}) => {
  const LeftComponent = () => {
    if (graphDataList.length) {
      return (
        <div className={OrderOverviewComponentStyle.backlog_container}>
          <div className={OrderOverviewComponentStyle.backlog_title}>Department Backlog</div>
          <DepartmentsOrdersGraph departmentOrders={graphDataList} />
        </div>
      );
    }
    return (
      <img className={OrderOverviewComponentStyle.image} src={logoImage} alt="failed to load img" />
    );
  };
  const RightComponent = () => {
    return (
      <div className={OrderOverviewComponentStyle.right_container}>
        <p className={OrderOverviewComponentStyle.header_style}>Overview</p>
        <div className={OrderOverviewComponentStyle.group}>
          <p className={OrderOverviewComponentStyle.label_group}>
            Plant Health
            <br />
            <b className={OrderOverviewComponentStyle.value} style={{ color: HealthColor[health] }}>
              {health}
            </b>
          </p>
          <p className={OrderOverviewComponentStyle.label_group}>
            Total No. of Orders
            <br />
            <b className={OrderOverviewComponentStyle.value}>{numOfOrders}</b>
          </p>
          <p className={OrderOverviewComponentStyle.label_group}>
            No. of Expedited Orders
            <br />
            <b className={OrderOverviewComponentStyle.value}>{numOfExpedited}</b>
          </p>
        </div>
        <p className={OrderOverviewComponentStyle.header_style}>Weekly Metrics</p>
        <div className={OrderOverviewComponentStyle.group}>
          <p className={OrderOverviewComponentStyle.label_group}>
            Avg. Velocity of Orders
            <br />
            <b className={OrderOverviewComponentStyle.value}>{averageVelocity}</b>
          </p>
          <p className={OrderOverviewComponentStyle.label_group}>
            Orders On-Time
            <br />
            <b className={OrderOverviewComponentStyle.value}>{numOfOnTime}</b>
          </p>
          <p className={OrderOverviewComponentStyle.label_group}>
            Orders Delayed
            <br />
            <b className={OrderOverviewComponentStyle.value}>{numOfDelayed}</b>
          </p>
        </div>
      </div>
    );
  };
  return <Overview leftComponent={<LeftComponent />} rightComponent={<RightComponent />} />;
};

export default OrderOverviewComponent;

OrderOverviewComponent.defaultProps = {
  logoImage: null,
  graphDataList: [],
  health: '',
  numOfOrders: null,
  numOfExpedited: null,
  numOfOnTime: null,
  numOfDelayed: null,
  averageVelocity: ''
};

OrderOverviewComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  logoImage: PropTypes.string,
  graphDataList: PropTypes.arrayOf(PropTypes.object),
  health: PropTypes.string,
  numOfOrders: PropTypes.number,
  numOfExpedited: PropTypes.number,
  numOfOnTime: PropTypes.number,
  numOfDelayed: PropTypes.number,
  averageVelocity: PropTypes.string
};
