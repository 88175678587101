class LocationStat {
  constructor(statName, analyzeFn) {
    this.statName = statName;
    this.analyzeFn = analyzeFn;
  }

  analyze(data) {
    this.value = this.analyzeFn(data);
  }

  getStatName() {
    return this.statName;
  }

  getValue() {
    return this.value;
  }
}

class IsMissingStat extends LocationStat {
  constructor() {
    super('missing', (trackingSessionEvents) => {
      if (!trackingSessionEvents) {
        return 0;
      }

      if (!Array.isArray(trackingSessionEvents)) {
        return 0;
      }

      const missingItems = trackingSessionEvents.filter((trackingSessionEvent) => {
        const { partOfTrackingSession: trackingSession } = trackingSessionEvent;
        const { is_missing: isMissing } = trackingSession[0];
        return isMissing;
      });

      return missingItems.length;
    });
  }
}

class IsExpiredStat extends LocationStat {
  constructor() {
    super('expired', (trackingSessionEvents) => {
      if (!trackingSessionEvents) {
        return 0;
      }

      if (!Array.isArray(trackingSessionEvents)) {
        return 0;
      }

      const now = Date.now();

      const expiredItems = trackingSessionEvents.map((trackingSessionEvent) => {
        const { partOfTrackingSession: trackingSession } = trackingSessionEvent;
        const { tracksItem: item } = trackingSession[0];
        const { expiry_date: expiredDate } = item;
        if (!expiredDate) {
          return false;
        }

        return expiredDate < now;
      });

      return expiredItems.length;
    });
  }
}

class TotalAssetStat extends LocationStat {
  constructor() {
    super('total', (trackingSessionEvents) => {
      if (!trackingSessionEvents) {
        return 0;
      }

      if (!Array.isArray(trackingSessionEvents)) {
        return 0;
      }

      const trackItems = trackingSessionEvents.map((trackingSessionEvent) => {
        const { partOfTrackingSession: trackingSession } = trackingSessionEvent;
        const { tracksItem: items } = trackingSession[0];
        return items[0];
      });

      return trackItems.length;
    });
  }
}

class AnalyzeTrackingSessionEventByTypeStat extends LocationStat {
  constructor() {
    super('itemTypes', (trackingSessionEvents) => {
      if (!trackingSessionEvents) {
        return {};
      }

      if (!Array.isArray(trackingSessionEvents)) {
        return {};
      }

      const itemTypeMap = {};
      trackingSessionEvents.forEach((trackingSessionEvent) => {
        const { partOfTrackingSession: trackingSession } = trackingSessionEvent;
        const { tracksItem: items } = trackingSession[0];
        const item = items[0];

        const { class: itemClass } = item;
        const specificItemTypeEvents = itemTypeMap[itemClass] || [];

        specificItemTypeEvents.push(trackingSessionEvent);
        itemTypeMap[itemClass] = specificItemTypeEvents;
      });

      return itemTypeMap;
    });
  }
}

const maskUnwantedStatistic = (statsInfo, includes) => {
  const { type, stats } = statsInfo;
  if (!includes || includes.length === 0) {
    return { type, stats };
  }

  const statsMap = stats.reduce((map, stat) => {
    const newMap = { ...map };
    const { name } = stat;

    newMap[name] = stat;
    return newMap;
  }, {});

  const newStats = [];
  includes.forEach((statsName) => {
    newStats.push(statsMap[statsName]);
  });

  return { type, stats: newStats };
};

export class LocationStats {
  constructor(locationData) {
    this.locationData = locationData;
    const analyticClasses = [IsMissingStat, IsExpiredStat, TotalAssetStat];

    const { associatedWithEvent } = locationData;
    const eventTypeAnalyzer = new AnalyzeTrackingSessionEventByTypeStat();
    eventTypeAnalyzer.analyze(associatedWithEvent);
    const eventsByItemType = eventTypeAnalyzer.getValue();

    const analyticByItemTypeMap = {};
    Object.keys(eventsByItemType).forEach((itemType) => {
      const trackingSessionEvents = eventsByItemType[itemType];
      const stats = analyticClasses.map((AnalyticClass) => {
        const instance = new AnalyticClass();
        instance.analyze(trackingSessionEvents);

        return {
          name: instance.getStatName(),
          value: instance.getValue()
        };
      });

      analyticByItemTypeMap[itemType] = {
        stats,
        type: itemType
      };
    });

    this.analyticByItemType = analyticByItemTypeMap;
    this.eventsByItemType = eventsByItemType;
  }

  static parse(locationDataList) {
    const domains = locationDataList.map((locationData) => {
      return new LocationStats(locationData);
    });
    return domains;
  }

  getLocationData() {
    return this.locationData;
  }

  getStats(itemTypes, statNames = null) {
    const results = [];
    const analyticByItemType = { ...this.analyticByItemType };
    itemTypes.forEach((itemType) => {
      const stats = analyticByItemType[itemType];
      if (stats) {
        const statsInfo = maskUnwantedStatistic(stats, statNames);
        results.push(statsInfo);
      }
    });
    return results;
  }

  getEventCount(itemTypes) {
    let total = 0;
    const eventsByItemType = { ...this.eventsByItemType };
    itemTypes.forEach((itemType) => {
      const events = eventsByItemType[itemType] || [];
      total += events.length;
    });

    return total;
  }
}
