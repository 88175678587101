import React from 'react';
import PropTypes from 'prop-types';
import GridViewStyle from './GridViewComponent.module.css';

const GridViewComponent = ({ children }) => {
  return <div className={GridViewStyle.container}>{children}</div>;
};

export default GridViewComponent;

GridViewComponent.defaultProps = {
  children: null
};

GridViewComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element)
};
