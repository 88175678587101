import { v4 as uuidV4 } from 'uuid';
import { AbstractMockPlanExecutor } from './abstract-mock-plan-executor';

const SystemSchemaFields = ['total'];

/**
 * @param fields {string[]}
 */
const removeSchemaSystemFields = (fields) => {
  return fields.filter((field) => {
    const foundIndex = SystemSchemaFields.indexOf(field);
    return foundIndex === -1;
  });
};

/**
 * @param fields {string[]}
 * @param propertyMap
 */
const generateTrackItem = (fields, propertyMap) => {
  const newPropertyMap = fields.reduce((map, field) => {
    const cloned = { ...map };
    cloned[field] = propertyMap[field];
    return cloned;
  }, {});

  return {
    ...newPropertyMap,
    id: uuidV4(),
    identifier: 'Angle Grinder A',
    hasSensorProfile: [
      {
        id: uuidV4(),
        vid: '201902013001',
        class: 'RFID Tag'
      }
    ],
    represents: [
      {
        id: uuidV4(),
        category: 'Work Order',
        identifier: '125555',
        due_date: 1581465660000,
        status_flags: [],
        hasOutput: [
          {
            quantity: 1,
            ofType: [
              {
                identifier: uuidV4(),
                name: ''
              }
            ]
          }
        ]
      }
    ]
  };
};

const generateAssociatedEvent = (fields, propertyMap) => {
  return {
    id: uuidV4(),
    partOfTrackingSession: [
      {
        id: uuidV4(),
        tracksItem: [generateTrackItem(fields, propertyMap)]
      }
    ]
  };
};

export class AssociatedWithEventMockPlanExecutor extends AbstractMockPlanExecutor {
  // eslint-disable-next-line no-useless-constructor
  constructor(schema) {
    super(schema);
  }

  static execute(schema) {
    const executor = new AssociatedWithEventMockPlanExecutor(schema);
    return executor.generateData();
  }

  generateData() {
    if (!this.schema) {
      return [];
    }

    if (!Array.isArray(this.schema)) {
      return [];
    }

    const schema = [...this.schema];
    const events = [];
    schema.forEach((descriptionMap) => {
      const { total = 1 } = descriptionMap;
      const fields = removeSchemaSystemFields(Object.keys(descriptionMap));

      // remove the total field if exist
      for (let i = 0; i < total; i++) {
        const event = generateAssociatedEvent(fields, descriptionMap);
        events.push(event);
      }
    });

    return events;
  }
}
