import { AbstractResourceManager } from '../../abstract-resource-manager';
import { ResourceManagerLoader } from '../resource-manager-loader';
import { locationSchemaMap } from './location-resource-manager';

export const detectorSchemaMap = {
  incomingDetector: {
    vendor: 'Brady',
    class: 'Handheld',
    name: 'F8F00123456A',
    vid: 'F8F00123456A',
    label: 'Detector_mockDemo',
    protocol: 'MQTT',
    locatedAt: [locationSchemaMap.incomingDepartment]
  },
  gpDepartmentDetector: {
    vendor: 'Brady',
    class: 'Handheld',
    name: 'F8F00123456B',
    vid: 'F8F00123456B',
    label: 'Detector_mockDemo',
    protocol: 'MQTT',
    locatedAt: [locationSchemaMap.gPDepartment]
  },
  qualityInspectionDetector: {
    vendor: 'Brady',
    class: 'Handheld',
    name: 'F8F00123456C',
    vid: 'F8F00123456C',
    label: 'Detector_mockDemo',
    protocol: 'MQTT',
    locatedAt: [locationSchemaMap.qualityInspectionDepartment]
  },
  weldingDetector: {
    id: '103',
    vendor: 'Brady',
    class: 'Handheld',
    name: 'F8F00123456D',
    vid: 'F8F00123456D',
    label: 'Detector_mockDemo',
    protocol: 'MQTT',
    locatedAt: [locationSchemaMap.weldingDepartment]
  },
  kentDetector: {
    id: '104',
    vendor: 'Brady',
    class: 'Handheld',
    name: 'F8F00123456E',
    vid: 'F8F00123456E',
    label: 'Detector_mockDemo',
    protocol: 'MQTT',
    locatedAt: [locationSchemaMap.kentWarehouse]
  },
  auburnDetector: {
    id: '105',
    vendor: 'Brady',
    class: 'Handheld',
    name: 'F8F00123456F',
    vid: 'F8F00123456F',
    label: 'Detector_mockDemo',
    protocol: 'MQTT',
    locatedAt: [locationSchemaMap.auburnWarehouse]
  }
};

const LocationConnectionFields = ['locatedAt'];
const init = (addItemFn) => {
  Object.values(detectorSchemaMap).forEach((element) => {
    addItemFn(element);
  });
};

// eslint-disable-next-line import/prefer-default-export
export class DetectorResourceManager extends AbstractResourceManager {
  constructor() {
    super('id');
    init(this.addItem.bind(this));
    const locationRM = ResourceManagerLoader.get('location');
    this.locationRM = locationRM;
  }

  supportMock(api, action, payload, headers) {
    if (api === 'query') {
      return true;
    }
    return super.supportMock(api, payload, headers);
  }

  createDetector(payload) {
    const { fields } = payload;
    const created = super.addItem(fields);
    return created;
  }

  attach(payload) {
    const { from, to, connection } = payload;
    const location = this.locationRM.get(to);
    const detector = super.get(from);
    detector[connection] = location.id;
    super.update(detector.id, detector);
    return this.get(detector.id);
  }

  get(id) {
    const detector = super.get(id);
    return this.transform(detector);
  }

  list() {
    const detectors = super.list();
    const transformFn = this.transform.bind(this);
    const transformed = detectors.map(transformFn);

    return transformed;
  }

  retire(payload) {
    const { id } = payload;
    const removed = this.get(id);
    super.remove(id);
    return removed;
  }

  filter(fieldName, value) {
    const detectors = super.filter(fieldName, value);
    const transformFn = this.transform.bind(this);
    const transformed = detectors.map(transformFn);

    return transformed;
  }

  transform(detector) {
    const cloned = { ...detector };
    LocationConnectionFields.forEach((connectionName) => {
      const refId = cloned[connectionName];
      if (!refId || typeof refId !== 'string') {
        return;
      }

      const refLocation = this.locationRM.get(refId);
      cloned[connectionName] = [refLocation];
    });
    return cloned;
  }
}

const resourceManager = new DetectorResourceManager();
export { resourceManager };
