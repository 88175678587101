import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LocationStats } from '../../domains/location-stats';
import './style.css';
import { LocationTooltip } from '../location-tooltip';
import { LocationFloorPlan } from '../../../location-floor-plan';
import { BubblePinHeadWithTooltip } from '../../../../components/interactable-pin';
import { useXemelgoClient } from '../../../../services/xemelgo-service';
import { Div } from '../../../../components/div';

// Constant
const BubbleSizeThreshold = 30;
/**
 * Configuration for this feature. The default configuration is provided in PropTypes.defaultProps
 * @typedef { object } FeatureConfiguration
 * @property { string[] } itemTypes - inspect the item statistics for the given item types.
 * @property { string[] } itemStats - inspect the item statistics for given statistics keywords.
 * @property { string } pinnedLocationCategoryName - the category name of the pinned location. Only one category is allowed.
 */

/**
 * This feature leverage the Location Floor Plan feature, with an additional distinctive features which are to
 *  provide location statistics in a form of tooltip when hovering over the location.
 * @param className { string } - given styling class for the floor plan component.
 * @param location { object } - location object
 * @param imageUrl { string } - floor plan image for given location
 * @param configuration { FeatureConfiguration }
 * @returns {*}
 */
export const LocationFloorMapWithTooltip = ({ className, location, imageUrl, configuration }) => {
  const [locationStatsMap, registerLocationStatsMap] = useState({});
  const [client] = useState(useXemelgoClient());
  const [dataLoaded, setDataLoaded] = useState(false);

  /**
   *
   */
  useEffect(() => {
    const locationClient = client.getLocationClient();
    const { pinnedLocationCategoryName } = configuration;
    locationClient.getLocationsOfCategory(pinnedLocationCategoryName).then((response) => {
      const locations = response.map((loc) => loc.getResponseData());
      const stats = LocationStats.parse(locations);

      const statsMap = stats.reduce((map, stat) => {
        const clonedMap = { ...map };
        const loc = stat.getLocationData();
        const { id: locId } = loc;
        clonedMap[locId] = stat;
        return clonedMap;
      }, {});

      registerLocationStatsMap(statsMap);
      setDataLoaded(true);
    });
  }, [client, location, configuration]);

  const buildPinFn = useCallback(
    (id, name, color, loc) => {
      const statObj = locationStatsMap[id];
      const { itemTypes, itemStats } = configuration;
      const locStats = statObj.getStats(itemTypes, itemStats);
      const tooltip = <LocationTooltip key={id} location={loc} resourceInfoList={locStats} />;

      const value = statObj.getEventCount(itemTypes);
      const scale =
        parseInt(value / BubbleSizeThreshold + (value % BubbleSizeThreshold) * 0.1, 10) * 0.3 + 1;
      const pin = (
        <BubblePinHeadWithTooltip
          name={value.toString()}
          color={color}
          tooltip={tooltip}
          bubbleScale={scale}
        />
      );

      return pin;
    },
    [locationStatsMap, configuration]
  );

  if (dataLoaded) {
    return (
      <LocationFloorPlan
        location={location}
        imageUrl={imageUrl}
        className={className}
        buildPinFn={buildPinFn}
        pinType="badge"
      />
    );
  }

  return <Div />;
};

LocationFloorMapWithTooltip.defaultProps = {
  className: '',
  configuration: {
    itemTypes: ['Asset'],
    itemStats: ['total', 'missing', 'expired'],
    pinnedLocationCategoryName: 'Department'
  }
};

LocationFloorMapWithTooltip.propTypes = {
  configuration: PropTypes.shape({
    itemTypes: PropTypes.arrayOf(PropTypes.string),
    itemStats: PropTypes.arrayOf(PropTypes.string),
    pinnedLocationCategoryName: PropTypes.string
  }),
  location: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  imageUrl: PropTypes.string.isRequired,
  className: PropTypes.string
};
